import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import MessagesComponent from '../Messages/messageComponent';

// Create a new context for the app
export const AppContext = React.createContext();

export function useAppContext() {
  const ctx = useContext(AppContext);

  if (ctx === undefined) {
    throw new Error('useAppContext cannot be used outside of AppProvider');
  }

  return ctx;
}

// Creates a provider Component
function AppProvider(props) {
  const [openMessageDrawer, setOpenMessageDrawer] = useState(null);
  const [openMessagesInbox, setOpenMessagesInbox] = useState(null);

  const onCloseMessagesDrawer = () => {
    setOpenMessageDrawer(null);
  };

  const onOpenMessagesDrawer = params => {
    setOpenMessageDrawer({
      ...params,
    });
  };

  const isMessageDrawerOpened = () => {
    return !!openMessageDrawer;
  };

  return (
    <AppContext.Provider
      value={{
        onCloseMessagesDrawer,
        onOpenMessagesDrawer,
        isMessageDrawerOpened,
      }}
    >
      {props.children}
      {!!openMessageDrawer && <MessagesComponent open={isMessageDrawerOpened()} onClose={() => onCloseMessagesDrawer()} {...openMessageDrawer} />}
    </AppContext.Provider>
  );
}

export default withRouter(AppProvider);
