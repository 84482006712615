import gql from 'graphql-tag';

export default gql`
    query FetchEventsByCharityId($charity_id: ID!, $exclude_past_events: Boolean) {
        eventsByCharityID(charity_id: $charity_id, exclude_past_events: $exclude_past_events) {
            _id
            name
            picture
            config {
                start
                frequency
                end
                volunteers_needed
            }
            location {
                city
                state
            }
            charity {
                _id
                name
                picture
                charity_users {
                    user_id
                }
            }
            confirmed_users {
                user_id
            }
            EventReviews {
                _id
            }
            event_causes {
                Cause {
                    _id
                    name
                }
            }
        }
    }
`;
