import gql from 'graphql-tag';

export default gql`
    query FetchEventById($id: ID!) {
        eventByID(id: $id) {
            _id
            name
            picture
            charity {
                _id
                name
                picture
                charity_users {
                    user_id
                }
                Events {
                    _id
                    name
                    picture
                    charity {
                        _id
                        name
                        picture
                        charity_users {
                            user_id
                        }
                    }
                    event_causes {
                        Cause {
                            _id
                            name
                        }
                    }
                    type
                    config {
                        start
                        end
                        frequency
                        volunteers_needed
                    }
                    location {
                        city
                        state
                    }
                    confirmed_users {
                        user_id
                    }
                    EventReviews {
                        _id
                    }
                }
            }
            description
            type
            config {
                volunteers_needed
                start
                end
                skills_needed
                frequency
            }
            location {
                location
                street
                city
                state
                postcode
                latitude
                longitude
            }
            testimonials {
                description
                author
            }
            faqs {
                question
                answer
            }
            confirmed_users {
                user_id
                User {
                    _id
                    firstname
                    lastname
                    picture
                }
            }
            event_causes {
                cause_id
                Cause {
                    _id
                    name
                }
            }
            EventReviews {
                _id
                user_id
                hours
                rating
                message
            }
        }
    }
`;
