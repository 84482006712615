import React, { useState } from 'react';
import { OnboardingContainer, StepIndicator } from '../../common';

// External Containers
import DetailsContainer from './viewDetails';
import ContactsContainer from './viewContacts';
import ReviewContainer from './viewReview';

// Styles
import './styles/createCharityStyles.css';

const CreateCharity = ({ match }) => {
    const { step } = match.params;
    const [error, setError] = useState('');

    const triggerError = (errorMessage) => {
        setError(errorMessage);
    };

    const getStepHeading = () => {
        switch (step) {
            case 'details':
                return (
                    <>
                        <h1>Register your charity</h1>
                        <p>The charity must be IRS registered to continue.</p>
                    </>
                );
            case 'contacts':
                return (
                    <>
                        <h1>Contact details</h1>
                        <p>Please fill in the contact details of the charity.</p>
                    </>
                );
            case 'review':
                return (
                    <>
                        <h1>Review and submit</h1>
                        <p>Take a minute to review your charity details.</p>
                    </>
                );
            default:
                return (
                    <>
                        <h1>Register your charity</h1>
                        <p>The charity must be IRS registered to continue.</p>
                    </>
                );
        }
    };

    const steps = [{
        name: 'details',
        label: '1. Charity Details'
      }, {
        name: 'contacts',
        label: '2. Contact Details',
      }, {
        name: 'review',
        label: '3. Review',
      }];

    return (
        <div className="createCharityContainer col-xs-12">
            <OnboardingContainer>
                {error && (
                    <div className="errorMessage">
                        <p className="error">{error}</p>
                    </div>
                )}
                <StepIndicator steps={steps} currentActive={step} />
                {getStepHeading()}
                {step === 'details' &&
                    <DetailsContainer
                        triggerError={triggerError}
                    />
                }
                {step === 'contacts' &&
                    <ContactsContainer
                        triggerError={triggerError}
                    />
                }
                {step === 'review' &&
                    <ReviewContainer
                        triggerError={triggerError}
                    />
                }
            </OnboardingContainer>
        </div>
    );
};

export default CreateCharity;
