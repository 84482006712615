import moment from 'moment';
import _ from 'lodash';
import { localStorage } from './storage';

const eventUtils = {
    isEventAdmin(event) {
        // Function to test if the user connect is admin on the charity that created the event
        const connectedUser = localStorage.get('userID');
        if (event.charity.charity_users.find(item => item.user_id === connectedUser)) return true;
        return false;
    },
    getFrequencyLabel(frequency) {
        switch (frequency) {
            case 1:
                return 'One-time';
            case 2:
                return 'Weekly';
            case 3:
                return 'Monthly';
            default:
                return 'One-time';
        }
    },
    shouldReviewEvent(event) {
        return moment(_.get(event, 'config.end')).isBefore() && _.size(event.EventReviews) === 0 && _.size(event.confirmed_users) > 0;
    },
    isFull(event) {
        let isFull = false;
        if (event.config.volunteers_needed > 0) {
            const qtdConfirmedUsers = _.size(_.get(event, 'confirmed_users'));
            if (qtdConfirmedUsers >= event.config.volunteers_needed) isFull = true;
        }
        return isFull;
    },
    isPast(event) {
        return moment(event.config.end).isBefore();
    },
    getNumberOfVolunteersLabel(qtd) {
        // If number of volunters is 0, it means the client has no limitation on applications
        if (qtd === 0) return 'unlimited';
        return qtd;
    },
    isOpenEvent(event) {
        // An event is considered opened when there's no specific skills needed
        return _.size(event.config.skills_needed) === 0;
    },
    hasUserAttendedEvent(event, user_id) {
        // Function to check whether the user has attended an event
        if (event.confirmed_users.find(item => item.user_id === user_id)) return true;
        return false;
    },
    hasUserBeenReviewed(event, user_id) {
        // Function to check whether an user has received a review for an specific event
        if (event.EventReviews.find(item => item.user_id === user_id)) return true;
        return false;
    }
};

export { eventUtils };
