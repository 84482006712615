import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Button } from '../../common';

// Styles
import './styles/finishCharityStyles.css';

const CreateCharityFinish = ({ history, location }) => (
  <div className="finishContainer flexCenter">
    <div className="finishContainerInternal">
      {_.has(location, 'state.charity.picture') && <div className="imgCharity" style={{ backgroundImage: `url(${location.state.charity.picture})` }} />}
      <h1 className="finishCharityHeader">
        Thank you <span>{location.state.charity.name}</span> your submitting your application!
      </h1>
      <div className="viewContent">
        <p>
          Veryfing your application can take up to 48 hours. <br /> You will receive an email once verified.
        </p>
      </div>
      <div className="divider" />
      <div className="buttonsContainer">
        <h5>Create your first task while you wait!</h5>
        <p>You can start creating your first task to get ahead.</p>
        <Button label="Create" onClick={() => history.replace('/task/create/overview')} className="btnDefault btnBlueSolid" />
      </div>
    </div>
  </div>
);

export default withRouter(CreateCharityFinish);
