import React from 'react';
import Dialog from 'material-ui/Dialog';

const Modal = (props) => {
    return (
        <Dialog
            title={props.title}
            actions={props.actions && props.actions}
            modal={props.modal ? props.modal : false}
            open={props.open}
            onRequestClose={props.onClose}
            contentClassName={props.className && props.className}
            bodyClassName={props.bodyClassName && props.bodyClassName}
            autoScrollBodyContent={props.autoScroll && props.autoScroll}
        >
            {props.children}
        </Dialog>
    );
};

export default Modal;
