import gql from 'graphql-tag';

export default gql`
  mutation updateUser($id: ID!, $user: UserInput!) {
    updateUser(id: $id, user: $user) {
      _id
      about
      Causes {
        _id
        name
      }
    }
  }
`;
