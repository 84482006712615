import { useMutation, useQuery } from '@apollo/client';
import { Counter, Input } from 'components/common';
import { FlatButton } from 'components/common/FlatButton/view';
import { ModalBase } from 'components/common/ModalBase/view';
import createTaskReviewMutation from 'mutations/task/createTaskReview';
import fetchSkills from 'queries/skills/fetchSkills';
import fetchUserById from 'queries/user/fetchUserById';
import React, { useState } from 'react';
import Select from 'react-select';
import './styles.scss';

export const AssignCreditModal = ({ onClose, userId, charityId }) => {
  const [name, setName] = useState('');
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedMacro, setMacro] = useState();
  const [createTaskReview] = useMutation(createTaskReviewMutation);
  const { data } = useQuery(fetchSkills);
  const totalMinutes = hours * 60 + minutes;
  if (!data) return null;
  const { skills } = data;
  const macros = skills.reduce((acc, curr) => {
    if (acc.some(({ _id }) => _id === curr.Macro._id)) return acc;
    return [...acc, curr.Macro];
  }, []);

  const assignCredit = async () => {
    setLoading(true);
    await createTaskReview({
      variables: {
        taskReview: [
          {
            _charity_id: charityId,
            _user_id: userId,
            _macro_id: selectedMacro.value,
            minutes: totalMinutes,
            name,
          },
        ],
      },
      refetchQueries: [
        {
          query: fetchUserById,
          variables: {
            id: userId,
          },
        },
      ],
    });
    onClose();
  };

  return (
    <ModalBase onClose={onClose} className="addCreditModal">
      <h3 className="fontPoppins textBlack normal textCenter">Assign Credit</h3>
      <div className="textCenter mbottom24">Credit should only be assigned for small, one-off tasks that are less than 2hrs.</div>
      <Input placeholder="Entry name" value={name} onChange={setName} />
      <div className="row mbottom16">
        <div className="col-md-8">
          <Select
            placeholder="Choose Skill Category"
            value={selectedMacro}
            onChange={setMacro}
            options={macros.map(macro => ({
              value: macro._id,
              label: macro.name,
            }))}
          />
        </div>
        <div className="col-md-2">
          <Counter initialCount={0} className="m0" onChange={setHours} />
          <h4 className="fontPoppins mtop8 mbottom0">Hours</h4>
        </div>
        <div className="col-md-2">
          <Counter initialCount={0} className="m0" onChange={setMinutes} />
          <h4 className="fontPoppins mtop8 mbottom0">Minutes</h4>
        </div>
      </div>
      <FlatButton label="Add Credit" filled className="mAuto" disabled={!(totalMinutes && name)} onClick={assignCredit} />
    </ModalBase>
  );
};
