import gql from 'graphql-tag';

export default gql`
  query FetchCharities {
    charities {
      _id
      name
      picture
      status
      registration
      phone
      email
      website
      created
    }
  }
`;
