import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

class MapComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lat: '',
            lng: ''
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.lat !== this.state.lat) {
            this.setState({
                lat: newProps.lat,
                lng: newProps.lng
            });
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.state.lat !== nextProps.lat) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <Map
                google={this.props.google}
                zoom={this.props.zoom || 15}
                className="map"
                center={{ lat: this.state.lat, lng: this.state.lng }}
                initialCenter={{ lat: this.state.lat, lng: this.state.lng }}
                onReady={this.fetchPlaces}
            >
                <Marker
                    name={this.props.name}
                    position={{ lat: this.state.lat, lng: this.state.lng }}
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCyH48m073brmEv0T_4qpxjAuwEGHLXYwg'
})(MapComponent);
