import gql from 'graphql-tag';

export default gql`
  query FetchUnacceptedJoinCharitiesByInvitor($invitedBy: ID!) {
    unacceptedJoinCharitiesByInvitor(invited_by: $invitedBy) {
      _id
      request_id
      email
      role
      InviteeEmail {
        _id
        firstname
        lastname
        email
        picture
      }
    }
  }
`;
