import gql from 'graphql-tag';
import { taskFieldsFragment } from '../../fragments/task/taskFields';

export default gql`
  query FetchTasksByCharityId($_charity_id: ID!) {
    tasksByCharityID(_charity_id: $_charity_id) {
      ...TaskFields
    }
  }
  ${taskFieldsFragment}
`;
