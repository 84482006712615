import gql from 'graphql-tag';

export default gql`
    mutation finishCharityCreation($id: ID!, $charity: CharityInput!) {
        finishCharityCreation(id: $id, charity: $charity) {
            _id
            picture
        }
    }
`;
