import React from 'react';
import styled from 'styled-components';
import Svg from '../Svg';

const RadioCardContainer = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 36px 24px;
  width: auto;
  border: 1px solid #E3E5E5;
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 767px) {
    width: 300px;
    margin-bottom: 0;
  }

  @media (min-width: 1169px) {
    width: 280px;
  }
`;

const CardIcon = styled(Svg)`
  width: 40px;
  height: 40px;
  fill: black;
`;

const RadioButtonElement = styled.input`
  display: none;

  &:checked + ${RadioCardContainer} {
    background-color: #3042D4;
    color: white;
    border-color: #3042D4;
  }

  &:checked + ${CardIcon} {
    fill: white;
  }
`;

const CardHeading = styled.p`
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  font-weight: 600;
`;

const CardContent = styled.p`
  font-size: 13px;
  line-height: 1.5;
  margin: 5px 0;
`;

const SingleRadioButtonCard = ({ icon, heading, content, id, value, name, active, onClick }) => {
  const handleRadioChange = (event) => {
    const radioValue = event.target.value;
    onClick(radioValue);
  };

  return (
    <>
      <RadioButtonElement
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={active === value}
        onChange={handleRadioChange}
      />
      <RadioCardContainer htmlFor={id}>
        <CardIcon>
          {icon}
        </CardIcon>
        <CardHeading>{heading}</CardHeading>
        {content && <CardContent>{content}</CardContent>}
      </RadioCardContainer>
    </>
  );
};

export default SingleRadioButtonCard;
