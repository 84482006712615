import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { LoadingOverlay, BackButton, Button } from '../../common';
import { utils } from '../../../lib/utils';

// Queries
import fetchAmbassadorById from '../../../queries/ambassador/fetchAmbassadorById';

// Assets
import twitterIcon from '../assets/twitterIcon.svg';
import instagramIcon from '../assets/instagramIcon.svg';

// Styles
import './styles/ambassadorDetailsStyles.css';

class AmbassadorDetails extends Component {
  constructor() {
    super();

    this.state = {
      error: '',
    };
  }

  render() {
    if (this.props.ambassadorData.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
    const { ambassadorById } = this.props.ambassadorData;
    const { charity } = this.props.location.state;

    return (
      <div className="ambassadorDetailsContainer col-xs-12 p0">
        {this.state.error && (
          <div className="errorMessage">
            <p className="error">{this.state.error}</p>
          </div>
        )}
        <BackButton title="Back to charity" />
        <div className="header">
          <div style={{ backgroundImage: `url(${ambassadorById.picture})` }} className="defaultImageStyle ambassadorPic" />
          <h1>{ambassadorById.fullname}</h1>
          <p className="description">{ambassadorById.description}</p>
          <div className="socialButtons">
            <a href={utils.normaliseLink(ambassadorById.social.instagram)} target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} className="instagramIcon" alt="Instagram Icon" />
            </a>
            <a href={utils.normaliseLink(ambassadorById.social.twitter)} target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} className="twitterIcon" alt="Twitter Icon" />
            </a>
          </div>
        </div>
        <div className="content col-xs-12 p0">
          <div className="defaultPageContainer">
            <p>{ambassadorById.bio}</p>
            <div className="quotesContainer col-xs-12">
              <div className="col-xs-3">
                <i className="material-icons quotes">&#xE244;</i>
              </div>
              <div className="col-xs-9">
                <h1 className="quotesContent">{ambassadorById.quote.content}</h1>
                <p className="quotesAuthor">{`— ${ambassadorById.quote.author}`}</p>
              </div>
            </div>
            <div className="charityContainer col-xs-12 p0">
              <div style={{ backgroundImage: `url(${charity.picture})` }} className="defaultImageStyle charityImg" />
              <h1>{this.props.location.state.name}</h1>
              <Button label="View all Tasks" onClick={() => this.props.history.push({ pathname: '/task/search' })} className="btnDefault mtop30" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ambassadorByIDContainer = graphql(fetchAmbassadorById, {
  name: 'ambassadorData',
  options: ({ match }) => ({
    variables: { _id: match.params.ambassador_id },
  }),
});

export default withRouter(_.flowRight(ambassadorByIDContainer)(AmbassadorDetails));
