import { FlatButton } from 'components/common/FlatButton/view';
import React, { useState } from 'react';
import BioEmptyIcon from 'assets/svg/bio-empty.svg';
import { userUtils } from 'lib/user';
import { ModalAddAbout } from './modalAddAbout';

export const ProfileBio = ({ user }) => {
  const [showAddAbout, setShowAddAbout] = useState(false);

  if (!user.about && userUtils.isAdmin(user._id)) {
    return (
      <div className="emptyContainer">
        <img src={BioEmptyIcon} alt="Bio Empty Icon" />
        <h3 className="fontPoppins">Tell us about yourself</h3>
        <div className="textCenter mbottom16">Write a short description about yourself and your prior work experience.</div>
        <FlatButton label="Add a Bio" onClick={() => setShowAddAbout(true)} />
        {showAddAbout && <ModalAddAbout open userByID={user} onClose={() => setShowAddAbout(false)} />}
      </div>
    );
  }
  return (
    <div className="bioContainer">
      <p className="bio">{user.about}</p>
      {userUtils.isAdmin(user._id) && <FlatButton className="selfStart" label="Edit Bio" onClick={() => setShowAddAbout(true)} />}
      {showAddAbout && <ModalAddAbout open userByID={user} onClose={() => setShowAddAbout(false)} />}
    </div>
  );
};
