import React from 'react';

// Styles
import './styles/headerSimpleStyles.scss';

const HeaderSimple = () => {
  return (
    <div className="headerSimpleContainer">
      <a href="/">
        <img src="/logo.svg" alt="Worthy logo" className="logo" />
      </a>
    </div>
  );
};

export default HeaderSimple;
