import React from 'react';
import { FlatButton as MaterialFlatButton } from 'material-ui';
import './styles.scss';

export const FlatButton = ({ label, icon, onClick, className, bordered, disabled, filled, href, bgColor = '#3042D4', size = 'normal' }) => {
  let backgroundColor;
  if (filled && disabled) {
    backgroundColor = '#B5BDC4';
  } else if (filled) {
    backgroundColor = bgColor;
  }

  const sizeClass = `size-${size}`;

  return (
    <MaterialFlatButton
      label={label}
      onClick={onClick}
      disabled={disabled}
      href={href}
      className={`flatButton ${sizeClass} ${bordered ? 'flatBordered' : ''} ${filled ? 'flatFilled' : ''} ${className || ''}`}
      rippleColor={filled ? 'white' : undefined}
      hoverColor={filled ? '#7D8FFF' : undefined}
      backgroundColor={backgroundColor}
      icon={
        icon ? <img src={icon} alt="button icon" /> : undefined
      }
    />
  );
};
