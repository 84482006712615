import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import Layout, { Steps } from '../common/Layout';
import StepCard from '../common/StepCard';
import Overview from './steps/overview';
import Details from './steps/details';
import Volunteers from './steps/volunteers';
import Skills from './steps/skills';
import Publish from './steps/publish';
import Finish from './steps/finish';

const Create = ({ history, match }) => {
  const { step } = match.params;
  const [formData, setFormData] = useState({});
  const [currentTaskDetailStep, setCurrentTaskDetailStep] = useState('');

  // Custom state for cases when the sidebar content is changed mid-step
  const [sidebarUpdated, setSidebarUpdated] = useState(false);

  useEffect(() => {
    const stepExists = Steps.find(singleStep => singleStep.name === step);

    // Redirect if formData is empty or if they enter a random step
    if ((!stepExists && step !== 'finish') || isEmpty(formData)) {
      history.push('/task/create/overview');
    }
  }, []);

  const sidebarTaskDetailContent = {
    taskName: 'What is the name of the position and how many volunteers are needed?',
    taskLocation: 'Add a location',
  };

  const getStepContent = () => {
    switch (step) {
      case 'overview':
        return {
          sidebarHeading: 'Tell us about the opportunity',
          sidebarContent: 'Creating the right opportunity will attract the right volunteers.',
          sidebarHint: 'Tasks automatically save as you progress and will be saved in Drafts on the Task Manager.',
          mainContent: <Overview history={history} setFormData={setFormData} />,
        };
      case 'details':
        return {
          sidebarHeading: sidebarUpdated ? " Now it's time to add more detail to the Role" : "Let's start with the basics",
          sidebarContent: sidebarUpdated ? (
            <>
              <p>Describe the role and what is required. We suggest covering the following:</p>
              <ul className="cleanList">
                <li>A brief task description</li>
                <li>Any documents to be provided prior to starting</li>
                <li>Equipment needed (e.g. bring your own laptop)</li>
                <li>Any safety requirements (e.g. closed shoes, sun protection)</li>
              </ul>
            </>
          ) : (
            sidebarTaskDetailContent[currentTaskDetailStep]
          ),
          sidebarHint: '',
          mainContent: (
            <Details history={history} formData={formData} setFormData={setFormData} setSidebarUpdated={setSidebarUpdated} setCurrentTaskDetailStep={setCurrentTaskDetailStep} />
          ),
        };
      case 'skills':
        return {
          sidebarHeading: 'Are any specific skills required?',
          sidebarContent: 'Add skills and sub-skills to give candidates more information on what is required.',
          sidebarHint: 'Add more than 1 sub-skill to increase the audience for the opportunity.',
          mainContent: <Skills history={history} setFormData={setFormData} />,
        };
      case 'volunteers':
        return {
          sidebarHeading: 'Do you know anyone who might suit this role?',
          sidebarContent: 'Search and select volunteers who have the experience you need.',
          sidebarHint: 'You can search by name or skills. Volunteers within your network are listed first.',
          mainContent: <Volunteers history={history} formData={formData} setFormData={setFormData} />,
        };
      case 'publish':
        return {
          sidebarHeading: 'Review and publish your task!',
          sidebarContent: 'Ensure everything looks correct before you publish it.',
          sidebarHint: 'You can always edit the task from the Task Manager.',
          mainContent: <Publish history={history} formData={formData} />,
        };
      default:
        return {
          sidebarHeading: 'Tell us about the opportunity',
          sidebarContent: 'Creating the right opportunity will attract the right volunteers. ',
          sidebarHint: 'Tasks automatically save as you progress and will be saved in Drafts on the Task Manager.',
        };
    }
  };

  return (
    <Layout step={step}>
      {step === 'finish' ? <Finish isVerified={formData.charityStatus && formData.charityStatus === 1} history={history} /> : <StepCard {...getStepContent()} />}
    </Layout>
  );
};

export default Create;
