import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import './styles/personThumbnailStyles.css';

const PersonThumbnail = (props) => {
    const {
        _id,
        firstname,
        lastname,
        picture
    } = props.user;
    return (
        <Link
            key={`person_${_id}`}
            className={`personThumbnailContainer ${props.className || ''}`}
            to={`/profile/${_id}`}
        >
            <div
                className="profilePicture"
                style={{ backgroundImage: `url(${picture})` }}
            />
            <h3>{`${firstname} ${lastname.substr(0, 1)}.`}</h3>
            {props.position &&
                <p className="smallBody p0">{props.position}</p>
            }
        </Link>
    );
};

export default PersonThumbnail;
