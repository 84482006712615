import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';

// Styles
import './styles/DrawerStyles.scss';

export const NavigationDrawer = ({ isOpen, onClose, children }) => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });

  useEffect(() => {
    toggleDrawer('right', true);
  }, [isOpen]);

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (!open) onClose();
    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
        {children}
      </Drawer>
    </div>
  );
};
