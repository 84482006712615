const localStorage = {
    save(id, value) {
        window.localStorage.setItem(id, value);
    },
    get(id) {
        return window.localStorage.getItem(id);
    },
    remove(id) {
        return window.localStorage.removeItem(id);
    },
    reset() {
        window.localStorage.clear();
    }
};

export { localStorage };
