import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useQuery, useMutation } from '@apollo/client';
import { Input, Button, LoadingOverlay, Snackbar, BackButton, Checkbox } from '../../common';
import { userUtils } from '../../../lib/user';
import { localStorage } from '../../../lib/storage';

// Queries
import fetchJoinCharity from '../../../queries/join_charity/joinCharityByRequestId';

// Mutations
import createUserMutation from '../../../mutations/user/createUser';
import resendUserConfirmationEmailMutation from '../../../mutations/user/resendUserConfirmationEmail';
import acceptInviteMutation from '../../../mutations/join_charity/acceptInvite';

// Styles
import './styles/signUpStyles.css';

const SignUp = ({ location, history, match }) => {
  const requestId = _.get(match, 'params.requestId');

  const [user, setUser] = useState({
    email: '',
    password: '',
    status: 0,
    firstname: '',
    lastname: '',
  });
  const [formError, setFormError] = useState('');
  const [userRegistered, setUserRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [emailValid, setEmailValid] = useState('');
  const [passwordValid, setPasswordValid] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [acceptedTerms, setAcceptTerms] = useState(false);
  const [charityName, setCharityName] = useState('');
  const [emailInputDisable, setEmailInputDisable] = useState(false);

  // Query
  const { data } = useQuery(fetchJoinCharity, {
    variables: { requestId },
    fetchPolicy: 'cache-and-network',
  });
  // Mutations
  const [createUser] = useMutation(createUserMutation);
  const [resendUserConfirmationEmail] = useMutation(resendUserConfirmationEmailMutation);
  const [acceptInvite] = useMutation(acceptInviteMutation);

  const getTitleForAction = action => {
    switch (action) {
      case 'apply':
        return 'Create an account to apply for this event';
      case 'save':
        return 'Create an account to save this event';
      case 'registerCharity':
        return "To register a charity, you'll need to create an account";
      case 'follow':
        return "To follow a charity, you'll need to create an account";
      default:
        return 'Create an account to apply for this event';
    }
  };

  const handleCreateUser = async event => {
    if (event) event.preventDefault();
    setLoading(true);
    try {
      const userCreated = await createUser({
        variables: {
          user,
        },
      });

      if (requestId) {
        const joinCharity = await acceptInvite({ variables: { requestId } });
        console.log(joinCharity);
      }

      setLoading(false);
      setUserRegistered(true);
    } catch (error) {
      setFormError(error.graphQLErrors[0].message);
      setLoading(false);
    }
  };

  const resendLink = async () => {
    const userId = localStorage.get('userID');
    if (userId) {
      setLoading(true);
      setFormError('');

      try {
        await resendUserConfirmationEmail({
          variables: {
            id: userId,
          },
        });
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage('Email successfully resent');
      } catch (error) {
        setLoading(false);
        setFormError(error);
      }
    }
  };

  const renderTitleContainer = () => {
    const eventId = _.get(location, 'state.eventId');
    const action = _.get(location, 'state.action');

    if (eventId) {
      return (
        <div>
          <BackButton />
          <h1 className="accountFormHeader">{getTitleForAction(_.get(location, 'state.action'))}</h1>
          <p>{`Helping ${_.get(location, 'state.eventById.charity.name')} is a great thing to do.`}</p>
        </div>
      );
    }

    if (action) {
      return (
        <div>
          <BackButton />
          <h1 className="accountFormHeader">{getTitleForAction(_.get(location, 'state.action'))}</h1>
        </div>
      );
    }

    return (
      <h1 className="accountFormHeader">
        Sign{' '}
        {requestId && (
          <>
            {charityName} <br /> on
          </>
        )}{' '}
        Up
      </h1>
    );
  };

  const renderSignUpContent = () => {
    if (userRegistered) {
      return (
        <div className="accountFormContainer equalPadding">
          <img src="/icons/email-tick.svg" alt="email verification icon" className="formIcon" />
          <h1 className="accountFormHeader">Please verify your email</h1>
          <p className="accountFormText">
            We&apos;ve sent a verification email to <span className="email">{user.email}</span>. Please click on the link provided to verify your email address.
          </p>

          <hr />

          <p className="accountFormText">
            Incorrect email? <a href="/sign-up">Change email address</a>
          </p>
          <p className="accountFormText">
            Didn&apos;t get the confirmation email? <a onClick={resendLink}>Resend</a>
          </p>
        </div>
      );
    }

    // It can have an event Id attached to it as the user can try to "Apply" for an event without being logged in
    const eventId = _.get(location, 'state.eventId');
    return (
      <div className="accountFormContainer">
        {renderTitleContainer()}
        {formError && (
          <div className="errorMessage">
            <p className="error">{formError}</p>
          </div>
        )}
        <div className="contentContainer">
          <form onSubmit={handleCreateUser} className="signUpForm">
            <Input
              name="email"
              placeholder="Email"
              type="email"
              errorText={emailValid}
              value={user.email}
              onChange={value => {
                setUser(prevUser => ({
                  ...prevUser,
                  email: value,
                }));
              }}
              onBlur={() => {
                if (userUtils.isValidEmail(user.email) === false) {
                  setEmailValid('Please enter a valid email.');
                } else {
                  setEmailValid('');
                }
              }}
              disabled={emailInputDisable}
            />
            {requestId && (
              <>
                <Input
                  name="firstname"
                  placeholder="FirstName"
                  type="text"
                  errorText=""
                  value={user.firstname}
                  onChange={value => {
                    setUser(prevUser => ({ ...prevUser, firstname: value }));
                  }}
                />
                <Input
                  name="lastname"
                  placeholder="LastName"
                  type="text"
                  errorText=""
                  value={user.lastname}
                  onChange={value => {
                    setUser(prevUser => ({ ...prevUser, lastname: value }));
                  }}
                />
              </>
            )}
            <Input
              name="password"
              placeholder="Password"
              type="password"
              errorText={passwordValid}
              value={user.password}
              onChange={value => {
                setUser(prevUser => ({
                  ...prevUser,
                  password: value,
                }));
              }}
              onBlur={() => {
                if (userUtils.isValidPassword(user.password) === false) {
                  setPasswordValid('Password must be 8 characters or more, and it must include at least one uppercase letter, one lowercase letter, and one number');
                } else {
                  setPasswordValid('');
                }
              }}
            />
            <Input
              name="password confirmation"
              placeholder="Confirm password"
              type="password"
              className="psw-confirm"
              errorText={passwordConfirm}
              value={passwordConfirmation}
              onChange={value => {
                setPasswordConfirmation(value);
                if (user.password !== value) {
                  setPasswordConfirm('Passwords need to match');
                } else {
                  setPasswordConfirm('');
                }
              }}
            />
            <div className="buttonsContainer">
              <Button
                label="Submit"
                type="submit"
                className="col-xs-12 p0"
                disabled={
                  !requestId
                    ? !(userUtils.isValidEmail(user.email) && userUtils.isValidPassword(user.password) && !passwordConfirm && acceptedTerms)
                    : !(userUtils.isValidEmail(user.email) && !!user.firstname && !!user.lastname && userUtils.isValidPassword(user.password) && !passwordConfirm && acceptedTerms)
                }
              />
            </div>
          </form>
          <div className="termsContainer">
            <Checkbox
              className="checkbox"
              checked={acceptedTerms}
              onCheck={() => {
                setAcceptTerms(!acceptedTerms);
              }}
              labelStyle={{ fontSize: 14 }}
            />
            <p className="textCenter">
              {requestId ? (
                <>
                  Registering signifies that you have read and agree to our <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy.</a>.
                </>
              ) : (
                <>
                  By checking this box and clicking &quot;Submit&quot; you accept to be bound our <a href="/terms">Beta Tester Agreement</a>
                </>
              )}
            </p>
          </div>
          {eventId && (
            <p className="col-xs-12 p0 doLater">
              Would you like to do this later? <a onClick={() => history.goBack()}>Go back</a>
            </p>
          )}
          {!requestId && (
            <p className="accountFormText textCenter">
              Already registered?{' '}
              <a className="bold" onClick={() => history.push({ pathname: '/login', state: { ...location.state } })}>
                Login
              </a>
            </p>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (user.password !== '' && passwordConfirmation !== '' && user.password !== passwordConfirmation) {
      setPasswordConfirm('Passwords need to match');
    }
  }, [user, passwordConfirmation]);

  useEffect(() => {
    const joinCharityData = _.get(data, 'joinCharityByRequestId');
    setCharityName(_.get(joinCharityData, 'Charity.name', ''));
    setUser({ ...user, email: _.get(joinCharityData, 'email') });
    setEmailInputDisable(!!joinCharityData);
  }, [data]);

  return (
    <div className="signUpContainer">
      <div className="signUpContainerInternal centredContainer textCenter">
        {loading && <LoadingOverlay style={{ opacity: 0.7 }} />}
        {renderSignUpContent()}
        <Snackbar open={snackbarOpen} message={snackbarMessage} snackbarHandleRequestClose={() => setSnackbarOpen(false)} />
      </div>
    </div>
  );
};

export default withRouter(SignUp);
