import gql from 'graphql-tag';

export default gql`
  mutation acceptInvite($requestId: String!) {
    acceptInvite(request_id: $requestId) {
      _id
      request_id
      email
      role
      accepted
      Charity {
        _id
        name
      }
    }
  }
`;
