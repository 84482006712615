import React, { useEffect, useRef } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { localStorage } from '../../../lib/storage';

import './styles/GoogleInputStyles.css';
import Input from '../Input';

const GoogleInput = ({ google, map, placeholder, value, onNewPlace, onValueChange }) => {
  const autocompleteRef = useRef();

  useEffect(() => {
    if (autocompleteRef && autocompleteRef.value) {
      autocompleteRef.value = value;
    }

    renderAutoComplete();
  }, [map]);

  const parseAddressComponent = (address_components, key, secondKey, field = 'long_name') => {
    let addressValue = address_components.filter(component => {
      return component.types.includes(key);
    });
    // Searches by the second key if nothing was found with the main key and a second key is passed
    if (addressValue.length === 0 && secondKey) {
      addressValue = address_components.filter(component => {
        return component.types.includes(secondKey);
      });
    }
    if (addressValue.length > 0) return addressValue[0][field];

    return null;
  };

  const parsePlaceResults = result => {
    const newObject = {};
    const { address_components } = result;
    newObject.location = result.name;
    newObject.latitude = result.geometry.location.lat();
    newObject.longitude = result.geometry.location.lng();
    newObject.postcode = parseAddressComponent(address_components, 'postal_code');
    const street_number = parseAddressComponent(address_components, 'street_number') || '';
    if (street_number) {
      newObject.street = `${street_number} ${parseAddressComponent(address_components, 'route') || ''}`;
    } else {
      newObject.street = `${parseAddressComponent(address_components, 'route') || ''}`;
    }
    newObject.state = parseAddressComponent(address_components, 'administrative_area_level_1');
    newObject.country = parseAddressComponent(address_components, 'country');
    newObject.city = parseAddressComponent(address_components, 'administrative_area_level_2', 'locality', 'short_name');
    return newObject;
  };

  const renderAutoComplete = () => {
    if (!google) return null;
    const autocomplete = new google.maps.places.Autocomplete(autocompleteRef.current, {
      types: ['geocode'],
      componentRestrictions: { country: 'US' },
    });

    // autocomplete.bindTo('bounds', map);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      onNewPlace(parsePlaceResults(place));
      onValueChange(place.formatted_address);
    });
  };

  return (
    <div className="googleInputContainer">
      <Input
        name="googleInput"
        ref={autocompleteRef}
        type="text"
        placeholder={placeholder || 'Address'}
        value={value}
        onChange={inputVal => onValueChange && onValueChange(inputVal)}
      />
    </div>
  );
};

const MapWrapper = props => {
  return (
    <Map google={props.google} className="googleInput" visible={false}>
      <GoogleInput {...props} />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCyH48m073brmEv0T_4qpxjAuwEGHLXYwg',
})(MapWrapper);
