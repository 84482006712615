import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import InboxIcon from './assets/inbox.svg';
import DashboardIcon from './assets/dashboard.svg';
import TaskManagerIcon from './assets/taskmanager.svg';
import SettingsIcon from './assets/settings.svg';
import { userUtils } from '../../../lib/user';

// Styles
import './styles/sidebarStyles.scss';

const sidebarItemsCharityManager = [
  {
    icon: DashboardIcon,
    title: 'Dashboard',
    href: '/dashboard/index',
  },
  {
    icon: InboxIcon,
    title: 'Inbox',
    href: '/dashboard/inbox',
  },
  {
    icon: TaskManagerIcon,
    title: 'Task Manager',
    href: '/dashboard/task-manager',
  },
  {
    icon: SettingsIcon,
    title: 'Settings',
    href: '/dashboard/settings',
  },
];

const sidebarItemsVolunteer = [
  {
    icon: DashboardIcon,
    title: 'Dashboard',
    href: '/dashboard/index',
  },
  {
    icon: InboxIcon,
    title: 'Inbox',
    href: '/dashboard/inbox',
  },
  {
    icon: TaskManagerIcon,
    title: 'Task Manager',
    href: '/dashboard/task-manager',
  },
  {
    icon: SettingsIcon,
    title: 'Settings',
    href: '/dashboard/settings',
  },
];

const Sidebar = props => {
  const { page, user } = props;
  const userData = _.get(user, 'data.userByID');
  const isCharityManager = userUtils.isCharityManager(userData);
  const charitiesManaging = _.get(userData, 'CharitiesAdmin.0');
  const sidebarItems = isCharityManager ? sidebarItemsCharityManager : sidebarItemsVolunteer;

  const renderCharityManagerHeader = () => {
    return (
      <Link to={`/charity/${charitiesManaging._id}`}>
        <div className="charityLogoContainer">
          <img className="charityLogo" src={_.get(charitiesManaging, 'picture')} alt="Charity logo" />
          <span>{_.get(charitiesManaging, 'name')}</span>
        </div>
      </Link>
    );
  };

  return (
    <div className="sidebarContainer">
      {user.loading ? (
        <p>loading</p>
      ) : (
        <>
          {isCharityManager && renderCharityManagerHeader()}
          <ul>
            {sidebarItems.map((item, index) => {
              const selected = item.href.includes(page);
              const last = index === sidebarItems.length - 1;

              return (
                <a
                  key={item.title}
                  className={`sidebarItem ${selected ? 'selected' : ''} ${last ? 'last' : ''}`}
                  onClick={() => {
                    props.history.replace({ pathname: item.href });
                  }}
                >
                  <div className="imageContainer">
                    <img src={item.icon} alt={item.title} />
                  </div>
                  <span>{item.title}</span>
                </a>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default withRouter(Sidebar);
