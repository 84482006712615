import { useQuery } from '@apollo/client';
import { Chips, ModalBase } from 'components/common';
import { FlatButton } from 'components/common/FlatButton/view';
import { getSkillMacros, normaliseSkillsArray } from 'lib/utils';
import _ from 'lodash';
import fetchSkills from 'queries/skills/fetchSkills';
import React, { useState } from 'react';
import './styles.scss';

export const UpdateSkillsModal = ({ userSkills, updateUser, onClose }) => {
  const [loading, setLoading] = useState(false);
  const userMacros = getSkillMacros(userSkills);
  const [primarySkill, setPrimarySkill] = useState(userMacros.length > 0 ? userMacros[0].name : undefined);
  const [subSkills, setSubSkills] = useState(userSkills.map(({ _id }) => _id));
  const { data } = useQuery(fetchSkills);

  const selectSkill = skill => {
    const newSubSkills = [...subSkills];
    if (newSubSkills.includes(skill._id)) {
      _.pull(newSubSkills, skill._id);
    } else {
      newSubSkills.push(skill._id);
    }
    setSubSkills(newSubSkills);
  };

  const updateUserSkills = async () => {
    setLoading(true);
    await updateUser({
      skills: subSkills,
    });
    onClose();
  };

  if (!data) return null;

  const allSkills = data.skills;
  const groupedSkills = _.groupBy(allSkills, 'Macro.name');
  const skills = normaliseSkillsArray(primarySkill ? groupedSkills[primarySkill] : [], subSkills);
  const pairs = _(groupedSkills).toPairs().sortBy(0).fromPairs();
  const orderedGroupedSkills = pairs.value();
  const macroColor = _.get(groupedSkills[primarySkill], '[0].Macro.color');

  return (
    <ModalBase className="updateSkillsModal" onClose={onClose}>
      <h2 className="textCenter fontPetrona textBlack">What skills can you offer?</h2>
      <div className="textCenter textBlack mbottom40">Showcase your skills on your Profile.</div>
      <select
        className={`select mbottom40 ${primarySkill ? 'active' : ''}`}
        style={
          primarySkill
            ? {
                color: macroColor,
                border: `2px solid ${macroColor}`,
              }
            : {}
        }
        value={primarySkill}
        onChange={e => setPrimarySkill(e.target.value)}
      >
        {Object.keys(orderedGroupedSkills).map(cat => {
          const categorySkills = allSkills.filter(({ _id }) => subSkills.includes(_id));
          const selected = categorySkills.filter(({ Macro }) => Macro.name === cat).length;
          return (
            <option key={cat} value={cat}>
              {cat} {selected ? `(${selected})` : null}
            </option>
          );
        })}
      </select>
      <Chips values={skills} onClick={selectSkill} />

      <FlatButton label="Update" filled className="mLeftAuto mRightAuto mtop40" size="large" disabled={loading} onClick={updateUserSkills} />
    </ModalBase>
  );
};
