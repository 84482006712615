import gql from 'graphql-tag';

export default gql`
    mutation inviteVolunteersForTask($_task_id: ID!, $_user_ids: [ID]!) {
        inviteVolunteersForTask(_task_id: $_task_id, _user_ids: $_user_ids) {
            _id
            _task_id
            _charity_id
            _user_id
            status
            created
            updated
        }
    }
`;
