import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { withRouter } from 'react-router';
import { LoadingOverlay } from '../common';
import { localStorage } from '../../lib/storage';
import { userUtils } from '../../lib/user';
import { utils } from '../../lib/utils';

// Queries
import fetchMacros from '../../queries/macros/fetchMacros';
import fetchUserById from '../../queries/user/fetchUserById';
import fetchGroupMessagesByUser from '../../queries/group_messages/fetchGroupMessagesByUser';

// External components
import MessagesComponent from './messagesComponent';
import Sidebar from './sidebar';

// Styles
import './styles/inboxStyles.scss';

function MessagesInbox(props) {
  const [charitySelected, setCharitySelected] = useState();
  const [macroSelected, setMacroSelected] = useState();
  const isDirectMessage = _.get(props, 'location.pathname').includes('/direct');
  const isGroupMessage = _.get(props, 'location.pathname').includes('/group');
  const _to_user_id = _.get(props, 'match.params.user_id');
  const _current_user_id = localStorage.get('userID');
  const _group_id_from_url = _.get(props, 'match.params.group_id');

  // Queries
  const userByIdResults = useQuery(fetchUserById, {
    variables: {
      id: _current_user_id,
    },
    fetchPolicy: 'network-only',
  });
  const userByIdData = _.get(userByIdResults, 'data.userByID');

  const isCharityManager = userUtils.isCharityManager(userByIdData);

  const macrosResults = useQuery(fetchMacros, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
  });
  const macrosData = utils.orderArrayByField(_.get(macrosResults, 'data.macros'), 'name');

  const groupMessagesByUserResults = useQuery(fetchGroupMessagesByUser, {
    variables: {
      _user_id: _current_user_id,
    },
    fetchPolicy: 'network-only',
    pollInterval: 5000,
  });
  const groupMessagesByUserData = _.get(groupMessagesByUserResults, 'data.groupMessagesByUser', []);

  // Define the charity context
  useEffect(() => {
    const charityOnURL = _.get(props, 'match.params.charity');
    const charitiesManaging = _.get(userByIdData, 'CharitiesAdmin.0._id');
    if (charityOnURL && !isCharityManager) {
      setCharitySelected(charityOnURL);
    } else {
      setCharitySelected(charitiesManaging);
    }
  }, [userByIdData]);

  // Define the macro context
  useEffect(() => {
    const macroOnURL = _.get(props, 'match.params.macro');
    if (macroOnURL) {
      setMacroSelected(macroOnURL);
    } else if (!isDirectMessage && !isGroupMessage) {
      setMacroSelected(_.get(macrosData, '[0]._id'));
    } else {
      setMacroSelected();
    }
  }, [macrosData]);

  if (userByIdResults.loading) return <LoadingOverlay />;

  return (
    <div className="messagesInbox">
      <Sidebar
        macrosData={macrosData}
        userByIdData={userByIdData}
        groupMessagesByUserData={groupMessagesByUserData}
        groupIdFromUrl={_group_id_from_url}
        macroSelected={macroSelected}
        setMacroSelected={setMacroSelected}
        charitySelected={charitySelected}
        setCharitySelected={setCharitySelected}
      />
      <div className="messagesContainer">
        <MessagesComponent
          charity={charitySelected}
          macro={macroSelected}
          macrosData={macrosData}
          _to_user_id={_to_user_id}
          connectedUser={userByIdData}
          _group_id={_group_id_from_url}
          groupData={groupMessagesByUserData}
        />
      </div>
    </div>
  );
}

export default withRouter(MessagesInbox);
