import gql from 'graphql-tag';

export default gql`
  query FetchCharityFollowersByMacro($_id: ID!, $_macro_id: ID!) {
    charityFollowersByMacro(_id: $_id, _macro_id: $_macro_id) {
      _id
      firstname
      lastname
      picture
      UserSkills {
        _id
        name
        skill_color
        skill_label_color
      }
      UserMacros {
        _id
        name
        color
      }
    }
  }
`;
