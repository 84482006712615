import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Button from '../../Button/view';
import { localStorage } from '../../../../lib/storage';

// Assets
import registerCharityImage from './assets/registerCharityImage.svg';

// Styles
import './styles/bannersStyles.css';

class RegisterCharityBanner extends Component {
    constructor(props) {
        super(props);

        this.onClickRegisterCharity = this.onClickRegisterCharity.bind(this);
    }

    onClickRegisterCharity() {
        if (!localStorage.get('userID')) {
            this.props.history.push({ pathname: '/sign-up', state: { action: 'registerCharity' } });
        } else {
            this.props.history.push({ pathname: '/charity/register/details' });
        }
    }

    render() {
        return (
            <div className={`banner banner-registerCharity ${this.props.className ? this.props.className : ''}`}>
                <div className="internalContainerStyle">
                    <div className="content">
                        <h1>Are you a charity?</h1>
                        <p>Register to create a page and start connecting with volunteers</p>
                        <a href="/for-charities">Learn How</a>
                        <div className="buttonsContainer">
                            <Button
                                label="Register Your Charity"
                                onClick={this.onClickRegisterCharity}
                                className="btnRegisterCharity"
                            />
                        </div>
                    </div>
                    <div className="imgContainer">
                        <img src={registerCharityImage} alt="Illustration" />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RegisterCharityBanner);
