import gql from 'graphql-tag';

export default gql`
  query FetchMacros {
    macros {
      _id
      name
      category
      Messages {
        _id
        seen
        context {
          _charity_id
        }
      }
    }
  }
`;
