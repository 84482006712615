import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import { withRouter } from 'react-router';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle-outline';
import _ from 'lodash';
import {
    Button,
    LoadingOverlay,
    List,
    Input,
    Causes,
    FileUpload
} from '../../common';
import { update } from '../../../lib/utils';
import { globalVariables } from '../../../lib/globalVariables';

// Queries
import fetchEventById from '../../../queries/event/fetchEventById';

// Mutations
import updateEventMutation from '../../../mutations/event/updateEvent';

class CreateEventDescription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event: {
                description: '',
                event_causes: [],
                testimonials: [{
                    description: '',
                    author: ''
                }],
                picture: ''
            },
            loading: false,
            error: ''
        };

        this.onNextClicked = this.onNextClicked.bind(this);
        this.onChangeCauses = this.onChangeCauses.bind(this);
        this.onChangeTestimonial = this.onChangeTestimonial.bind(this);
    }

    componentWillReceiveProps(newProps) {
        const { eventByID } = newProps.eventData;
        if (_.size(eventByID.testimonials) > 0) {
            this.setState({
                event: {
                    ...this.state.event,
                    description: eventByID.description,
                    event_causes: eventByID.event_causes.map((item) => {
                        return {
                            cause_id: item.cause_id
                        };
                    }),
                    testimonials: eventByID.testimonials.map((item) => {
                        return {
                            description: item.description,
                            author: item.author
                        };
                    }),
                    picture: eventByID.picture
                },
            });
        }
    }

    onUpdateEventInput(value, fieldName) {
        this.setState({
            event: {
                ...this.state.event,
                [fieldName]: value
            }
        });
    }

    onChangeCauses(selectedCauses) {
        this.setState({
            event: {
                ...this.state.event,
                event_causes: selectedCauses.map((item) => {
                    return {
                        cause_id: item
                    };
                })
            }
        });
    }

    onChangeTestimonial(fieldName, index, value) {
        this.setState({
            event: {
                ...this.state.event,
                testimonials: update(this.state.event.testimonials, {
                    $auto: {
                        [index]: {
                            $auto: {
                                [fieldName]: {
                                    $set: value
                                }
                            }
                        },
                    }
                })
            }
        });
    }

    onNextClicked() {
        this.setState({
            loading: true
        });

        // Update event
        this.props.updateEvent(this.props.location.state.eventId, this.state.event)
            .then(() => {
                this.setState({
                    loading: false
                });
                this.props.history.push({ pathname: '/event/register/faqs', state: { ...this.props.location.state } });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error
                });
            });
    }

    renderCategories() {
        return (
            <div
                key="areasContainer"
                className="areasContainer"
            >
                <Causes
                    onGetNewCausesArray={this.onChangeCauses}
                    selectedValues={this.state.event.event_causes}
                    max={1}
                />
            </div>
        );
    }

    renderTitleCategories() {
        return (
            <div className="titleCategoriesContainer">
                <h3 className="m0">Event Cause</h3>
                <p className="smallBody">Select the main cause category that is most relevant to your event</p>
            </div>
        );
    }

    renderTestimonials() {
        return (
            <div
                key="testimonial"
                className="testimonialsContentContainer"
            >
                <div className="leftContainer">
                    <p className="subtitle">Are there any testimonials which you&apos;d like to add to your event?</p>
                    {this.state.event.testimonials.map((item, index) => {
                        return (
                            <div
                                key={`testimonial-${index}`}
                                className="testimonialsCreateContainer"
                            >
                                <Input
                                    name="testimonialField"
                                    placeholder="Testimonial"
                                    type="text"
                                    value={item.description}
                                    rowsMax={3}
                                    multiLine
                                    onChange={value => this.onChangeTestimonial('description', index, value)}
                                />
                                <Input
                                    name="testimonialField"
                                    placeholder="Author"
                                    type="text"
                                    value={item.author}
                                    onChange={value => this.onChangeTestimonial('author', index, value)}
                                />
                            </div>
                        );
                    })}
                    <div className="addAnotherContainer">
                        <a
                            onClick={() => {
                                this.setState({
                                    event: {
                                        ...this.state.event,
                                        testimonials: update(this.state.event.testimonials, {
                                            $push: [{
                                                description: '',
                                                author: ''
                                            }]
                                        })
                                    }
                                });
                            }}
                        >
                            <AddCircleOutline
                                color="#20C1C9"
                                className="add"
                            />
                            <span>Add another</span>
                        </a>
                    </div>
                </div>
                <div className="rightContainer">
                    <h3>EXAMPLE</h3>
                    <p>&quot;2017&apos;s Event XYZ raised 10 million in blah&quot;</p>
                    <h4>The Australian</h4>
                </div>
            </div>
        );
    }

    render() {
        if (!_.get(this.props.location, 'state.eventId')) {
            // If there's not Charity id to assign to it should redirect to the homepage
            this.props.history.replace({ pathname: '/' });
        }
        return (
          <div className="childContainer descriptionContainer">
            {this.state.loading && (
              <LoadingOverlay style={{ opacity: 0.8 }} />
            )}
            {this.state.error && (
              <p className="error">{this.state.error}</p>
            )}
            <div className="contentContainer">
              <div className="picturesContainer">
                <FileUpload
                  onFinishUpload={(filename) => {
                    this.setState({
                      event: {
                        ...this.state.event,
                        picture: `${globalVariables.getEnvironmentVariables().fileServer}${filename}`,
                      },
                    });
                  }}
                  previewImage={this.state.event.picture}
                  label="Drag and drop image here or click"
                  instructions="Upload 1200X674 pixels for best quality"
                />
              </div>
              <div className="aboutEventContainer">
                <Input
                  name="description"
                  placeholder="About your event"
                  type="text"
                  helpText="Describe why you're holding this event and what you hope to achieve"
                  value={this.state.event.description}
                  rowsMax={7}
                  multiLine
                  onChange={value => this.onUpdateEventInput(value, 'description')}
                />
              </div>
              <div className="causesContainer">
                <List
                  className="defaultListStyles"
                  values={[
                    {
                      name: this.renderTitleCategories(),
                      component: this.renderCategories(),
                      initiallyOpen: true
                    }
                  ]}
                />
              </div>
              <div className="testimonialsContainer">
                <List
                  className="defaultListStyles"
                  values={[
                    {
                      name: (
                        <h3 className="m0">Reputable testimonials</h3>
                      ),
                      component: this.renderTestimonials(),
                      initiallyOpen: true
                    }
                  ]}
                />
              </div>
            </div>
            <div className="buttonsContainer mtop60 textCenter">
              <Button
                label="Back"
                labelColor="#212B35"
                bgColor="#fff"
                onClick={() => this.props.history.goBack()}
                className="btnDefault btnWhite"
              />
              <Button
                label="Next"
                onClick={this.onNextClicked}
                className="btnDefault btnBlueSolid mleft15"
                disabled={
                  !(
                    this.state.event.description &&
                    this.state.event.event_causes.length > 0
                  )
                }
              />
            </div>
          </div>
        );
    }
}

const eventByIDContainer = graphql(fetchEventById, {
    name: 'eventData',
    options: ({ location }) => {
        return {
            variables: { id: _.get(location, 'state.eventId') },
        };
    },
});

const updateEventMutationContainer = graphql(updateEventMutation, {
    props: ({ mutate }) => ({
        updateEvent: (id, event) => mutate({
            variables: { id, event }
        }),
    }),
});

export default withRouter(_.flowRight(
    updateEventMutationContainer,
    eventByIDContainer
)(CreateEventDescription));
