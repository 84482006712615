import React, { useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Button, LoadingOverlay, Input } from '../../common';
import { localStorage } from '../../../lib/storage';

// Mutations
import loginUserMutationGQL from '../../../mutations/user/loginUser';

// Styles
import './styles/loginStyles.css';

const Login = ({ loginUser, history, location }) => {
    const [user, setUser] = useState({
        email: '',
        password: ''
    });
    const [formError, setFormError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();

        setFormError('');
        setLoading(true);

        loginUser(user.email, user.password)
            .then(({ data }) => {
                localStorage.save('userID', data.login._id);
                setLoading(false);

                // If the login happened successfully it should check if the user came from a cold login process or from an event page
                if (_.has(location, 'state.eventId')) {
                    // The user came from an event, so it should redirect to the event application page
                    history.replace({ pathname: `/event/${location.state.eventId}/apply`, state: { ...location.state } });
                } else {
                    // Redirects to the origin page. If it doesn't have an origin page, then it should redirect to the dashboard
                    history.push({ pathname: _.get(location, 'state.from.pathname') || '/' });
                }
            })
            .catch((error) => {
                setFormError(error.graphQLErrors[0].message);
                setLoading(false);
            });
    };

    return (
        <div className="loginContainer">
            <div className="loginContainerInternal centredContainer">
                {loading &&
                    <LoadingOverlay
                        style={{ opacity: 0.7 }}
                    />
                }
                <div className="accountFormContainer">
                    <h1 className="accountFormHeader">Login</h1>
                    {formError && (
                        <div className="errorMessage">
                            <p className="error">{formError}</p>
                        </div>
                    )}
                    <div className="contentContainer">
                        <form
                            onSubmit={handleSubmit}
                            className="mtop30"
                        >
                            <Input
                                name="email"
                                placeholder="Email"
                                type="email"
                                value={user.email}
                                onChange={(value) => {
                                    setUser(prevUser => {
                                        return {
                                            ...prevUser,
                                            email: value
                                        };
                                    });
                                }}
                            />
                            <Input
                                name="password"
                                placeholder="Password"
                                type="password"
                                value={user.password}
                                onChange={(value) => {
                                    setUser(prevUser => {
                                        return {
                                            ...prevUser,
                                            password: value
                                        };
                                    });
                                }}
                            />
                            <a
                                className="forgotPassword accountFormText smaller col-xs-12 p0"
                                onClick={() => history.push({ pathname: '/forgot-password' })}
                            >
                                Forgotten password?
                            </a>
                            <div className="buttonsContainer col-xs-12 p0">
                                <Button
                                    label="Login with email"
                                    type="submit"
                                    className="loginButton"
                                    buttonStyle={{
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                    }}
                                    disabled={!(user.email && user.password)}
                                />
                            </div>

                            <p className="textCenter accountFormText">Don&apos;t have an account? <a className="bold" onClick={() => history.push({ pathname: '/sign-up', state: { ...location.state } })}>Create an account</a></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const loginUserMutation = graphql(loginUserMutationGQL, {
    props: ({ mutate }) => ({
        loginUser: (email, password) => mutate({
            variables: { email, password }
        }),
    }),
});

export default withRouter(_.flowRight(loginUserMutation)(Login));
