import React from 'react';
import { useQuery } from '@apollo/client';
import { localStorage } from 'lib/storage';
import _ from 'lodash';
import { LoadingOverlay } from 'components/common';

// Queries
import fetchUserById from '../../../queries/user/fetchUserById';

// Components
import TasksList from './tasksList';

const TaskManager = ({ history, match }) => {
  const userByIdResults = useQuery(fetchUserById, {
    variables: {
      id: localStorage.get('userID'),
    },
  });
  const userByIdData = _.get(userByIdResults, 'data.userByID');

  return <div className="taskManagerContainer">{userByIdResults.loading ? <LoadingOverlay /> : <TasksList userData={userByIdData} history={history} />}</div>;
};

export default TaskManager;
