import React, { useState } from 'react';
import { FlatButton } from 'components/common/FlatButton/view';
import { ModalBase } from 'components/common/ModalBase/view';
import { SkillsList } from 'components/common/SkillsList/view';
import { getSkillMacros } from 'lib/utils';
import './styles.scss';

export const ApplyModal = ({ task, onClose, onApply }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const apply = async () => {
    setLoading(true);
    await onApply();
    setOpen(false);
  };

  return (
    <ModalBase open={open} className="applyModal" onClose={onClose}>
      <h1 className="fontPoppins textBlack textCenter mtop0 mbottom24">Apply for this opportunity</h1>
      <div className="divider mbottom24" />
      <div className="innerContainer">
        <div className="flexRow alignCenter">
          <img className="charityLogo mright8" alt="Charity logo" src={task.Charity.picture} />
          <div className="textBlack">{task.Charity.name}</div>
        </div>
        <h2 className="textBlack">{task.name}</h2>
        <SkillsList skills={task.TaskSkills} className="mbottom56" macros={getSkillMacros(task.TaskSkills)} />
      </div>
      <FlatButton filled label="Apply now" className="mLeftAuto mRightAuto applyBtn" onClick={apply} disabled={loading} />
    </ModalBase>
  );
};
