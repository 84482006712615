import gql from 'graphql-tag';

export default gql`
    query FetchAmbassadorByID($_id: ID) {
        ambassadorById(_id: $_id) {
            fullname
            picture
            description
            social {
              twitter
              instagram
            }
            bio
            quote {
              content
              author
            }
        }
    }
`;
