import moment from 'moment';
import React from 'react';
import StarIcon from '../assets/star.svg';

export const ProfileTask = ({ task }) => {
  const hours = Math.floor(task.minutes / 60);
  const minutes = task.minutes % 60;
  return (
    <div key={task._id}>
      <div className="task">
        <p>{task.name}</p>
        <h3 className="bold m0 fontPoppins">
          {hours > 0 && `${hours}h`} {minutes > 0 && `${minutes}m`}
        </h3>
      </div>
      <p className="review">
        {task.message}
      </p>
      <div className="dateAndStar">
        <div className="star">
          <img src={StarIcon} alt="Star" />
          <p>{task.rating}</p>
        </div>
        <p className="date">{moment(task.created).format('MMMM YYYY')}</p>
      </div>
      <div className="h-line" />
    </div>
  );
};
