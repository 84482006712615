import { useQuery } from '@apollo/client';
import fetchCharityById from 'queries/charity/fetchCharityById';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import './styles.scss';
import { LoadingOverlay, MembersModal } from 'components/common';
import _ from 'lodash';
import AboutEmptyIcon from 'components/charity/assets/about-empty.svg';
import { FlatButton } from 'components/common/FlatButton/view';
import { NetworkList } from 'components/common/NetworkList/view';
import { EditCharityModal } from './EditCharityModal/view';
import { CharityDetailsHeader } from './detailsHeader/view';
import { CharityDetailsTasks } from './tasks/view';
import { ShareModal } from './shareModal/view';
import { localStorage } from '../../../lib/storage';

const CharityDetails = ({ history, match }) => {
  const { charity_id: charityId } = match.params;
  const { loading, data } = useQuery(fetchCharityById, {
    variables: { _id: charityId },
    fetchPolicy: 'network-only',
  });
  const [showShare, setShowShare] = useState(false);
  const [isEditCharity, setIsEditCharity] = useState(false);
  const [showMembers, setShowMembers] = useState(false);

  if (!data) {
    return <LoadingOverlay style={{ opacity: 0.8 }} />;
  }

  const { charityById } = data;

  return (
    <div className="charityDetailsContainer col-xs-12">
      <div className="header-background"></div>
      <CharityDetailsHeader charity={charityById} onShare={() => setShowShare(true)} isEditCharity={isEditCharity} setIsEditCharity={setIsEditCharity} history={history} />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div className="sectionContainer">
              {charityById.description ? (
                <>
                  <h2 className="bold mtop0">About</h2>
                  <p className="mbottom16">{charityById.description}</p>
                  {!!localStorage.get('userId') && <FlatButton label="Edit bio" onClick={() => setIsEditCharity(true)} />}
                </>
              ) : (
                <>
                  {charityById.user_id === localStorage.get('userID') &&
                  <div className="flexCenter">
                    <img src={AboutEmptyIcon} alt="About Empty Icon" />
                    <div className="subTitle mtop16 mbottom16">Tell us about the charity</div>
                    <div className="content mbottom16">Write a short description about the charity. What is your mission and purpose?</div>
                    <FlatButton label="Add bio" onClick={() => setIsEditCharity(true)} />
                  </div>}
                  {charityById.user_id !== localStorage.get('userID') &&
                  <>
                    <h2 className="bold mtop0">About</h2>
                    <p className="mbottom16">{`Based in ${_.get(charityById, 'location.city')}, ${_.get(charityById, 'location.state')}`}</p>
                  </>}
                </>
              )}
            </div>
            <CharityDetailsTasks charity={charityById} />
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="sectionContainer flexCenter">
              <img
                src={_.get(charityById, 'charity_users.0.user.picture', 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/defaultUserImage.png')}
                className="charityManagerLogo mbottom16"
                alt="Charity logo"
              />
              <div className="chairtyManagerName mbottom8">{`${charityById.charity_users[0].user.firstname || ''} ${charityById.charity_users[0].user.lastname || ''}`}</div>
              <div className="charityManager mbottom8">Charity Manager</div>
              <h4 className="charityManagerDescription">{charityById.charity_users[0].user.about || ''}</h4>
            </div>
            <NetworkList charity={charityById} users={charityById.charity_followers} onShowAll={() => setShowMembers(true)} />
          </div>
        </div>
      </div>
      {showShare && (
        <ShareModal
          charity={charityById}
          onClose={() => setShowShare(false)}
          title={charityById.name}
          emailSubject={`${charityById.name} is on Worthy`}
          emailBody={`Take a look at ${charityById.name}'s profile on Worthy! ${window.location.href}`}
        />
      )}
      {showMembers && <MembersModal users={_.map(charityById.charity_followers, followers => followers.user)} title={charityById.name} onClose={() => setShowMembers(false)} />}
    </div>
  );
};

export default withRouter(CharityDetails);
