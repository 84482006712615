import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Button, Input } from '../../../common';
import { utils } from '../../../../lib/utils';
import { localStorage } from '../../../../lib/storage';

import inviteToJoinCharity from '../../../../mutations/join_charity/inviteToJoinCharity';

const SignupBlockContainer = styled.div`
  background: #e4eaf7;
  border-radius: 10px;
  padding: 28px;
  margin-bottom: 40px;

  @media (min-width: 767px) {
    margin-bottom: 0;
  }
`;

const SignupFormContainer = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignupHeading = styled.h3`
  font-family: 'Poppins' !important;
  font-weight: 700;
  font-size: 15px;
  margin: 0;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  width: 72%;
  margin-right: 10px;

  input {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

function SignupBlock(props) {
  const [email, setEmail] = useState('');
  const [inviteToCharity, useMutationData] = useMutation(inviteToJoinCharity);

  const onClickInvite = e => {
    e.preventDefault();
    inviteToCharity({
      variables: {
        joinCharity: {
          email,
          invited_by: localStorage.get('userID'),
          charity_id: props.charityId,
          accepted: false,
        },
      },
    });
  };

  return (
    <SignupBlockContainer>
      <SignupHeading>Do you know anyone that might be interested in this opportunity?</SignupHeading>
      <SignupFormContainer>
        <InputContainer>
          <Input name="email" placeholder="Enter email address" value={email} onChange={setEmail} />
        </InputContainer>
        <Button label={utils.isMobile() ? 'Send' : 'Send invite'} onClick={e => onClickInvite(e)} />
      </SignupFormContainer>
    </SignupBlockContainer>
  );
}

export default SignupBlock;
