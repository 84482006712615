import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { userUtils } from 'lib/user';
import CloseIcon from 'assets/svg/close.svg';
import { LoadingOverlay, Modal, Button, Input, SearchInput } from '../common';
import { IconButton } from '../common/IconButton/view';
import { localStorage } from '../../lib/storage';

// query
import fetchUserByKeyword from '../../queries/user/fetchUserByKeyword';
import fetchUnacceptedJoinCharitiesByInvitor from '../../queries/join_charity/unacceptedJoinCharitiesByInvitor';

// mutation
import inviteToJoinCharity from '../../mutations/join_charity/inviteToJoinCharity';

import './styles/component.scss';

function ModalInviteTeamMember(props) {
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isViaEmail, setIsViaEmail] = useState(true);
  const [volunteers, setVolunteers] = useState([]);
  const [selectedIndexs, setSelectedIndexs] = useState([]);

  const [getUsers, { error, data }] = useLazyQuery(fetchUserByKeyword, {
    onCompleted: () => setSearchLoading(false),
  });
  const searchFunc = useMemo(() => _.debounce(getUsers, 1000), [getUsers]);
  const [inviteToCharity, useMutationData] = useMutation(inviteToJoinCharity);

  const onEmailSend = forWho => {
    setLoading(true);
    for (let i = 0; i < selectedIndexs.length; i += 1) {
      inviteToCharity({
        variables: {
          joinCharity: {
            email: volunteers[selectedIndexs[i]].email,
            invited_by: localStorage.get('userID'),
            charity_id: props.charityId,
            accepted: false,
            role: forWho,
          },
        },
        refetchQueries: [
          {
            query: fetchUnacceptedJoinCharitiesByInvitor,
            variables: { invitedBy: localStorage.get('userID') },
            fetchPolicy: 'network-only',
          },
        ],
      });
    }
    setLoading(false);
    props.onClose();
  };

  const onInvitePeopleViaEmail = () => {
    setLoading(true);
    inviteToCharity({
      variables: {
        joinCharity: {
          email,
          invited_by: localStorage.get('userID'),
          charity_id: props.charityId,
          accepted: false,
          role: props.forWho,
        },
      },
      onCompleted: setLoading(false),
    });
    props.onClose();
  };

  const moveToExistingMemberInvite = () => {
    setIsViaEmail(false);
  };

  useEffect(() => {
    setIsViaEmail(true);
    setEmail('');
    setEmailValid('');
    setSearchText('');
    setSelectedIndexs([]);
  }, [props.open]);

  useEffect(() => {
    searchFunc({ variables: { keyword: searchText } });
    setSearchLoading(true);
  }, [searchText]);

  useEffect(() => {
    if (!data) return;
    setVolunteers(_.get(data, 'usersByKeyword', []));
  }, [data]);

  useEffect(() => {
    searchFunc({ variables: { keyword: searchText, limit: 5 } });
    return searchFunc.cancel;
  }, []);

  const renderSkillComponent = ({ _id, name, bgColor, labelColor, macro }) => {
    return (
      <div
        key={_id}
        className={`${macro ? 'macroBadge' : 'skillBadge'} bold`}
        style={{
          backgroundColor: bgColor,
          color: labelColor,
        }}
      >
        {name}
      </div>
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.onClose(false);
      }}
      className={`confirmModal ${!isViaEmail ? 'existing' : ''}`}
    >
      <IconButton icon={CloseIcon} className="modalClose" onClick={() => props.onClose(false)} />
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <div className="inviteFormContainer equalPadding textCenter">
        {isViaEmail ? (
          <>
            <h1 className="inviteFormHeader">Invite {props.forWho === 1 ? 'a Team Member' : 'an Ambassador'}</h1>
            <p className="inviteFormText">Invite a Charity Manager to join {props.charityName} on Worthy.</p>
            <Input
              name="email"
              type="email"
              placeholder="Invite via email"
              value={email}
              onChange={value => setEmail(value)}
              errorText={emailValid}
              onBlur={() => {
                if (userUtils.isValidEmail(email) === false) {
                  setEmailValid('Please enter a valid email.');
                } else {
                  setEmailValid('');
                }
              }}
            />
            <p className="inviteFormText">
              Or, search for an <a onClick={() => moveToExistingMemberInvite()}>existing volunteer</a>
            </p>
            <Button label="Invite" onClick={() => onInvitePeopleViaEmail()} disabled={!userUtils.isValidEmail(email)} />
          </>
        ) : (
          <>
            <h3 className="inviteFormText textLeft existing">Invite existing volunteers to join your team as {props.forWho === 1 ? 'a Charity Manager' : 'an Ambassador'}</h3>
            <SearchInput
              placeholder="Search Volunteers by name or skill"
              value={searchText}
              onChange={value => {
                setSelectedIndexs([]);
                setSearchText(value);
              }}
            />
            <hr />
            <div className="searchContentBlock">
              {volunteers &&
                volunteers.map((volunteer, index) => (
                  <div className="listItem" key={`volunteer-${index}`}>
                    <img src={_.get(volunteer, 'picture')} alt="Volunteer Avatar" />
                    <div className="volunteerInfo">
                      <span>{`${_.get(volunteer, 'firstname', '')} ${_.get(volunteer, 'lastname', '')}`}</span>
                      <span>{`${_.get(volunteer, 'location.state', '')} ${_.get(volunteer, 'location.country', '')}`}</span>
                    </div>
                    {volunteer.UserMacros &&
                      _.slice(volunteer.UserMacros, 0, 2).map(macro => {
                        return renderSkillComponent({ _id: macro._id, name: macro.name, bgColor: macro.color, labelColor: '#fff', macro: true });
                      })}
                    {_.size(volunteer.UserMacros) > 2 && <span style={{ fontSize: '36px', marginLeft: '6px' }}>...</span>}
                    <IconButton
                      className="addIcon"
                      icon={selectedIndexs.includes(index) ? '/icons/addedIcon.svg' : '/icons/addIcon.svg'}
                      onClick={() => {
                        selectedIndexs.indexOf(index) === -1
                          ? setSelectedIndexs([...selectedIndexs, index])
                          : setSelectedIndexs([...selectedIndexs].filter(item => item !== index));
                      }}
                    />
                  </div>
                ))}
            </div>
            <Button
              label="Invite"
              onClick={() => {
                onEmailSend(props.forWho);
              }}
            />
          </>
        )}
      </div>
    </Modal>
  );
}

export default ModalInviteTeamMember;
