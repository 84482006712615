import _ from 'lodash';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Chips, LoadingOverlay } from 'components/common';
import { FlatButton } from 'components/common/FlatButton/view';
import fetchTaskById from 'queries/task/fetchTaskById';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import './styles.scss';
import ExitToAppIcon from 'assets/svg/exit-to-app.svg';
import InProgressIcon from 'assets/svg/in-progress.svg';
import CalendarCheckIcon from 'assets/svg/calendar-check.svg';
import { CharityNetworkButton } from 'components/charity/networkButton/view';
import { charityUtils } from 'lib/charity';
import { SkillsList } from 'components/common/SkillsList/view';
import { ShareModal } from 'components/charity/details/shareModal/view';
import { TASK_FREQUENCY, TASK_ONGOING_HOURS_MAP, TASK_ONGOING_TYPE_MAP, TASK_TYPE } from 'lib/task';
import moment from 'moment';
import fetchUserById from 'queries/user/fetchUserById';
import { localStorage } from 'lib/storage';
import createTaskSubmission from 'mutations/task/createTaskSubmission';
import taskSubmissionsByTask from 'queries/task/taskSubmissionsByTask';
import { getSkillMacros } from 'lib/utils';
import { TASK_SUBMISSION_STATUS } from 'lib/task_submission';
import { OtherOpportunities } from './OtherOpportunities/view';
import { ApplyModal } from './ApplyModal/view';

const TaskDetails = ({ match }) => {
  const { task_id: taskId } = match.params;
  const userId = localStorage.get('userID');
  const [showShare, setShowShare] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const [taskApply] = useMutation(createTaskSubmission);
  const { data: taskData } = useQuery(fetchTaskById, {
    variables: {
      _id: taskId,
    },
  });
  const { data: userData } = useQuery(fetchUserById, {
    variables: { id: userId },
  });
  const { data: taskSubmissionsData } = useQuery(taskSubmissionsByTask, {
    variables: {
      taskId,
    },
  });
  const { taskByID } = taskData || {};
  const { userByID } = userData || {};
  const { taskSubmissionsByTask: applications } = taskSubmissionsData || {};
  const charityIdForUser = useMemo(() => _.get(userByID, 'CharitiesAdmin.0._id', ''), [userByID]);

  const onApply = useCallback(async () => {
    await taskApply({
      variables: {
        task_submission: {
          _task_id: taskId,
          _charity_id: taskByID.Charity._id,
          _user_id: userId,
          cancel: false, // will be true if other volunteer is assigned the task
          status: 1,
        },
      },
      refetchQueries: [
        {
          query: taskSubmissionsByTask,
          variables: { taskId },
        },
      ],
    });
  }, [taskApply, taskByID]);

  if (!taskByID || !userByID || !applications) return <LoadingOverlay />;
  const application = applications.find(item => _.get(item, 'User._id') === userId && _.get(item, 'status') !== 2);
  const inProgress = application && application.status === TASK_SUBMISSION_STATUS.IN_PROGRESS;

  const renderTaskApply = () => {
    if (!application) {
      return <FlatButton label="Apply now" filled className="mright16" onClick={() => setShowApply(true)} />;
    }
    if (inProgress) {
      return <FlatButton icon={InProgressIcon} label="In Progress" filled className="mright16" disabled bgColor="#EDBA42" />;
    }
    return <FlatButton label="You've applied!" bordered className="mright16" disabled />;
  };

  return (
    <div className="taskDetails col-xs-12 mbottom80">
      <div className="headerBackground"></div>
      <div className="container mtop80">
        <div className="row">
          <div className="col-xs-12 col-md-7">
            <a className="flexRow alignCenter" href={`/charity/${taskByID.Charity._id}`}>
              <img className="charityLogo mright8" alt="Charity logo" src={taskByID.Charity.picture} />
              <div>{taskByID.Charity.name}</div>
            </a>
            <h1 className="fontPoppins textBlack mtop16 mbottom32">{taskByID.name}</h1>
            {inProgress && <div className="textPrimary bold mbottom16">Task assigned to you</div>}
            <div className="flexRow mbottom144">
              {taskByID._user_id !== userId ? renderTaskApply() : ''}
              <FlatButton label="Share" icon={ExitToAppIcon} bordered onClick={() => setShowShare(true)} />
            </div>
            <h3 className="fontPoppins mbottom24">About this task</h3>
            <p className="textGrey mbottom104">{taskByID.description}</p>
          </div>
          <div className="col-xs-12 col-md-5">
            <div className="taskDetailsContainer">
              <div className="flexRow alignCenter mbottom32">
                <img src={CalendarCheckIcon} alt="calendar check icon" className="mright16" />
                <h2 className="bold m0">{taskByID.frequency === TASK_FREQUENCY.ONE_OFF ? 'Task' : 'Ongoing Opportunity'}</h2>
              </div>
              <div className="bold mbottom8">Skills:</div>
              <SkillsList skills={taskByID.TaskSkills} className="mbottom16" macros={getSkillMacros(taskByID.TaskSkills)} />
              <div className="mbottom16">
                <b>Volunteers Needed:</b>
                &nbsp;
                {taskByID.volunteers_needed}
              </div>
              <div className="mbottom16">
                <b>Location:</b>
                &nbsp;
                {taskByID.type === TASK_TYPE.FACE_TO_FACE
                  ? `${taskByID.location.street}, ${taskByID.location.city} ${taskByID.location.state} ${taskByID.location.postcode}`
                  : 'Remote'}
              </div>
              {taskByID.frequency === TASK_FREQUENCY.ONE_OFF ? (
                <>
                  <div className="mbottom16">
                    <b>Start Date:</b>
                    &nbsp;
                    {taskByID.oneoff_frequency_config.is_date_flexible ? 'Flexible' : moment(taskByID.oneoff_frequency_config.start).format('DD MMM YYYY')}
                  </div>
                  <div className="mbottom16">
                    <b>End Date:</b>
                    &nbsp;
                    {taskByID.oneoff_frequency_config.is_date_flexible ? 'Flexible' : moment(taskByID.oneoff_frequency_config.end).format('DD MMM YYYY')}
                  </div>
                </>
              ) : (
                <>
                  <div className="mbottom16">
                    <b>Type:&nbsp;</b>
                    {TASK_ONGOING_TYPE_MAP[taskByID.ongoing_frequency_config.type]}
                  </div>
                  <div className="mbottom16">
                    <b>Hours per week:&nbsp;</b>
                    {TASK_ONGOING_HOURS_MAP[taskByID.ongoing_frequency_config.hours_per_week]}
                  </div>
                </>
              )}
              <div className="divider mbottom24" />
              <div className="flexRow alignCenter mbottom32">
                <a className="flexRow alignCenter mRightAuto" href={`/charity/${taskByID.Charity._id}`}>
                  <img className="charityLogo mright8" alt="Charity logo" src={taskByID.Charity.picture} />
                  <div className="mRightAuto">{taskByID.Charity.name}</div>
                </a>
                {charityIdForUser !== taskByID.Charity._id && <CharityNetworkButton charity={taskByID.Charity} />}
              </div>
              <div className="bold mbottom16">About the charity</div>
              <div className="mbottom24">{taskByID.Charity.description}</div>
              <Chips values={charityUtils.normaliseCausesArray(taskByID.Charity.Causes)} />
            </div>
          </div>
        </div>
        <h3 className="fontPoppins mbottom32">Other Opportunities</h3>
        <OtherOpportunities />
      </div>
      {showShare && (
        <ShareModal
          onClose={() => setShowShare(false)}
          title={taskByID.name}
          emailSubject={`${userByID.firstname} ${userByID.lastname} shared a task with you!`}
          emailBody={`${userByID.firstname} ${userByID.lastname} shared a task with you on Worthy:\n${taskByID.name}\n${window.location.href}`}
        />
      )}
      {showApply && <ApplyModal task={taskByID} onClose={() => setShowApply(false)} onApply={onApply} />}
    </div>
  );
};

export default withRouter(TaskDetails);
