import React from 'react';
import _ from 'lodash';
import { Modal, Button } from '../../common';

// Styles
import './styles/modalCancelAttendanceStyles.css';

const ModalCancelAttendance = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className="mocalCancelAttendance flexCenter textCenter"
        >
            <a onClick={props.onClose}>Close</a>
            <p>Oh no, what a shame!<br /> We&apos;ve removed you from attending &apos;{_.get(props.event, 'name')}&apos; for {_.get(props.event, 'charity.name')}</p>
            <Button
                label="OK"
                onClick={props.onClose}
                className="btnOK"
            />
        </Modal>
    );
};

export default ModalCancelAttendance;
