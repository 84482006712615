import gql from 'graphql-tag';

export default gql`
  query FetchSkills {
    skills {
      _id
      name
      macro
      _macro_id
      Macro {
        _id
        name
        category
        color
      }
      skill_color
      skill_label_color
    }
  }
`;
