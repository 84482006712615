import React, { useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Input, Button, LoadingOverlay } from '../../common';

// Mutations
import forgotPasswordGQL from '../../../mutations/user/forgotPassword';

// Styles
import './styles/forgotPasswordStyles.css';

const ForgotPassword = ({ forgotPassword }) => {
  const [user, setUser] = useState({
    email: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async event => {
    if (event) event.preventDefault();

    setError('');
    setLoading(true);

    try {
      await forgotPassword(user.email);
      setLoading(false);
      setEmailSent(true);
    } catch (e) {
      setError('Hmm. We don’t recognise that email.');
      setLoading(false);
    }
  };

  const renderForgotPasswordContent = () => {
    if (emailSent) {
      return (
        <div className="accountFormContainer equalPadding emailSentContainer">
          <img src="/icons/email-tick.svg" alt="email verification icon" className="formIcon mtop10" />
          <h1 className="accountFormHeader smallMargin">Email sent!</h1>
          <p className="accountFormText">
            We&apos;ve sent you an email with instructions to reset your password. Please check your Spam inbox if you don&apos;t receive it shortly.
          </p>
          <div className="buttonsContainer mtop20 mbottom40">
            <Button label="Login" href="/login" />
          </div>
          <p className="accountFormText">
            Still need help?{' '}
            <a className="bold" href="/contact-us">
              Contact us
            </a>
          </p>
        </div>
      );
    }

    return (
      <div className="accountFormContainer">
        <h1 className="accountFormHeader smallMargin">Update your password</h1>
        <p className="accountFormText">
          Please enter your email address. <br />
          You will receive a link to create a new password via email.
        </p>

        <form onSubmit={handleSubmit} className="mtop30">
          <Input
            name="email"
            placeholder="Email address"
            type="email"
            value={user.email}
            errorText={error}
            onChange={value => {
              setUser(prevState => ({
                ...prevState,
                email: value,
              }));
            }}
          />

          <div className="buttonsContainer mtop10 mbottom40">
            <Button label="Reset password" type="submit" disabled={!user.email} />
          </div>
        </form>

        <p className="accountFormText">
          <a className="bold" href="/login">
            Back to login
          </a>
        </p>
      </div>
    );
  };

  return (
    <div className="forgotPasswordContainer">
      <div className="forgotPasswordContainerInternal centredContainer textCenter">
        {loading && <LoadingOverlay style={{ opacity: 0.7 }} />}

        {renderForgotPasswordContent()}
      </div>
    </div>
  );
};

const forgotPasswordMutation = graphql(forgotPasswordGQL, {
  props: ({ mutate }) => ({
    forgotPassword: email =>
      mutate({
        variables: { email },
      }),
  }),
});

export default forgotPasswordMutation(ForgotPassword);
