import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router';
import { Query } from '@apollo/client/react/components';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import TaskDetails from 'components/task/TaskDetails/TaskDetails';
import { AppProvider } from './components/common';
import fetchUserRoleByID from './queries/user/fetchUserRoleByID';
import { localStorage } from './lib/storage';
import App from './components/app/view';
import Login from './components/user/login/view';
import ForgotPassword from './components/user/forgotPassword/view';
import ResetPassword from './components/user/resetPassword/view';
import SignUp from './components/user/signUp/view';
import Homepage from './components/homepage/view';
import Profile from './components/user/profile/view';
import ConfirmAccount from './components/user/confirmAccount/view';
import CreateCharity from './components/charity/create/view';
import CharityDetails from './components/charity/details/view';
import CreateCharityFinish from './components/charity/create/viewFinish';
import CreateAmbassador from './components/ambassador/create/view';
import AmbassadorDetails from './components/ambassador/details/view';
import CreateEvent from './components/event/create/view';
import FinishEventCreation from './components/event/create/viewFinish';
import EventDetails from './components/event/details/view';
import VolunteerConfirmation from './components/event/details/viewVolunteerConfirmation';
import ApplyForEvent from './components/event/details/viewApply';
import ProfileCharities from './components/user/old_profile/charityListView';
// import Events from './components/event/view';
// import ReviewCharityEvents from './components/charity/details/charityListToReview';
import ReviewEvent from './components/event/review/view';
import TermsUse from './components/legal/termsOfUse/view';
import PrivacyPolicy from './components/legal/privacy/view';
import TermsConditions from './components/legal/termsConditions/view';
import AboutUs from './components/about/view';
import ForCharities from './components/about/viewForCharities';
import ForVolunteers from './components/about/viewForVolunteers';
import Contact from './components/about/viewFAQ';
import Charities from './components/charity/view';
import MyEvents from './components/user/myEvents/view';
import Settings from './components/settings/view';
import Admin from './components/admin/view';
import Onboarding from './components/onboarding/view';
import CreateTask from './components/task/create';
import SearchTasks from './components/task/search/view';
import Dashboard from './components/dashboard';

const muiTheme = getMuiTheme({
  palette: {
    accent1Color: '#2723EB',
    accent2Color: '#fafafa',
    accent3Color: '#2723EB',
    primary1Color: '#2723EB',
    textColor: '#4F4F4F',
  },
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = () => {
    return !!localStorage.get('userID');
  };

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const SecuredPrivateRoute = ({ component: Component, ...rest }) => (
  <Query id="userRoleByID" query={fetchUserRoleByID} variables={{ id: localStorage.get('userID') }}>
    {result => {
      if (result.loading) return null;
      const { userByID } = result.data;
      return <Route {...rest} render={props => (userByID && userByID.roles.includes('Admin') ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />)} />;
    }}
  </Query>
);

const Routes = props => {
  return (
    <MuiThemeProvider muiTheme={muiTheme}>
      <Router {...props}>
        <AppProvider>
          <App>
            <Switch>
              <Route path="/login" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route path="/forgot-password/:requestId" component={ResetPassword} />
              <Route exact path="/sign-up" component={SignUp} />
              <Route path="/sign-up/:requestId" component={SignUp} />
              <Route path="/confirm-account/:userId" component={ConfirmAccount} />
              <Route exact path="/charity/:charity_id" component={CharityDetails} />
              <Route exact path="/charities" component={Charities} />
              <Route exact path="/event/:event_id" component={EventDetails} />
              <PrivateRoute exact path="/task/create/:step" component={CreateTask} />
              <Route exact path="/task/search" component={SearchTasks} />
              <Route exact path="/task/:task_id" component={TaskDetails} />
              {/* <Route path="/events" component={Events} /> */}
              <Route path="/terms-use" component={TermsUse} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/terms" component={TermsConditions} />
              <Route path="/about" component={AboutUs} />
              <Route path="/for-charities" component={ForCharities} />
              <Route path="/for-volunteers" component={ForVolunteers} />
              <Route path="/contact" component={Contact} />
              <PrivateRoute exact path="/ambassador/register/new" component={CreateAmbassador} />
              <Route path="/ambassador/:ambassador_id" component={AmbassadorDetails} />
              <PrivateRoute exact path="/profile/:user_id" component={Profile} />
              <Route exact path="/profile/:user_id/charities" component={ProfileCharities} />
              <PrivateRoute path="/charity/register/:step" component={CreateCharity} />
              <PrivateRoute path="/charity-finish" component={CreateCharityFinish} />
              <PrivateRoute path="/event/register/:step" component={CreateEvent} />
              <PrivateRoute path="/event-finish" component={FinishEventCreation} />
              <PrivateRoute path="/event/:event_id/volunteer_confirmation" component={VolunteerConfirmation} />
              <PrivateRoute path="/event/:event_id/apply" component={ApplyForEvent} />
              {/* <PrivateRoute path="/charity/:charity_id/review-events" component={ReviewCharityEvents} /> */}
              <PrivateRoute path="/review-event/:event_id/:step" component={ReviewEvent} />
              <PrivateRoute path="/my-events" component={MyEvents} />
              <PrivateRoute path="/settings" component={Settings} />
              <PrivateRoute path="/onboarding/:step" component={Onboarding} />
              <PrivateRoute path="/dashboard/:page/charity/:charity/macro/:macro" component={Dashboard} />
              <PrivateRoute path="/dashboard/:page/macro/:macro" component={Dashboard} />
              <PrivateRoute path="/dashboard/:page/direct/:user_id" component={Dashboard} />
              <PrivateRoute path="/dashboard/:page/group/:group_id" component={Dashboard} />
              <PrivateRoute path="/dashboard/:page" component={Dashboard} />
              <SecuredPrivateRoute exact path="/admin/charities" component={Admin} />
              <Route path="/" component={Homepage} />
            </Switch>
          </App>
        </AppProvider>
      </Router>
    </MuiThemeProvider>
  );
};

export default Routes;
