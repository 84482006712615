import React, { useState } from 'react';
import { withRouter } from 'react-router';
import Gabriel from './assets/gabriel.png';
import { Button, Causes } from '../common';

function OnboardingAboutYou(props) {
  const [selectedCauses, setSelectedCauses] = useState([]);

  const handleSubmit = () => {
    props.history.push({
      pathname: '/onboarding/skills',
      state: {
        data: {
          ...props.location.state.data,
          causes: selectedCauses,
        },
      },
    });
  };

  return (
    <div className="informationContainer noSidebar">
      <div className="informationContent">
        <h2 className="mbottom8">What causes would you like to help?</h2>
        <p className="mbottom32">Select at least 1 interest so we can recommend opportunities and charities</p>
        <Causes onGetNewCausesArray={newSelectedCauses => setSelectedCauses(newSelectedCauses)} containerClass="mtop20" selectedValues={selectedCauses} />
      </div>
      <div className="buttonContainer">
        <Button
          label="Continue"
          type="button"
          labelStyle={{
            paddingLeft: 30,
            paddingRight: 30,
          }}
          onClick={handleSubmit}
          disabled={selectedCauses.length === 0}
        />
      </div>
    </div>
  );
}

export default withRouter(OnboardingAboutYou);
