import React from 'react';

// Styles
import '../styles/legalStyles.css';

function TermsOfUse() {
  return (
    <div className="legalContainerNew">
      <div className="header">
        <h1>Terms of Use</h1>
      </div>
      <div className="mainContainer">
        <div className="content">
          <h1 id="terms">Terms of Use</h1>
          <p>
            This website ( Site ) is operated by Voluntech Pty Ltd (ACN 623 358 663) trading as Worthy® ( we , our or us ). It is available at: https://www.worthycause.com.au and
            may be available through other addresses or channels.
          </p>
          <p>
            <span className="bold">Consent:</span> By accessing and/or using our Site, you agree to these Terms of Use and our Privacy Policy (available on our Site) (Terms).
            Please read these Terms carefully and immediately cease using our Site if you do not agree to them.
          </p>
          <p>
            You may visit our Site or register an account as a Volunteer, Charity, Business or Authorised User (as defined in the Terms of Service available on the Site) on the
            online platform (<span className="bold">Account</span>)
          </p>
          <p>
            If you are a minor (under the age of 18) accessing or using the Site you must have the permission of and have your parent or guardian read and agree to these Terms
            prior to your use of the Site.
          </p>
          <p>
            <span className="bold">Variations:</span> We may, at any time and at our discretion, vary these Terms by publishing the varied terms on our Site. We recommend you check
            our Site regularly to ensure you are aware of our current terms. Materials and information on this Site ( <span className="bold">Consent:</span> ) are subject to change
            without notice. We do not undertake to keep our Site up-to-date and we are not liable if any Content is inaccurate or out-of-date.
          </p>
          <p>
            <span className="bold">Licence to use our Site:</span> We grant you a non-exclusive, royalty-free, revocable, worldwide, non- transferable licence to use our Site in
            accordance with these Terms. All other uses are prohibited without our prior written consent.
          </p>
          <p>
            <span className="bold">Use</span>: You must only use the Site in accordance with and as permitted by these Terms and for no other purpose.{' '}
            <span className="bold">Prohibited conduct</span> : In consideration for the Licence granted to you, you must not do or attempt to do anything: that is unlawful;
            prohibited by any laws or regulations applicable to our Site; which we would consider inappropriate; or which might bring us or our Site into disrepute, including
            (without limitation):
          </p>
          <p>
            (a) anything that would constitute a breach of an individual&apos;s privacy (including uploading private or personal information without an individual&apos;s consent)
            or any other legal rights;
          </p>
          <p>(b) using our Site to defame, harass, threaten, menace, offend or impersonate any person or other entity;</p>
          <p>(c) interfering with any user using our Site;</p>
          <p>
            (d) tampering with or modifying our Site, knowingly transmitting viruses or other disabling features, or damaging or interfering with our Site, including (without
            limitation) using trojan horses, viruses or piracy or programming routines that may damage or interfere with our Site;
          </p>
          <p>(e) scraping information from our Site or using website scraping software on our Site;</p>
          <p>(f) using our Site to send unsolicited messages via the Platform messaging function, email messages or any other form of electronic communication service; or</p>
          <p>(g) facilitating or assisting a third party to do any of the above acts.</p>
          <p>You may share and embed links from our Site on third-party websites, however:</p>
          <p>
            (a) You must not represent yourself as being in any way connected with Voluntech or any business conducted by Voluntech or having any relationship of employment, trust,
            agency, partnership or joint venture without obtaining our prior written consent, which may be withheld at our absolute discretion;
          </p>
          <p>(b) We reserve the right to remove or request the removal of any such links at our sole discretion.</p>
          <p>
            <span className="bold">Exclusion of competitors</span> : You are prohibited from using our Site, including the Content, in any way that competes with our business.
          </p>
          <p>
            <span className="bold">Information</span>: You acknowledge and agree that:
          </p>
          <p>
            (a) The Content is not comprehensive and is for general information purposes only. It does not take into account your specific needs, objectives or circumstances, and
            it is not advice. While we use reasonable attempts to ensure the accuracy and completeness of the Content, we make no representation or warranty in relation to it, to
            the maximum extent permitted by law.
          </p>
          <p>
            (b) The Content on our Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be
            contrary to law or regulation within that jurisdiction or country. If you access or use of the Site from such locations, you acknowledge that you do so at your own
            initiative and you are solely responsible for compliance with any applicable laws.
          </p>
          <p>
            <span className="bold">Intellectual Property rights:</span> Unless otherwise indicated, we own or licence all rights, title and interest (including intellectual
            property rights) in our Site and all of the Content. Your use of our Site and your use of and access to any Content does not grant or transfer to you any rights, title
            or interest in relation to our Site or the Content. You must not:
          </p>
          <p>(a) copy or use, in whole or in part, any Content;</p>
          <p>(b) reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any Content to any third party; or</p>
          <p>
            (c) breach any intellectual property rights connected with our Site or the Content, including (without limitation) altering or modifying any of the Content, causing any
            of the Content to be framed or embedded in another website or platform, or creating derivative works from the Content.
          </p>
          <p>
            <span className="bold">User Content:</span> You may be permitted to post, upload, publish, submit or transmit relevant information and content ( User Content ) on our
            Site. By making available any User Content on or through our Site, you grant to us a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free
            licence to use the User Content, with the right to use, view, copy, adapt, modify, distribute, license, sell, transfer, communicate, publicly display, publicly perform,
            transmit, stream, broadcast, access, or otherwise exploit such User Content on, through or by means of our Site.
          </p>
          <p>You agree that you are solely responsible for all User Content that you make available on or through our Site. You represent and warrant that:</p>
          <p>
            (a) you are either the sole and exclusive owner of all User Content or you have all rights, licences, consents and releases that are necessary to grant to us the rights
            in such User Content (as contemplated by these Terms); and
          </p>
          <p>
            (b) neither the User Content nor the posting, uploading, publication, submission or transmission of the User Content or our use of the User Content on, through or by
            means of our Site will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation
            of any applicable law or regulation.
          </p>
          <p>We do not endorse or approve, and are not responsible for, any User Content. We may, at any time (at our sole discretion), remove any User Content.</p>
          <p>
            <span className="bold">Third party sites:</span> Our Site may contain links to websites operated by third parties. Unless expressly stated otherwise, we do not control,
            endorse or approve, and are not responsible for, the content on those websites. You should make your own investigations with respect to the suitability of those
            websites.
          </p>
          <p>
            <span className="bold">Discontinuance:</span> We may, at any time and without notice to you, discontinue our Site, in whole or in part. We may also exclude any person
            from using our Site, at any time and at our sole discretion. We are not responsible for any Liability you may suffer arising from or in connection with any such
            discontinuance or exclusion.
          </p>
          <p>
            <span className="bold">Warranties and disclaimers:</span> To the maximum extent permitted by law, we make no representations or warranties about our Site or the
            Content, including (without limitation) that:
          </p>
          <p>(a) they are complete, accurate, reliable, up-to-date and suitable for any particular purpose;</p>
          <p>(b) access will be uninterrupted, error-free or free from viruses; or</p>
          <p>(c) our Site will be secure.</p>
          <p>You read, use and act on our Site and the Content at your own risk.</p>
          <p>
            <span className="bold">Limitation of liability:</span> To the maximum extent permitted by law, we are not responsible for any loss, damage or expense, howsoever
            arising, whether direct or indirect and/or whether present, unascertained, future or contingent ( Liability ) suffered by you or any third party, arising from or in
            connection with your use of our Site and/or the Content and/or any inaccessibility of, interruption to or outage of our Site and/or any loss or corruption of data
            and/or the fact that the Content is incorrect, incomplete or out-of-date.
          </p>
          <p>
            <span className="bold">Indemnity:</span> To the maximum extent permitted by law, you must indemnify us, and hold us harmless, against any Liability suffered or incurred
            by us arising from or in connection with your use of our Site or any breach of these Terms or any applicable laws by you. This indemnity is a continuing obligation,
            independent from the other obligations under these Terms, and continues after these Terms end. It is not necessary for us to suffer or incur any Liability before
            enforcing a right of indemnity under these Terms.
          </p>
          <p>
            <span className="bold">Termination:</span> These Terms are effective until terminated by us, which we may do at any time and without notice to you. In the event of
            termination, all restrictions imposed on you by these Terms and limitations of liability set out in these Terms will survive.
          </p>
          <p>
            <span className="bold">Disputes:</span> In the event of any dispute arising from, or in connection with, these Terms ( Dispute ), the party claiming there is a Dispute
            must give written notice to the other party setting out the details of the Dispute and proposing a resolution. Within 7 days after receiving the notice, the parties
            must, by their senior executives or senior managers (who have the authority to reach a resolution on behalf of the party), meet at least once to attempt to resolve the
            Dispute or agree on the method of resolving the Dispute by other means, in good faith. All aspects of every such conference, except the fact of the occurrence of the
            conference, will be privileged. If the parties do not resolve the Dispute, or (if the Dispute is not resolved) agree on an alternate method to resolve the Dispute,
            within 21 days after receipt of the notice, the Dispute may be referred by either party (by notice in writing to the other party) to litigation.
          </p>
          <p>
            <span className="bold">Severance:</span> If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision must be read down as
            narrowly as necessary to allow it to be valid or enforceable. If it is not possible to read down a provision (in whole or in part), that provision (or that part of that
            provision) is severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions in these Terms.
          </p>
          <p>
            <span className="bold">Jurisdiction:</span> Your use of our Site and these Terms are governed by the laws of New South Wales, Australia. You irrevocably and
            unconditionally submit to the exclusive jurisdiction of the courts operating in New South Wales and any courts entitled to hear appeals from those courts and waive any
            right to object to proceedings being brought in those courts.
          </p>
          <p>
            Our Site may be accessed throughout Australia and overseas. We make no representation that our Site complies with the laws (including intellectual property laws) of any
            country outside Australia. If you access our Site from outside Australia, you do so at your own risk and are responsible for complying with the laws of the jurisdiction
            where you access our Site.
          </p>
          <p className="bold">For any questions and notices, please contact us at :</p>
          <p>Voluntech Pty Ltd ACN 623 358 663 trading as Worthy®</p>
          <p>
            <span className="bold">Email:</span> mail@testenvironment1.com
          </p>
          <p>
            <span className="bold">Last update:</span> 23 October 2022
          </p>
        </div>
        <div className="sidebar">
          <a href="#terms">Terms of Use</a>
          <a href="#contact">Contact</a>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
