import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import IconButton from 'material-ui/IconButton';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle-outline';
import { Modal, Chips, Input, Button, LoadingOverlay } from '../../common';
import { update } from '../../../lib/utils';

// Queries
import fetchUserById from '../../../queries/user/fetchUserById';

// Mutations
import updateUserMutation from '../../../mutations/user/updateUser';

// Styles
import '../profile/styles/profileModals.scss';

class ModalAddSkills extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        skills: [],
      },
      loading: false,
      error: '',
      skillToAdd: '',
    };

    this.onSaveSkills = this.onSaveSkills.bind(this);
    this.onAddSkill = this.onAddSkill.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.open && this.state.user.skills.length !== newProps.userByID.skills.length) {
      this.setState({
        user: {
          ...this.state.user,
          skills: update(this.state.user.skills, {
            $push: newProps.userByID.skills,
          }),
        },
      });
    }
  }

  onAddSkill(event) {
    if (event) event.preventDefault();

    if (this.state.skillToAdd) {
      this.setState({
        user: {
          ...this.state.user,
          skills: update(this.state.user.skills, {
            $push: [this.state.skillToAdd],
          }),
        },
        skillToAdd: '',
      });
    }
  }

  onSaveSkills() {
    const { userByID } = this.props;
    if (userByID._id) {
      this.setState({ error: '', loading: true });
      this.props
        .updateUser(userByID._id, this.state.user)
        .then(() => {
          this.setState({ loading: false });
          this.props.onClose();
        })
        .catch(error => {
          this.setState({ error, loading: false });
        });
    } else {
      this.props.onClose();
    }
  }

  normaliseSkillsArray(skills) {
    return skills.map(skill => {
      return {
        _id: skill,
        label: skill,
      };
    });
  }

  render() {
    const { userByID } = this.props;
    return (
      <Modal open={this.props.open} onClose={this.props.onClose} className="defaultModal">
        {this.state.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
        {this.state.error && <span className="error">{this.state.error}</span>}
        <div className="subHeader">
          <h3>{`${userByID.firstname}'s Skills`}</h3>
          <a onClick={this.props.onClose}>Close</a>
        </div>
        <div className="specificContainer">
          <form onSubmit={this.onAddSkill}>
            <Input
              name="addSpecificSkills"
              placeholder="E.g Accounting..."
              type="text"
              containerStyle="inputContainer"
              value={this.state.skillToAdd}
              fullWidth={false}
              onChange={value => this.setState({ skillToAdd: value })}
            />
            <IconButton className={`addItem ${this.state.skillToAdd ? 'enabled' : ''}`} onClick={this.onAddSkill}>
              <AddCircleOutline color="#B5BDC4" />
            </IconButton>
          </form>
        </div>
        <div className="skillsAddedContainer">
          <Chips
            containerStyle="mtop20"
            values={this.normaliseSkillsArray(this.state.user.skills)}
            onDelete={value => {
              this.setState({
                user: {
                  ...this.state.user,
                  skills: update(this.state.user.skills, {
                    $splice: [[this.state.user.skills.findIndex(item => item === value._id), 1]],
                  }),
                },
              });
            }}
          />
        </div>
        <Button label="Save" onClick={this.onSaveSkills} className="btnDefault mtop40" disabled={this.state.user.skills.length === 0} />
      </Modal>
    );
  }
}

const updateUserMutationContainer = graphql(updateUserMutation, {
  props: ({ mutate }) => ({
    updateUser: (id, user) =>
      mutate({
        variables: { id, user },
        refetchQueries: [
          {
            query: fetchUserById,
            variables: { id },
          },
        ],
      }),
  }),
});

export default updateUserMutationContainer(ModalAddSkills);
