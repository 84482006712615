import React, { useState, useCallback } from 'react';
import Modal from 'components/common/Modal/view';
import { Button, Causes, LoadingOverlay } from 'components/common';
import { ApolloProvider, useMutation, useApolloClient } from '@apollo/client';
import _ from 'lodash';
// Mutations
import updateUserMutation from '../../../../mutations/user/updateUser';

export const ModalAddInterests = ({ open, userByID, onClose }) => {
  const [selectedCauses, setSelectedCauses] = useState(userByID.Causes.map(cause => cause._id));
  const [isLoading, setIsLoading] = useState(false);
  // Mutations
  const [updateUser] = useMutation(updateUserMutation);
  const apolloClient = useApolloClient();

  const handleUpdateCauses = useCallback(async () => {
    setIsLoading(true);
    const updatedData = await updateUser({
      variables: {
        id: _.get(userByID, '_id'),
        user: {
          interests: selectedCauses,
        },
      },
    });
    const updateCauses = _.get(updatedData, 'data.updateUser.Causes');
    setSelectedCauses(updateCauses.map(cause => cause._id));
    setIsLoading(false);
    onClose();
  }, [selectedCauses]);

  return (
    <Modal open={open} onClose={onClose} className="AddCausesContainer">
      <ApolloProvider client={apolloClient}>
        {isLoading && <LoadingOverlay style={{ opacity: 0.8 }} />}
        <h2 className="title textCenter mbottom32">What causes are you most passionate for?</h2>
        <p className="subTitle">Select causes below:</p>
        <Causes onGetNewCausesArray={setSelectedCauses} containerClass="mtop20 mbottom32 causes" selectedValues={selectedCauses} />
        <div className="flexCenter">
          <Button
            label="Update"
            type="button"
            labelStyle={{
              paddingLeft: 30,
              paddingRight: 30,
            }}
            onClick={handleUpdateCauses}
            disabled={selectedCauses.length === 0}
          />
        </div>
      </ApolloProvider>
    </Modal>
  );
};
