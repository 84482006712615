import { gql } from '@apollo/client';

export const taskFieldsFragment = gql`
  fragment TaskFields on Task {
    _id
    _user_id
    _charity_id
    name
    type
    status
    frequency
    oneoff_frequency_config {
      start
      end
      is_date_flexible
    }
    ongoing_frequency_config {
      type
    }
    Charity {
      _id
      name
      charity_users {
        user {
          _id
          firstname
          lastname
        }
      }
    }
    TaskSubmissions {
      _id
      _user_id
      _task_id
      status
      User {
        _id
        firstname
        lastname
      }
      TaskTimesheet {
        _id
        status
        start
        end
      }
      TaskReview {
        _id
        _user_id
        rating
        message
      }
    }
  }
`;
