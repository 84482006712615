import React, { useMemo, useState } from 'react';
import _, { isEmpty } from 'lodash';

import { Chips } from 'components/common';
import { FlatButton } from 'components/common/FlatButton/view';
import { userUtils, getAddOrEditLabel } from 'lib/user';
import { normaliseCausesArray } from 'lib/utils';
import CausesEmptyIcon from 'assets/svg/causes-empty.svg';
import { ModalAddInterests } from './modalAddInterests';

export const ProfileCauses = ({ user }) => {
  const [showAdd, setShowAdd] = useState(false);
  const btnLabel = useMemo(() => getAddOrEditLabel(_.get(user, 'Causes')), [user]);
  return (
    <div className="causesContainer">
      <div className="flowRow alignCenter">
        <h2>Causes</h2>
        {userUtils.isAdmin(user._id) && !isEmpty(_.get(user, 'Causes')) && <FlatButton className="mLeftAuto" label={btnLabel} onClick={() => setShowAdd(true)} />}
      </div>
      <Chips values={normaliseCausesArray(user.Causes)} />
      {showAdd && <ModalAddInterests open userByID={user} onClose={() => setShowAdd(false)} />}
      {isEmpty(_.get(user, 'Causes')) && (
        <div className="flexCenter textCenter">
          <img src={CausesEmptyIcon} alt="Causes Empty Icon" />
          <div className="emptySubtitle mtop16">What causes would you like to help?</div>
          <div className="emptyDescription mtop8 mbottom16">Select at least 1 interest so we can recommend opportunities and charities</div>
          <FlatButton label="Select causes" onClick={() => setShowAdd(true)} />
        </div>
      )}
    </div>
  );
};
