import React, { Component } from 'react';
import { List, ListItem } from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';
import KeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import KeyboardArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import DeleteForever from 'material-ui/svg-icons/action/delete-forever';

// Styles
import './styles/listStyles.css';

class ListComponent extends Component {
    constructor() {
        super();

        this.state = {
            openListItem: null
        };

        this.renderListItem = this.renderListItem.bind(this);
        this.onNestedListClick = this.onNestedListClick.bind(this);
    }

    onNestedListClick(value, index) {
        if (value.opened) {
            // Closing list
            this.setState({ openListItem: null });
            if (this.props.onClick) this.props.onClick('close', index);
        } else if (this.state.openListItem === index) {
            // Closing list
            this.setState({ openListItem: null });
            if (this.props.onClick) this.props.onClick('close', index);
        } else {
            // Opening List
            this.setState({ openListItem: index });
            if (this.props.onClick) this.props.onClick('open', index);
        }
    }

    renderListItem(value, index) {
        let showToggle = false;
        // If some nested component exists then it created a toggle on right and render it
        if (value.component) {
            showToggle = true;
        }
        let rightIcon = <KeyboardArrowDown color="#20C1C9" className="defaultArrowStyle" />;
        // if (!value.opened) {
        //     rightIcon = <KeyboardArrowDown className="defaultArrowStyle" />;
        // } else if (this.state.openListItem === index || value.initiallyOpen) {
        //     rightIcon = <KeyboardArrowUp className="defaultArrowStyle" />;
        // }
        if (this.state.openListItem === index || value.initiallyOpen) {
            rightIcon = <KeyboardArrowUp color="#20C1C9" className="defaultArrowStyle" />;
        }
        return (
            <div key={`${value.name}-${index}`} className={`${value.className ? value.className : ''}`}>
                <ListItem
                    key={`${value.name}-${index}-child`}
                    primaryText={value.name}
                    secondaryText={value.secondaryText && value.secondaryText}
                    initiallyOpen={value.initiallyOpen}
                    nestedItems={[value.component]}
                    rightIcon={rightIcon}
                    primaryTogglesNestedList={showToggle}
                    hoverColor={value.hoverColor}
                    onClick={() => this.onNestedListClick(value, index)}
                />
                {this.props.onRemove && value.opened &&
                    <a
                        className="delete"
                        onClick={() => this.props.onRemove(index)}
                    >
                        <DeleteForever
                            className="deleteIcon"
                        />
                    </a>
                }
                {(index + 1) < this.props.values.length &&
                    <Divider className="defaultDivider" />
                }
            </div>
        );
    }

    render() {
        return (
            <List
                style={this.props.style || {}}
                className={this.props.className || ''}
            >
                {this.props.title && (
                    <Subheader
                        style={this.props.titleStyle || ''}
                    >
                        {this.props.title}
                    </Subheader>
                )}
                {this.props.values.map((value, index) => this.renderListItem(value, index))}
            </List>
        );
    }
}

export default (ListComponent);
