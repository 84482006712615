import React from 'react';
import { withRouter } from 'react-router';
import Button from '../../Button/view';
import { localStorage } from '../../../../lib/storage';

// Assets
import whyImage from './assets/whyBanner.svg';

// Styles
import './styles/bannersStyles.css';

const WhyBanner = (props) => {
    return (
        <div className={`banner banner-why ${props.className ? props.className : ''}`}>
            <div className="internalContainerStyle">
                <div className="imgContainer">
                    <img src={whyImage} alt="Illustration" />
                </div>
                <div className="content">
                    <h1>Worthy connects people with charities</h1>
                    <p>Join now to find volunteer opportunities, stay connected and build your profile as a volunteer</p>
                    <a href="/about">Learn More</a>
                    <div className="buttonsContainer">
                        {!localStorage.get('userID') &&
                            <Button
                                label="Sign Up Now"
                                href="/sign-up"
                                className="btnSignUp"
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(WhyBanner);
