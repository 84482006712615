import React from 'react';
// import ReactTooltip from 'react-tooltip';

import QuestionIcon from './assets/question.svg';
import ExclamationIcon from './assets/exclamation.svg';

import './styles/tooltipStyles.css';

function Tooltip({ text, icon, type = 'horizontal' }) {
  const handleOpenTooltip = () => {
    if (window.innerWidth < 768) {
      // eslint-disable-next-line
      window.alert(text);
    }
  };

  return (
    <div className="tooltipComponent" onClick={handleOpenTooltip}>
      <img src={icon === 'exclamation' ? ExclamationIcon : QuestionIcon} alt="Tooltip" />
      <span className={`tooltipText ${type}`}>{text}</span>
    </div>
  );
}

export default Tooltip;
