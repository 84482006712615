import gql from 'graphql-tag';

export default gql`
    query SearchCharities($searchText: String, $searchCauses: [ID], $status: Int) {
        searchCharities(searchText: $searchText, searchCauses: $searchCauses, status: $status) {
            _id
            name
            picture
            user_id
            location {
                location
                street
                city
                postcode
                state
                country
                latitude
                longitude
            }
            Causes {
                _id
                name
            }
            charity_followers {
                user_id
            }
        }
    }
`;
