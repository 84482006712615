const globalVariables = {
  getEnvironmentVariables() {
    const envVariables = {};
    switch (window.location.hostname) {
      case 'localhost':
        envVariables.graphqlURI = 'http://localhost:4000/graphql';
        envVariables.uploadURI = 'http://localhost:4000/upload';
        envVariables.environment = 'DEV';
        envVariables.fileServer = 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/';
        break;
      case 'worthycause.com.au':
        envVariables.graphqlURI = 'https://graphql.worthycause.com.au/graphql';
        envVariables.uploadURI = 'https://graphql.worthycause.com.au/upload';
        envVariables.environment = 'PROD';
        envVariables.fileServer = 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/';
        break;
      case 'www.worthycause.com.au':
        envVariables.graphqlURI = 'https://graphql.worthycause.com.au/graphql';
        envVariables.uploadURI = 'https://graphql.worthycause.com.au/upload';
        envVariables.environment = 'PROD';
        envVariables.fileServer = 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/';
        break;
      case 'www.devenvworthy.com':
        envVariables.graphqlURI = 'https://graphql.devenvworthy.com/graphql';
        envVariables.uploadURI = 'https://graphql.devenvworthy.com/upload';
        envVariables.environment = 'DEV';
        envVariables.fileServer = 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/';
        break;
      case 'devenvworthy.com':
        envVariables.graphqlURI = 'https://graphql.devenvworthy.com/graphql';
        envVariables.uploadURI = 'https://graphql.devenvworthy.com/upload';
        envVariables.environment = 'DEV';
        envVariables.fileServer = 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/';
        break;
      case 'testenvironment1.com':
        envVariables.graphqlURI = 'https://graphql.worthycause.com/graphql';
        envVariables.uploadURI = 'https://graphql.worthycause.com/upload';
        envVariables.environment = 'DEV';
        envVariables.fileServer = 'https://worthy-files.s3.amazonaws.com/';
        break;
      case 'www.testenvironment1.com':
        envVariables.graphqlURI = 'https://graphql.worthycause.com/graphql';
        envVariables.uploadURI = 'https://graphql.worthycause.com/upload';
        envVariables.environment = 'DEV';
        envVariables.fileServer = 'https://worthy-files.s3.amazonaws.com/';
        break;
      case 'worthycause.com':
        envVariables.graphqlURI = 'https://graphql.worthycause.com/graphql';
        envVariables.uploadURI = 'https://graphql.worthycause.com/upload';
        envVariables.environment = 'PROD';
        envVariables.fileServer = 'https://worthy-files.s3.amazonaws.com/';
        break;
      default:
    }
    return envVariables;
  },
};

export { globalVariables };
