import React, { useState, useEffect, useCallback } from 'react';
import './styles/guideComponentStyles.scss';
import LeftArrow from './assets/left-arrow.svg';

export const GuideComponent = ({ props, image, title, content, link, charityId, className }) => {
  const handleRedirect = useCallback(() => {
    if (charityId) {
      props.history.push({ pathname: link, state: { charity_id: charityId } });
    } else {
      props.history.push({ pathname: link });
    }
  }, [link, charityId]);

  return (
    <div className={`guideContainer flexBetween ${className}`} onClick={() => handleRedirect()}>
      <div className="alignCenter">
        <img src={image} alt={image} />
        <div className="mleft32">
          <div className="title mbottom16">{title}</div>
          <div className="content mtop16">{content}</div>
        </div>
      </div>
      {link && <img className="mleft45" src={LeftArrow} alt={LeftArrow} />}
    </div>
  );
};
