import React from 'react';

const OngoingTask = () => (
  <>
    <g clipPath="url(#a)" fill="currentColor">
      <path d="M9.773 19.544a9.774 9.774 0 0 0-6.131 17.389 9.77 9.77 0 0 0 14.877-3.252c.168-.338.315-.686.44-1.043a9.56 9.56 0 0 0 .586-3.322 9.776 9.776 0 0 0-9.773-9.773v.002Zm0 17.103a7.33 7.33 0 1 1 0-14.659 7.33 7.33 0 0 1-.001 14.659Z" />
      <path d="M13.03 28.91h-2.85v-3.665a1.223 1.223 0 0 0-1.833-1.058c-.378.219-.61.622-.61 1.058v4.887a1.22 1.22 0 0 0 1.221 1.221h4.072a1.223 1.223 0 0 0 1.058-1.832 1.221 1.221 0 0 0-1.058-.61v-.001ZM12.68 8.144h.7A2.296 2.296 0 0 0 15.472 5.7V2.443A2.297 2.297 0 0 0 13.38 0h-.7a2.296 2.296 0 0 0-2.093 2.443V5.7a2.296 2.296 0 0 0 2.092 2.444ZM28.967 8.144h.7A2.3 2.3 0 0 0 31.761 5.7V2.443A2.294 2.294 0 0 0 29.667 0h-.7a2.297 2.297 0 0 0-2.093 2.443V5.7a2.294 2.294 0 0 0 2.093 2.444Z" />
      <path d="M35.832 3.258H33.39a.407.407 0 0 0-.407.407V5.7a3.257 3.257 0 0 1-3.258 3.258h-.814A3.257 3.257 0 0 1 25.652 5.7V3.665a.407.407 0 0 0-.408-.407h-8.143a.407.407 0 0 0-.407.407V5.7a3.257 3.257 0 0 1-3.258 3.258h-.814A3.257 3.257 0 0 1 9.364 5.7V3.665a.407.407 0 0 0-.407-.407H6.514a3.257 3.257 0 0 0-3.257 3.257v13.453a10.509 10.509 0 0 1 1.629-.952v-5.579c0-.224.182-.407.407-.407h31.761a.407.407 0 0 1 .407.407v17.51a1.63 1.63 0 0 1-1.629 1.628h-15.13a11.221 11.221 0 0 1-.628 1.63h15.758a3.257 3.257 0 0 0 3.258-3.259V6.515a3.258 3.258 0 0 0-3.258-3.257Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h39.09v39.096H0z" />
      </clipPath>
    </defs>
  </>
);

export default OngoingTask;
