import React, { useState } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router';
import { localStorage } from 'lib/storage';
import { userUtils } from 'lib/user';

// Mutations
import createGroupMessageMutation from '../../../mutations/group_messages/createGroupMessage';
import LoadingOverlay from '../LoadingOverlay/view';

function MessagerButton({ users, isDark, history }) {
  const [loading, setLoading] = useState(false);
  const _current_user_id = localStorage.get('userID');
  const [createGroupMessage] = useMutation(createGroupMessageMutation);

  const onMessage = async () => {
    setLoading(true);
    // Users on group should be everyone selected by the CM + themselves
    const usersOnGroup = _.map(users, '_id');
    usersOnGroup.push(_current_user_id);
    const groupMessageCreated = await createGroupMessage({
      variables: {
        group: {
          name: userUtils.getSelectedUserNames(users),
          users: usersOnGroup,
        },
      },
    });
    setLoading(false);
    // Redirect to the newly created group
    history.push({ pathname: `/dashboard/inbox/group/${_.get(groupMessageCreated, 'data.createGroupMessage._id')}` });
  };
  return (
    <a onClick={onMessage}>
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <img src={isDark ? '/icons/messages-dark.svg' : '/icons/messages.svg'} alt="Messages" className="messagesIcon" />
    </a>
  );
}

export default withRouter(MessagerButton);
