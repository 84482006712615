import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle-outline';
import DeleteForever from 'material-ui/svg-icons/action/delete-forever';
import _ from 'lodash';
import {
    Button,
    LoadingOverlay,
    // List,
    Input
} from '../../common';
import { update } from '../../../lib/utils';

// Queries
import fetchEventById from '../../../queries/event/fetchEventById';

// Mutations
import updateEventMutation from '../../../mutations/event/updateEvent';

class CreateEventFAQs extends Component {
    constructor() {
        super();

        this.state = {
            event: {
                faqs: [],
                status: 1
            },
            loading: false,
            error: ''
        };

        this.onCreateEventClicked = this.onCreateEventClicked.bind(this);
        this.onChangeFaqs = this.onChangeFaqs.bind(this);
        this.renderFaqs = this.renderFaqs.bind(this);
    }

    componentWillReceiveProps(newProps) {
        const { eventByID } = newProps.eventData;
        if (_.size(eventByID.faqs) > 0) {
            this.setState({
                event: {
                    ...this.state.event,
                    faqs: eventByID.faqs.map((item) => {
                        return {
                            question: item.question,
                            answer: item.answer,
                            opened: false
                        };
                    })
                },
            });
        }
    }

    onChangeFaqs(fieldName, index, value) {
        this.setState({
            event: {
                ...this.state.event,
                faqs: update(this.state.event.faqs, {
                    $auto: {
                        [index]: {
                            $auto: {
                                [fieldName]: {
                                    $set: value
                                }
                            }
                        },
                    }
                })
            }
        });
    }

    onRemoveFaq(index) {
        this.setState({
            event: {
                ...this.state.event,
                faqs: update(this.state.event.faqs, {
                    $splice: [[index, 1]]
                })
            }
        });
    }

    onCreateEventClicked() {
        this.setState({
            loading: true
        });

        // Sanitize the FAQs array before sending to it
        const objectToUpdate = this.state.event;
        objectToUpdate.faqs = objectToUpdate.faqs.map((item) => {
            return {
                question: item.question,
                answer: item.answer
            };
        });

        // Update event
        this.props.updateEvent(this.props.location.state.eventId, objectToUpdate)
            .then(() => {
                this.setState({
                    loading: false
                });
                if (_.get(this.props.location, 'state.updateMode')) {
                    this.props.history.replace({ pathname: `/event/${this.props.location.state.eventId}` });
                } else {
                    this.props.history.push({ pathname: '/event-finish', state: { ...this.props.location.state } });
                }
                window.location.reload();
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error
                });
            });
    }

    renderFaqs(item, index) {
        return (
            <div
                key="faqs"
                className="faqsContentContainer"
            >
                <div
                    key={`faqs-${index}`}
                    className="faqsCreateContainer"
                >
                    <Input
                        name="questionField"
                        // hintText="E.g: What is the appropriate clothing?"
                        helpText="Frequently Asked Question"
                        type="text"
                        value={item.question}
                        rowsMax={3}
                        multiLine
                        onChange={value => this.onChangeFaqs('question', index, value)}
                    />
                    <Input
                        name="answerField"
                        // hintText="E.g: You should wear casual clothes"
                        helpText="Provide a short answer"
                        type="text"
                        value={item.answer}
                        onChange={value => this.onChangeFaqs('answer', index, value)}
                    />
                    <a
                        className="delete"
                        onClick={() => this.onRemoveFaq(index)}
                    >
                        <DeleteForever
                            className="deleteIcon"
                        />
                    </a>
                </div>
            </div>
        );
    }

    render() {
        if (!_.get(this.props.location, 'state.eventId')) {
            // If there's not Charity id to assign to it should redirect to the homepage
            this.props.history.replace({ pathname: '/' });
        }
        return (
            <div className="childContainer faqsEventContainer">
                {this.state.loading &&
                    <LoadingOverlay
                        style={{ opacity: 0.8 }}
                    />
                }
                {this.state.error &&
                    <p className="error">{this.state.error}</p>
                }
                <div className="contentContainer">
                    <div className="faqsContainerContent">
                        {this.state.event.faqs.map((item, index) => this.renderFaqs(item, index))}
                        {/* <List
                            className="defaultListStyles"
                            onClick={(operation, value) => this.onChangeFaqs('opened', value, operation === 'open')}
                            onRemove={index => this.onRemoveFaq(index)}
                            values={this.state.event.faqs.map((item, index) => {
                                return {
                                    name: <span className={`${item.opened ? 'opened' : 'closed'}`}>{item.question}</span>,
                                    component: this.renderFaqs(item, index),
                                    initiallyOpen: index === 0,
                                    opened: item.opened
                                };
                            })}
                        /> */}
                    </div>
                    <a
                        className="addMoreContainer"
                        onClick={() => {
                            this.setState({
                                event: {
                                    ...this.state.event,
                                    faqs: update(this.state.event.faqs, {
                                        $push: [{
                                            question: '',
                                            answer: '',
                                            opened: true
                                        }]
                                    })
                                }
                            });
                        }}
                    >
                        <AddCircleOutline
                            color="#20C1C9"
                            className="add"
                        />
                        <span>{(_.size(this.state.event.faqs) > 0) ? 'Add more' : 'Add FAQ'}</span>
                    </a>
                </div>
                <div className="buttonsContainer mtop60 textCenter">
                    <Button
                        label="Back"
                        labelColor="#212B35"
                        bgColor="#fff"
                        onClick={() => this.props.history.goBack()}
                        className="btnDefault btnWhite"
                    />
                    <Button
                        label={_.get(this.props.location, 'state.updateMode') ? 'Update Event' : 'Create Event'}
                        onClick={this.onCreateEventClicked}
                        className="btnDefault btnBlueSolid mleft15"
                    />
                </div>
            </div>
        );
    }
}

const eventByIDContainer = graphql(fetchEventById, {
    name: 'eventData',
    options: ({ location }) => {
        return {
            variables: { id: _.get(location, 'state.eventId') },
        };
    },
});

const updateEventMutationContainer = graphql(updateEventMutation, {
    props: ({ mutate }) => ({
        updateEvent: (id, event) => mutate({
            variables: { id, event },
            refetchQueries: [{
                query: fetchEventById,
                variables: { id },
            }]
        }),
    }),
});

export default withRouter(_.flowRight(
    updateEventMutationContainer,
    eventByIDContainer
)(CreateEventFAQs));
