import React from 'react';
import _ from 'lodash';
import './styles.scss';

export const SkillsList = ({ macros, skills, className }) => {
  // It should render a skill list.
  const renderSkillComponent = ({ _id, name, bgColor, labelColor, macro }) => {
    return (
      <div
        key={_id}
        className={`${macro ? 'macroBadge' : 'skillBadge'} bold`}
        style={{
          marginBottom: '4px',
          width: 'fit-content',
          backgroundColor: bgColor,
          color: labelColor,
        }}
      >
        {name}
      </div>
    );
  };
  if (macros) {
    return (
      <div className={`skillListContainer ${className || ''}`}>
        {macros.map(macro => {
          const micros = _.filter(skills, ({ Macro }) => Macro._id === macro._id) || [];
          return (
            <>
              {renderSkillComponent({ _id: macro._id, name: macro.name, bgColor: macro.color, labelColor: '#fff', macro: true })}
              {micros.map(skill => renderSkillComponent({ _id: skill._id, name: skill.name, bgColor: skill.skill_color, labelColor: skill.skill_label_color }))}
            </>
          );
        })}
      </div>
    );
  }
  return (
    <div className={`skillsContainer ${className || ''}`}>
      {skills?.map(skill => renderSkillComponent({ _id: skill._id, name: skill.name, bgColor: skill.skill_color, labelColor: skill.skill_label_color }))}
    </div>
  );
};
