import React from 'react';

const SingleTask = () => (
  <>
    <g clipPath="url(#a)" fill="currentColor">
      <path d="M17.86 26.28a1.224 1.224 0 0 0-.928 1.458 7.328 7.328 0 1 1-4.348-5.196 1.219 1.219 0 0 0 1.622-.649 1.224 1.224 0 0 0-.686-1.607 9.81 9.81 0 0 0-3.746-.741A9.776 9.776 0 0 0 0 29.318a9.773 9.773 0 0 0 18.519 4.357c.168-.335.315-.68.44-1.035.392-1.064.59-2.189.586-3.323a9.556 9.556 0 0 0-.228-2.109 1.221 1.221 0 0 0-1.457-.927Z" />
      <path d="M19.993 20.302a1.223 1.223 0 0 0-1.726 0l-8.681 8.885-1.776-1.873a1.223 1.223 0 0 0-2.038.5c-.126.411-.026.859.263 1.177l2.655 2.77a1.196 1.196 0 0 0 1.742.015l9.56-9.772a1.221 1.221 0 0 0 0-1.702ZM12.68 8.144h.7A2.297 2.297 0 0 0 15.472 5.7V2.443A2.297 2.297 0 0 0 13.38 0h-.7a2.296 2.296 0 0 0-2.093 2.443V5.7a2.296 2.296 0 0 0 2.092 2.444ZM28.968 8.144h.7A2.3 2.3 0 0 0 31.76 5.7V2.443A2.294 2.294 0 0 0 29.668 0h-.7a2.297 2.297 0 0 0-2.094 2.443V5.7a2.294 2.294 0 0 0 2.094 2.444Z" />
      <path d="M35.832 3.258H33.39a.407.407 0 0 0-.408.407V5.7a3.257 3.257 0 0 1-3.258 3.258h-.814A3.257 3.257 0 0 1 25.652 5.7V3.665a.407.407 0 0 0-.407-.407H17.1a.407.407 0 0 0-.407.407V5.7a3.257 3.257 0 0 1-3.258 3.258h-.814A3.257 3.257 0 0 1 9.364 5.7V3.665a.407.407 0 0 0-.407-.407H6.514a3.257 3.257 0 0 0-3.257 3.257v13.454a10.501 10.501 0 0 1 1.629-.953v-5.579c0-.224.182-.407.407-.407h31.761a.407.407 0 0 1 .407.407v17.51a1.63 1.63 0 0 1-1.629 1.629H20.693c-.163.558-.37 1.102-.619 1.629h15.758a3.257 3.257 0 0 0 3.258-3.258V6.515a3.258 3.258 0 0 0-3.258-3.257Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h39.09v39.087H0z" />
      </clipPath>
    </defs>
  </>
);

export default SingleTask;
