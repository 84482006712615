import gql from 'graphql-tag';
import { taskFieldsFragment } from '../../fragments/task/taskFields';

export default gql`
  query FetchTasksByUserId($_user_id: ID!) {
    tasksByUserID(_user_id: $_user_id) {
      ...TaskFields
    }
  }
  ${taskFieldsFragment}
`;
