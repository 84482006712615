import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import SwtichSettingForm from './switchSettingForm';
import { Button, LoadingOverlay, Notification } from '../common';

import fetchUserById from '../../queries/user/fetchUserById';
import updateUserMutation from '../../mutations/user/updateUser';

const switchStyle = {
  handleDiameter: 30,
  height: 32,
  window: 53,
  onColor: '#3042D4',
};

function NotificationPanel() {
  const [user, setUser] = useState(null);
  const [taskUpdate, setTaskUpdate] = useState(false);
  const [taskReminder, setTaskReminder] = useState(false);
  const [message, setMessage] = useState(false);
  const [platformUpdate, setPlatformUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationContent, setNotificationContent] = useState('');

  const [updateUser] = useMutation(updateUserMutation);
  const { data, refetch } = useQuery(fetchUserById, {
    variables: { id: localStorage.getItem('userID') },
  });

  const onSave = async () => {
    setLoading(true);
    await updateUser({
      variables: {
        id: user._id,
        user: {
          settings: {
            notifications: {
              task_update: taskUpdate,
              task_reminder: taskReminder,
              message,
              platform_update: platformUpdate,
            },
          },
        },
      },
    });
    setLoading(false);
    setNotificationContent('Notification Settings updated successfully');
  };

  useEffect(() => {
    setUser(_.get(data, 'userByID'));
    const settings = _.get(data, 'userByID.settings');
    if (settings) {
      setTaskUpdate(_.get(settings, 'notifications.task_update', false));
      setTaskReminder(_.get(settings, 'notifications.task_reminder', false));
      setMessage(_.get(settings, 'notifications.message'));
      setPlatformUpdate(_.get(settings, 'notifications.platform_update', false));
    }
  }, [data]);
  useEffect(() => {
    refetch({ id: localStorage.getItem('userID') });
  }, []);

  return (
    <div className="notificationPanel">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <h2>Notifications</h2>
      <div className="contentBlock">
        <h3>General</h3>
        <SwtichSettingForm
          title="Task Updates"
          detail="Task invitations, status updates & feedback"
          switchType="Email"
          checked={taskUpdate}
          switchStyle={switchStyle}
          onChange={value => setTaskUpdate(value)}
          id="taskUpdates"
          underlined
        />
        <SwtichSettingForm
          title="Task Reminders"
          detail="Timesheet & due date reminders"
          switchType="Email"
          checked={taskReminder}
          switchStyle={switchStyle}
          onChange={value => setTaskReminder(value)}
          id="taskReminders"
          underlined
        />
        <SwtichSettingForm
          title="Messages"
          detail="New inbox messages (limited per day)"
          switchType="Email"
          checked={message}
          switchStyle={switchStyle}
          onChange={value => setMessage(value)}
          id="messages"
          underlined
        />
      </div>
      <div className="contentBlock platform">
        <h3>Platform Updates</h3>
        <SwtichSettingForm
          title="Promotion & Offers"
          detail="Latest news and offers from Worthy"
          switchType="Email"
          checked={platformUpdate}
          switchStyle={switchStyle}
          onChange={value => setPlatformUpdate(value)}
          id="subscribe"
        />
      </div>
      <Button label="Save" onClick={onSave} />
      <Notification content={notificationContent} />
    </div>
  );
}

export default NotificationPanel;
