import React, { Component } from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

class RadioButtonComponent extends Component {
    constructor(props) {
        super(props);

        this.renderRadioButton = this.renderRadioButton.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    renderRadioButton(value, index) {
        return (
            <RadioButton
                key={index}
                value={value.value}
                label={value.label}
                iconStyle={{ fill: '#20C1C9' }}
            />
        );
    }

    render() {
        return (
            <RadioButtonGroup
                name={this.props.name}
                defaultSelected={this.props.value}
                valueSelected={this.props.value}
                onChange={this.onChange}
                className={this.props.className ? this.props.className : ''}
            >
                {this.props.values.map(this.renderRadioButton)}
            </RadioButtonGroup>
        );
    }
}

export default RadioButtonComponent;
