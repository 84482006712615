import React, { useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import moment from 'moment';

import { Button, Checkbox, Counter, DatePicker, Input, TimePicker } from '../../../../common';
import { ButtonContainer, CustomSelectStyles, CustomTextFieldStyle, InlineContent } from '../../../common/Elements';

const SelectLabel = styled.span`
  display: block;
  margin-bottom: 10px;

  @media and (min-width: 767px) {
    margin-bottom: 0;
  }
`;

const TaskDescription = ({ history, formData, setStep, setFormData }) => {
  const [volunteerHours, setVolunteerHours] = useState(1);
  const [taskDescription, setTaskDescription] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [flexibleDate, setFlexibleDate] = useState(false);
  const [flexibleTime, setFlexibleTime] = useState(false);
  const [opportunityType, setOpportunityType] = useState('');
  const [requiredHours, setRequiredHours] = useState('');

  const createDate = (date, time) => {
    const dateString = `${moment(date).format('DD/MM/YYYY')} ${time}`;
    return moment(dateString, 'DD/MM/YYYY HH:mm').valueOf();
  };

  const handleContinueClick = () => {
    if (formData.type === 'single') {
      setFormData(prevState => ({
        ...prevState,
        description: taskDescription,
        startDate: flexibleDate ? createDate(new Date(), '00:00') : createDate(startDate, startTime),
        endDate: flexibleDate ? null : createDate(endDate, endTime),
        startTime,
        endTime,
        isFlexibleDate: flexibleDate,
        isFlexibleTime: flexibleTime,
        volunteerHours,
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        description: taskDescription,
        requiredHoursPerWeek: requiredHours,
        opportunityType,
      }));
    }
    history.push({ pathname: '/task/create/skills' });
  };

  return (
    <>
      <Input maxLength={1000} multiLine placeholder="Enter the description of the task here" value={taskDescription} onChange={setTaskDescription} />

      {formData.type === 'single' ? (
        <>
          <div className="row mbottom40">
            <div className="col-xs-12 col-sm-6">
              <div className="row">
                <div className="col-xs-6 col-sm-6 mbottom20">
                  <DatePicker
                    placeholder="Start date"
                    disabled={flexibleDate}
                    minDate={new Date()}
                    value={moment(startDate).toDate()}
                    format="DD MMMM"
                    textFieldStyle={CustomTextFieldStyle}
                    onChange={(e, value) => setStartDate(value)}
                  />
                </div>
                <div className="col-xs-6 col-sm-6">
                  <TimePicker placeholder="Start time" disabled={flexibleTime} value={startTime} style={CustomTextFieldStyle} onChange={setStartTime} />
                </div>
              </div>
              <Checkbox
                id="flexibleDate"
                label="I'm flexible with dates"
                checked={flexibleDate}
                iconStyle={{
                  marginRight: 10,
                }}
                className="plain"
                onCheck={() => {
                  setFlexibleDate(!flexibleDate);
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <div className="row">
                <div className="col-xs-6 col-sm-6 mbottom20">
                  <DatePicker
                    placeholder="End date"
                    disabled={flexibleDate}
                    minDate={moment(startDate).toDate()}
                    value={moment(endDate).toDate()}
                    format="DD MMMM"
                    textFieldStyle={CustomTextFieldStyle}
                    onChange={(e, value) => setEndDate(value)}
                  />
                </div>
                <div className="col-xs-6 col-sm-6">
                  <TimePicker placeholder="End time" disabled={flexibleTime} value={endTime} style={CustomTextFieldStyle} onChange={setEndTime} />
                </div>
              </div>
              <Checkbox
                id="flexibleTime"
                label="I'm flexible with times"
                iconStyle={{
                  marginRight: 10,
                }}
                className="plain"
                checked={flexibleTime}
                onCheck={() => {
                  setFlexibleTime(!flexibleTime);
                }}
              />
            </div>
          </div>
          <InlineContent>
            Estimated hrs per volunteer needed
            <Counter initialCount={1} onChange={setVolunteerHours} />
          </InlineContent>
        </>
      ) : (
        <>
          <div className="row mbottom20 alignCenter flexWrap">
            <div className="col-xs-12 col-md-6">
              <SelectLabel>Type of opportunity</SelectLabel>
            </div>
            <div className="col-xs-12 col-md-6">
              <Select
                name="opportunityType"
                styles={CustomSelectStyles}
                placeholder="Please select"
                options={[
                  {
                    label: 'Short-term (less than 3 months)',
                    value: '1',
                  },
                  {
                    label: 'Long-term (more than 3 months)',
                    value: '2',
                  },
                ]}
                onChange={option => setOpportunityType(option.value)}
              />
            </div>
          </div>
          <div className="row alignCenter flexWrap">
            <div className="col-xs-12 col-md-6">
              <SelectLabel>Amount of hours required per week</SelectLabel>
            </div>
            <div className="col-xs-12 col-md-6">
              <Select
                name="requiredHours"
                styles={CustomSelectStyles}
                placeholder="Please select"
                options={[
                  {
                    label: 'Less than 5 hours per week',
                    value: '1',
                  },
                  {
                    label: 'Between 5-10 hours per week',
                    value: '2',
                  },
                  {
                    label: 'Between 10-20 hours per week',
                    value: '3',
                  },
                  {
                    label: 'More than 20 hours per week',
                    value: '4',
                  },
                ]}
                onChange={option => setRequiredHours(option.value)}
              />
            </div>
          </div>
        </>
      )}

      <ButtonContainer largeMargin>
        <Button
          label="Back"
          borderedButton
          onClick={() => {
            setStep('taskLocation');
          }}
        />
        <Button label="Continue" disabled={taskDescription === ''} onClick={handleContinueClick} />
      </ButtonContainer>
    </>
  );
};

export default TaskDescription;
