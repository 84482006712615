import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button, Input } from '../common';
import { localStorage } from '../../lib/storage';
import IllustrationOne from './assets/illustration-1.svg';
import IllustrationTwo from './assets/illustration-2.svg';
import CheckIcon from './assets/check.svg';

// Mutations
import updateUserMutation from '../../mutations/user/updateUser';

function OnboardingWelcome(props) {
  const [type, setType] = useState(1);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [promotion, setPromotiom] = useState(false);

  // Mutations
  const [updateUser] = useMutation(updateUserMutation);

  const handleSubmit = () => {
    updateUser({
      variables: {
        id: localStorage.get('userID'),
        user: {
          firstname,
          lastname,
          settings: {
            notifications: {
              promotional: promotion,
            },
          },
          type,
        },
      },
    });

    props.history.push({
      pathname: type === 1 ? '/onboarding/about-you' : '/charity/register/details',
      state: {
        data: {
          firstname,
          lastname,
          promotion,
        },
      },
    });
  };

  return (
    <div className="content">
      <h1>Welcome to Worthy!</h1>
      <p className="mbottom24">Please populate the following pages to add to your profile.</p>
      <div className="inputsContainer">
        <Input name="firstname" placeholder="First Name" type="text" value={firstname} onChange={value => setFirstName(value)} className="input" />
        <Input name="lastname" placeholder="Last Name" type="text" value={lastname} onChange={value => setLastName(value)} className="input" />
      </div>
      <p className="mbottom24">Please choose your profile</p>
      <div className="profilesContainer mbottom24">
        <div className={`profile ${type === 1 ? 'active' : ''}`} onClick={() => setType(1)}>
          {type === 1 ? <img className="checkIcon volunteer" src={CheckIcon} alt="Check" /> : null}
          <img className="mbottom8" src={IllustrationOne} alt="Illustration One" />
          <p>I am a volunteer looking for opportunities to help charities</p>
        </div>
        <div className={`profile ${type === 2 ? 'active' : ''}`} onClick={() => setType(2)}>
          {type === 2 ? <img className="checkIcon" src={CheckIcon} alt="Check" /> : null}
          <img className="mbottom8" src={IllustrationTwo} alt="Illustration Two" />
          <p>I am a charity manager looking for skilled volunteers</p>
        </div>
      </div>
      <div className="checkboxContainer mbottom24">
        <label className="checkbox" htmlFor="check">
          <input type="checkbox" id="check" checked={promotion} onChange={e => setPromotiom(e.target.checked)} />
          <span className="checkmark" />
        </label>
        <p>Yes! send me useful emails to get the most out of Worthy</p>
      </div>
      <Button
        label="Get Started"
        type="button"
        labelStyle={{
          paddingLeft: 30,
          paddingRight: 30,
        }}
        onClick={handleSubmit}
        disabled={!(firstname && lastname && type)}
      />
    </div>
  );
}

export default withRouter(OnboardingWelcome);
