import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { useMutation } from '@apollo/client';
import { LoadingOverlay } from '../../common';
import { localStorage } from '../../../lib/storage';

// Mutations
import updateUserMutation from '../../../mutations/user/updateUser';

const ConfirmAccount = props => {
  const [loading, setLoading] = useState(false);
  const userId = _.get(props, 'match.params.userId');

  // Mutations
  const [updateUser] = useMutation(updateUserMutation);

  useEffect(() => {
    const triggerAction = async () => {
      setLoading(true);
      try {
        await updateUser({
          variables: {
            id: userId,
            user: {
              status: 1,
            },
          },
        });
        localStorage.save('userID', userId);
        setLoading(false);
        props.history.replace('/');
      } catch (error) {
        setLoading(false);
        props.history.replace('/');
      }
    };
    triggerAction();
  }, []);

  if (loading) return <LoadingOverlay />;
  return null;
};

export default withRouter(ConfirmAccount);
