import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Step, Stepper, StepLabel } from 'material-ui/Stepper';

// Styles
import './styles/stepperStyles.css';

class StepperComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 0
        };

        this.renderStep = this.renderStep.bind(this);
    }

    componentWillMount() {
        const activeStep = this.props.values.find(value => this.props.location.pathname.includes(value.link)).index;
        this.setState({ activeStep });
    }

    componentWillReceiveProps(nextProps) {
        const activeStep = this.props.values.find(value => nextProps.location.pathname.includes(value.link)).index;
        if (activeStep !== this.state.activeStep) this.setState({ activeStep });
    }

    renderStep(value, index) {
        return (
            <Step key={index}>
                <StepLabel
                    className={`${(this.state.activeStep === index) ? 'activeStep' : ''} ${(this.state.activeStep > index) ? 'completedStep' : ''}`}
                >
                    {value.label}
                </StepLabel>
            </Step>
        );
    }

    render() {
        // Check what's the active step by cross matching the URL
        return (
            <div className={`stepperComponent ${this.props.className ? this.props.className : ''}`}>
                <Stepper
                    activeStep={this.state.activeStep}
                    orientation={this.props.orientation}
                >
                    {this.props.values.map((value, index) => this.renderStep(value, index))}
                </Stepper>
            </div>
        );
    }
}

export default withRouter(StepperComponent);
