import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import SearchIcon from './assets/search.svg';

const getBorderColor = (hasError, hasContent, hasNoBorder) => {
  if (hasNoBorder) return 'transparent';

  if (hasError) {
    return '#EA3119';
  }

  if (hasContent) {
    return '#3042D4';
  }

  return '#E3E5E5';
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid;
  border-color: transparent;
  border-radius: 24px;
  width: 100%;
  background-color: #f3f5f7;
  padding: 13px 26px;
`;

const StyledImg = styled.img`
  width: 20px;
  height: 20px;
`;

const StyledInput = styled.input`
  border: none;
  width: 100%;
  color: #222222;
  height: auto;
  background-color: #f3f5f7;
  margin-left: 12px;
  font-size: 16px;

  &::-webkit-input-placeholder,
  ::placeholder {
    color: #637381;
  }

  &:active,
  &:focus {
    border: none;
    outline: none;
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  margin-bottom: 12px;
`;

const SearchInput = forwardRef(({ value, name, onChange, hasNoBorder, ...props }, ref) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <>
      <StyledDiv>
        <StyledImg src={SearchIcon} />
        <StyledInput
          name={name}
          value={value != null ? value : inputValue}
          onChange={event => {
            !value && setInputValue(event.target.value);
            onChange && onChange(event.target.value);
          }}
          hasContent={inputValue !== '' || (value != null && value !== '')}
          hasNoBorder={hasNoBorder}
          ref={ref}
          {...props}
        />
      </StyledDiv>
    </>
  );
});

export default SearchInput;
