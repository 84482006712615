import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, LoadingOverlay, Input, Button } from '../common';
import { userUtils } from '../../lib/user';

import './styles/component.scss';

import updatePasswordMutation from '../../mutations/user/updatePassword';

function ModalChangePassword(props) {
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [confirmText, setConfirmText] = useState('');
  const [passwordValid, setPasswordValid] = useState('');

  const [updatePassword] = useMutation(updatePasswordMutation);
  const onSaveNewPassword = async () => {
    try {
      setLoading(true);
      setErrorText('');
      await updatePassword({
        variables: {
          id: props.user_id,
          oldPassword,
          newPassword,
        },
      });
      setLoading(false);
      setOldPassword('');
      setNewPassword('');
      props.onClose(true);
    } catch (error) {
      setLoading(false);
      setErrorText(error.graphQLErrors[0].message);
    }
  };

  return (
    <Modal open={props.open} onClose={() => props.onClose(false)} className="confirmModal">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <div className="changePasswordFormContainer textCenter">
        <h1 className="changePasswordFormHeader">Change Password</h1>
        {errorText && <span className="error">{errorText}</span>}
        <div className="changePasswordContent textLeft">
          <p className="inputLabel">Old Password</p>
          <Input name="oldpassword" placeholder="Old Password" type="password" value={oldPassword} onChange={value => setOldPassword(value)} />
          <p className="inputLabel">New Password</p>
          <Input
            name="newpassword"
            placeholder="New Password"
            type="password"
            errorText={passwordValid}
            value={newPassword}
            onChange={value => setNewPassword(value)}
            onBlur={() => {
              if (userUtils.isValidPassword(newPassword) === false) {
                setPasswordValid('Password must be 8 characters or more, and it must include at least one uppercase letter, one lowercase letter, and one number');
              } else {
                setPasswordValid('');
              }
            }}
          />
          <p className="inputLabel">Confirm New Password</p>
          <Input
            name="newpassword confirmation"
            placeholder="Confirm Password"
            type="password"
            errorText={confirmText}
            value={passwordConfirmation}
            onChange={value => {
              setPasswordConfirmation(value);
              if (newPassword !== value) {
                setConfirmText('Passwords need to match');
              } else {
                setConfirmText('');
              }
            }}
          />
        </div>
        <Button
          label="Save"
          onClick={onSaveNewPassword}
          disabled={!(oldPassword && newPassword && userUtils.isValidPassword(newPassword) && newPassword === passwordConfirmation)}
        />
      </div>
    </Modal>
  );
}

export default ModalChangePassword;
