import React, { useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { localStorage } from '../../lib/storage';
import { userUtils } from '../../lib/user';

// External components
import TaskManager from '../task/manage/view';
import Welcome from '../welcome/index';
import Inbox from '../messages/inbox';
import Settings from '../settings/view';
import Sidebar from './Sidebar';

import fetchUserById from '../../queries/user/fetchUserById';

import './styles/dashboardStyles.scss';

function Dashboard(props) {
  const { page } = props.match.params;

  const userById = useQuery(fetchUserById, {
    variables: {
      id: localStorage.get('userID'),
    },
  });

  return (
    <div className="dashboardContainer">
      <Sidebar page={page} user={userById} />
      <div className="contentContainer">
        {page === 'index' ? <Welcome /> : null}
        {page === 'inbox' ? <Inbox /> : null}
        {page === 'task-manager' ? <TaskManager /> : null}
        {page === 'settings' ? <Settings /> : null}
      </div>
    </div>
  );
}

export default Dashboard;
