import React from 'react';
import styled from 'styled-components';

const StepIndicatorContainer = styled.div`
  display: none;
  width: 100%;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    display: block;
    width: ${props => (props.stepCount > 4 ? '800px' : '650px')};
    margin-bottom: 60px;
  }
`;

const StepIndicatorSegments = styled.ol`
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const StepIndicatorSegment = styled.li`
  width: ${({ segmentCount }) => `calc(100% / ${segmentCount})`};
  color: ${({ active, completed }) => (active || completed ? 'black' : '#B5BDC4')};
  font-size: 14px;
  font-weight: 300;
  text-align: center;

  &::after {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 4px;
    background-color: ${({ active, completed }) => (active || completed ? '#3042D4' : '#E4EAF7')};
    margin-top: 14px;
    z-index: 1;
  }

  &:first-child {
    &::after {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }
  }

  &:last-child {
    &::after {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    visibility: hidden;
    white-space: nowrap;
    line-height: 0;

    &::after {
      visibility: visible;
    }
  }
`;

const MobileStepIndicator = styled.div`
  display: block;
  color: #3042D4;
  font-size: 14px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const StepIndicators = ({ currentActive, steps, showIndex }) => {
  // If the currentActive step number in the provided array
  // is higher than the current step then it's completed
  const isStepCompleted = stepName => {
    return steps.findIndex(step => step.name === currentActive) > steps.findIndex(step => step.name === stepName);
  };

  const stepLabel = steps.find(step => step.name === currentActive).label;
  const stepIndex = steps.findIndex(step => step.name === currentActive);

  return (
    <>
      <StepIndicatorContainer stepCount={steps.length}>
        <StepIndicatorSegments>
          {steps.map((step, index) => (
              <StepIndicatorSegment segmentCount={steps.length} active={step.name === currentActive} completed={isStepCompleted(step.name)} key={step.name}>
                {showIndex && `${index + 1}.`} {step.label}
              </StepIndicatorSegment>
          ))}
        </StepIndicatorSegments>
      </StepIndicatorContainer>
      <MobileStepIndicator>
        {stepLabel} ({stepIndex + 1}/{steps.length})
      </MobileStepIndicator>
    </>
  );
};

export default StepIndicators;
