import gql from 'graphql-tag';

export default gql`
  query FetchForumsByCharityId($charity_id: ID!) {
    forumsByCharityId(charity_id: $charity_id) {
      _id
      name
      charity_id
      Charity {
        _id
        user_id
        charity_users {
          user_id
          role
          user {
            _id
            firstname
            lastname
            email
          }
        }
        charity_followers {
          user_id
          user {
            _id
            firstname
            lastname
            email
          }
        }
      }
      Messages {
        _id
        seen
      }
    }
  }
`;
