import React, { useState } from 'react';
import { IconButton } from 'components/common/IconButton/view';
import DotsIcon from 'assets/svg/dots.svg';
import { Menu, Popover } from '../../../common';
import { InviteTaskModal } from '../InviteTaskModal/view';
import { AssignCreditModal } from '../AssignCreditModal/view';

export const CharityManagerMenu = ({ user, charityId }) => {
  const [showInvite, setShowInvite] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [openAnchorEl, setOpenAnchorEl] = useState();
  return (
    <>
      <IconButton
        icon={DotsIcon}
        onClick={(event) => {
          event.preventDefault();
          setOpenAnchorEl(event.currentTarget);
        }}
      />
      <Popover
        open={!!openAnchorEl}
        anchorEl={openAnchorEl}
        handleRequestClose={() => setOpenAnchorEl(undefined)}
        className="userPopover"
      >
        <Menu
          values={[
            {
              label: 'Invite to task',
              action: () => {
                setShowInvite(true);
                setOpenAnchorEl(undefined);
              }
            },
            {
              label: 'Assign Credit',
              action: () => {
                setShowAssign(true);
                setOpenAnchorEl(undefined);
              },
            }
          ]}
        />
      </Popover>
      {showInvite && (
        <InviteTaskModal
          user={user}
          onClose={() => setShowInvite(false)}
        />
      )}
      {showAssign && (
        <AssignCreditModal
          charityId={charityId}
          userId={user._id}
          onClose={() => setShowAssign(false)}
        />
      )}
    </>
  );
};
