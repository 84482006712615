import { Chips, FileUpload, Menu, Popover } from 'components/common';
import React from 'react';
import ExitToAppIcon from 'assets/svg/exit-to-app.svg';
import EditIcon from 'assets/svg/edit.svg';
import SettingIcon from 'assets/svg/setting.svg';
import _ from 'lodash';
import { IconButton } from 'components/common/IconButton/view';
import { FlatButton } from 'components/common/FlatButton/view';
import { localStorage } from 'lib/storage';
import { CharityNetworkButton } from 'components/charity/networkButton/view';
import { charityUtils } from 'lib/charity';
import { TASK_STATUS } from 'lib/task';
import { globalVariables } from 'lib/globalVariables';
import { useMutation } from '@apollo/client';
import fetchCharityById from 'queries/charity/fetchCharityById';
import { EditCharityModal } from '../EditCharityModal/view';
import updateCharityMutation from '../../../../mutations/charity/updateCharity';

export const CharityDetailsHeader = ({ charity, onShare, isEditCharity, setIsEditCharity, history }) => {
  const [updateCharity] = useMutation(updateCharityMutation);
  const { name, picture, Causes, Tasks, description, location, website, user_id, charity_followers } = charity;

  const userID = localStorage.get('userID');
  const inNetwork = charity_followers.some(item => item.user_id === userID);
  const isOwner = charityUtils.isCharityAdmin(charity);
  const availableTasks = Tasks.filter(({ status }) => status === TASK_STATUS.ACTIVE);

  const normaliseCausesArray = causes => {
    let causesArray = [];
    if (_.size(causes) > 0) {
      causesArray = causes.map(cause => {
        return {
          _id: cause._id,
          label: cause.name,
        };
      });
    }
    return causesArray;
  };

  const onUpdateCharity = charityUpdate => {
    return updateCharity({
      variables: {
        id: charity._id,
        charity: charityUpdate,
      },
      refetchQueries: [
        {
          query: fetchCharityById,
          variables: {
            _id: charity._id,
          },
        },
      ],
    });
  };

  return (
    <div className="header-card">
      <div className="headerCardInner container">
        {isOwner ? (
          <FileUpload
            onFinishUpload={filename => onUpdateCharity({ picture: `${globalVariables.getEnvironmentVariables().fileServer}${filename}` })}
            label="CHARITY LOGO"
            previewImage={picture}
          />
        ) : (
          <img className="charity-logo" src={picture} alt="Charity Logo" />
        )}
        <div className="mRightAuto">
          <h1 className="text-primary mtop0">{name}</h1>
          <h3>
            {location.city}, {location.state}
            &nbsp;&#8226;&nbsp;
            <a href={website} target="none">
              {website.replace(/(https|http):\/\//, '')}
            </a>
          </h3>
          <Chips values={normaliseCausesArray(Causes)} />
          {availableTasks.length > 1 && (
            <div className="flex flex-center">
              <img src="/icons/calendar.svg" alt="tasks icon" />
              <h3 className="ml-1">
                &nbsp;
                {availableTasks.length}
                &nbsp;
                {availableTasks.length > 1 ? 'tasks' : 'task'}
                &nbsp;available
              </h3>
            </div>
          )}
        </div>
        <div className="flex">
          {isOwner ? (
            <>
              <FlatButton icon={EditIcon} label="Edit Profile" onClick={() => setIsEditCharity(true)} />
              <FlatButton icon={ExitToAppIcon} label="Share" onClick={onShare} />
              <FlatButton
                icon={SettingIcon}
                label="Settings"
                onClick={() => {
                  history.push('/dashboard/settings');
                }}
              />
            </>
          ) : (
            <>
              <FlatButton icon={ExitToAppIcon} label="Share" onClick={onShare} />
              <CharityNetworkButton charity={charity} />
            </>
          )}
        </div>
      </div>
      {isEditCharity && (
        <EditCharityModal
          charity={charity}
          onClose={() => {
            setIsEditCharity(false);
          }}
          onUpdateCharity={onUpdateCharity}
        />
      )}
    </div>
  );
};
