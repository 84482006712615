import gql from 'graphql-tag';

export default gql`
  mutation updateCharity($id: ID!, $charity: CharityInput!) {
    updateCharity(id: $id, charity: $charity) {
      user_id
      name
      website
      registration
      description
    }
  }
`;
