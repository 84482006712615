import React from 'react';

// Styles
import './styles/topBarStyles.css';

const TopBar = (props) => {
    return (
        <div className="topBarContainer">
            {props.children}
        </div>
    );
};

export default TopBar;
