import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CounterContainer = styled.span`
  border: 1px solid #e3e5e5;
  border-radius: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-family: 'Poppins';
  font-size: 16px;
  min-width: 95px;
  padding: 3px 5px;
  margin-left: 15px;
`;

const CounterButton = styled.button`
  color: #b5bdc4;
  font-weight: normal;
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0 5px;
  font-size: 27px;
  line-height: 1;
  background: transparent;
`;

const CounterChip = ({ initialCount = 0, className, onChange }) => {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    onChange(count);
  }, [count]);

  return (
    <CounterContainer className={className}>
      <CounterButton
        onClick={() => {
          count > initialCount && setCount(count - 1);
        }}
      >
        -
      </CounterButton>
      {count}
      <CounterButton onClick={() => setCount(count + 1)}>+</CounterButton>
    </CounterContainer>
  );
};

export default CounterChip;
