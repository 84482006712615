import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import _ from 'lodash';

import './styles/SelectStyles.css';

export default class Select extends Component {
    constructor() {
        super();

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, index, value) {
        this.props.onChange && this.props.onChange(value, index);
    }

    render() {
        return (
            <SelectField
                disabled={this.props.disabled}
                floatingLabelText={this.props.floatingLabelText}
                value={this.props.value}
                onChange={this.handleChange}
                labelStyle={this.props.labelStyle ? this.props.labelStyle : {}}
                className={`selectField ${this.props.className}`}
                fullWidth={this.props.fullWidth ? this.props.fullWidth : false}
                style={this.props.style ? this.props.style : {}}
                underlineStyle={{ bottom: 0, ...this.props.underlineStyle }}
                errorStyle={{ bottom: 13, fontSize: 11 }}
            >
                {/* {_.map(_.orderBy(this.props.values, 'label', 'asc'), (val) => { */}
                {_.map(this.props.values, (val) => {
                    if (this.props.renderItem) {
                        return this.props.renderItem(val);
                    }

                    return (
                        <MenuItem
                            value={val.value}
                            primaryText={val.label}
                            key={val.label}
                        />
                    );
                })}
                {/* })} */}
            </SelectField>
        );
    }
}
