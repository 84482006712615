import React from 'react';

// Styles
import './styles/imageStyles.css';

import defaultImage from './assets/defaultFaceImage.jpeg';

const Image = (props) => {
    if (props.url) {
        return (
            <a
                className={`imgComponent ${props.containerClass}`}
                href={props.url}
            >
                <img
                    src={props.source ? props.source : defaultImage}
                    alt={props.alt ? props.alt : 'default'}
                />
            </a>
        );
    }
    return (
        <img
            src={props.source ? props.source : defaultImage}
            alt={props.alt ? props.alt : 'default'}
            className={props.className ? props.className : ''}
        />
    );
};

export default Image;
