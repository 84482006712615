import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Button, LoadingOverlay } from '../../common';
import { localStorage } from '../../../lib/storage';

// Queries
import fetchCharityById from '../../../queries/charity/fetchCharityById';

// Mutations
import finishCharityCreationMutation from '../../../mutations/charity/finishCharityCreation';

const CreateCharityReview = ({ history }) => {
  // Queries
  const charityCreatedID = localStorage.get('charity-temp');
  const { loading, data } = useQuery(fetchCharityById, {
    variables: { _id: charityCreatedID },
    fetchPolicy: 'network-only',
  });
  const charityById = _.get(data, 'charityById');

  // Mutations
  const [finishCharityCreation] = useMutation(finishCharityCreationMutation);

  const onSubmitCharity = async () => {
    const charityPayload = {
      email: _.get(charityById, 'email'),
    };

    // Update charity
    try {
      await finishCharityCreation({
        variables: {
          id: charityCreatedID,
          charity: charityPayload,
        },
      });
      localStorage.remove('charity-temp');
      history.replace({ pathname: '/charity-finish', state: { charity: charityPayload } });
    } catch (submissionError) {
      console.error(submissionError);
    }
  };

  const getAddress = charityDataObject => {
    const { location } = charityDataObject;
    return `${location.street} ${location.city}, ${location.state} ${location.postcode}`;
  };

  return loading ? (
    <LoadingOverlay style={{ opacity: 0.8 }} />
  ) : (
    <div className="childContainer reviewContainer">
      <hr />
      <div className="row contactDetailsRow">
        <div className="col-xs-12 col-md-9">
          <div className="companyDetailsContainer">
            <div className="companyLogo">
              <img src={data.charityById.picture} alt={`${data.charityById.name} logo`} />
            </div>
            <div>
              <h3 className="companyName">{data.charityById.name}</h3>
              <span className="lineItem">{getAddress(data.charityById)}</span>
              <span className="lineItem">EIN/IRS: {data.charityById.registration}</span>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="contactDetailsColumn">
            <h4 className="sectionHeading">Contact details</h4>
            <span className="lineItem smallMargin">{data.charityById.phone}</span>
            <span className="lineItem smallMargin">{data.charityById.email}</span>
            <span className="lineItem smallMargin">{data.charityById.website}</span>
          </div>
        </div>
      </div>
      <div className="row contactDetailsRow">
        <div className="col-xs-12">
          <h4 className="sectionHeading">About the charity</h4>
          <p>{data.charityById.description}</p>
        </div>
      </div>
      <div className="row contactDetailsRow">
        <div className="col-xs-12">
          <h4 className="sectionHeading">Categories</h4>
          {data.charityById.Causes.map((cause, index) => (
            <span key={`cause-${index}`} className="singleCause">
              {cause.name}
            </span>
          ))}
        </div>
      </div>

      <div className="buttonsContainer">
        <Button label="Back" labelColor="#212B35" bgColor="#fff" onClick={() => history.goBack()} className="btnDefault btnWhite" />
        <Button label="Submit" onClick={onSubmitCharity} className="btnDefault btnBlueSolid" />
      </div>
    </div>
  );
};

export default withRouter(CreateCharityReview);
