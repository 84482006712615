import React from 'react';
import { withRouter } from 'react-router';
import { Button } from '../common';
import { localStorage } from '../../lib/storage';

// Styles
import './styles/forCharityStyles.css';

import IllustrationThree from './assets/illustration-3.png';
import IllustrationFour from './assets/illustration-4.png';

function ForCharitiesPage({ history }) {
  const moveTo = (regPathname, unregPathname, action) => {
    localStorage.get('userID') ? history.push({ pathname: regPathname }) : history.push({ pathname: unregPathname, state: { action } });
  };

  return (
    <div className="forCharitiesContainerNew">
      <div className="introSection">
        <div className="wrapperContent">
          <div className="content">
            <h1 className="mbottom40">Build and manage your charity’s network.</h1>
            <p className="mbottom40">View your network as a collection of skills, ready for you to make the most of.</p>
            <Button label="Sign up" onClick={() => moveTo(`/profile/${localStorage.get('userID')}`, '/sign-up')} />
          </div>
          <div className="background" />
        </div>
      </div>
      <div className="assignTasksSection">
        <div className="wrapperContent">
          <div className="background" />
          <div className="content">
            <div>
              <p className="small">Assign Tasks</p>
              <div className="line" />
              <h1 className="mbottom30">Assign tasks to skilled volunteers</h1>
              <p className="mbottom32">Outsource your to-do list to a network of skilled, reliable volunteers</p>
              <Button label="Register Charity" onClick={() => moveTo('/charity/register/details', '/sign-up', 'registerCharity')} />
            </div>
          </div>
        </div>
      </div>
      <div className="manageTasksSection">
        <div className="wrapperContent">
          <div className="content">
            <p className="small">Manage Tasks</p>
            <div className="line" />
            <h1 className="mbottom30">Track your volunteer’s progress easily</h1>
            <p className="mbottom32">View outstanding and in-progress tasks from your Task Manager.</p>
          </div>
          <div className="screenshotContainer">
            <div className="screenshot">
              <img src={IllustrationThree} alt="Illustration" />
            </div>
          </div>
        </div>
      </div>
      <div className="charityNetworksSection">
        <div className="wrapperContent">
          <div className="content">
            <p className="small">Charity Networks</p>
            <div className="line" />
            <h1 className="mbottom30">Join now and start building a network of repeat volunteers</h1>
            <p className="mbottom32">Expand your Charity’s network with talented and willing supporters</p>
            <Button className="btn_transparent" label="Search Charities" onClick={() => moveTo('/charities', '/charities')} />
          </div>
          <div className="imageContainer">
            <img src={IllustrationFour} alt="Illustration" />
          </div>
        </div>
      </div>
      <div className="outroSection">
        <div className="wrapperContent">
          <div className="background" />
          <div className="content">
            <h1 className="mbottom40">Elevate your charity</h1>
            <p className="mbottom40">Scale your network to elevate your Charity’s brand and supporter base.</p>
            <Button label="Register Now" onClick={() => moveTo('/charity/register/details', '/sign-up', 'registerCharity')} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ForCharitiesPage);
