import React, { useState } from 'react';
import { LoadingOverlay, Modal, Button } from '../common';

import './styles/component.scss';

function ModalVerifyCharity(props) {
  const [loading, setLoading] = useState(false);

  return (
    <Modal open={props.open} onClose={() => props.onClose(false)} className="confirmModal">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <div className="accountFormContainer equalPadding textCenter">
        <h1 className="accountFormHeader">Awaiting verification</h1>
        <p className="accountFormText">Your new charity details will be reviewed and updated within 1-2 business days.</p>
        <Button
          label="OK"
          onClick={() => {
            props.onClose(false);
            setLoading(false);
          }}
        />
      </div>
    </Modal>
  );
}

export default ModalVerifyCharity;
