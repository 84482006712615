import gql from 'graphql-tag';

export default gql`
    query FetchEventComments($id: ID!) {
        eventComments(id: $id) {
            _id
            user_id
            content
            created
            User {
                firstname
                lastname
                picture
            }
        }
    }
`;
