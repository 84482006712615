import React, { useState, useCallback } from 'react';
import { ModalBase } from 'components/common/ModalBase/view';
import { FlatButton } from 'components/common/FlatButton/view';
import { LoadingOverlay } from 'components/common';
import _ from 'lodash';
import './styles.scss';
import { useMutation, useQuery } from '@apollo/client';
import fetchTasksByCharityId from 'queries/task/fetchTasksByCharityId';
import deleteTask from 'mutations/task/deleteTask';

export const ModalDeleteTask = ({ onClose, taskId, charityId }) => {
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteTaskMutation] = useMutation(deleteTask);

  const handleDeleteTask = useCallback(async () => {
    setIsLoading(true);
    await deleteTaskMutation({
      variables: {
        taskId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: fetchTasksByCharityId,
          variables: {
            _charity_id: charityId,
          },
          skip: false,
        },
      ],
    });
    setIsLoading(false);
    onClose();
  }, [taskId, taskId, onClose]);

  return (
    <ModalBase open={open} onClose={onClose} className="deleteTaskModal">
      {isLoading ? (
        <div className="flexCenter relative">
          <LoadingOverlay style={{ opacity: 0.8 }} />
        </div>
      ) : (
        <>
          <h2 className="fontPoppins textBlue bold textCenter mt0">Delete Task?</h2>
          <h3 className="fontPoppins textBlack normal textCenter mbottom20">Are you sure you want to delete this task? Any timesheets submitted will be lost.</h3>
          <div className="flexBetween">
            <FlatButton className="btnMedium" label="Delete" filled onClick={() => handleDeleteTask()} />
            <FlatButton className="btnMedium" label="Cancel" onClick={onClose} />
          </div>
        </>
      )}
    </ModalBase>
  );
};
