/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import StarEmptyIcon from 'assets/svg/star-empty.svg';
import StarFilledIcon from 'assets/svg/star-filled.svg';
import _ from 'lodash';
import './styles.scss';
import CheckedCircle from 'assets/svg/check-circle.svg';
import UpIcon from 'assets/svg/up.svg';
import DownIcon from 'assets/svg/down.svg';
import { Input, LoadingOverlay } from 'components/common';
import { IconButton } from 'components/common/IconButton/view';
import moment from 'moment/moment';
import { userUtils } from 'lib/user';

export const Feedback = ({ taskName, taskSubmission, index, reviewData, setReviewData }) => {
  const [isExpand, setIsExpand] = useState(false);

  const timeDuration = () => {
    const totalTime = taskSubmission.TaskTimesheet.reduce((acc, timesheet) => {
      const start = moment(timesheet.start);
      const end = moment(timesheet.end);
      return acc + end.diff(start);
    }, 0);

    return moment.duration(totalTime);
  };

  const starRatings = isEditable => {
    const ratings = [];
    for (let i = 1; i < 6; i += 1) {
      ratings.push(
        <IconButton
          key={i}
          icon={i <= reviewData[index]?.rating ? StarFilledIcon : StarEmptyIcon}
          onClick={() => {
            const temp = reviewData;
            const k = [...temp];
            k[index] = { ...k[index], rating: i };
            isEditable && setReviewData(k);
          }}
        />,
      );
    }
    return ratings;
  };

  useEffect(() => {
    if (index === 0) setIsExpand(true);
  }, [index]);

  return (
    <div>
      <div className="forceCursor alignCenter ptop10 pbottom10" onClick={() => setIsExpand(!isExpand)}>
        <div className="col-xs-2 textBlack textStart">
          <img className="mright8" src={isExpand ? UpIcon : DownIcon} alt="Arrow Icon" />
          {taskName}
        </div>
        <div className="col-xs-2 textGreen textCenter">
          <img src={CheckedCircle} alt="Completed Icon" className="mright4" />
          Completed
        </div>
        <div className="col-xs-2 textBlack textCenter">{userUtils.getFullName(_.get(taskSubmission, 'User'))}</div>
        <div className="col-xs-3 textBlack textCenter">
          Total Time: {timeDuration(taskSubmission).hours()}:{timeDuration(taskSubmission).minutes()}:{timeDuration(taskSubmission).seconds()}
        </div>
        <div className="col-xs-3 starsHeaderContainer">{starRatings(false)}</div>
      </div>
      {isExpand && (
        <>
          <h3 className="textBlack fontPoppins textCenter mtop40">
            How was your experience with {_.get(taskSubmission, 'User.firstname')}?
          </h3>
          <div className="starsContainer">{starRatings(true)}</div>
          <Input
            name="feedback"
            placeholder="Volunteers are more likely to apply for your tasks if you reward them with recognition and feedback."
            type="text"
            maxLength="500"
            multiLine
            value={reviewData[index]?.message}
            onChange={value => {
              const temp = reviewData;
              const k = [...temp];
              k[index] = { ...k[index], message: value };
              setReviewData(k);
            }}
          />
        </>
      )}
    </div>
  );
};
