import React, { useState } from 'react';
import { ModalBase } from 'components/common/ModalBase/view';
import { FlatButton } from 'components/common/FlatButton/view';
import './styles.scss';

export const LeaveModal = ({ onClose, charityName, onLeave }) => {
  const [open, setOpen] = useState(true);
  return (
    <ModalBase
      onClose={onClose}
      className="leaveModal"
      open={open}
    >
      <h1 className="textBlack">
        Leave Network
      </h1>
      <p className="textBlack textCenter mbottom24">
      Are you sure you want to leave the {charityName} network?
      <br />
      You won&#39;t receive direct invites to their tasks or ongoing opportunities.
      </p>
      <FlatButton
        label="Leave Network"
        filled
        className="mbottom24"
        onClick={() => {
          setOpen(false);
          onLeave();
        }}
      />
      <div className="textBlack">
        No,&nbsp;
        <a onClick={() => setOpen(false)}>
          I&#39;ll stay
        </a>
      </div>
    </ModalBase>
  );
};
