import React from 'react';

const CameraIcon = ({ color, className, height, width }) => (
  <svg className={className} width={width || '42'} height={height || '42'} viewBox="0 0 42 42" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
    <path d="M36.1742 13.6293H30.4292C30.055 13.6293 29.7517 13.9326 29.7517 14.3068C29.7517 14.6809 30.055 14.9843 30.4292 14.9843H36.1742C37.4117 14.9843 38.525 15.4835 39.3367 16.2935C40.1458 17.1043 40.645 18.2177 40.6458 19.4552V36.1472C40.6458 37.3856 40.1458 38.5072 39.335 39.3239C38.5233 40.1406 37.41 40.644 36.175 40.6448H5.8525C4.61667 40.6448 3.495 40.1398 2.67667 39.3223C1.85917 38.5039 1.355 37.3822 1.35417 36.1464V19.4544C1.35417 18.2185 1.85833 17.106 2.675 16.2943C3.4925 15.4835 4.61417 14.9834 5.8525 14.9834H11.57C11.9442 14.9834 12.2467 14.6801 12.2467 14.3068C12.2467 13.9334 11.9433 13.6301 11.57 13.6301H5.8525C2.63583 13.6293 0.00166667 16.2301 0 19.4544V36.1464C0.00333333 39.3689 2.63 41.9965 5.8525 41.999H36.1742C39.3992 41.9973 41.9992 39.3631 42 36.1464V19.4544C42 16.2351 39.3933 13.6284 36.1742 13.6284V13.6293Z" fill="black" />
    <path d="M15.9393 33.2156C17.2359 34.4981 19.0318 35.2923 21.0076 35.2915C22.9834 35.2915 24.7793 34.4981 26.0759 33.2156C27.3734 31.9339 28.1801 30.1547 28.1793 28.1938C28.1793 26.2338 27.3734 24.4538 26.0759 23.1721C24.7793 21.8895 22.9834 21.0954 21.0076 21.0962C19.0318 21.0962 17.2359 21.8895 15.9393 23.1721C14.6418 24.4538 13.8351 26.233 13.8359 28.1938C13.8359 30.1539 14.6418 31.9339 15.9393 33.2156V33.2156ZM16.8926 24.1363C17.9459 23.0954 19.3976 22.4521 21.0084 22.4521C22.6184 22.4521 24.0701 23.0962 25.1243 24.1363C26.1768 25.1779 26.8251 26.6088 26.8251 28.1947C26.8251 29.7805 26.1776 31.2106 25.1243 32.2531C24.0709 33.2939 22.6193 33.9373 21.0084 33.9373C19.3984 33.9373 17.9468 33.2931 16.8926 32.2531C15.8401 31.2114 15.1918 29.7805 15.1918 28.1947C15.1918 26.6088 15.8393 25.1788 16.8926 24.1363V24.1363Z" fill="black" />
    <path d="M34.1687 22.3589C34.5428 22.3589 34.8462 22.0556 34.8462 21.6814C34.8462 21.3072 34.5428 21.0039 34.1687 21.0039H32.5662C32.192 21.0039 31.8887 21.3072 31.8887 21.6814C31.8887 22.0556 32.192 22.3589 32.5662 22.3589H34.1687Z" fill="black" />
    <path d="M21.0077 14.9888C21.3818 14.9888 21.6852 14.6854 21.6852 14.3113V2.31269L26.0418 6.66944C26.3068 6.93445 26.7352 6.93445 27.0002 6.66944C27.2652 6.40444 27.2652 5.97609 27.0002 5.71109L21.4868 0.19848C21.4243 0.135979 21.3485 0.0859782 21.2652 0.0518109L21.2535 0.0484775C21.1777 0.0184769 21.0952 0.000976562 21.0085 0.000976562H21.006C20.9193 0.000976562 20.8368 0.0184769 20.761 0.0484775L20.7493 0.0526443C20.666 0.0868116 20.591 0.136813 20.5277 0.200147L15.0152 5.71359C14.7502 5.97859 14.7502 6.40694 15.0152 6.67194C15.2802 6.93695 15.7085 6.93695 15.9735 6.67194L20.3302 2.31519V14.3104C20.3302 14.6846 20.6335 14.9879 21.0077 14.9879V14.9888Z" fill="black" />
  </svg>
);

export default CameraIcon;
