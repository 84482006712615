import React, { useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Input, Button, LoadingOverlay } from '../../common';

// Mutations
import resetPasswordGQL from '../../../mutations/user/resetPassword';

// Queries
import fetchUserByRequestId from '../../../queries/user/fetchUserByRequestId';

// Styles
import './styles/resetPasswordStyles.css';

const ResetPassword = ({
    history,
    match,
    resetPassword,
    userDetails
}) => {
    const [user, setUser] = useState({
        password: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const handleResetPassword = (event) => {
        if (event) event.preventDefault();

        setError('');
        setLoading(true);

        resetPassword(userDetails.userByForgetPasswordID.email, user.password, match.params.requestId)
            .then(() => {
                setLoading(false);
                setRedirectToLogin(true);
            })
            .catch(() => {
                setError('');
                setLoading(false);
            });
    };

    const renderSorryMessage = () => {
        history.replace({ pathname: '/login' });
    };

    const { userByForgetPasswordID } = userDetails;

    if (userDetails.loading) {
        return <LoadingOverlay style={{ opacity: 0.8 }} />;
    } if (_.size(userByForgetPasswordID) === 0) {
        renderSorryMessage();
    }

    return (
        <div className="resetPasswordContainer">
            <div className="resetPasswordContainerInternal centredContainer textCenter">
                {loading &&
                    <LoadingOverlay
                        style={{ opacity: 0.7 }}
                    />
                }
                <div className="accountFormContainer">
                    <h1 className="accountFormHeader smallMargin">Update your password</h1>
                    <p className="accountFormText">Please enter your new password</p>
                    {error && (
                        <div className="errorMessage">
                            <p className="error">{error}</p>
                        </div>
                    )}
                    <form onSubmit={handleResetPassword}>
                        <div className="mtop20">
                            <Input
                                name="password"
                                placeholder="New Password"
                                type="password"
                                value={user.password}
                                onChange={(value) => {
                                    setUser(prevState => ({
                                        ...prevState,
                                        password: value
                                    }));
                                }}
                            />
                        </div>

                        <div className="buttonsContainer mtop10 mbottom40">
                            <Button
                                label="Update password"
                                type="submit"
                                disabled={!(user.password)}
                            />
                        </div>
                        <p className="accountFormText">
                            <a className="bold" href="/login">Back to login</a>
                        </p>
                    </form>
                </div>
                {redirectToLogin && (
                    <Redirect push to="/login" />
                )}
            </div>
        </div>
    );
};

const UserDetailsByRequestId = graphql(fetchUserByRequestId, {
    name: 'userDetails',
    options: ({ match }) => ({
        variables: { id: match.params.requestId },
    }),
});

const resetPasswordMutation = graphql(resetPasswordGQL, {
    props: ({ mutate }) => ({
        resetPassword: (email, password, requestId) => mutate({
            variables: { email, password, requestId }
        }),
    }),
});

export default withRouter(_.flowRight(
    UserDetailsByRequestId,
    resetPasswordMutation
)(ResetPassword));
