import React from 'react';
import Chip from 'material-ui/Chip';
import _ from 'lodash';

// Styles
import './styles/ChipsStyles.css';

function Chips(props) {
  const renderChip = chip => {
    // Check if the chips component will have a delete functionality and built it
    let deleteFunction = null;
    if (props.onDelete) {
      deleteFunction = () => props.onDelete(chip);
    }

    const primaryColor = props.onClick ? '#3042d4' : '#000000';

    const getChipCustomStyles = () => {
      const bgColor = _.get(chip, 'bgColor') || primaryColor;
      if (_.get(chip, 'selected')) {
        return {
          backgroundColor: bgColor,
          border: `1px solid ${bgColor}`,
        };
      }
      return {
        backgroundColor: 'transparent',
        border: `1px solid ${bgColor}`,
      };
    };

    return (
      <Chip
        key={chip._id}
        onRequestDelete={deleteFunction}
        onClick={() => props.onClick && props.onClick(chip)}
        className={`chip ${chip.selected ? 'selected' : ''} ${props.onClick ? 'isClickable' : ''} ${chip.image ? 'hasImage' : ''}`}
        style={getChipCustomStyles()}
      >
        {chip.image && <div style={{ backgroundImage: `url(${chip.image})` }} className="defaultImageStyle imgUser" />}
        <span
          className="chipTitle"
          style={{
            color: `${chip.labelColor || (chip.selected ? '#FFFFFF' : primaryColor)}`
          }}
        >
          {chip.label}
        </span>
      </Chip>
    );
  };

  return (
    <div key="chip" className={`chipsComponent ${!props.vertical ? 'wrapContainer' : ''} ${props.containerStyle || ''} ${props.isRounded ? 'roundedChips' : ''}`}>
      {props.values.map(renderChip)}
    </div>
  );
}

export default Chips;
