import React, { Component } from 'react';
import MenuItem from 'material-ui/MenuItem';
import Select from '../Select/view';
import { utils } from '../../../lib/utils';

class TimePickerField extends Component {
    constructor(props) {
        super(props);

        this.arrayOfHoursMinutes = [];
        const halfHours = ['00', '30'];
        for (let i = 0; i <= 23; i += 1) {
            for (let j = 0; j < 2; j += 1) {
                this.arrayOfHoursMinutes.push(`${(i < 10) ? `0${i}` : i}:${halfHours[j]}`);
            }
        }
    }

    renderHour(value, minHour) {
        return (
            <MenuItem
                value={value}
                primaryText={utils.convert24HourTo12Hour(value)}
                key={value}
                disabled={value < minHour}
            />
        );
    }

    render() {
        return (
            <Select
                renderItem={value => this.renderHour(value, this.props.minHour)}
                className={`selectTime font--18 ${this.props.className}`}
                disabled={this.props.disabled}
                value={this.props.value}
                values={this.arrayOfHoursMinutes}
                floatingLabelText={this.props.placeholder}
                textFieldStyle={this.props.textFieldStyle ? this.props.textFieldStyle : null}
                underlineStyle={this.props.underlineStyle || null}
                onChange={(e, value) => this.props.onChange(e, value)}
                style={this.props.style}
            />
        );
    }
}

export default TimePickerField;
