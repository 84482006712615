import gql from 'graphql-tag';

export default gql`
    query FetchUserByRequestId($id: String!) {
        userByForgetPasswordID(id: $id) {
            _id
            email
            firstname
        }
    }
`;
