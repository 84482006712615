import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { LoadingOverlay } from 'components/common';

import fetchCharityById from '../../../../queries/charity/fetchCharityById';

import { Button, MembersList } from '../../../common';
import { ButtonContainer, SectionContent, SectionHeading } from '../../common/Elements';
import SignupBlock from '../../common/SignupBlock';

const SmallHeading = styled.h4`
  font-size: 14px;
  font-family: 'Poppins' !important;
  font-weight: 700;
  margin-top: 40px;
`;

const CenteredContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 40px;

  p {
    margin: 0;
  }

  @media and (min-width: 767px) {
    padding: 40px;
  }
`;

const VolunteerStep = ({ history, formData, setFormData }) => {
  const [selectedFollowers, setSelectedFollowers] = useState([]);
  const { charityId } = formData;

  const { loading, data } = useQuery(fetchCharityById, {
    skip: !charityId,
    variables: {
      _id: charityId,
    },
    fetchPolicy: 'network-only',
  });

  const retransformFollowerArray = followersArray => {
    return followersArray.map(follower => follower.user);
  };

  const handleFollowerClick = user => {
    const hasFollower = selectedFollowers.find(follower => follower === user._id);

    if (!hasFollower) {
      setSelectedFollowers([...selectedFollowers, user._id]);
    } else {
      setSelectedFollowers(selectedFollowers.filter(item => item !== user._id));
    }
  };

  return loading ? (
    <LoadingOverlay style={{ opacity: 0.8 }} />
  ) : (
    <>
      <div className="mtop40 mbottom20">
        {data.charityById.charity_followers.length ? (
          <>
            <SectionHeading>Invite volunteers to apply for this task</SectionHeading>

            <SectionContent>
              You can invite volunteers in your network to apply for this opportunity but we will also advertise your opportunity to the broader Worthy community.
            </SectionContent>

            <MembersList users={retransformFollowerArray(data.charityById.charity_followers)} onClick={handleFollowerClick} disableLink activeUsers={selectedFollowers} />
          </>
        ) : (
          <CenteredContent>
            <SectionHeading>There are currently no volunteers in your network</SectionHeading>
            <p>Don&apos;t worry, your opportunity will always be advertised to the broader worthy network.</p>
          </CenteredContent>
        )}
      </div>

      <SmallHeading>Invite volunteers to join Worthy</SmallHeading>

      <SignupBlock charityId={charityId} />

      <ButtonContainer largeMargin>
        <Button
          borderedButton
          label="Back"
          onClick={() => {
            history.push({ pathname: '/task/create/skills' });
          }}
        />
        <Button
          label="Continue"
          onClick={() => {
            if (selectedFollowers.length) {
              setFormData(prevState => ({
                ...prevState,
                invitedFollowers: selectedFollowers,
                charityStatus: data.charityById.status,
              }));
            }
            setFormData(prevState => ({
              ...prevState,
              charityStatus: data.charityById.status,
            }));
            history.push({ pathname: '/task/create/publish' });
          }}
        />
      </ButtonContainer>
    </>
  );
};

export default VolunteerStep;
