import React, { useState } from 'react';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import MessageButton from '../MessageButton/view';
import Button from '../Button/view';
import DropDownIcon from './assets/dropdown.svg';

// Styles
import './styles/tableStyles.css';

const TableComponent = props => {
  const [selected, setSelected] = useState([]);
  const [isSelectAction, setIsSelectAction] = useState(null);

  const { containerClassNameStructure, className, handleRowSelection, bodyDisplayRowCheckbox, headerValues, bodyValues } = props;

  const renderTableHeaderColumn = (title, index) => {
    return (
      <TableHeaderColumn key={`header-${title}`} className={containerClassNameStructure[index]}>
        {title}
      </TableHeaderColumn>
    );
  };

  const renderContentRow = (item, indexParent, index) => {
    if (!item) return;
    return (
      <TableRowColumn key={`column-${indexParent}-${index}`} className={containerClassNameStructure[index]}>
        {item.beforeClassName && <span className={item.beforeClassName} />}
        {item.title && <span className={item.titleClassName}>{item.title}</span>}
        {item.isGroupLink && (
          <div className="flexColumn groupTitle" onClick={item.linkCallback}>
            {item.title1 && <span className={`mtop8 ${item.title1ClassName}`}>{item.title1}</span>}
            {item.title2 && <span className={`mbottom8 mtop8 ${item.title2ClassName}`}>{item.title2}</span>}
          </div>
        )}
        {item.isMultiTitle && (
          <div className="flexColumn groupTitle">
            {item.title1 && (
              <a href={item.linkURL1} className={item.multiTitleClassName}>
                <span className="mtop8">{item.title1}</span>
              </a>
            )}
            {item.title2 && (
              <a href={item.linkURL2} className={`mtop8 ${item.multiTitleClassName}`}>
                <span className="mbottom8">{item.title2}</span>
              </a>
            )}
          </div>
        )}
        {item.linkTitle && (
          <a href={item.linkURL} onClick={item.linkCallback} className={item.linkTitleClassName}>
            {item.linkTitle}
          </a>
        )}
        {item.isIconTitle && (
          <div className="flex alignCenter">
            <img src={item.icon} alt="Message Icon" />
            <div className={`mleft16 ${item.className}`}>{item.iconTitle}</div>
          </div>
        )}
        {item.helperContainer && item.helperContainer}
        {item.isBtnGroup && (
          <div className="flexRow flexBetween">
            <div onClick={() => (isSelectAction === indexParent ? setIsSelectAction(null) : setIsSelectAction(indexParent))} className="actionGroupContainer mright8">
              <div className="actionGroupHeader flexBetween">
                <div>Select action</div>
                <img alt="DropDown" src={DropDownIcon} />
              </div>
              {item.btnActions.map((btnAction, i) => {
                return (
                  isSelectAction === indexParent && (
                    <div className="action" key={i} onClick={btnAction.actionCallback}>
                      {btnAction.actionTitle}
                    </div>
                  )
                );
              })}
            </div>
            {item.isVolunteer && <MessageButton users={[item.charityManagerInfo]} isDark />}
          </div>
        )}
        {item.btnTitle && <Button label={item.btnTitle} onClick={item.btnCallback} />}
      </TableRowColumn>
    );
  };

  const renderContent = (content, index) => {
    return (
      <TableRow key={`row-${index}`} selectable className="contentRow" selected={index === selected}>
        {content.map((item, indexItem) => renderContentRow(item, index, indexItem))}
      </TableRow>
    );
  };

  return (
    <Table
      className={`defaultTable ${className || ''}`}
      fixedHeader
      selectable
      onRowSelection={index => {
        if (index.length > 0) {
          setSelected(index[0]);
          if (handleRowSelection) handleRowSelection(index);
        }
      }}
    >
      <TableHeader displaySelectAll={false} adjustForCheckbox={bodyDisplayRowCheckbox} enableSelectAll={false} className="tableHeader">
        <TableRow className="tableHeader">{headerValues.map((item, index) => renderTableHeaderColumn(item.title, index))}</TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={bodyDisplayRowCheckbox} showRowHover>
        {bodyValues.map((value, index) => renderContent(value.content, index))}
      </TableBody>
    </Table>
  );
};

export default TableComponent;
