import gql from 'graphql-tag';

export default gql`
    query FetchUserRoleByID($id:ID) {
        userByID(id: $id) {
            _id
            roles
        }
    }
`;
