import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Header, HeaderSimple, Footer } from '../common';

// Styles
import './appStyles.scss';

const routesWithoutHeader = ['/sign-up', '/login', '/onboarding', '/forgot-password', '/confirm-account'];
const routesWithoutFooter = ['/dashboard'];

function App(props) {
  // When the page gets changed it should scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  const renderHeader = () => {
    // Define whether it should render the header for specific route
    const pathname = _.get(props, 'location.pathname');
    if (routesWithoutHeader.some(substring => pathname.includes(substring))) return <HeaderSimple />;
    return <Header />;
  };

  const renderFooter = () => {
    // Define whether it should render the header for specific route
    const pathname = _.get(props, 'location.pathname');
    if (routesWithoutFooter.some(substring => pathname.includes(substring))) return null;
    return <Footer />;
  };

  const header = renderHeader();
  const footer = renderFooter();

  return (
    <div className="appRoot">
      {header}
      <div className={`appContent ${!header ? 'noHeader' : ''}`}>{props.children}</div>
      {footer}
    </div>
  );
}

export default withRouter(App);
