import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import _ from 'lodash';
import FavoriteIcon from 'material-ui/svg-icons/action/favorite';
import FavoriteBorderIcon from 'material-ui/svg-icons/action/favorite-border';
import CheckIcon from 'material-ui/svg-icons/navigation/check';
import LoadingOverlay from '../../LoadingOverlay/view';
import VerifyEmail from '../VerifyEmail/view';
import { eventUtils } from '../../../../lib/event';
import { localStorage } from '../../../../lib/storage';

// Queries
import fetchUserById from '../../../../queries/user/fetchUserById';

// Mutations
import saveEvent from '../../../../mutations/user/saveEvent';
import unSaveEvent from '../../../../mutations/user/unSaveEvent';

// Styles
import './styles/eventThumbnailStyles.css';

/*
    Example of params
    <EventThumbnail
        containerClassName="custom"
        event={event}
    />
*/
class EventThumbnail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openVerifyEmail: false
        };

        this.onFavIconClicked = this.onFavIconClicked.bind(this);
        this.onUnFavoriteClicked = this.onUnFavoriteClicked.bind(this);
        this.onClickReviewVolunteers = this.onClickReviewVolunteers.bind(this);
        this.renderStatusBars = this.renderStatusBars.bind(this);
    }

    onFavIconClicked(event) {
        if (event) event.preventDefault();
        const { userByID } = this.props.userDetails;
        if (userByID && userByID.status === 0) {
            this.setState({ openVerifyEmail: true });
        } else if (userByID && userByID.status === 1) {
            this.props.saveEvent(this.props.userDetails.userByID._id, this.props.event._id);
        } else {
            this.props.history.push({ pathname: '/sign-up', state: { action: 'save', eventId: this.props.event._id, eventById: this.props.event } });
        }
    }

    onUnFavoriteClicked(event) {
        if (event) event.preventDefault();
        this.props.unSaveEvent(this.props.userDetails.userByID._id, this.props.event._id);
    }

    onClickReviewVolunteers(event) {
        if (event) event.preventDefault();
        this.props.history.push({ pathname: `/review-event/${this.props.event._id}/${(_.size(_.get(this.props.event, 'confirmed_users')) <= 10) ? 'rate-final' : 'select'}` });
    }

    renderStatusBars(shouldReviewEvent, isAttending, isFull) {
        // It should only render ONE bar a time
        if (shouldReviewEvent) {
            return (
                <div
                    className="barContainer reviewVolunteersContainer"
                    onClick={this.onClickReviewVolunteers}
                >
                    <h5>REVIEW VOLUNTEERS</h5>
                </div>
            );
        }

        if (isAttending) {
            return (
                <div className="barContainer attendingContainer">
                    <CheckIcon
                        className="checkIcon"
                        color="#FFF"
                    />
                    <h5>{eventUtils.isPast(this.props.event) ? 'ATTENDED' : 'ATTENDING'}</h5>
                </div>
            );
        }

        if (isFull) {
            return (
                <div className="barContainer isFullContainer">
                    <h5>EVENT FULL</h5>
                </div>
            );
        }
        return null;
    }

    renderFavouriteIcon(isFavoriteEvent, event) {
        if (eventUtils.isEventAdmin(event)) return null;
        if (isFavoriteEvent) {
            return (
                <div className="favoriteContainer">
                    <FavoriteIcon
                        className="favoriteIcon"
                        color="#F53920"
                        onClick={this.onUnFavoriteClicked}
                    />
                </div>
            );
        }
        return (
            <div className="favoriteContainer">
                <FavoriteBorderIcon
                    className="favoriteIcon"
                    color="#F53920"
                    onClick={this.onFavIconClicked}
                />
            </div>
        );
    }

    render() {
        if (this.props.userDetails.loading) {
            return (
                <LoadingOverlay style={{ opacity: 0.8 }} />
            );
        }
        const { userByID } = this.props.userDetails;

        let isFavoriteEvent = false;
        let isAttending = false;
        let shouldReviewEvent = false;
        const isFull = eventUtils.isFull(this.props.event);
        if (userByID) {
            // Test if event has been favorited by the user
            isFavoriteEvent = userByID.saved_events.filter(item => item.event_id === this.props.event._id).length > 0;
            // Test if the connected user is attending the event
            if (this.props.event.confirmed_users) {
                isAttending = this.props.event.confirmed_users.filter(item => item.user_id === userByID._id).length > 0;
            }
        }
        // If the user is the admin of the event it should test if it's time to review applicants
        if (eventUtils.isEventAdmin(this.props.event) && eventUtils.shouldReviewEvent(this.props.event)) shouldReviewEvent = true;

        return (
            <Link
                key={this.props.event._id}
                className={`eventThumbnailComponent ${(this.props.containerClassName) ? this.props.containerClassName : ''}`}
                to={`/event/${this.props.event._id}`}
            >
                <div className="internalContainer">
                    <div
                        className="header"
                        style={{ backgroundImage: `url(${_.get(this.props.event, 'picture')})` }}
                    >
                        <div className="date"><h5>{moment(this.props.event.config.start).format('DD MMM')}</h5></div>
                        {this.renderFavouriteIcon(isFavoriteEvent, this.props.event)}
                        {this.renderStatusBars(shouldReviewEvent, isAttending, isFull)}
                    </div>
                    <div className="content">
                        {/* <h4 className="location">{`${this.props.event.location.city} ${this.props.event.location.state} • ${eventUtils.getFrequencyLabel(this.props.event.config.frequency)}`}</h4> */}
                        <h4 className="location">{`${this.props.event.location.city} ${this.props.event.location.state}`}</h4>
                        <h2 className="title">{this.props.event.name}</h2>
                        <p className="smallBody category">{_.get(this.props.event, 'event_causes[0].Cause.name')}</p>
                    </div>
                    <div className="footer">
                        <div className="imgCharity">
                            <div
                                className="image"
                                style={{ backgroundImage: `url(${_.get(this.props.event.charity, 'picture')})` }}
                            />
                        </div>
                        <div className="charityName">
                            <p className="smallBody name">{this.props.event.charity.name}</p>
                        </div>
                    </div>
                </div>
                <VerifyEmail
                    open={this.state.openVerifyEmail}
                    onClose={() => this.setState({ openVerifyEmail: false })}
                    user={_.get(this.props.userDetails, 'userByID')}
                />
            </Link>
        );
    }
}

const UserDetailsByIdContainer = graphql(fetchUserById, {
    name: 'userDetails',
    options: () => ({
        variables: { id: localStorage.get('userID') },
    }),
});

const saveEventContainer = graphql(saveEvent, {
    props: ({ mutate }) => ({
        saveEvent: (id, event_id) => mutate({
            variables: { id, event_id },
            refetchQueries: [{
                query: fetchUserById,
                variables: { id },
            }]
        }),
    }),
});

const unSaveEventContainer = graphql(unSaveEvent, {
    props: ({ mutate }) => ({
        unSaveEvent: (id, event_id) => mutate({
            variables: { id, event_id },
            refetchQueries: [{
                query: fetchUserById,
                variables: { id },
            }]
        }),
    }),
});

export default withRouter(_.flowRight(
    UserDetailsByIdContainer,
    saveEventContainer,
    unSaveEventContainer
)(EventThumbnail));
