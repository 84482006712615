import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router';
import {
    LoadingOverlay,
    Button,
    TopBar,
    Chips
} from '../../common';
import { localStorage } from '../../../lib/storage';
import { eventUtils } from '../../../lib/event';

// Queries
import fetchEventById from '../../../queries/event/fetchEventById';

// Mutations
import applyForEvent from '../../../mutations/event/applyForEvent';

// Styles
import './styles/applyForEventStyles.css';

class ApplyForEvent extends Component {
    constructor() {
        super();

        this.state = {
            error: '',
            loading: false
        };

        this.onApplyForEvent = this.onApplyForEvent.bind(this);
    }

    onApplyForEvent() {
        this.setState({
            loading: true,
            error: ''
        });
        this.props.applyForEvent(this.props.match.params.event_id, localStorage.get('userID'))
            .then(() => {
                this.setState({ loading: false });

                this.props.history.replace({
                    pathname: `/event/${this.props.match.params.event_id}`,
                    state: {
                        applicationSuccessfull: true
                    }
                });
            })
            .catch((error) => {
                this.setState({
                    error,
                    loading: false
                });
            });
    }

    normaliseSkillsArray(skills_needed) {
        let skillsArray = [];
        if (_.size(skills_needed) > 0) {
            skillsArray = skills_needed.map((skill, index) => {
                return {
                    _id: index,
                    label: skill
                };
            });
        }
        return skillsArray;
    }

    render() {
        // if (this.props.eventData.loading || this.props.userData.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
        if (!_.get(this.props.location, 'state.eventId')) {
            // If there's not Charity id to assign to it should redirect to the homepage
            this.props.history.replace({ pathname: '/' });
        }
        const { eventById } = this.props.location.state;
        return (
            <div className="applyForEventContainer col-xs-12 p0">
                {this.state.error && (
                    <div className="errorMessage">
                        <p className="error">{this.state.error}</p>
                    </div>
                )}
                {this.state.loading && (
                    <LoadingOverlay style={{ opacity: 0.8 }} />
                )}
                <div className="applyForEventHeader col-xs-12 p0">
                    <TopBar>
                        <p>
                            Give yourself a better chance by adding more details to your profile!
                            <a
                                onClick={() => this.props.history.push({ pathname: `/profile/${localStorage.get('userID')}` })}
                                className="mleft15"
                            >
                                Edit Profile
                            </a>
                        </p>
                    </TopBar>
                </div>
                <div className="contentApply col-xs-12">
                    <div className="defaultMainRootContentContainer contentInternal">
                        <h1 className="xlarge">Are you ready to apply?</h1>
                        <p>You&apos;re one step closer to helping <strong>{eventById.charity.name}</strong> with <strong>{eventById.name}</strong></p>
                        <div className="skillsetContainer">
                            <h2>{eventById.charity.name} is looking for {eventUtils.getNumberOfVolunteersLabel(eventById.config.volunteers_needed)} volunteers with the following skillset:</h2>
                            <Chips
                                containerStyle="mtop40"
                                values={this.normaliseSkillsArray(eventById.config.skills_needed)}
                            />
                            <h2>If you think your profile shows the best of abilities for the event, apply now! <br />Otherwise, <a onClick={() => this.props.history.push({ pathname: `/profile/${localStorage.get('userID')}` })}>Edit your profile</a></h2>
                        </div>
                        {moment(eventById.config.start).isAfter() &&
                            <Button
                                label="Send my application"
                                className="btnDefault mtop30 btnSendApplicantion"
                                onClick={this.onApplyForEvent}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const applyForEventContainer = graphql(applyForEvent, {
    props: ({ mutate }) => ({
        applyForEvent: (id, user_id) => mutate({
            variables: { id, user_id },
            refetchQueries: [{
                query: fetchEventById,
                variables: { id },
            }]
        }),
    }),
});

export default withRouter(_.flowRight(applyForEventContainer)(ApplyForEvent));
