import gql from 'graphql-tag';

export default gql`
  mutation continueTaskSubmission($taskId: ID!, $submissionId: ID!) {
    continueTaskSubmission(_task_id: $taskId, _task_submission_id: $submissionId) {
      _id
      _task_id
      _charity_id
      _user_id
      status
    }
  }
`;
