import _ from 'lodash';
import { localStorage } from './storage';

const userUtils = {
  isAdmin(user) {
    // Function to test if the user connect is admin on the charity or not
    const connectedUser = localStorage.get('userID');
    if (user === connectedUser) return true;
    return false;
  },
  getAverageStarRating(eventReviews) {
    const totalNumberOfReviews = _.size(eventReviews);
    if (totalNumberOfReviews === 0) return 0;
    const totalStarRatings = _.sumBy(eventReviews, o => o.rating);
    const avgStarRating = totalStarRatings / totalNumberOfReviews;
    return avgStarRating.toFixed(1);
  },
  getSumOfHours(eventReviews) {
    return _.sumBy(eventReviews, o => o.hours);
  },
  getLocationtext(location, completeFormat = true) {
    if (!location) return '';
    if (location.city && !completeFormat) return `${location.city} ${location.state}`;
    if (location.street) return `${location.street} ${location.city} ${location.state} ${location.postcode} ${location.country}`;
    if (location.location) return location.location;
    return '';
  },
  isValidPassword(password) {
    // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/);
    return regex.test(`${password}`);
  },
  isValidEmail(email) {
    const regex = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i);
    return regex.test(`${email}`);
  },
  isValidPhone(phone) {
    const regex = new RegExp(/^\(\d{3}\)\s\d{3}\s\d{4}$/);
    return regex.test(`${phone}`);
  },
  isCharityManager(user) {
    // Checks whether the user is a Charity Manager
    return _.get(user, 'type') === 2;
  },
  getFullName(user) {
    return `${_.get(user, 'firstname')} ${_.get(user, 'lastname')}`;
  },
  getCharityNetworkText(user) {
    const charities = _.get(user, 'CharitiesFollowing');
    const charityLength = _.size(charities);
    if (charityLength > 0) {
      if (charityLength === 1) {
        return `${_.get(charities[0], 'name')}`;
      }
      if (charityLength === 2) {
        return `${_.get(charities[0], 'name')} and ${_.get(charities[1], 'name')}`;
      }
      return `${_.get(charities[0], 'name')} and ${charityLength - 1} others`;
    }
    return '';
  },
  getLocationAndCharityText(locationTxt, charityTxt, character) {
    if (locationTxt && charityTxt) {
      return `${locationTxt} ${character} ${charityTxt}`;
    }
    return `${locationTxt} ${charityTxt}`;
  },
  getSelectedUserNames(selectedUsers) {
    return _.join(
      _.map(selectedUsers, user => `${_.get(user, 'firstname')} ${_.get(user, 'lastname')}`),
      ', ',
    );
  },
};

export { userUtils };

export const getAddOrEditLabel = (array = []) => {
  if (_.size(array) >= 1) return 'Edit';
  return 'Add';
};
