import React, { Component } from 'react';
import { Modal, Button, Input } from '../../common';

// Styles
import './styles/reviewStyles.css';

class ModalVolunteerFeedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: ''
        };

        this.onClose = this.onClose.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.feedback !== this.state.message) {
            this.setState({ message: newProps.feedback });
        }
    }

    onClose() {
        this.props.onClose();
        this.setState({ message: '' });
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onClose={this.onClose}
                className="defaultModal"
            >
                <div className="messageContainer">
                    <Input
                        name="addMessage"
                        type="text"
                        placeholder={this.props.title || 'Global feedback'}
                        helpText={this.props.helpText || 'Write a short review for your volunteers'}
                        containerStyle="inputContainer"
                        value={this.state.message}
                        rowsMax={5}
                        multiLine
                        fullWidth={false}
                        onChange={(value) => {
                            this.setState({
                                message: value
                            });
                        }}
                    />
                </div>
                <Button
                    label="Save"
                    onClick={() => {
                        this.props.onSaveFeedback(this.state.message);
                        this.onClose();
                    }}
                    className="btnDefault mtop40"
                    disabled={!this.state.message}
                />
            </Modal>
        );
    }
}

export default ModalVolunteerFeedback;
