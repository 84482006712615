import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';
import { withRouter } from 'react-router';
import _ from 'lodash';
import ChatIcon from 'material-ui/svg-icons/communication/chat';
import { Button, LoadingOverlay, EventThumbnail } from '../../common';
import { eventUtils } from '../../../lib/event';
import { localStorage } from '../../../lib/storage';

// Queries
import fetchEventsByCharityId from '../../../queries/event/fetchEventsByCharityId';
import fetchUserById from '../../../queries/user/fetchUserById';

// Styles
import './styles/myEventsStyles.css';

class MyEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  renderEvent(event) {
    if (!event._id) return null;

    return <EventThumbnail containerClassName="col-xs-12 col-sm-6 col-md-4" event={event} key={event._id} />;
  }

  renderEventsForCharity(charity) {
    return (
      <Query query={fetchEventsByCharityId} variables={{ charity_id: charity._id }} key={charity._id}>
        {result => {
          if (result.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
          const { eventsByCharityID } = result.data;
          const eventsToReview = eventsByCharityID.filter(event => eventUtils.shouldReviewEvent(event));
          return (
            <div className="listEvents col-xs-12">
              <div className="internalContainerStyle">
                <div className="headerCharity col-xs-12 p0">
                  <div className="headerLeft col-xs-12 col-sm-4">
                    <h4>My Events for {charity.name}</h4>
                    <h2>
                      Hosted Events ∙ <span className="font--blue">{eventsByCharityID.length}</span>
                    </h2>
                  </div>
                  <div className="headerRight col-xs-12 col-sm-8">
                    <Button
                      label={`Give Feedback (${eventsToReview.length})`}
                      labelColor="#2723EB"
                      bgColor="#fff"
                      icon={<ChatIcon />}
                      onClick={() => {
                        if (eventsToReview.length > 0) {
                          this.props.history.push({ pathname: `/charity/${charity._id}/review-events` });
                        }
                      }}
                      className="btnDefault"
                    />
                    <Button
                      label="+ Create Event"
                      onClick={() => this.props.history.push({ pathname: '/event/register/details', state: { charity_id: charity._id } })}
                      className="btnDefault btnCreateEvent"
                    />
                  </div>
                </div>
                {_.size(eventsByCharityID) > 0 && eventsByCharityID.map(event => this.renderEvent(event))}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }

  renderMySavedEvents(userByID) {
    return (
      <div className="savedEventsContainer col-xs-12 p0">
        <div className="internalContainerStyle">
          <div className="internalContent col-xs-12">
            <div className="headerCharity col-xs-12 p0">
              <div className="headerLeft col-xs-12 col-sm-4">
                <h4>My Events Saved from Other Charities</h4>
                <h2>
                  Saved Events ∙ <span className="font--blue">{userByID.saved_events.length}</span>
                </h2>
              </div>
              <div className="headerRight col-xs-12 col-sm-8">
                <Button label="Browse tasks" href="/task/search" className="btnDefault" />
              </div>
            </div>
            {userByID.saved_events.map(({ Event }) => this.renderEvent(Event))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Query query={fetchUserById} variables={{ id: localStorage.get('userID') }}>
        {result => {
          if (result.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
          const { userByID } = result.data;

          return (
            <div className="myEventsContainer col-xs-12 p0">
              {this.state.error && (
                <div className="errorMessage">
                  <p className="error">{this.state.error}</p>
                </div>
              )}
              {_.map(userByID.CharitiesAdmin, item => this.renderEventsForCharity(item))}
              {userByID.saved_events.length > 0 && this.renderMySavedEvents(userByID)}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(MyEvents);
