import axios from 'axios';

const apiUtils = {
    checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
          return response;
        }

        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    },
    convertIntoPost(obj) {
        let str = '';
        return Object.keys(obj).reduce((previous, key) => {
            if (previous !== '') {
                str = '&';
            }
            return `${previous}${str}${key}=${encodeURIComponent(obj[key])}`;
        }, '');
    },
    getRandomKey() {
        return Math.random() * 10000000;
    }
};

// Create a single instance of Axios
const instance = axios.create({
    // baseURL: 'http://localhost:4000',
    // transformRequest: [apiUtils.convertIntoPost]
});

const http = {
    // Functions to request something via POST
    post(api, params, headers) {
         return http.request('post', api, params, headers);
    },
    get(api, params) {
        return http.request('get', api, params);
    },
    request(method, api, params, headers) {
        const extraHeaders = {};
        if (headers && headers.authorization) Object.assign(extraHeaders, { headers: { Authorization: headers.authorization } });
        const request = instance[method](api, { ...params }, extraHeaders);

        return request;
    }
};

export { http, apiUtils };
