import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { Button } from '../../../common';
import TaskCreatedIcon from './assets/taskCreated';
import { localStorage } from '../../../../lib/storage';

import fetchUserById from '../../../../queries/user/fetchUserById';

const FinishStepContainer = styled.div`
  max-width: 710px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px 0;

  h1 {
    font-size: 36px;
    font-weight: bolder;
    color: black;
    margin: 30px 0 10px;
  }

  p {
    margin: 20px 0;
  }

  .btn_primary {
    margin-top: 20px;
  }
`;

const FinishStep = ({ isVerified, history }) => {
  const taskCreated = _.get(history, 'location.state.task');
  const { data, refetch } = useQuery(fetchUserById, {
    variables: { id: localStorage.get('userID') },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <FinishStepContainer>
        {isVerified ? (
          <>
            <TaskCreatedIcon />
            <h1>Task created!</h1>
            <p>This task will be managed by:</p>
            <p>{`${_.get(data, 'userByID.firstname')} ${_.get(data, 'userByID.lastname')}`}</p>
            <Button href={`/task/${_.get(taskCreated, '_id')}`} label="View task" />
          </>
        ) : (
          <>
            <h1>Your charity is awaiting Charity verification</h1>
            <p>Your task has now been created, but it won&apos;t go live until your charity has been verified. Verification should only take up to 48 hrs.</p>
            <Button href={`/task/${_.get(taskCreated, '_id')}`} label="View draft task" />
          </>
        )}
      </FinishStepContainer>
    </>
  );
};

export default FinishStep;
