import { useMutation, useQuery } from '@apollo/client';
import { LoadingOverlay } from 'components/common';
import { FlatButton } from 'components/common/FlatButton/view';
import { ModalBase } from 'components/common/ModalBase/view';
import { localStorage } from 'lib/storage';
import { TASK_FREQUENCY, TASK_STATUS } from 'lib/task';
import { TASK_SUBMISSION_STATUS } from 'lib/task_submission';
import fetchCharitiesByUserId from 'queries/charity/fetchCharitiesByUserId';
import React, { useState } from 'react';
import InProgressYellowIcon from 'assets/svg/in-progress-yellow.svg';
import './styles.scss';
import inviteVolunteersForTask from 'mutations/task/inviteVolunteersForTask';
import moment from 'moment';
import fetchUserById from 'queries/user/fetchUserById';

export const InviteTaskModal = ({ onClose, user }) => {
  const [loading, setLoading] = useState(false);
  const { data } = useQuery(fetchCharitiesByUserId, {
    variables: {
      userId: localStorage.get('userID'),
    },
  });
  const [inviteVolunteer] = useMutation(inviteVolunteersForTask);
  if (!data) return <LoadingOverlay />;
  const { charityByUserId } = data;
  const tasks = charityByUserId.reduce((acc, curr) => {
    curr.Tasks.forEach(task => {
      if (task.status === TASK_STATUS.ACTIVE) acc.push(task);
    });
    return acc;
  }, []);

  const sendInvite = async taskId => {
    setLoading(true);
    await inviteVolunteer({
      variables: {
        _task_id: taskId,
        _user_ids: [user._id],
      },
      refetchQueries: [
        {
          query: fetchUserById,
          variables: {
            id: user._id,
          },
        },
      ],
    });
    setLoading(false);
  };

  const getTaskStated = task => {
    if (task.frequency === TASK_FREQUENCY.ONGOING) return true;
    // eslint-disable-next-line new-cap
    const startDate = new moment(task.oneoff_frequency_config.start);
    if (moment().isAfter(startDate)) return true;
    return false;
  };

  const renderTaskEnd = task => {
    if (task.frequency === TASK_FREQUENCY.ONGOING) return 'Ongoing';
    // eslint-disable-next-line new-cap
    return moment(task.oneoff_frequency_config.start).format('MMMM Do YYYY');
  };

  const renderTaskInvite = task => {
    const taskSubmission = user.TaskSubmissions.find(({ _task_id }) => _task_id === task._id);
    if (!taskSubmission) {
      return <FlatButton label="Invite" bordered onClick={() => sendInvite(task._id)} disabled={loading} />;
    }
    if (taskSubmission.status === TASK_SUBMISSION_STATUS.INVITED) {
      return <FlatButton label="Invite Sent" disabled />;
    }
    return <FlatButton label="Assigned" disabled />;
  };

  return (
    <ModalBase onClose={onClose} className="inviteTaskModal">
      <h3 className="fontPoppins textBlack normal textCenter">Invite {user.firstname} to a task</h3>
      <div className="row">
        <div className="col-xs-4">
          <h4 className="fontPoppins">Task name</h4>
        </div>
        <div className="col-xs-3">
          <h4 className="fontPoppins">Status</h4>
        </div>
        <div className="col-xs-3">
          <h4 className="fontPoppins">Due</h4>
        </div>
        <div className="col-xs-2" />
      </div>
      {tasks.map(task => (
        <div className="row" key={task._id}>
          <div className="col-xs-4">
            <h3 className="fontPoppins normal textBlack">{task.name}</h3>
          </div>
          {getTaskStated(task) ? (
            <div className="col-xs-3 flexRow alignCenter">
              <img src={InProgressYellowIcon} alt="In progress" className="mright8" />
              <h4 className="fontPoppins textYellow">In progress</h4>
            </div>
          ) : (
            <div className="col-xs-3 flexRow alignCenter">
              <img src={InProgressYellowIcon} alt="In progress" className="mright8" />
              <h4 className="fontPoppins textRed">Upcoming</h4>
            </div>
          )}
          <div className="col-xs-3">
            <h3 className="fontPoppins normal textBlack">{renderTaskEnd(task)}</h3>
          </div>
          <div className="col-xs-2">{renderTaskInvite(task)}</div>
        </div>
      ))}
    </ModalBase>
  );
};
