import React from 'react';
import Toggle from 'material-ui/Toggle';

const ToggleSwitch = (props) => {
    return (
        <Toggle
            label={props.label}
            toggled={props.value}
            style={props.style ? props.style : null}
            trackSwitchedStyle={{ backgroundColor: '#20C1C9' }}
            thumbSwitchedStyle={{ backgroundColor: '#2723EB' }}
            thumbStyle={{ marginTop: 15 }}
            trackStyle={{ marginTop: 15 }}
            onToggle={(e, isInputChecked) => props.onChange(isInputChecked)}
        />
    );
};

export default ToggleSwitch;
