import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import _ from 'lodash';
import {
    Stepper,
    LoadingOverlay
} from '../../common';
import { eventUtils } from '../../../lib/event';
import { utils } from '../../../lib/utils';

// Queries
import fetchEventById from '../../../queries/event/fetchEventById';

// Styles
import './styles/reviewStyles.css';

// External components
import RateVolunteers from './viewRateVolunteers';
import SelectAttendees from './viewSelectAttendees';

class ReviewEvent extends Component {
    constructor() {
        super();

        this.state = {
            error: '',
            loading: false,
        };

        this.renderStepper = this.renderStepper.bind(this);
    }

    renderStepper(step, event) {
        if (step === 'rate-final') return null;

        return (
            <Stepper
                className="reviewStepper"
                orientation="horizontal"
                values={[
                    {
                        index: 0,
                        label: 'Select Attendees',
                        link: `/review-event/${event._id}/select`
                    },
                    {
                        index: 1,
                        label: 'Rate Volunteers',
                        link: `/review-event/${event._id}/rate`
                    }
                ]}
            />
        );
    }

    render() {
        if (this.props.eventData.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
        const { eventByID } = this.props.eventData;
        const step = _.get(this.props.match, 'params.step');
        let request_review = false;
        // Get an extra argument 'request_review'. If it's there, it should enable the user to access the page
        const extraArguments = utils.parseQueryParams(_.get(this.props, 'location.search'));
        if (extraArguments && extraArguments.get('request_review')) {
            request_review = true;
        } else if (!eventUtils.shouldReviewEvent(eventByID)) {
            this.props.history.replace({ pathname: '/' });
        }
        return (
            <div className="reviewEventContainer col-xs-12 p0">
                {this.state.loading &&
                    <LoadingOverlay />
                }
                {this.state.error && (
                    <div className="errorMessage">
                        <p className="error">{this.state.error}</p>
                    </div>
                )}
                <div className="content col-xs-12">
                    <div className="internalContainerStyle">
                        <h1 className="xlarge">{eventByID.name} is over!</h1>
                        {step === 'rate-final' ? (
                            <h2>Record the number of hours and a star rating for each volunteer</h2>
                        ) : (
                            <h2>We hope your event was a huge success</h2>
                        )}
                        <div className="stepperContainer">
                            {this.renderStepper(step, eventByID)}
                        </div>
                        {step === 'rate-final' &&
                            <RateVolunteers
                                fullVersion
                                event={eventByID}
                                request_review={request_review}
                            />
                        }
                        {step === 'select' &&
                            <SelectAttendees
                                event={eventByID}
                            />
                        }
                        {step === 'rate' &&
                            <RateVolunteers
                                event={eventByID}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const eventByIDContainer = graphql(fetchEventById, {
    name: 'eventData',
    options: ({ match }) => ({
        variables: { id: match.params.event_id },
    }),
});

export default withRouter(_.flowRight(eventByIDContainer)(ReviewEvent));
