import React from 'react';

const NetworkPeople = () => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="35"
      fill="none"
      viewBox="0 0 36 35"
  >
      <ellipse
        cx="17.602"
        cy="17.5"
        fill="#000"
        rx="17.602"
        ry="17.5"
      >
      </ellipse>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M23.235 24.499v-1.4c0-.743-.297-1.455-.825-1.98a2.825 2.825 0 00-1.991-.82h-5.633c-.747 0-1.463.295-1.991.82a2.792 2.792 0 00-.825 1.98v1.4M17.602 17.498a2.808 2.808 0 002.817-2.8c0-1.546-1.261-2.8-2.817-2.8a2.808 2.808 0 00-2.816 2.8c0 1.547 1.261 2.8 2.816 2.8z"
      >
      </path>
  </svg>
);

export default NetworkPeople;
