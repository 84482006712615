import React, { useState } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router';
import { userUtils } from 'lib/user';
import { ModalBase, CharityManagerSearch, Button, LoadingOverlay } from '../common';

// Mutations
import createGroupMessageMutation from '../../mutations/group_messages/createGroupMessage';

// Query
import fetchGroupMessagesByUser from '../../queries/group_messages/fetchGroupMessagesByUser';

import './styles/inboxModal.scss';

function NewMessageModal({ onClose, _current_user_id, apolloClient, history }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [createGroupMessage] = useMutation(createGroupMessageMutation);

  const onSelectUser = user => {
    let newSelectedUsers = [...selectedUsers];
    // Check whether user is already on the list
    if (_.find(selectedUsers, item => item._id === user._id)) {
      newSelectedUsers = _.filter(selectedUsers, item => item._id !== user._id);
    } else {
      newSelectedUsers.push(user);
    }
    setSelectedUsers(newSelectedUsers);
  };

  const onCreateGroupMessage = async () => {
    setLoading(true);
    // Users on group should be everyone selected by the CM + themselves
    const usersOnGroup = _.map(selectedUsers, '_id');
    usersOnGroup.push(_current_user_id);
    try {
      const groupMessageCreated = await createGroupMessage({
        variables: {
          group: {
            name: userUtils.getSelectedUserNames(selectedUsers),
            users: usersOnGroup,
          },
        },
        refetchQueries: [
          {
            query: fetchGroupMessagesByUser,
            variables: {
              _user_id: _current_user_id,
            },
            fetchPolicy: 'network-only',
          },
        ],
      });
      setLoading(false);
      onClose();
      // Redirect to the newly created group
      history.push({ pathname: `/dashboard/inbox/group/${_.get(groupMessageCreated, 'data.createGroupMessage._id')}` });
    } catch (e) {
      setError(e);
    }
  };

  return (
    <ModalBase className="newMessageModal" onClose={onClose}>
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      {error && (
        <div className="errorMessage">
          <p className="error">{error}</p>
        </div>
      )}
      <h3 className="mtop0 mbottom24 fontPoppins textBlack">New message to {userUtils.getSelectedUserNames(selectedUsers)}</h3>
      <CharityManagerSearch apolloClient={apolloClient} displayAsPopover={false} onUserClicked={onSelectUser} selectedUsers={selectedUsers} shouldSelectUsers />
      <div className="flexEnd">
        <Button label="Continue" className="mtop30" onClick={onCreateGroupMessage} disabled={_.size(selectedUsers) === 0} />
      </div>
    </ModalBase>
  );
}

export default withRouter(NewMessageModal);
