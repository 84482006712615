import React, { useMemo, useState } from 'react';
import _, { isEmpty } from 'lodash';
import { FlatButton } from 'components/common/FlatButton/view';
import { SkillsList } from 'components/common/SkillsList/view';
import ModalAddSkills from 'components/user/old_profile/modalAddSkills';
import { userUtils, getAddOrEditLabel } from 'lib/user';
import { getSkillMacros } from 'lib/utils';
import SkillsEmptyIcon from 'assets/svg/skills-empty.svg';
import { UpdateSkillsModal } from './UpdateSkillsModal/view';

export const ProfileSkills = ({ user, updateUser }) => {
  const [showAdd, setShowAdd] = useState(false);
  const btnLabel = useMemo(() => getAddOrEditLabel(_.get(user, 'UserSkills')), [user]);

  return (
    <div className="skillsContainer">
      <div className="flexRow alignCenter mbottom32">
        <h2 className="m0">Skills</h2>
        {userUtils.isAdmin(user._id) && !isEmpty(_.get(user, 'UserSkills')) && <FlatButton className="mLeftAuto" label={btnLabel} onClick={() => setShowAdd(true)} />}
      </div>
      <SkillsList skills={user.UserSkills} macros={getSkillMacros(user.UserSkills)} />
      {showAdd && <UpdateSkillsModal userSkills={user.UserSkills} updateUser={updateUser} onClose={() => setShowAdd(false)} />}
      {isEmpty(_.get(user, 'UserSkills')) && (
        <div className="flexCenter textCenter">
          <img src={SkillsEmptyIcon} alt="Skills Empty Icon" />
          <div className="emptySubtitle mtop8">Add your skills</div>
          <div className="emptyDescription mtop8 mbottom16">Write a short description about yourself and your prior work experience.</div>
          <FlatButton label="Add skills" onClick={() => setShowAdd(true)} />
        </div>
      )}
    </div>
  );
};
