import React from 'react';

const Remote = () => (
  <svg width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12454_12634)">
      <path d="M23.6732 23.3419C23.6801 23.6038 23.4801 23.8175 23.2182 23.8313H16.1726C15.9107 23.8244 15.7038 23.6038 15.7176 23.3419V22.4043H-0.000399463V23.9692C-0.0210811 24.6586 0.516641 25.2308 1.20603 25.2584H38.7432C39.4326 25.2377 39.9703 24.6586 39.9427 23.9692V22.4043H23.6663V23.3419H23.6732Z" fill="currentColor" />
      <path d="M36.1655 1.95097C36.1655 0.875522 35.29 0 34.2076 0H5.17753C4.10208 0 3.22656 0.868628 3.22656 1.95097V21.2952H36.1655V1.95097ZM34.1387 18.9444H5.24647V2.84717H34.1387V18.9444Z" fill="currentColor" />
      <g clipPath="url(#clip1_12454_12634)">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.4992 7.33333L21.8838 8.73926C22.2412 8.85841 22.2889 9.31117 21.9791 9.5018L20.7161 10.312L22.7893 12.3852C23.0038 12.5758 23.0038 12.9332 22.7893 13.1477C22.5748 13.3622 22.2174 13.3622 22.0268 13.1477L19.9536 11.0745L19.1434 12.3375C18.9528 12.6473 18.5 12.5996 18.3809 12.2422L16.9749 7.85757C16.8558 7.54779 17.1894 7.21418 17.4992 7.33333Z" fill="currentColor" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_12454_12634">
        <rect width="39.95" height="25.2592" fill="white" />
      </clipPath>
      <clipPath id="clip1_12454_12634">
        <rect width="6" height="6" fill="white" transform="translate(16.9502 7.30859)" />
      </clipPath>
    </defs>
  </svg>
);

export default Remote;
