import React from 'react';
import AutoComplete from 'material-ui/AutoComplete';
import Spinner from 'react-spinkit';

// Styles
import './styles/AutoCompleteStyles.css';

const Autocomplete = props => {
  return (
    <div className={`autoCompleteComponent ${props.className || ''}`}>
      <AutoComplete
        name={props.name}
        placeholder={props.placeholder}
        floatingLabelText={props.text}
        dataSource={props.dataSource}
        searchText={props.value}
        dataSourceConfig={props.dataSourceConfig}
        filter={AutoComplete.caseInsensitiveFilter}
        onUpdateInput={props.onUpdateInput}
        onNewRequest={props.onItemSelected}
        fullWidth={props.fullWidth || false}
        style={
          props.style || {
            border: '1px solid #E3E5E5',
            borderRadius: 25,
            padding: '0px 15px',
          }
        }
        textFieldStyle={{
          fontSize: 13,
        }}
        listStyle={{
          width: 356,
        }}
        menuStyle={{
          width: 356,
        }}
        hintText={props.hintText || ''}
        openOnFocus={props.openOnFocus}
        maxSearchResults={props.maxSearchResults}
        underlineStyle={{ display: 'none' }}
        errorStyle={{ bottom: 13, fontSize: 11 }}
        onClick={props.onClick && props.onClick}
      />
      {props.loading && <Spinner fadeIn="none" name="circle" className={`loadingComponent ${props.loading ? '' : 'invisible'}`} />}
      {props.helpText && <h4 className="helpText">{props.helpText}</h4>}
    </div>
  );
};

export default Autocomplete;
