import React, { Component } from 'react';
import Button from '../../Button/view';

// Styles
import './styles/contentBlockStyles.css';

class ContentBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // openMenu: false
        };
    }

    render() {
        return (
            <div className={`contentBlockContainer ${this.props.className ? this.props.className : ''}`}>
                {this.props.icon && this.props.icon}
                {this.props.title &&
                    <h1 className={`title ${this.props.titleClass ? this.props.titleClass : ''}`}>{this.props.title}</h1>
                }
                {this.props.subtitle &&
                    <h2 className={`subtitle ${this.props.subtitleClass ? this.props.subtitleClass : ''}`}>{this.props.subtitle}</h2>
                }
                <p className="content">{this.props.content}</p>
                {this.props.btnLabel &&
                    <Button
                        label={this.props.btnLabel}
                        labelColor={this.props.btnLabelColor ? this.props.btnLabelColor : '#fff'}
                        bgColor={this.props.btnBgColor ? this.props.btnBgColor : '#2723EB'}
                        onClick={() => this.props.onBtnClick()}
                        className={this.props.btnClass ? this.props.btnClass : ''}
                    />
                }
            </div>
        );
    }
}

export default ContentBlock;
