import { IconButton } from 'components/common/IconButton/view';
import React from 'react';
import DownloadIcon from 'assets/svg/download.svg';
import ExperienceEmptyIcon from 'assets/svg/experience-empty.svg';
import { FlatButton } from 'components/common/FlatButton/view';
import { ProfileTask } from '../ProfileTask/view';

export const VolunteerExperience = ({ userId, userName, viewerId, taskReviews }) => {
  if (taskReviews.length < 1) {
    return (
      <>
        <div className="emptyContainer">
          <div className="flexRow flexCenter mbottom24 selfStart">
            <h2 className="bold m0 mRightAuto">Experience</h2>
          </div>
          <img src={ExperienceEmptyIcon} alt="Experience Empty Icon" />
          {userId === viewerId ? (
            <>
              <h3 className="fontPoppins">Your volunteer experience</h3>
              <div className="textCenter mbottom16">
                Each time you volunteer a charity will leave you a record of your experience. This will
                <br />
                include a log of hours and some feedback. This is how you build your profile as a<br />
                volunteer.
              </div>
              <FlatButton label="Browse Tasks" href="/task/search" />
            </>
          ) : (
            <>
              <h3 className="fontPoppins">Nothing here yet!</h3>
              <div className="textCenter mbottom16">{userName} is just getting started.</div>
            </>
          )}
        </div>
      </>
    );
  }

  const charityTasks = taskReviews.reduce(
    (acc, curr) => {
      if (!curr.Charity) {
        acc[''].push(curr);
      } else if (acc[curr.Charity.name]) {
        acc[curr.Charity.name].push(curr);
      } else {
        acc[curr.Charity.name] = [curr];
      }
      return acc;
    },
    { '': [] },
  );

  return (
    <div className="experienceContainer">
      <div className="flexRow flexCenter mbottom24">
        <h2 className="bold m0 mRightAuto">Experience</h2>
        <IconButton icon={DownloadIcon} />
      </div>
      <div className="exp">
        {Object.keys(charityTasks).map(
          charity =>
            charity && (
              <div key={charity}>
                <div className="networkLabel">
                  <img src={charityTasks[charity][0].Charity.picture} alt="Icon" />
                  <p>{charity}</p>
                </div>
                {charityTasks[charity].map(task => (
                  <ProfileTask task={task} />
                ))}
              </div>
            ),
        )}
      </div>
    </div>
  );
};
