import React, { useState } from 'react';
import { Modal, Button } from '../common';
import './styles/component.scss';

function ModalRemoveUser(props) {
  const { open, onClose, onRemove, forWho, userName } = props;

  return (
    <Modal onClose={onClose} className="confirmModal" open={open}>
      <div className="removeFormContainer equalPadding textCenter">
        <h1 className="formHeader">Remove {forWho === 1 ? 'team member' : 'ambassador'}</h1>
        <div className="formText">
          {forWho !== 1 ? (
            <>
              Are you sure you want to remove <span>{userName}</span> as an ambassador?
            </>
          ) : (
            <>
              Are you sure you want to remove <span>{userName}</span> from your team? <br />
              They will not be able to create and manage tasks for your charity.
            </>
          )}
        </div>
        <Button
          label="Remove"
          className="removeBtn"
          onClick={() => {
            onRemove();
            onClose();
          }}
        />
        <div className="formText">
          No <a>keep team member</a>
        </div>
      </div>
    </Modal>
  );
}

export default ModalRemoveUser;
