import _ from 'lodash';
import { hasTaskStarted } from './task';

export const TASK_SUBMISSION_STATUS = {
  APPLIED: 1,
  INVITED: 2,
  ASSIGNED: 3,
  IN_PROGRESS: 4,
  COMPLETED: 5,
  REVIEWED: 6,
};

export const getAppliedSubmission = (taskSubmissions = []) => {
  return taskSubmissions.filter(taskSubmission => taskSubmission.status === TASK_SUBMISSION_STATUS.APPLIED);
};

export const getAssignedSubmission = (taskSubmissions = []) => {
  return taskSubmissions.filter(taskSubmission => taskSubmission.status > 2);
};

export const getCompletedSubmission = (taskSubmissions = []) => {
  return taskSubmissions.filter(taskSubmission => taskSubmission.status === TASK_SUBMISSION_STATUS.COMPLETED);
};

export const getTaskSubmissionStatus = taskSubmission => {
  switch (_.get(taskSubmission, 'status')) {
    case TASK_SUBMISSION_STATUS.APPLIED:
      return 'Unassigned';
    case TASK_SUBMISSION_STATUS.INVITED:
      return 'Invited | Unsasigned';
    case TASK_SUBMISSION_STATUS.ASSIGNED:
      if (hasTaskStarted(_.get(taskSubmission, 'Task'))) return 'In Progress';
      return 'Upcoming';
    case TASK_SUBMISSION_STATUS.IN_PROGRESS:
      return 'In Progress';
    case TASK_SUBMISSION_STATUS.COMPLETED:
      return 'Completed';
    default:
      return '';
  }
};
