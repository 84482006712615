import React from 'react';
import styled from 'styled-components';
import StepIndicator from '../../../common/StepIndicator';

const TaskLayoutContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
`;

const TaskContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 20px;

  @media (min-width: 767px) {
    padding: 40px 50px;
  }

  @media (min-width: 1169px) {
    padding: 40px 100px;
  }
`;

export const Steps = [{
  name: 'overview',
  label: 'Overview'
}, {
  name: 'details',
  label: 'Details',
}, {
  name: 'skills',
  label: 'Add skills',
}, {
  name: 'volunteers',
  label: 'Find volunteers',
}, {
  name: 'publish',
  label: 'Publish'
}];

const Layout = ({ step, children }) => (
  <TaskLayoutContainer>
    {/* <Sidebar /> */}
    <TaskContentContainer>
      {step !== 'finish' && <StepIndicator steps={Steps} currentActive={step} showIndex />}
      {children}
    </TaskContentContainer>
  </TaskLayoutContainer>
);

export default Layout;
