import _ from 'lodash';
import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Popover, Menu } from '../common';
import { IconButton } from '../common/IconButton/view';
import { messageUtils } from '../../lib/message';
import { localStorage } from '../../lib/storage';

// mutation
import deleteGroupMessageMutation from '../../mutations/group_messages/deleteGroupMessage';

// Query
import fetchGroupMessagesByUser from '../../queries/group_messages/fetchGroupMessagesByUser';

function GroupChatItem({ item, itemSelected, isPined, dragHandleProps, commonProps }) {
  const [openAnchorEl, setOpenAnchorEl] = useState();
  const usersNumber = _.size(_.get(item, 'Users'));
  const groupId = _.get(item, '_id');
  const userId = localStorage.get('userID');
  const { pinGroupChatItem, unpinGroupChatItem, groupIdFromUrl } = commonProps;
  const isSelected = groupIdFromUrl === groupId;

  const getUnreadMessages = messages => {
    return _.filter(messages, message => !messageUtils.hasSeenMessage(message.seen, userId));
  };
  const handleMouseDown = event => {
    if (!isPined) {
      const { onMouseDown } = dragHandleProps;
      document.body.style.overflow = 'hidden';
      onMouseDown(event);
    }
  };
  const handleMouseUp = event => {
    if (!isPined) {
      document.body.style.overflow = 'visible';
    }
  };
  const handleClickMenu = event => {
    isPined ? unpinGroupChatItem(item) : setOpenAnchorEl(event.currentTarget);
  };

  const unreadMessagesNumber = useMemo(() => getUnreadMessages(item.Messages), [item.Messages]);

  const [deleteGroupMessage] = useMutation(deleteGroupMessageMutation);

  return (
    <div className={`item ${isSelected ? 'selected' : ''}`} onMouseDown={e => handleMouseDown(e)} onMouseUp={e => handleMouseUp(e)}>
      {!isPined && (
        <Popover open={!!openAnchorEl} anchorEl={openAnchorEl} handleRequestClose={() => setOpenAnchorEl(undefined)}>
          <Menu
            className="groupChatRowMenu"
            values={[
              {
                label: 'Pin to top',
                action: () => {
                  setOpenAnchorEl(undefined);
                  pinGroupChatItem(item);
                },
              },
              {
                label: 'Archive',
                action: async () => {
                  setOpenAnchorEl(undefined);
                  await deleteGroupMessage({
                    variables: {
                      id: groupId,
                    },
                    refetchQueries: {
                      query: fetchGroupMessagesByUser,
                      variables: {
                        _user_id: userId,
                      },
                    },
                  });
                },
              },
            ]}
          />
        </Popover>
      )}
      <div className="infoView">
        <div className={`profilePic ${usersNumber > 2 ? 'multi' : ''}`}>
          <img src={_.get(item, 'Users.0.picture')} alt="Profile Avatar" />
          {usersNumber > 2 && <span>{usersNumber - 1}</span>}
        </div>
        <Link className={`${itemSelected ? 'selected' : ''}`} to={`/dashboard/inbox/group/${groupId}`}>
          {_.get(item, 'name')}
        </Link>
        {unreadMessagesNumber > 0 && (
          <div className="unreadMessageBadge">
            <span>{unreadMessagesNumber > 9 ? '9+' : unreadMessagesNumber}</span>
          </div>
        )}
      </div>
      <div className={`menu ${isPined ? 'pined' : ''}`} onClick={e => handleClickMenu(e)}>
        {isPined ? <IconButton className="pinIcon" icon="/icons/pin.svg" /> : <IconButton className="menuIcon" icon="/icons/3dots.svg" />}
      </div>
    </div>
  );
}

export default GroupChatItem;
