import React, { useState } from 'react';
import _ from 'lodash';
import { Menu, Popover } from 'components/common';
import { IconButton } from 'components/common/IconButton/view';
import { getTimeEntry, getTotalTime } from 'lib/task_timesheets';
import CheckCircleIcon from 'assets/svg/check-circle.svg';
import UnCheckCircleIcon from 'assets/svg/uncheck-circle.svg';
import DotsIcon from 'assets/svg/dots.svg';

export const TimeSheet = ({ key, timesheet, isCharityManager, handleApproveTimesheet, handleDeleteTimesheet, handleEditTimesheetRequest }) => {
  const [openAnchorEl, setOpenAnchorEl] = useState();

  const handleOpenPopover = event => {
    event.preventDefault();
    setOpenAnchorEl(event.currentTarget);
  };

  return (
    <div className="flex row" key={key}>
      <div className="col-xs-4">
        <h3 className="fontPoppins normal textBlack content">{timesheet.name}</h3>
      </div>
      <div className="col-xs-4">
        <h3 className="fontPoppins normal textBlack content">{getTimeEntry(timesheet.start, timesheet.end)}</h3>
      </div>
      <div className="col-xs-2">
        <h3 className="fontPoppins normal textBlack content">{getTotalTime(timesheet.start, timesheet.end)}</h3>
      </div>
      <div className="col-xs-2">
        <div className="alignCenter button_layout mtop8 mbottom8">
          {timesheet.status === 1 ? (
            <div className={`alignCenter ${isCharityManager && 'forceCursor'}`} onClick={() => isCharityManager && handleApproveTimesheet(timesheet._id, timesheet._task_submission_id)}>
              <img alt="UnCheckCircleIcon" src={UnCheckCircleIcon} />
            </div>
          ) : (
            <div className="alignCenter">
              <img alt="CheckCircleIcon" src={CheckCircleIcon} />
            </div>
          )}
          <IconButton icon={DotsIcon} onClick={handleOpenPopover} />
          <Popover open={!!openAnchorEl} anchorEl={openAnchorEl} handleRequestClose={() => setOpenAnchorEl(undefined)} className="userPopover">
            <Menu
              values={
                isCharityManager && timesheet.status === 1
                  ? [
                      {
                        label: 'Approve',
                        action: () => {
                          setOpenAnchorEl(undefined);
                          handleApproveTimesheet(timesheet._id, timesheet._task_submission_id);
                        },
                      },
                      {
                        label: 'Edit',
                        action: () => {
                          setOpenAnchorEl(undefined);
                          handleEditTimesheetRequest(timesheet);
                        },
                      },
                      {
                        label: 'Delete',
                        action: () => {
                          setOpenAnchorEl(undefined);
                          handleDeleteTimesheet(timesheet._id);
                        },
                      },
                    ]
                  : [
                      {
                        label: 'Edit',
                        action: () => {
                          setOpenAnchorEl(undefined);
                          handleEditTimesheetRequest(timesheet);
                        },
                      },
                      {
                        label: 'Delete',
                        action: () => {
                          setOpenAnchorEl(undefined);
                          handleDeleteTimesheet(timesheet._id);
                        },
                      },
                    ]
              }
              className="mainMenuUserContent"
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};
