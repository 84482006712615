import React, { useState } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router';
import { ModalBase, Button, LoadingOverlay, Input } from '../common';
import { localStorage } from '../../lib/storage';

// query
import fetchForumsByCharityId from '../../queries/forum/fetchForumsByCharityId';

// mutation
import createForumMutation from '../../mutations/forum/createForum';

// scss
import './styles/inboxModal.scss';

function NewForumModal({ onClose, charityId }) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [createForum] = useMutation(createForumMutation);

  const onCreateNewForum = async () => {
    try {
      const createdForumData = await createForum({
        variables: {
          forum: {
            name,
            charity_id: charityId,
          },
        },
        refetchQueries: [
          {
            query: fetchForumsByCharityId,
            variables: {
              charity_id: charityId,
            },
            fetchPolicy: 'network-only',
          },
        ],
      });
      const forumId = _.get(createdForumData, 'data.createForum._id');
      const channelOrders = JSON.parse(localStorage.get('_wc_channelOrders'));
      localStorage.save('_wc_channelOrders', JSON.stringify([forumId, ...channelOrders]));
      setLoading(false);
      onClose();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <ModalBase className="newForumModal" onClose={onClose}>
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <h3 className="mtop0 mbottom24 fontPoppins textBlack">Create New Forum </h3>
      <Input name="forumName" placeholder="New Forum Name" value={name} onChange={value => setName(value)} />
      <Button label="Save" onClick={onCreateNewForum} disabled={!name} />
    </ModalBase>
  );
}

export default withRouter(NewForumModal);
