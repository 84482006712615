import gql from 'graphql-tag';

export default gql`
    query FetchLocationsBySuburbOrPostcode($location: String!, $state: String) {
          getLocationBySuburbOrPostcode(location: $location, state: $state) {
              latitude
              longitude
              location
              state
              postcode
          }
     }
`;
