import gql from 'graphql-tag';

export default gql`
  mutation createTaskTimeSheet($taskTimeSheet: TaskTimesheetInput!) {
    createTaskTimesheet(task_timesheet: $taskTimeSheet) {
      _id
      _task_submission_id
      name
      start
      end
      status
    }
  }
`;
