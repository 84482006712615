import React, { useState, useEffect } from 'react';

import { SectionHeading } from '../../common/Elements';
import { TaskDescription, TaskLocation, TaskName } from './detailsPages';

const DetailsStep = ({ history, formData, setFormData, setSidebarUpdated, setCurrentTaskDetailStep }) => {
  const [currentStep, setCurrentStep] = useState('taskName');

  useEffect(() => {
    setCurrentTaskDetailStep(currentStep);
  }, [currentStep]);

  const steps = [
    {
      name: 'taskName',
      heading: 'Position title',
      content: <TaskName setFormData={setFormData} setStep={setCurrentStep} history={history} />,
    },
    {
      name: 'taskLocation',
      heading: 'Where is the location of this task?',
      content: <TaskLocation setFormData={setFormData} setStep={setCurrentStep} />,
    },
    {
      name: 'taskDescription',
      heading: 'Position description',
      content: <TaskDescription formData={formData} setStep={setCurrentStep} setFormData={setFormData} history={history} />,
    },
  ];

  const activeStep = steps.find(step => step.name === currentStep);

  if (currentStep === 'taskDescription') {
    setSidebarUpdated(true);
  } else {
    setSidebarUpdated(false);
  }

  return (
    <>
      <SectionHeading>{activeStep.heading}</SectionHeading>

      {activeStep.content}
    </>
  );
};

export default DetailsStep;
