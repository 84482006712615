import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import {
    Button,
    Input,
    LoadingOverlay,
    GoogleInput,
    DatePicker,
    TimePicker
} from '../../common';
import { location } from '../../../lib/location';
import { localStorage } from '../../../lib/storage';
import { utils } from '../../../lib/utils';

// Queries
import fetchEventById from '../../../queries/event/fetchEventById';

// Mutations
import createEventMutation from '../../../mutations/event/createEvent';
import updateEventMutation from '../../../mutations/event/updateEvent';

class CreateEventDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event: {
                charity_id: '',
                name: '',
                location: {},
                status: 0,
                config: {
                    frequency: 1,
                    start: '',
                    end: '',
                    timezone: ''
                }
            },
            locationText: '',
            startDate: '',
            endDate: '',
            startHour: '',
            endHour: '',
            loading: false
        };

        this.onUpdateEventInput = this.onUpdateEventInput.bind(this);
        this.onUpdateEventConfigInput = this.onUpdateEventConfigInput.bind(this);
        this.onNextClicked = this.onNextClicked.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onChangeGoogleInput = this.onChangeGoogleInput.bind(this);
        this.buildHourTimestamp = this.buildHourTimestamp.bind(this);
        this.getEndDate = this.getEndDate.bind(this);
        this.getMinStartHour = this.getMinStartHour.bind(this);
        this.getMinEndHour = this.getMinEndHour.bind(this);
    }

    componentWillMount() {
        const timeFormat = 'DD/MM/YYYY hh:00a';
        const timeHourNow = moment().format(timeFormat);
        const convertedTime = moment(timeHourNow, timeFormat).valueOf();
        const startDate = utils.addHoursToTimestamp(convertedTime, 5);
        const endDate = utils.addHoursToTimestamp(convertedTime, 6);
        const startHour = utils.extractHoursFromTimestamp(startDate, true);
        const endHour = utils.extractHoursFromTimestamp(endDate, true);

        this.setState({
            event: {
                ...this.state.event,
                charity_id: _.get(this.props.location, 'state.charity_id'),
                created_by: localStorage.get('userID'),
                config: {
                    ...this.state.event.config,
                    start: startDate,
                    end: endDate
                }
            },
            startDate,
            endDate,
            startHour,
            endHour
        });
    }

    componentWillReceiveProps(newProps) {
        const { eventByID } = newProps.eventData;
        if (_.size(eventByID) > 0) {
            this.setState({
                event: {
                    ...this.state.event,
                    charity_id: eventByID.charity._id,
                    name: eventByID.name,
                    location: {
                        ...this.state.event.location,
                        location: eventByID.location.location,
                        street: eventByID.location.street,
                        city: eventByID.location.city,
                        postcode: eventByID.location.postcode,
                        state: eventByID.location.state,
                        country: eventByID.location.country,
                        latitude: eventByID.location.latitude,
                        longitude: eventByID.location.longitude,
                    },
                    config: {
                        ...this.state.event.config,
                        frequency: eventByID.config.frequency,
                        start: eventByID.config.start,
                        end: eventByID.config.end
                    }
                },
                locationText: eventByID.location.location,
                startDate: eventByID.config.start,
                endDate: eventByID.config.end,
                startHour: moment(eventByID.config.start).format('HH:mm'),
                endHour: moment(eventByID.config.end).format('HH:mm')
            });
        }
    }

    onChangeGoogleInput(value) {
        this.setState({ locationText: value });
    }

    onLocationSelected(value) {
        location.getTimezoneFromCoordinates(value.latitude, value.longitude)
            .then(({ data }) => {
                // If timezone is not found from the google api then it should try to get from ipData
                const timezone = data.timeZoneId || localStorage.get('timezone');
                this.setState(prevState => ({
                    event: {
                        ...prevState.event,
                        config: {
                            ...prevState.event.config,
                            timezone
                        }
                    },
                }));
            });

        this.setState(prevState => ({
            event: {
                ...prevState.event,
                location: {
                    ...this.state.event.location,
                    location: value.location,
                    street: value.street,
                    city: value.city,
                    postcode: value.postcode,
                    state: value.state,
                    country: value.country,
                    latitude: value.latitude,
                    longitude: value.longitude,
                }
            },
            locationText: value.location
        }));
    }

    onUpdateEventInput(value, fieldName) {
        this.setState({
            event: {
                ...this.state.event,
                [fieldName]: value
            }
        });
    }

    onUpdateEventConfigInput(value, fieldName, callback) {
        this.setState({
            event: {
                ...this.state.event,
                config: {
                    ...this.state.event.config,
                    [fieldName]: value
                }
            }
        }, () => callback && callback());
    }

    onStartDateChanged(value) {
        // Once the start date is changed, then it should test if end date should be changed
        const start = moment(value).valueOf();
        const end = moment(this.getEndDate(start)).valueOf();
        this.setState({
            startDate: start,
            endDate: end
        }, () => {
            this.onUpdateEventConfigInput(this.buildHourTimestamp('start'), 'start', () => {
                this.onUpdateEventConfigInput(this.buildHourTimestamp('end'), 'end');
                this.changeEndTimeIfNeeded();
            });
        });
    }

    onStartTimeChanged(value) {
        this.setState({ startHour: value }, () => {
            this.onUpdateEventConfigInput(this.buildHourTimestamp('start'), 'start');
            this.changeEndTimeIfNeeded();
        });
    }

    onEndDateChanged(value) {
        this.setState({ endDate: moment(value).valueOf() }, () => {
            this.onUpdateEventConfigInput(this.buildHourTimestamp('end'), 'end');
            this.changeEndTimeIfNeeded();
        });
    }

    onEndTimeChanged(value) {
        this.setState({ endHour: value }, () => this.onUpdateEventConfigInput(this.buildHourTimestamp('end'), 'end'));
    }

    onNextClicked() {
        this.setState({
            loading: true
        });

        // It should only create the charity if there's no charity onto the localStorage
        // If there is a charity into the local storage it should then update the current charity
        if (_.has(this.props.location, 'state.eventId')) {
            const objectToUpdate = this.state.event;
            delete objectToUpdate.charity_id;
            delete objectToUpdate.status;
            // Update event
            this.props.updateEvent(this.props.location.state.eventId, objectToUpdate)
                .then(() => {
                    this.setState({
                        loading: false
                    });
                    this.props.history.push({ pathname: '/event/register/volunteers', state: { ...this.props.location.state } });
                });
        } else {
            // Create charity
            this.props.createEvent(this.state.event)
                .then(({ data }) => {
                    this.setState({
                        loading: false
                    });
                    this.props.history.push({ pathname: '/event/register/volunteers', state: { eventId: data.createEvent._id } });
                });
        }
    }

    getEndDate(updatedStartDate) {
        const { endDate, startDate } = this.state;
        const start = updatedStartDate || startDate;
        if (endDate > start) return moment(endDate).toDate();
        return moment(start).toDate();
    }

    getMinStartHour() {
        // Checks whether the day is different than today
        //  Yes -> It should not validate a min hour
        //   No -> It should validate hour
        if (moment(this.state.startDate).isSame(moment(), 'day')) {
            return this.state.startHour;
        }
        return '00:00';
    }

    getMinEndHour() {
        // If the start date is the same as the end date then it should validate what's the min hour to be set
        // That is to prevent an user to set it up and event with a start date bigger than an end date
        const startDate = moment(this.state.startDate).format('DD/MM/YYYY');
        const endDate = moment(this.getEndDate()).format('DD/MM/YYYY');
        if (startDate !== endDate) return '00:00';
        return utils.addTimeToTimeString(this.state.startHour, 30, 'm');
    }

    changeEndTimeIfNeeded() {
        // Function to test whether or not the end hour is valid
        // If it's not then it should change it
        // Rule 1 -> It should only change when the start date and end date are the same
        if (moment(this.state.startDate).format('DD/MM/YYYY') === moment(this.state.endDate).format('DD/MM/YYYY')) {
            // Rule 2 -> Check whether or not the end hour is before the start hour
            if (this.state.startHour >= this.state.endHour) {
                // Change the end hour to be 1 hour ahead of the start hour
                const newEndHour = utils.addTimeToTimeString(this.state.startHour, 1, 'h');
                this.setState({ endHour: newEndHour });
            }
        }
    }

    buildHourTimestamp(target) {
        if (target === 'start') {
            // Gets the temp variables on state and transform them into a valid moment timestamp to store taking into account date and hour
            const startDate = moment(this.state.startDate).format('DD/MM/YYYY');
            return moment(`${startDate} ${this.state.startHour}`, 'DD/MM/YYYY hh:mma').valueOf();
        }
        // Gets the temp variables on state and transform them into a valid moment timestamp to store taking into account date and hour
        const endDate = moment(this.state.endDate).format('DD/MM/YYYY');
        return moment(`${endDate} ${this.state.endHour}`, 'DD/MM/YYYY hh:mma').valueOf();
    }

    renderHelperArea(title, countState, maxCount) {
        return (
            <div className="helper">
                <h4>{title}</h4>
                <h4 className="count">{`${countState}/${maxCount}`}</h4>
            </div>
        );
    }

    render() {
        if (!_.get(this.props.location, 'state.charity_id') && !_.get(this.props.location, 'state.eventId')) {
            // If there's not Charity id to assign to it should redirect to the homepage
            this.props.history.replace({ pathname: '/' });
        }
        return (
            <div className="childContainer detailsContainer">
                {this.state.loading &&
                    <LoadingOverlay
                        style={{ opacity: 0.8 }}
                    />
                }
                <div className="nameContainer col-xs-12">
                    <Input
                        name="name"
                        placeholder="Event name"
                        type="text"
                        helperArea={() => this.renderHelperArea('A short and clear title for your event', this.state.event.name.length, 34)}
                        value={this.state.event.name}
                        onChange={(value) => {
                            // Only allow 34 charachters
                            if (value.length <= 34) this.onUpdateEventInput(value, 'name');
                        }}
                    />
                    <GoogleInput
                        onNewPlace={this.onLocationSelected}
                        placeholder="Location"
                        value={this.state.locationText}
                        onValueChange={this.onChangeGoogleInput}
                    />
                    <div className="datesContainer col-xs-12 p0">
                        <div className="fromContainer col-xs-12 col-sm-6 pleft0">
                            <h5>From</h5>
                            <div className="fromContainerContent">
                                <DatePicker
                                    id="startDatePicker"
                                    value={moment(this.state.startDate).toDate()}
                                    format="ddd DD MMM"
                                    disableYearSelection
                                    autoOk
                                    className="inline"
                                    textFieldStyle={{ width: '100%', float: 'left' }}
                                    minDate={moment().toDate()}
                                    onChange={(e, value) => this.onStartDateChanged(value)}
                                />
                                <h5 className="col-xs-1">AT</h5>
                                <TimePicker
                                    value={this.state.startHour}
                                    className="inline col-xs-5"
                                    underlineStyle={{ width: '80%' }}
                                    minHour={this.getMinStartHour()}
                                    onChange={value => this.onStartTimeChanged(value)}
                                />
                            </div>
                        </div>
                        <div className="toContainer col-xs-12 col-sm-6 pright0">
                            <h5>To</h5>
                            <div className="toContainerContent">
                                <DatePicker
                                    id="endDatePicker"
                                    value={this.getEndDate()}
                                    format="ddd DD MMM"
                                    disableYearSelection
                                    autoOk
                                    textFieldStyle={{ width: '100%', float: 'left' }}
                                    className="inline"
                                    minDate={moment(this.state.startDate).toDate()}
                                    onChange={(e, value) => this.onEndDateChanged(value)}
                                />
                                <h5 className="col-xs-1">AT</h5>
                                <TimePicker
                                    value={this.state.endHour}
                                    className="inline col-xs-5"
                                    underlineStyle={{ width: '80%' }}
                                    minHour={this.getMinEndHour()}
                                    onChange={value => this.onEndTimeChanged(value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buttonsContainer col-xs-12 p0">
                    <Button
                        label="Next"
                        onClick={this.onNextClicked}
                        className="btnDefault btnBlueSolid mtop60"
                        disabled={!(this.state.event.name &&
                                    this.state.event.location.location &&
                                    this.state.event.config.start &&
                                    this.state.event.config.end &&
                                    this.state.event.config.end)}
                    />
                </div>
            </div>
        );
    }
}

const eventByIDContainer = graphql(fetchEventById, {
    name: 'eventData',
    options: (props) => {
        return {
            variables: { id: _.get(props.location, 'state.eventId') },
        };
    },
});

const createEventMutationContainer = graphql(createEventMutation, {
    props: ({ mutate }) => ({
        createEvent: event => mutate({
            variables: { event }
        }),
    }),
});

const updateEventMutationContainer = graphql(updateEventMutation, {
    props: ({ mutate }) => ({
        updateEvent: (id, event) => mutate({
            variables: { id, event }
        }),
    }),
});

export default withRouter(_.flowRight(
    createEventMutationContainer,
    eventByIDContainer,
    updateEventMutationContainer
)(CreateEventDetails));
