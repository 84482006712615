import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import fetchCharitiesByUserId from 'queries/charity/fetchCharitiesByUserId';
import { localStorage } from 'lib/storage';

import { Button, RadioButtonCards, LoadingOverlay } from '../../../common';
import { SectionHeading, ButtonContainer } from '../../common/Elements';
import OngoingTaskIcon from './assets/ongoingTask';
import SingleTaskIcon from './assets/singleTask';

const cards = [{
  heading: 'Task',
  content: 'You are looking for a volunteer to perform one or various tasks within an agreed upon timeframe.',
  icon: <SingleTaskIcon />,
  id: 'singleRadio',
  value: 'single',
}, {
  heading: 'Ongoing',
  content: 'You are looking for a long-term volunteer and you are offering an ongoing opportunity.',
  icon: <OngoingTaskIcon />,
  id: 'ongoingRadio',
  value: 'ongoing',
}];

const OverviewStep = ({ setFormData, history }) => {
  const userId = localStorage.get('userID');
  const [type, setType] = useState('single');
  const [charityId, setCharityId] = useState();

  const { loading, data } = useQuery(fetchCharitiesByUserId, {
    fetchPolicy: 'network-only',
    variables: {
      userId
    }
  });

  if (loading) {
    return <LoadingOverlay style={{ opacity: 0.8 }} />;
  }

  if (data && !charityId) {
    setCharityId(_.get(data.charityByUserId[0], '_id'));
  }

  return (
    <>
      <SectionHeading>Please choose a type </SectionHeading>
      <RadioButtonCards
        name="opportunityType"
        cards={cards}
        active="single"
        onChange={selectedOption => {
          setType(selectedOption);
        }}
      />

      <ButtonContainer largeMargin>
        <Button
          label="Continue"
          onClick={() => {
            setFormData(prevState => ({
              ...prevState,
              type,
              charityId,
            }));
            history.push({ pathname: '/task/create/details' });
          }}
        />
      </ButtonContainer>
    </>
    );
};

export default OverviewStep;
