import styled from 'styled-components';

export const SectionHeading = styled.h3`
  font-size: 24px;
  font-weight: bolder;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;

  @media and (min-width: 767px) {
    text-align: left;
    margin-bottom: auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.largeMargin ? '50px' : '10px'};

  > .btn_primary:first-child {
    margin-right: 10px;
  }

  @media and (min-width: 767px) {
    justify-content: flex-end;
    margin-top: ${props => props.largeMargin ? '200px' : '50px'};
  }
`;

export const InlineContent = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomSelectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 48,
    fontSize: 13,
    borderRadius: 8,
    borderColor: '#E3E5E5',
    paddingLeft: 5,
    maxWidth: 390,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export const CustomTextFieldStyle = {
  maxWidth: '120px',
};

export const SectionContent = styled.p`
  font-size: 15px;
`;
