import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import Drawer from 'material-ui/Drawer';
import Linkify from 'react-linkify';
import { Query } from '@apollo/client/react/components';
import Input from '../../Input';
import Button from '../../Button/view';
import LoadingOverlay from '../../LoadingOverlay/view';
import { localStorage } from '../../../../lib/storage';
import { utils } from '../../../../lib/utils';

// Queries
import searchMessages from '../../../../queries/messages/searchMessages';
import fetchUserById from '../../../../queries/user/fetchUserById';

// Mutations
import createMessageMutation from '../../../../mutations/messages/createMessage';

// Styles
import './styles/messageComponentStyles.scss';

function MessageDrawer(props) {
  const [messageToSend, setMessageToSend] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [loadingUI, setLoadingUI] = useState(false);

  // User sending message
  const userByIdResults = useQuery(fetchUserById, {
    variables: {
      id: localStorage.get('userID'),
    },
  });
  const userByIdData = _.get(userByIdResults, 'data.userByID');

  // Mutations
  const [createMessage] = useMutation(createMessageMutation);

  useEffect(() => {
    setTimeout(() => {
      setIsScrolled(false);
      scrollToBottom();
      focusOnMessageToSendComponent();
    }, 50);
  }, []);

  const focusOnMessageToSendComponent = () => {
    const messageToSendComponent = document.getElementById('messageToSend');
    if (messageToSendComponent) messageToSendComponent.focus();
  };

  const onClickSendMessage = async () => {
    setLoadingUI(true);
    const messageParams = {
      type: 1,
      direction: 1,
      body: messageToSend,
      sms: true,
      _from_user_id: userByIdData._id,
    };

    // Check whether the message is being sent to a group of people
    let isMessageToGroup = false;
    if (props._group_id) {
      isMessageToGroup = true;
      messageParams._group_id = props._group_id;
    }

    if (props._task_id) {
      messageParams._task_id = props._task_id;
    }

    // Define the message direction
    switch (_.get(userByIdData, 'type')) {
      case 1: // Volunteer
        if (isMessageToGroup) messageParams.direction = 7;
        messageParams.direction = 2;
        break;
      case 2: // Charity Manager
        if (isMessageToGroup) messageParams.direction = 8;
        messageParams.direction = 1;
        break;
      default:
        break;
    }

    if (props._to_user_id) {
      messageParams._to_user_id = props._to_user_id;
    }

    setMessageToSend('');
    await createMessage({
      variables: {
        message: messageParams,
      },
      refetchQueries: [
        {
          query: searchMessages,
          variables: {
            search: {
              _user_id: props._to_user_id,
            },
          },
        },
      ],
    });
    setLoadingUI(false);
    scrollToBottom();
  };

  const sortMessagesToDisplay = messages => {
    return utils.orderArrayByField(messages, 'created', 'asc');
  };

  const scrollToBottom = () => {
    const messageTextBoxComponent = document.getElementById('anchorPoint');
    if (messageTextBoxComponent) {
      setTimeout(() => {
        messageTextBoxComponent.scrollIntoView(true, { behavior: 'smooth', block: 'start', inline: 'start' });
        toggleIsScrolled();
      }, 100);
    }
  };

  const toggleIsScrolled = () => {
    setTimeout(() => setIsScrolled(true), 100);
  };

  const componentDecorator = (href, text, key) => {
    return (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  const renderSystemMessage = message => {
    return (
      <div key={message._id} className="systemMessage">
        <img alt="Askable logo Icon" src="/askable-logo-icon.svg" />
        <div className="contentMessage">
          <p className="body">[System Message] {message.body}</p>
          <p className="date">{moment(message.created).format('h:mm A')}</p>
        </div>
      </div>
    );
  };

  const renderTextMessage = message => {
    // Check whether the user selected received or sent the message
    const isSentByUser = localStorage.get('userID') !== message._from_user_id;

    return (
      <div key={message._id} className={`messageBox ${isSentByUser ? 'received' : 'sent'}`}>
        <Query
          query={fetchUserById}
          variables={{
            id: _.get(message, '_from_user_id'),
          }}
        >
          {({ loading, data }) => {
            if (loading) return 'Loading...';
            return <p className="user">{_.get(data, 'userByID.meta.identity.firstname')}</p>;
          }}
        </Query>
        <p className="body">{message.body}</p>
        <div className="additionalInfo">
          <p className="time">{moment(message.created).format('h:mm A')}</p>
        </div>
      </div>
    );
  };

  const renderMessages = messages => {
    return _.map(messages, message => {
      // Check whether or not it is a System Message
      if (!props.hideAutomatedMessages && message.type === 2) return renderSystemMessage(message);

      // If it's not either a System Message or a Status Update then render the proper message
      return renderTextMessage(message);
    });
  };

  const renderHeaderContent = () => {
    // Check whether it's sending a message to a single user or to a group
    if (props._group_id) {
      return (
        <div className="nameContainer">
          <h2>Group abc</h2>
        </div>
      );
    }
    if (props._to_user_id) {
      return (
        <Query
          query={fetchUserById}
          variables={{
            id: props._to_user_id,
          }}
        >
          {({ loading, error, data }) => {
            if (loading || error) return null;
            const userEmail = _.get(data, 'userByID.email');
            const fullName = `${_.get(data, 'userByID.firstname')} ${_.get(data, 'userByID.lastname')}`;
            return (
              <>
                <div className="nameContainer">
                  <h2>{fullName}</h2>
                </div>
                <div className="contactDetailsContainer">
                  <a href={`mailto:${userEmail}`}>{userEmail}</a>
                </div>
              </>
            );
          }}
        </Query>
      );
    }
  };

  const renderMessagesContent = messagesToDisplay => {
    // Group messages by date
    const groupedMessagesByDate = utils.groupByDates(messagesToDisplay, 'created');

    return (
      <>
        <div className="messageHeader">{renderHeaderContent()}</div>
        <div className={`messagesContentContainer ${isScrolled ? 'isScrolled' : ''} ${props.messagesContentContainerClass}`}>
          {_.size(groupedMessagesByDate) > 0 && (
            <>
              {_.map(groupedMessagesByDate, date => {
                return (
                  <div className="dateContainer" key={date.day}>
                    <p className="dateTitle">{date.day}</p>
                    <Linkify componentDecorator={componentDecorator}>{renderMessages(date.group)}</Linkify>
                  </div>
                );
              })}
              <div id="anchorPoint">Last message</div>
            </>
          )}
        </div>
        <div id="messageTextBox" className="messageTextBox">
          <Input
            placeholder={props.hintMessage || 'Type a message'}
            value={messageToSend}
            underlineShow={false}
            multiLine
            rows={1}
            rowsMax={4}
            hasNoBorder
            style={{ lineHeight: '24px', marginBottom: 0 }}
            className="messageToSend"
            id="messageToSend"
            onChange={value => setMessageToSend(value)}
            onFocus={() => {
              if (props.onFocus) props.onFocus();
            }}
          />
          <div className="actionsContainer">
            <Button
              label="Send"
              onClick={event => {
                event.preventDefault();
                onClickSendMessage();
              }}
              className="sendMessageButton"
              buttonStyle={{ borderRadius: 3 }}
              smallButton
              disabled={_.size(messageToSend) <= 0 || loadingUI}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Query
      query={searchMessages}
      variables={{
        search: {
          _user_id: props._to_user_id,
        },
      }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        if (loading || userByIdResults.loading || error) {
          return (
            <Drawer containerClassName="messageDrawer" open={props.open} width={430} openSecondary>
              <LoadingOverlay style={{ opacity: 0.8 }} />
            </Drawer>
          );
        }

        const messagesToDisplay = sortMessagesToDisplay(_.get(data, 'searchMessages'));

        return (
          <Drawer
            containerClassName="messageDrawer"
            containerStyle={{ zIndex: 3000 }}
            open={props.open}
            width={430}
            openSecondary
            docked={false}
            onRequestChange={open => {
              if (!open) props.onClose();
            }}
          >
            {renderMessagesContent(messagesToDisplay)}
          </Drawer>
        );
      }}
    </Query>
  );
}

export default MessageDrawer;
