import _ from 'lodash';
import validator from 'validator';
import { localStorage } from './storage';

const charityUtils = {
  isCharityAdmin(charity, connectedUser = localStorage.get('userID')) {
    // Function to test if the user connect is admin on the charity or not
    if (_.get(charity, 'charity_users') && charity.charity_users.find(item => item.user_id === connectedUser)) return true;
    return false;
  },
  isCharityOwner(charity) {
    // Function to check whether the user is the owner of the charity.
    // It is considered to be the owner of the charity an user that has created it
    const connectedUser = localStorage.get('userID');
    if (charity.user_id === connectedUser) return true;
    return false;
  },
  isValidEIN(ein) {
    const regex = new RegExp(/^\d{9}$/);
    return regex.test(`${ein}`);
  },
  normaliseCausesArray(causes) {
    let causesArray = [];
    if (_.size(causes) > 0) {
      causesArray = causes.map(cause => {
        return {
          _id: cause._id,
          label: cause.name,
        };
      });
    }
    return causesArray;
  },
};

export { charityUtils };
