import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { Button } from '../common';
import PurpleCheck from './assets/purple-small-check.svg';

// Queries
import searchCharities from '../../queries/charity/searchCharities';

function OnboardingNetworks(props) {
  const causes = _.get(props, 'location.state.data.causes');
  const [selectedCharities, setSelectedCharities] = useState([]);

  const charitiesResults = useQuery(searchCharities, {
    variables: {
      searchCauses: causes,
      status: 1, // Active charities
    },
  });
  const charitiesData = _.get(charitiesResults, 'data.searchCharities');

  const handleSelectCharity = charity => {
    const newSelectedCharities = [...selectedCharities];
    if (newSelectedCharities.includes(charity._id)) {
      _.pull(newSelectedCharities, charity._id);
    } else {
      newSelectedCharities.push(charity._id);
    }
    setSelectedCharities(newSelectedCharities);
  };

  const handleSubmit = () => {
    props.history.push({
      pathname: '/onboarding/finish',
      state: {
        data: {
          ...props.location.state.data,
          charities: selectedCharities,
        },
      },
    });
  };

  return (
    <div className="informationContainer noSidebar">
      <div className="informationContent">
        <h2 className="mbottom8">Do you want to connect with any of these charities?</h2>
        <p className="mbottom32">You can connect with other charities later using the Search Charities page.</p>
        <div className="charities">
          {charitiesData
            ? charitiesData.map(charity => (
                <button
                  type="button"
                  className={`charity ${selectedCharities.includes(charity._id) ? 'active' : ''}`}
                  key={charity._id}
                  onClick={() => handleSelectCharity(charity)}
                >
                  {selectedCharities.includes(charity._id) ? <img className="check" src={PurpleCheck} alt="Purple Check" /> : null}
                  {charity.picture ? <img className="picture" src={charity.picture} alt={charity.name} /> : <div className="logo" />}
                  <p>{charity.name}</p>
                </button>
              ))
            : null}
        </div>

        <div className="buttonContainer">
          <Button label="Back" borderedButton onClick={() => props.history.goBack()} />
          <Button
            label="Continue"
            type="button"
            labelStyle={{
              paddingLeft: 30,
              paddingRight: 30,
            }}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(OnboardingNetworks);
