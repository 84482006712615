import gql from 'graphql-tag';

export default gql`
  query FetchJoinCharityByRequestId($requestId: String!) {
    joinCharityByRequestId(request_id: $requestId) {
      _id
      request_id
      email
      InvitedBy {
        _id
        email
        firstname
        lastname
      }
      Charity {
        _id
        name
      }
      role
    }
  }
`;
