import React from 'react';
import DatePicker from 'material-ui/DatePicker';
import moment from 'moment';

// import './styles/DatePickerStyles.css';

const DatePickerField = (props) => {
    return (
        <DatePicker
            id={props.id}
            disabled={props.disabled}
            formatDate={date => moment(date).format(props.format || 'DD/MM/YYYY')}
            value={props.value}
            hintText={props.hintText}
            floatingLabelText={props.placeholder}
            onChange={props.onChange}
            container={props.container}
            className={props.className}
            disableYearSelection={props.disableYearSelection}
            hideCalendarDate={props.hideCalendarDate}
            autoOk={props.autoOk}
            minDate={props.minDate ? props.minDate : null}
            textFieldStyle={props.textFieldStyle ? props.textFieldStyle : null}
            underlineStyle={{ bottom: 0 }}
            errorStyle={{ bottom: 13, fontSize: 11 }}
        />
    );
};

export default DatePickerField;
