import gql from 'graphql-tag';

export default gql`
  query FetchTasks {
    tasks {
      _id
      _charity_id
      name
      description
      TaskSkills { 
        _id
        name
        skill_color
        skill_label_color
        Macro {
          _id
          name
          color
        }
      }
      Charity {
        name
        picture
      }
      frequency 
      oneoff_frequency_config {
        hours_per_volunteer
      }
      ongoing_frequency_config {
        hours_per_week
      }
    }
  }
`;
