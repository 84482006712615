import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { withRouter } from 'react-router';
import moment from 'moment';
import { utils } from '../../lib/utils';
import { localStorage } from '../../lib/storage';
import { LoadingOverlay, Table, Select, Button, Notification } from '../common';

// Queries
import fetchCharities from '../../queries/charity/fetchCharities';

// Mutations
import approveCharity from '../../mutations/charity/approveCharity';
import reproveCharity from '../../mutations/charity/reproveCharity';

// Styles
import './styles/adminStyles.css';

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      loading: false,
      sortBy: 'status',
      forceRender: false,
      notificationContent: '',
    };

    this.tableHeaders = [
      { label: 'Status', value: 'status' },
      { label: 'Charity Name', value: 'name' },
      { label: 'Registration', value: 'registration' },
      { label: 'Email', value: 'email' },
      { label: 'Website', value: 'website' },
      { label: 'Phone', value: 'phone' },
      { label: 'Created', value: 'created' },
    ];

    this.tableData = [];
    this.selectedCharity = '';

    this.parseBodyValues = this.parseBodyValues.bind(this);
    this.onApproveCharity = this.onApproveCharity.bind(this);
    this.onReproveCharity = this.onReproveCharity.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.charities.loading !== newProps.charities.loading || this.state.forceRender) {
      this.tableData = newProps.charities.charities;
      this.setState({ forceRender: false });
    }
  }

  onApproveCharity() {
    this.setState({ loading: true });

    this.props.approveCharity(this.selectedCharity._id).then(() => {
      this.setState({
        loading: false,
        forceRender: true,
        notificationContent: (
          <span>
            {this.selectedCharity.name} has been <strong>approved</strong>
          </span>
        ),
      });
    });
  }

  onReproveCharity() {
    this.setState({ loading: true });

    this.props.reproveCharity(this.selectedCharity._id).then(() => {
      this.setState({
        loading: false,
        forceRender: true,
        notificationContent: (
          <span>
            {this.selectedCharity.name} has been <strong>reproved</strong>
          </span>
        ),
      });
    });
  }

  getStatus(status) {
    switch (status) {
      case 0:
        return 'Innactive';
      case 1:
        return 'Active';
      case 2:
        return 'In Review';
      default:
        return 'Innactive';
    }
  }

  parseBodyValues(charities = []) {
    // Function to parse the values coming from the databse into a renderable array of objects to get passed to the table common component
    return charities.map(charity => {
      return {
        content: [
          { title: this.getStatus(charity.status) },
          { title: charity.name },
          { title: charity.registration },
          { title: charity.email },
          { title: charity.website },
          { title: charity.phone },
          { title: moment(charity.created).format('DD/MM/YYYY HH:mm') },
        ],
      };
    });
  }

  render() {
    if (!localStorage.get('userID')) this.props.history.replace({ pathname: '/' });
    if (this.props.charities.loading) return <LoadingOverlay />;

    const orderedArray = utils.orderArrayByField(this.tableData, this.state.sortBy);

    return (
      <div className="adminContainer col-xs-12 p0">
        {this.state.error && (
          <div className="errorMessage">
            <p className="error">{this.state.error}</p>
          </div>
        )}
        {this.state.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
        <div className="adminHeaderContainer">
          <h1>Charities</h1>
          <Select value={this.state.sortBy} onChange={value => this.setState({ sortBy: value })} floatingLabelText="Sort By" className="sortBySelect" values={this.tableHeaders} />
        </div>
        <Table
          headerValues={this.tableHeaders.map(header => {
            return { title: header.label };
          })}
          bodyDisplayRowCheckbox
          bodyValues={this.parseBodyValues(orderedArray)}
          handleRowSelection={index => {
            this.selectedCharity = orderedArray[index];
          }}
          containerClassNameStructure={['narrow', 'medium', 'narrow', 'narrow', 'narrow', 'narrow', 'narrow', 'narrow', 'medium']}
        />
        <div className="buttonsContainer">
          <Button label="Approve" className="btnApprove" onClick={this.onApproveCharity} />
          <Button label="Revoke" className="btnReprove" labelColor="#2723EB" bgColor="#FFF" onClick={this.onReproveCharity} />
        </div>
        <Notification content={this.state.notificationContent} />
      </div>
    );
  }
}

const fetchCharitiesContainer = graphql(fetchCharities, {
  name: 'charities',
});

const approveCharityContainer = graphql(approveCharity, {
  props: ({ mutate }) => ({
    approveCharity: id =>
      mutate({
        variables: { id },
        refetchQueries: [
          {
            query: fetchCharities,
          },
        ],
      }),
  }),
});

const reproveCharityContainer = graphql(reproveCharity, {
  props: ({ mutate }) => ({
    reproveCharity: id =>
      mutate({
        variables: { id },
        refetchQueries: [
          {
            query: fetchCharities,
          },
        ],
      }),
  }),
});

export default withRouter(_.flowRight(approveCharityContainer, reproveCharityContainer, fetchCharitiesContainer)(Admin));
