import { ApolloProvider } from '@apollo/client';
import { CharityList } from 'components/charity/charityList/view';
import { CharityNetworkButton } from 'components/charity/networkButton/view';
import { ModalBase } from 'components/common';
import { localStorage } from 'lib/storage';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

export const CharitiesModal = ({ charities, onClose, apolloClient }) => {
  const userId = localStorage.get('userID');
  const sorted = [...charities].sort((charity) => (
    charity.charity_followers.some(item => item.user_id === userId) ? -1 : 1
  ));

  return (
    <ModalBase className="charitiesModal" onClose={onClose}>
      <ApolloProvider client={apolloClient}>
        <div className="scrollContainer">
          <h2 className="textBlack bold">Network ({charities.length})</h2>
          <div className="textBlack mbottom40">
            Grow your network via&nbsp;
            <Link to="/charities">Search Charities.</Link>
          </div>
          <CharityList charities={sorted} />
        </div>
      </ApolloProvider>
    </ModalBase>
  );
};
