import React, { useState } from 'react';

import { Button, Counter, Input } from '../../../../common';
import { ButtonContainer, InlineContent } from '../../../common/Elements';

const TaskName = ({ history, setFormData, setStep }) => {
  const [taskName, setTaskName] = useState('');
  const [volunteerCount, setVolunteerCount] = useState(1);

  return (
    <>
      <Input maxLength={50} placeholder="What is the title for the opportunity?" value={taskName} onChange={setTaskName} />

      <InlineContent>
        How many volunteers do you need?
        <Counter initialCount={1} onChange={setVolunteerCount} />
      </InlineContent>

      <ButtonContainer largeMargin>
        <Button label="Back" borderedButton onClick={() => history.push('/task/create/overview')} />
        <Button
          label="Continue"
          disabled={taskName === ''}
          onClick={() => {
            setFormData(prevState => ({
              ...prevState,
              name: taskName,
              volunteers_needed: volunteerCount,
            }));
            setStep('taskLocation');
          }}
        />
      </ButtonContainer>
    </>
  );
};

export default TaskName;
