import React from 'react';

const TaskCreated = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="178"
    height="178"
    fill="none"
    viewBox="0 0 178 178"
  >
    <path
      fill="#000"
      d="M89.212 29.154c-1.676-2.617-4.573-4.345-7.861-4.345-5.191 0-9.394 4.292-9.394 9.594 0 5.303 4.208 9.595 9.394 9.595.174 0 .349-.005.518-.016L89.212 37v-7.845z"
    >
    </path>
    <path
      fill="#C2826C"
      d="M92.86 35.974l-3.648-6.82s-2.22 5.815-5.624 7.428c-.18-.846-.883-1.48-1.734-1.48-.983 0-1.782.85-1.782 1.897 0 1.047.799 1.898 1.782 1.898l.026 14.231 9.415-2.125-.94-5.62a.432.432 0 01.168-.407c2.39-1.813 1.92-6.052 1.73-7.252a.45.45 0 01.242-.47.89.89 0 00.355-1.275l.01-.005z"
    >
    </path>
    <path
      fill="#000"
      d="M68.204 38.87a3.124 3.124 0 100-6.249 3.124 3.124 0 000 6.249z"
    >
    </path>
    <path
      fill="#1A3465"
      d="M154.363 106.553l15.23 7.517-45.31 39.452-8.363-8.389 38.443-38.58z"
    >
    </path>
    <path
      fill="#1A3465"
      d="M154.363 106.553l-13.787 13.84-48.402-19.031 3.425-23.815 58.764 29.006z"
    >
    </path>
    <path
      fill="#CFCCC4"
      d="M115.92 145.133l-12.322 12.365 16.784 19.702-.534-19.819 4.435-3.859-8.363-8.389z"
    >
    </path>
    <path
      fill="#C2826C"
      d="M177.2 0v5.043l-7.966 2.887-67.159 46.858-12.999-5.413L164.947 0H177.2zM0 9.854v5.043l7.972 2.891L75.13 64.642l12.994-5.413-75.87-49.375H0z"
    >
    </path>
    <path
      fill="#338C75"
      d="M113.942 86.603L74.464 100.13l.666-35.487L57.44 52.304l6.201-9.002 16.018 8.204 11.18-3.272 14.279-9.267 5.704 9.716-8.749 6.106 11.868 31.814z"
    >
    </path>
    <path
      fill="#1A3465"
      d="M90.941 160.321l-1.829 16.88-52.61-29.007 5.006-10.731 49.433 22.858zM90.942 160.321l-17.731-8.199 1.253-51.992 23.889-8.189-7.411 68.38z"
    >
    </path>
    <path
      fill="#CFCCC4"
      d="M41.509 137.462l-15.844-7.327L12.92 152.66l18.434-7.306 5.148 2.839 5.007-10.731z"
    >
    </path>
  </svg>
);

export default TaskCreated;
