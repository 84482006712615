import React from 'react';
import { Button, ContentBlock } from '../common';
import { localStorage } from '../../lib/storage';

// Styles
import './styles/aboutUsStyles.css';

const AboutUsPage = () => {
  return (
    <div className="aboutUsContainer">
      <div className="internalContainerStyle">
        <div className="section1">
          <ContentBlock
            className="content"
            title="We’re building Australia’s largest network of charities and volunteers"
            content="Worthy connects you with charities who need your help"
          />
          <img src="/illustrations/aboutUsBanner1.svg" alt="Illustration" className="illustrationImage" />
        </div>
        <div className="section2">
          <img src="/illustrations/aboutUsBanner2.svg" alt="Illustration" className="illustrationImage" />
          <ContentBlock
            className="content"
            title="Choose how and when you’ll help out"
            content={
              <div>
                <p>We connect you with charities so you can make a meaningful impact</p>
                <p>Charities are already looking for people with your skills. You’ll be helping by pledging your skills towards their worthy cause</p>
              </div>
            }
          />
        </div>
        <div className="section3 col-xs-12 p0">
          <div className="contents">
            <ContentBlock
              className="col-xs-12 col-sm-4 littleBlock benefit1"
              icon={<img src="/icons/tasks.svg" alt="tasks icon" />}
              subtitle="Volunteer based on your preferences"
              content="Easily find opportunities based on time, location or interests. Use your skills to make a meaningful impact"
            />
            <ContentBlock
              className="col-xs-12 col-sm-4 littleBlock benefit2"
              icon={<img src="/icons/badge-check.svg" alt="badge-check icon" />}
              subtitle="Join a network of charities and like-minded volunteers"
              content="Connect with charities to become a trusted and reliable volunteer. Stay connected with people you have volunteered with"
            />
            <ContentBlock
              className="col-xs-12 col-sm-4 littleBlock benefit3"
              icon={<img src="/icons/file-alt.svg" alt="file-alt icon" />}
              subtitle="Certified volunteering"
              content="Each time you volunteer your hours will be logged on your profile so you can keep track of what you’ve accomplished"
            />
          </div>
          <div className="buttonsContainer">
            <Button label="Browse tasks" href="/task/search" className="btnOpportunities" />
          </div>
        </div>
      </div>
      <div className="joinContainer col-xs-12 p0 mtop80">
        <div className="internalContainerStyle">
          <img src="/icons/bicycle-white.svg" alt="bicycle icon" />
          <h1 className="xlarge title">Join now to find your worthy cause</h1>
          <p>Charities and volunteers sign up below</p>
          <div className="buttonsContainer">
            {!localStorage.get('userID') && <Button label="I'm a volunteer" labelColor="#2723EB" bgColor="#fff" href="/sign-up" className="btnDefaultAboutUs mtop40" />}
            <Button label="I'm registering a charity" labelColor="#2723EB" bgColor="#fff" href="/for-charities" className="btnDefaultAboutUs mtop40 mleft15" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
