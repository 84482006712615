import _ from 'lodash';
import React from 'react';

import { userUtils } from '../../../lib/user';
import { getLocationText } from '../../../lib/utils';
import { SkillsList } from '../SkillsList/view';
import './styles.scss';

export const MembersList = ({
  users = [],
  onClick,
  selectedUsers,
  shouldSelectUsers,
  activeUsers,
  isScrollable,
}) => {
  const renderIcons = user => {
    // Check if user has been selected already. That defines which icon to display
    if (_.find(selectedUsers, item => item._id === user._id)) {
      return <img src="/icons/addedIcon.svg" alt="Added icon" className="selectUserImg" />;
    }
    return <img src="/icons/addIcon.svg" alt="Add icon" className="selectUserImg" />;
  };
  return (
    <div className={`flexColumn membersList ${isScrollable && 'scrollable'}`}>
      {users.map(user => {
        const isActive = _.includes(activeUsers, user?._id);
        return (
          <div key={user?._id} className={`memberRow ${isActive ? 'active' : ''}`} onClick={() => onClick(user)}>
            <div className="flexContainer">
              <img className="memberImg flexItem" src={user?.picture} alt="member profile" />
              <div className="flexColumn userInfoContainer">
                <div className="flexRow nameMacroContainer">
                  <a href={`/profile/${user?._id}`}>
                    <div className={`${isActive ? 'textWhite' : 'textBlack'} flexItem`}>
                      {user?.firstname} {user?.lastname}
                    </div>
                  </a>
                  <div className="flexItem">
                    <SkillsList macros={user?.UserMacros} />
                  </div>
                </div>
                <span>{userUtils.getLocationAndCharityText(getLocationText(user?.location), userUtils.getCharityNetworkText(user), '•')}</span>
              </div>
            </div>
            {shouldSelectUsers && renderIcons(user)}
          </div>
        );
      })}
    </div>
  );
};
