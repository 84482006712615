import gql from 'graphql-tag';

export default gql`
    mutation deleteCommentEvent($event_id: ID!, $comment_id: ID) {
        deleteCommentEvent(event_id: $event_id, comment_id: $comment_id) {
            _id
            comments {
                _id
                user_id
                content
                created
                User {
                    firstname
                    lastname
                    picture
                }
            }
        }
    }
`;
