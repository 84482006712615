import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { getLocationText } from 'lib/utils';
import { localStorage } from 'lib/storage';
import { CharityNetworkButton } from '../networkButton/view';
import './styles.scss';
import Test1DefaultCharity from '../assets/test1-default-charity.png';

export const CharityList = ({ charities }) => {
  const userID = localStorage.get('userID');
  return (
    <div className="charityList">
      {charities.map((value, index) => (
        <Fragment key={index}>
          <div className="charity">
            <div className="charityInner">
              <Link to={`/charity/${value._id}`}>
                <img src={value.picture === 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/defaultCharityImage.png' ? Test1DefaultCharity : value.picture} className="charityLogo" alt="Logo" />
              </Link>
              <div className="details">
                <Link to={`/charity/${value._id}`}>
                  <p className="charityName">{value.name}</p>
                </Link>
                <div className="causesList">
                  {value.Causes &&
                    value.Causes.map((cause, idx) => (
                      <Fragment key={idx}>
                        <p>{cause.name}</p>
                        {value.Causes.length !== idx + 1 ? <div className="v-line" /> : null}
                      </Fragment>
                    ))}
                </div>
                <div className="members">
                  {value.location && (
                    <div className="location"><p>{getLocationText(value.location)}</p></div>
                  )}
                  <p>{value.charity_followers.length} members in this network</p>
                </div>
              </div>
            </div>
            {value.user_id !== userID && <CharityNetworkButton charity={value} />}
          </div>
          {_.size(charities) !== index + 1 ? <div className="h-line" /> : null}
        </Fragment>
      ))}
    </div>
  );
};
