import { FlatButton } from 'components/common/FlatButton/view';
import { Dialog } from 'material-ui';
import React from 'react';
import CopyIcon from 'assets/svg/copy.svg';
import './styles.scss';
import { ModalBase } from 'components/common/ModalBase/view';

export const ShareModal = ({ onClose, title, emailSubject, emailBody }) => {
  const shareLink = window.location.href;
  const encodedEmailSubject = encodeURIComponent(emailSubject);
  const encodedEmailBody = encodeURIComponent(emailBody);

  const onCopy = () => {
    navigator.clipboard.writeText(shareLink);
    window.alert('Link copied!');
  };

  return (
    <ModalBase
      onClose={onClose}
      className="shareModal"
    >
      <h1 className="textCenter mtop0 mbottom40">
        {title}
      </h1>
      <div className="flexContainer flexCenter">
        <div className="flexItem linkContainer">
          {shareLink}
        </div>
        <div className="flexItem">
          <FlatButton
            label="Copy link"
            icon={CopyIcon}
            onClick={onCopy}
          />
        </div>
      </div>
      <div className="mtop40 flexCenter">
      <FlatButton
        label="Share via email"
        filled
        href={`mailto:?subject=${encodedEmailSubject}&body=${encodedEmailBody}`}
      />
      </div>
    </ModalBase>
  );
};
