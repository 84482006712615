import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import Rebecca from './assets/rebecca.png';
import { Button } from '../common';
import Chips from '../common/Chips/view';
import Tooltip from '../common/Tooltip/view';

// Queries
import fetchSkills from '../../queries/skills/fetchSkills';

function OnboardingSkills(props) {
  const [primarySkill, setPrimarySkill] = useState('');
  const [subSkills, setSubSkills] = useState([]);

  const skillsResults = useQuery(fetchSkills);
  const skillsData = _.get(skillsResults, 'data.skills');
  const groupedSkills = _.groupBy(skillsData, 'Macro.name');
  const pairs = _(groupedSkills).toPairs().sortBy(0).fromPairs();
  const orderedGroupedSkills = pairs.value();
  const macroColor = _.get(groupedSkills[primarySkill], '[0].Macro.color');

  const normaliseSkills = skills => {
    return skills.map(skill => ({
      _id: skill._id,
      label: skill.name,
      selected: subSkills.find(item => item === skill._id),
      bgColor: _.get(skill, 'skill_color'),
      labelColor: _.get(skill, 'skill_label_color'),
    }));
  };

  const handleClickSkill = skill => {
    const newSubSkills = [...subSkills];
    if (newSubSkills.includes(skill._id)) {
      _.pull(newSubSkills, skill._id);
    } else {
      newSubSkills.push(skill._id);
    }
    setSubSkills(newSubSkills);
  };

  const handleSubmit = () => {
    props.history.push({
      pathname: '/onboarding/networks',
      state: {
        data: {
          ...props.location.state.data,
          skills: subSkills,
        },
      },
    });
  };

  return (
    <div className="informationContainer noSidebar">
      <div className="informationContent">
        <h2 className="mbottom8">
          What skills do you bring?
          <Tooltip text="Add skills most relevant to your experience. You can add or change these skills later on your Profile page." />
        </h2>
        <p className="mbottom32">Please choose your primary skill.</p>
        <select
          className={`select mbottom40 ${primarySkill ? 'active' : ''}`}
          style={
            primarySkill
              ? {
                  color: macroColor,
                  border: `2px solid ${macroColor}`,
                }
              : {}
          }
          value={primarySkill}
          onChange={e => setPrimarySkill(e.target.value)}
        >
          <option value="">Please select a category</option>
          {Object.keys(orderedGroupedSkills).map(cat => {
            const categorySkills = skillsData.filter(({ _id }) => subSkills.includes(_id));
            const selected = categorySkills.filter(({ Macro }) => Macro.name === cat).length;
            return (
              <option key={cat} value={cat}>
                {cat} {selected ? `(${selected})` : null}
              </option>
            );
          })}
        </select>
        {primarySkill && (
          <>
            <h2 className="mbottom8">Add sub-skills so charities can see your strengths</h2>
            <p className="mbottom32">
              Your skills show charities what you can offer to them. It’s important to select skills relevant to your industry of work (or industry of passion ✨).
            </p>
            <Chips values={normaliseSkills(groupedSkills[primarySkill])} onClick={handleClickSkill} />
          </>
        )}
        <div className="buttonContainer">
          <Button label="Back" borderedButton onClick={() => props.history.goBack()} />
          <Button label="Continue" type="button" onClick={handleSubmit} disabled={!(primarySkill && subSkills.length > 0)} />
        </div>
      </div>
    </div>
  );
}

export default withRouter(OnboardingSkills);
