import React, { Component } from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Button, NumberInput, LoadingOverlay } from '../../common';
import { update, utils } from '../../../lib/utils';

// Styles
import './styles/reviewStyles.css';

class SelectAttendees extends Component {
    constructor() {
        super();

        this.state = {
            error: '',
            loading: false,
            userReviews: []
        };

        this.onClickUser = this.onClickUser.bind(this);
        this.isOnList = this.isOnList.bind(this);
    }

    componentWillMount() {
        if (_.get(this.props.location, 'state.userReviews')) {
            this.setState({
                userReviews: this.props.location.state.userReviews,
                rating: this.props.location.state.rating,
                message: this.props.location.state.message
            });
        }
    }

    onClickUser(User) {
        // It should check if the person has been added to the list of userReviews
        //   Yes -> It should take the person out of the list
        //    No -> It should add the person to the list
        if (this.isOnList(User._id)) {
            this.setState({
                userReviews: update(this.state.userReviews, {
                    $splice: [[this.state.userReviews.findIndex(item => item.user_id === User._id), 1]]
                })
            });
        } else {
            this.setState({
                userReviews: update(this.state.userReviews, {
                    $push: [{
                        event_id: this.props.event._id,
                        user_id: User._id,
                        hours: 6,
                        User
                    }]
                })
            });
        }
    }

    isOnList(user_id) {
        return this.state.userReviews.find(item => item.user_id === user_id);
    }

    renderConfirmedUsers(users) {
        return users.map(({ User }) => {
            return (
                <div
                    className={`user ${this.isOnList(User._id) ? 'active' : 'inactive'}`}
                    key={User._id}
                >
                    <a
                        onClick={() => this.onClickUser(User)}
                    >
                        <div
                            className="picture"
                            style={{ backgroundImage: `url(${User.picture})` }}
                        >
                            {this.isOnList(User._id) &&
                                <img
                                    src="/icons/checkedIcon.svg"
                                    alt="Confirmed person"
                                    className="checkedIcon"
                                />
                            }
                        </div>
                        <h3>{User.firstname} {utils.formatLastName(User.lastname)}</h3>
                    </a>
                    {this.isOnList(User._id) &&
                        <NumberInput
                            value={this.state.userReviews.find(item => item.user_id === User._id).hours}
                            min={1}
                            onChange={(value) => {
                                this.setState({
                                    userReviews: update(this.state.userReviews, {
                                        $auto: {
                                            [this.state.userReviews.findIndex(item => item.user_id === User._id)]: {
                                                $auto: {
                                                    hours: {
                                                        $set: value
                                                    }
                                                }
                                            },
                                        }
                                    })
                                });
                            }}
                        />
                    }
                </div>
            );
        });
    }

    render() {
        const { event } = this.props;
        return (
            <div className="selectAttendesContainer col-xs-12">
                {this.state.loading &&
                    <LoadingOverlay />
                }
                {this.state.error && (
                    <div className="errorMessage">
                        <p className="error">{this.state.error}</p>
                    </div>
                )}
                <div className="internalContainerStyle">
                    <p className="mbottom30">Select who <strong>attended</strong> your event below and adjust their hours</p>
                    <div className="confirmedUsersContainer col-xs-12">
                        {this.renderConfirmedUsers(event.confirmed_users)}
                    </div>
                    <Button
                        label="Next"
                        className="btnDefault"
                        onClick={() => this.props.history.push({ pathname: `/review-event/${this.props.event._id}/rate`, state: { userReviews: this.state.userReviews, rating: this.state.rating, message: this.state.message } })}
                        disabled={this.state.userReviews.length === 0}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(SelectAttendees);
