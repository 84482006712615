import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { Button, Input, FileUpload, LoadingOverlay, Causes } from '../../common';
import { charityUtils } from '../../../lib/charity';
import { localStorage } from '../../../lib/storage';
import { globalVariables } from '../../../lib/globalVariables';

// Queries
import fetchCharityById from '../../../queries/charity/fetchCharityById';

// Mutations
import createCharityMutation from '../../../mutations/charity/createCharity';
import updateCharityMutation from '../../../mutations/charity/updateCharity';

const CreateCharityDetails = ({ history, location }) => {
  const [charity, setCharity] = useState({
    name: '',
    registration: '',
    picture: '',
    causes: [],
    featured: false,
    description: '',
    user_id: localStorage.get('userID'),
  });
  const [loading, setLoading] = useState(false);
  const [registrationError, setRegistrationError] = useState('');

  // Queries
  const fetchCharitiesByIdQuery = useQuery(fetchCharityById, {
    variables: {
      _id: localStorage.get('charity-temp'),
    },
    skip: !localStorage.get('charity-temp'),
  });
  const charityData = _.get(fetchCharitiesByIdQuery, 'data.charityById');

  // Mutations
  const [createCharity] = useMutation(createCharityMutation);
  const [updateCharity] = useMutation(updateCharityMutation);

  useEffect(() => {
    if (_.size(charityData) > 0) {
      setCharity(prevState => ({
        ...prevState,
        name: charityData.name,
        registration: charityData.registration,
        picture: charityData.picture,
        description: charityData.description,
        causes: charityData.causes,
      }));
    }
  }, [charityData]);

  const onUpdateCharityInput = (value, fieldName) => {
    setCharity({
      ...charity,
      [fieldName]: value,
    });
  };

  const onChangeCauses = selectedCauses => {
    setCharity({
      ...charity,
      causes: selectedCauses,
    });
  };

  const onNextClicked = async () => {
    setLoading(true);

    // It should only create the charity if there's no charity onto the localStorage
    // If there is a charity into the local storage it should then update the current charity
    if (localStorage.get('charity-temp')) {
      // Update charity
      await updateCharity({
        variables: {
          id: localStorage.get('charity-temp'),
          charity,
        },
      });
      setLoading(false);
      history.push({ pathname: '/charity/register/contacts', state: { ...location.state } });
    } else {
      // Create charity
      const createCharityResponse = await createCharity({
        variables: {
          charity,
        },
      });
      setLoading(false);
      localStorage.save('charity-temp', createCharityResponse.data.createCharity._id);
      history.push({ pathname: '/charity/register/contacts' });
    }
  };

  return (
    <div className="childContainer detailsContainer">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <div className="nameContainer col-xs-12">
        <div className="col-xs-12 col-sm-9">
          <div className="col-xs-12 col-sm-6">
            <Input name="name" placeholder="Charity name" type="text" value={charity.name} onChange={value => onUpdateCharityInput(value, 'name')} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Input
              name="registration"
              placeholder="EIN"
              type="text"
              errorText={registrationError}
              value={charity.registration}
              onChange={value => onUpdateCharityInput(value, 'registration')}
              onBlur={() => {
                if (charityUtils.isValidEIN(charity.registration) === false) {
                  setRegistrationError('Invalid, please enter your EIN number without dashes or spaces.');
                } else {
                  setRegistrationError('');
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        <div className="col-xs-12 col-sm-3 p0">
          <FileUpload
            onFinishUpload={filename => onUpdateCharityInput(`${globalVariables.getEnvironmentVariables().fileServer}${filename}`, 'picture')}
            previewImage={charity.picture}
            label="Upload logo"
          />
        </div>
        <div className="col-xs-12 col-sm-9">
          <Input
            name="description"
            label="About the charity"
            placeholder="What is your mission & purpose? Write a short description to encourage volunteers to work with you."
            type="text"
            value={charity.description}
            onChange={value => onUpdateCharityInput(value, 'description')}
            maxLength="500"
            multiLine
          />
        </div>
      </div>
      <div className="categoriesContainer p0">
        <div className="titleCategoriesContainer">
          <h3 className="m0">Causes</h3>
          <p className="smallBody">
            <span className={`${_.size(charity.causes) === 3 ? 'maxReached' : ''}`}>Select maximum 3 causes</span> that are relevant to your charity
          </p>
        </div>
        <div key="areasContainer" className="areasContainer">
          <Causes
            onGetNewCausesArray={onChangeCauses}
            selectedValues={charity.causes
              .filter(item => item !== null)
              .map(item => {
                return {
                  cause_id: item,
                };
              })}
            max={3}
            containerClass="mtop20"
          />
        </div>
      </div>
      <div className="buttonsContainer col-xs-12 p0">
        <Button
          label="Continue"
          onClick={onNextClicked}
          className="btnDefault btnBlueSolid"
          disabled={!(charity.name && charity.registration && charity.description && charity.causes.length > 0 && _.size(registrationError) === 0)}
        />
      </div>
    </div>
  );
};

export default withRouter(CreateCharityDetails);
