import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { SkillsList } from 'components/common/SkillsList/view';
import { TASK_FREQUENCY, TASK_TYPE } from 'lib/task';
import { charityUtils } from 'lib/charity';
import { Chips } from 'components/common';
import CalendarIcon from 'assets/svg/calendar.svg';
import LocationIcon from 'assets/svg/location.svg';
import { Link } from 'react-router-dom';
import { getSkillMacros } from 'lib/utils';
import Test1DefaultCharity from 'components/charity/assets/test1-default-charity.png';
import Logo from './assets/logo.png';
import OngoingLogo from './assets/ongoing.svg';
import PinLogo from './assets/pin.svg';
import Avatar from './assets/avatar.png';
import './styles/taskCardStyle.scss';

function TaskCard({ userId, task: { _id, Charity, name, created, description, TaskSkills, frequency, type, oneoff_frequency_config, ongoing_frequency_config, location } }) {
  const getTaskHours = () => {
    const { hours_per_volunteer } = oneoff_frequency_config || {};
    const { hours_per_week } = ongoing_frequency_config || {};
    if (frequency === TASK_FREQUENCY.ONE_OFF) {
      return `${hours_per_volunteer}hrs per volunteer`;
    }
    return `${hours_per_week}hrs per week`;
  };

  const getTaskLocation = () => {
    if (type === TASK_TYPE.REMOTE) {
      return 'Remote';
    }
    return `In person - ${location.state}`;
  };

  const charityAdmin = Charity.charity_users.find(({ role }) => role === 'Admin');
  const inNetwork = Charity.charity_followers.some(({ user_id }) => user_id === userId);

  return (
    <div className="taskCard">
      <div className="taskHeader">
        <div className="logo">
          <img src={Charity.picture === 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/defaultCharityImage.png' ? Test1DefaultCharity : Charity.picture} alt="Logo" />
          <p>
            <Link to={`/charity/${Charity._id}`}>{Charity.name}</Link>
          </p>
        </div>
        <div className="info">
          <div className="name">
            <h1>
              <Link to={`/task/${_id}`}>{name}</Link>
            </h1>
            <p>{moment(created).fromNow()}</p>
          </div>
          <div className="skillInfo">
            <SkillsList macros={getSkillMacros(TaskSkills)} skills={TaskSkills} />
          </div>
          <div className="flexContainer">
            <div className="textGrey flexItem">
              <img src={OngoingLogo} alt="Ongoing" className="mright8" />
              {frequency === TASK_FREQUENCY.ONE_OFF ? 'One Off' : 'Ongoing'}
              &nbsp;·&nbsp;
              {getTaskHours()}
            </div>
            <div className="textGrey flexItem">
              <img src={PinLogo} alt="Pin" className="mright8" />
              {getTaskLocation()}
            </div>
          </div>
        </div>
      </div>
      <p className="description">{description}</p>
      {inNetwork && charityAdmin && (
        <div className="connection">
          <div className="user">
            <img src={_.get(charityAdmin, 'user.picture', 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/b465d53e034a73095dffed7f51e43230.jpeg')} alt="Avatar" />
            <Link to={`/profile/${_.get(charityAdmin, 'user._id')}`}>
              <p>
                {_.get(charityAdmin, 'user.firstname', '')} {_.get(charityAdmin, 'user.lastname', '')}
              </p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskCard;
