import React from 'react';
import styled from 'styled-components';

const StyledOnboardingContainer = styled.div`
  background-color: #FFFFFF;
  border-radius: 16px;
  max-width: 1030px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 41px;
    color: #212B35;

    span {
      font-family: 'Petrona';
      color: #3042D4;
      font-size: 36px;
    }
  }

  p, input, button {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
  }
`;

const OnboardingContainer = ({ children }) => (
  <StyledOnboardingContainer className="content">
    {children}
  </StyledOnboardingContainer>
);

export default OnboardingContainer;
