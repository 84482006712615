import gql from 'graphql-tag';

export default gql`
  mutation markMultipleMessagesAsSeen($_ids: [ID]!, $_user_id: ID!) {
    markMultipleMessagesAsSeen(_ids: $_ids, _user_id: $_user_id) {
      _id
      seen
    }
  }
`;
