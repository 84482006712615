import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import _ from 'lodash';
import UncircledAddIcon from 'assets/svg/uncircled-add.svg';
import { LoadingOverlay, Popover, Menu } from '../common';
import Tooltip from '../common/Tooltip/view';
import Button from '../common/Button/view';
import { FlatButton } from '../common/FlatButton/view';
import { IconButton } from '../common/IconButton/view';
import { localStorage } from '../../lib/storage';
import ModalInviteTeamMember from './modalInviteTeamMember';
import ModalRemoveUser from './modalRemoveUser';

import fetchCharitiesByUserId from '../../queries/charity/fetchCharitiesByUserId';
import fetchUnacceptedJoinCharitiesByInvitor from '../../queries/join_charity/unacceptedJoinCharitiesByInvitor';
import unlinkCharityMutation from '../../mutations/charity/unlinkCharity';
import updateCharityMutation from '../../mutations/charity/updateCharity';

import './styles/settingsStyles.scss';

const tableHeaderItems = [
  { type: 'normal', content: 'Name' },
  { type: 'normal', content: 'Email' },
  { type: 'normal', content: 'Role' },
];
const roles = [
  { id: 'Manager', title: 'Charity Manager' },
  { id: 'Admin', title: 'Charity Admin' },
  { id: 'Ambassador', title: 'Charity Ambassador' },
];

function AccessPanel() {
  const [openAnchorEl, setOpenAnchorEl] = useState();
  const [loading, setLoading] = useState(false);
  const [charity, setCharity] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [ambassadors, setAmbassadors] = useState([]);
  const [unacceptedMembers, setUnacceptedMembers] = useState([]);
  const [openInviteTeamMemberModal, setOpenInviteTeamMemberModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [forWho, setForWho] = useState(-1); // forWho - 1 : Team Member, 2 : Ambassador
  const [selectedUserForRemove, setSelectedUserForRemove] = useState(null);
  const [roleError, setRoleError] = useState('');

  const [getCharities, { data, error }] = useLazyQuery(fetchCharitiesByUserId, {
    onCompleted: () => setLoading(false),
  });
  const { data: unacceptedJoinCharitiesData } = useQuery(fetchUnacceptedJoinCharitiesByInvitor, {
    variables: { invitedBy: localStorage.get('userID') },
    fetchPolicy: 'cache-and-network',
  });
  const getCharitiesFunc = useMemo(() => _.debounce(getCharities, 1000), [getCharities]);
  const [unlinkCharity] = useMutation(unlinkCharityMutation);
  const [updateCharity] = useMutation(updateCharityMutation);

  const unacceptedTeamMembers = useMemo(() => _.filter(unacceptedMembers, unacceptedMember => _.get(unacceptedMember, 'promisedRole') === 'Manager'), [unacceptedMembers]);
  const unacceptedAmbassadors = useMemo(() => _.filter(unacceptedMembers, unacceptedMember => _.get(unacceptedMember, 'promisedRole') === 'Ambassador'), [unacceptedMembers]);

  useEffect(() => {
    if (!data) return;
    const charities = _.get(data, 'charityByUserId', []);
    const unacceptedJoinCharities = _.get(unacceptedJoinCharitiesData, 'unacceptedJoinCharitiesByInvitor');
    const charityUsers = _.size(charities) > 0 ? _.get(charities[0], 'charity_users') : [];
    const teamMembersData = [];
    const ambassadorsData = [];
    _.map(charityUsers, charityUser => {
      const user = {
        id: _.get(charityUser, 'user_id'),
        avatar: _.get(charityUser, 'user.picture'),
        name: `${_.get(charityUser, 'user.firstname')} ${_.get(charityUser, 'user.lastname')}`,
        email: _.get(charityUser, 'user.email'),
        role: charityUser.role,
        seat: '',
      };
      _.get(charityUser, 'role') === 'Ambassador' ? ambassadorsData.push(user) : teamMembersData.push(user);
    });

    const unacceptedMembersData = _.map(unacceptedJoinCharities, unacceptedJoinCharity => {
      return unacceptedJoinCharity.InviteeEmail
        ? {
            id: _.get(unacceptedJoinCharity.InviteeEmail, '_id'),
            avatar: _.get(unacceptedJoinCharity.InviteeEmail, 'picture'),
            name: `${_.get(unacceptedJoinCharity.InviteeEmail, 'firstname')} ${_.get(unacceptedJoinCharity.InviteeEmail, 'lastname')}`,
            email: _.get(unacceptedJoinCharity.InviteeEmail, 'email'),
            role: 'NA', // still don't accept the invite,
            seat: '',
            promisedRole: _.get(unacceptedJoinCharity, 'role') === 1 ? 'Manager' : 'Ambassador',
          }
        : { email: _.get(unacceptedJoinCharity, 'email'), role: 'NA', promisedRole: _.get(unacceptedJoinCharity, 'role') === 1 ? 'Manager' : 'Ambassador' };
    });

    setCharity(charities[0]);
    setTeamMembers(teamMembersData);
    setAmbassadors(ambassadorsData);
    setUnacceptedMembers(unacceptedMembersData);
    getCharitiesFunc.cancel;
  }, [data, unacceptedJoinCharitiesData]);

  useEffect(() => {
    getCharitiesFunc({ variables: { userId: localStorage.get('userID') }, fetchPolicy: 'cache-and-network' });
  }, []);

  const renderHeaderItem = (item, index) => {
    const headerItems = {
      normal: (
        <div className="headerItem" key={`header-item-${index}`}>
          <span>{item.content}</span>
        </div>
      ),
      icony: (
        <div className="headerItem" key={`header-item-${index}`}>
          <Tooltip text="You have unlimited Team Seats while we’re in testing mode!" icon="exclamation" type="vertical" />
          <span style={{ marginLeft: '15px' }}>{item.content}</span>
        </div>
      ),
    };
    return _.get(headerItems, item.type);
  };

  const onSave = async () => {
    await updateCharity({
      variables: {
        id: charity._id,
        charity: {
          charity_users: [...[...teamMembers, ...ambassadors].map(item => ({ user_id: item.id, role: item.role }))],
        },
      },
      refetchQueries: [
        {
          query: fetchCharitiesByUserId,
          variables: { userId: localStorage.get('userID') },
        },
      ],
    });
  };

  const onClickInviteMember = who => {
    setOpenInviteTeamMemberModal(true);
    setForWho(who);
  };

  const handleOpenPopover = (event, user, who) => {
    event.preventDefault();
    setSelectedUserForRemove(user);
    setForWho(who);
    setOpenAnchorEl(event.currentTarget);
  };

  const handleRemoveUser = () => {
    unlinkCharity({
      variables: {
        id: charity._id,
        user_id: selectedUserForRemove.id,
      },
      refetchQueries: [
        {
          query: fetchCharitiesByUserId,
          variables: { userId: localStorage.get('userID') },
          fetchPolicy: 'cache-and-network',
        },
      ],
    });
  };
  const handleChangeRoleStatusForTeamMember = (event, index) => {
    const roleStatus = checkRoleStatus(index, event.target.value);
    roleStatus && setTeamMembers(teamMembers.map((teamMember, i) => (i === index ? { ...teamMember, role: event.target.value } : teamMember)));
  };
  const handleChangeRoleStatusForAmbassador = (event, index) => {
    const roleStatus = checkRoleStatus(teamMembers.length + index, event.target.value);
    roleStatus && setAmbassadors(ambassadors.map((ambassador, i) => (i === index ? { ...ambassador, role: event.target.value } : ambassador)));
  };

  const checkRoleStatus = (index, value) => {
    const charityUsers = [...teamMembers, ...ambassadors];
    charityUsers[index] = { ...teamMembers[index], role: value };

    if (charityUsers.filter(user => user.role === 'Admin').length === 0) {
      setRoleError('You must have 1 charity admin on your team.');
      return false;
    }
    if (charityUsers.filter(user => user.role === 'Admin').length > 1) {
      setRoleError('There can only be 1 charity admin on your team.');
      return false;
    }
    setRoleError('');
    return true;
  };

  return (
    <div className="accessPanel">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <h2>Access & Privileges</h2>
      <Popover open={!!openAnchorEl} anchorEl={openAnchorEl} handleRequestClose={() => setOpenAnchorEl(undefined)} className="userPopover">
        <Menu
          values={[
            {
              label: 'Remove',
              action: () => {
                setOpenAnchorEl(undefined);
                setOpenRemoveModal(true);
              },
            },
          ]}
          className="mainMenuUserContent"
        />
      </Popover>
      <div className="teamContainer">
        <div className="headerBlock">
          <p>Team Members</p>
          <FlatButton label="Invite team member" filled={false} bordered icon={UncircledAddIcon} onClick={() => onClickInviteMember(1)} />
        </div>
        <div className="contentBlock">
          <p>Charity Managers & Admins have access to your Task Manager. They are part of your internal team.</p>
          <div className="memberTable">
            <div className="tableHeader">{tableHeaderItems.map((item, index) => renderHeaderItem(item, index))}</div>
            <hr />
            <div className="tableBody">
              {teamMembers &&
                [...teamMembers, ...unacceptedTeamMembers].map((row, index) => (
                  <div className={`tableRow ${row.role === 'NA' ? 'textGray' : ''}`} key={`tablerow-${index}`}>
                    <div className="cell name">
                      {row.avatar && <img src={row.avatar} alt="Team Members Avatar" />}
                      <span>{row.name}</span>
                    </div>
                    <div className="cell email">
                      <span>{row.email}</span>
                    </div>
                    <div className="cell role">
                      {row.role === 'NA' ? (
                        <p>Invitation sent</p>
                      ) : (
                        <>
                          <select className="select" value={row.role} onChange={e => handleChangeRoleStatusForTeamMember(e, index)}>
                            {roles.map((role, j) => (
                              <option key={`role-${j}`} value={role.id}>
                                {role.title}
                              </option>
                            ))}
                          </select>
                          <div className="cell remove">
                            <IconButton className="addIcon" icon="/icons/3dots.svg" onClick={event => handleOpenPopover(event, row, 1)} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <p className="roleErrorText">{roleError}</p>
          </div>
        </div>
      </div>
      <div className="ambassadorsContainer">
        <div className="headerBlock">
          <p>Charity Ambassadors</p>
          <FlatButton label="Invite ambassador" filled={false} bordered icon={UncircledAddIcon} onClick={() => onClickInviteMember(2)} />
        </div>
        <div className="contentBlock">
          <p>Charity Ambassadors do not have access to your Task Manager. They are representatives of your charity. </p>
          <div className="memberTable">
            <div className="tableHeader">{tableHeaderItems.map((item, index) => renderHeaderItem(item, index))}</div>
            <hr />
            <div className="tableBody">
              {ambassadors &&
                [...ambassadors, ...unacceptedAmbassadors].map((row, index) => (
                  <div className={`tableRow ${row.role === 'NA' ? 'textGray' : ''}`} key={`tablerow-${index}`}>
                    <div className="cell name">
                      {row.avatar && <img src={row.avatar} alt="Ambassadors Avatar" />}
                      <span>{row.name}</span>
                    </div>
                    <div className="cell email">
                      <span>{row.email}</span>
                    </div>
                    <div className="cell role">
                      {row.role ? (
                        <p>Invitation Sent</p>
                      ) : (
                        <>
                          <select className="select" value={row.role} onChange={e => handleChangeRoleStatusForAmbassador(e, index)}>
                            {roles.map((role, j) => (
                              <option key={`role-${j}`} value={role.id}>
                                {role.title}
                              </option>
                            ))}
                          </select>
                          <div className="cell remove">
                            <IconButton className="addIcon" icon="/icons/3dots.svg" onClick={event => handleOpenPopover(event, row, 2)} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Button label="Save" onClick={onSave} />
      {!!charity && (
        <ModalInviteTeamMember
          open={openInviteTeamMemberModal}
          onClose={() => setOpenInviteTeamMemberModal(false)}
          charityId={charity._id}
          charityName={charity.name}
          forWho={forWho}
        />
      )}
      {openRemoveModal && (
        <ModalRemoveUser
          open={openRemoveModal}
          onClose={() => setOpenRemoveModal(false)}
          onRemove={() => handleRemoveUser()}
          forWho={forWho}
          userName={`${_.get(selectedUserForRemove, 'firstname', '')} ${_.get(_.get(selectedUserForRemove, 'lastname', ''))}`}
        />
      )}
    </div>
  );
}

export default AccessPanel;
