import React, { Component } from 'react';
import { withRouter } from 'react-router';
import './styles/backButtonStyles.css';

/*
    <BackButton
        title="Back to charity"
    />
*/

class BackButton extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.history.goBack();
    }

    render() {
        return (
            <a
                className={`backButton ${!this.props.title ? 'noBorder' : ''}`}
                onClick={this.onClick}
            >
                <i
                    className="material-icons"
                >
                    &#xE5C4;
                </i>
                {this.props.title &&
                    <p className="smallBody bold">{this.props.title}</p>
                }
            </a>
        );
    }
}

export default withRouter(BackButton);
