import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';

import createTaskMutation from '../../../../mutations/task/createTask';
import inviteVolunteersForTask from '../../../../mutations/task/inviteVolunteersForTask';

import { Button, LoadingOverlay } from '../../../common';
import { localStorage } from '../../../../lib/storage';
import { SectionHeading, ButtonContainer, InlineContent } from '../../common/Elements';
import { utils } from '../../../../lib/utils';
import NetworkPeopleIcon from './assets/networkPeople';

const TaskContentContainer = styled.div`
  background: #f7f5f0;
  border-radius: 5px;
  margin-top: 40px;
  padding: 30px 20px;
  margin-bottom: 30px;

  h3 {
    font-size: 22px;
    font-family: 'Poppins' !important;
    font-weight: 800;
  }

  p {
    font-size: 15px;
    line-height: 1.75;
  }

  @media (min-width: 767px) {
    margin-top: 40px;
    padding: 30px 40px;
  }
`;

const Skill = styled.span`
  display: inline-block;
  border-radius: 5px;
  font-family: 'Poppins';
  font-size: 16px;
  color: white;
  background: ${props => props.bgColor};
  margin-right: 15px;
  margin-top: 15px;

  ${props =>
    props.secondary
      ? css`
          vertical-align: bottom;
          padding: 4px 13px;
        `
      : css`
          padding: 8px 20px;
          font-weight: bold;
          font-weight: bold;
        `}
`;

const ShareContent = styled.span`
  display: inline-block;
  margin-left: 8px;
`;

const PublishStep = ({ history, formData }) => {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [createTask] = useMutation(createTaskMutation);
  const [inviteVolunteer] = useMutation(inviteVolunteersForTask);

  const getHoursPerWeekText = () => {
    if (formData.requiredHoursPerWeek === '1') {
      return 'Less than 5 hours per week';
    }
    if (formData.requiredHoursPerWeek === '2') {
      return 'Between 5-10 hours per week';
    }
    if (formData.requiredHoursPerWeek === '3') {
      return 'Between 10-20 hours per week';
    }
    return 'More than 20 hours per week';
  };

  const getSkillArray = () => {
    const skillArray = [formData.primarySkill.id];
    formData.subSkills.forEach(skill => skillArray.push(skill.id));

    return skillArray;
  };

  const recordedTimezone = localStorage.get('timezone');

  const sanitiseFormDataForMutation = () => {
    let sanitisedLocation = {};
    if (formData.locationType === 'inPerson') {
      sanitisedLocation = utils.removeTypenames(formData.location);
      sanitisedLocation.location = sanitisedLocation.text;
      sanitisedLocation.postcode = parseInt(formData.location.postcode);
    }

    return {
      _user_id: localStorage.get('userID'),
      _charity_id: formData.charityId,
      name: formData.name,
      description: formData.description,
      type: formData.locationType === 'inPerson' ? 1 : 2,
      status: 1,
      volunteers_needed: formData.volunteers_needed,
      frequency: formData.type === 'single' ? 1 : 2,
      ...(formData.type === 'single'
        ? {
            oneoff_frequency_config: {
              start: formData.startDate,
              end: formData.endDate,
              is_date_flexible: formData.isFlexibleDate,
              is_time_flexible: formData.isFlexibleTime,
              hours_per_volunteer: formData.volunteerHours,
            },
          }
        : {
            ongoing_frequency_config: {
              type: parseInt(formData.opportunityType),
              hours_per_week: parseInt(formData.requiredHoursPerWeek),
            },
          }),
      skills_needed: getSkillArray(),
      timezone: recordedTimezone || 'Australia/Sydney',
      ...(formData.locationType === 'inPerson' && {
        location: sanitisedLocation,
      }),
    };
  };

  const handleCreateTask = async () => {
    setLoading(true);
    setFormError('');

    try {
      const task = sanitiseFormDataForMutation();
      const taskCreatedResults = await createTask({
        variables: {
          task,
        },
      });
      const taskCreated = _.get(taskCreatedResults, 'data.createTask');

      setLoading(false);

      if (formData.invitedFollowers) {
        await inviteVolunteer({
          variables: {
            _task_id: _.get(taskCreated, '_id'),
            _user_ids: formData.invitedFollowers,
          },
        });
      }

      history.push({
        pathname: '/task/create/finish',
        state: {
          task: taskCreated,
        },
      });
    } catch (error) {
      setFormError(error.graphQLErrors[0].message);
      setLoading(false);
    }
  };

  return (
    <>
      <SectionHeading>Let&apos;s take a look at the summary</SectionHeading>

      {loading ? (
        <LoadingOverlay style={{ opacity: 0.8 }} />
      ) : (
        <>
          {formError && (
            <div className="errorMessage">
              <p className="error">{formError}</p>
            </div>
          )}
          <TaskContentContainer>
            <h3>{formData.name}</h3>
            <div className="mbottom30">
              <Skill bgColor={formData.primarySkill.bgColor}>{formData.primarySkill.label}</Skill>
              {formData.subSkills.map(subSkill => (
                <Skill bgColor={subSkill.bgColor} key={subSkill.name} secondary>
                  {subSkill.name}
                </Skill>
              ))}
            </div>
            <p>{formData.description}</p>
            <p>
              <strong>Volunteers needed:</strong> {formData.volunteers_needed}
            </p>
            <p>
              <strong>Hours needed per volunteer:</strong> {formData.type === 'single' ? formData.volunteerHours : getHoursPerWeekText()}
            </p>
            <p>
              <strong>Location:</strong> {formData.location && formData.locationType === 'inPerson' ? formData.locationText : 'Remote'}
            </p>
            {formData.type === 'single' ? (
              <>
                <p>
                  <strong>Task start/end date:</strong>{' '}
                  {formData.isFlexibleDate ? 'Flexible' : `${moment(formData.startDate).format('DD/MM/YYYY HH:mm')} - ${moment(formData.endDate).format('DD/MM/YYYY HH:mm')}`}
                </p>
                <p>
                  <strong>Task start/end time:</strong> {formData.isFlexibleTime ? 'Flexible' : `${formData.startTime} - ${formData.endTime}`}
                </p>
              </>
            ) : (
              <>
                <p>
                  <strong>Ongoing task period: </strong>
                  {formData.opportunityType === '1' ? 'Short-term (less than 3 months)' : 'Long-term (more than 3 months)'}
                </p>
              </>
            )}

            {formData.invitedFollowers && formData.invitedFollowers.length > 0 && (
              <InlineContent>
                <NetworkPeopleIcon />
                <ShareContent>
                  You&apos;re sending this task to&nbsp;
                  <a href="#">
                    {formData.invitedFollowers.length} {formData.invitedFollowers.length > 1 ? 'people' : 'person'}
                  </a>
                  &nbsp;in your network
                </ShareContent>
              </InlineContent>
            )}
          </TaskContentContainer>

          <ButtonContainer>
            <Button
              borderedButton
              label="Back"
              onClick={() => {
                history.push({ pathname: '/task/create/volunteers' });
              }}
            />
            <Button label="Continue" onClick={handleCreateTask} />
          </ButtonContainer>
        </>
      )}
    </>
  );
};

export default PublishStep;
