import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import RichTextEditor from 'react-rte';
import { Modal, Button, LoadingOverlay } from '../../common';
import { utils } from '../../../lib/utils';

// Mutations
import sendEmailToAttendeesMutations from '../../../mutations/event/sendEmailToAttendees';

// Styles
import './styles/modalSendEmailAttendees.css';

class ModalCancelAttendance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            textToSend: RichTextEditor.createEmptyValue()
        };

        this.configRightTextEditor = {
            display: ['INLINE_STYLE_BUTTONS', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' }
            ],
        };

        this.onClickSend = this.onClickSend.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onClickSend() {
        const content = utils.transformsIntoMarkdown(this.state, 'textToSend');
        this.setState({ loading: true });
        this.props.sendEmailToAttendees(this.props.event_id, content)
            .then(() => {
                this.setState({ loading: false });
                this.onCloseModal(true);
            });
    }

    onCloseModal(messageSent = false) {
        this.resetState();
        this.props.onClose(messageSent);
    }

    resetState() {
        this.setState({ textToSend: RichTextEditor.createEmptyValue() });
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onClose={this.onCloseModal}
                className="modalSendEmailAttendees flexCenter"
            >
                {this.state.loading &&
                    <LoadingOverlay style={{ opacity: 0.8 }} />
                }
                <a onClick={this.onCloseModal}>Close</a>
                <h2>Send a blast message to all your attendees</h2>
                <div className="emailContainer">
                    <RichTextEditor
                        value={this.state.textToSend}
                        toolbarConfig={this.configRightTextEditor}
                        onChange={value => this.setState({ textToSend: value })}
                    />
                </div>
                <Button
                    label="Send Email"
                    onClick={this.onClickSend}
                    className="btnSend"
                />
            </Modal>
        );
    }
}

const sendEmailToAttendeesContainer = graphql(sendEmailToAttendeesMutations, {
    props: ({ mutate }) => ({
        sendEmailToAttendees: (event_id, content) => mutate({
            variables: { event_id, content }
        }),
    }),
});

export default sendEmailToAttendeesContainer(ModalCancelAttendance);
