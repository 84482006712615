import React from 'react';
import Dialog from 'material-ui/Dialog';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import Button from '../Button/view';
import LoadingOverlay from '../LoadingOverlay/view';

// Styles
import './styles/DialogStyles.css';

// Component created to display confirmation messages
const DialogMessage = (props) => {
    return (
        <Dialog
            title={props.title}
            modal={false}
            open={props.open}
            contentStyle={props.styles && props.styles}
            contentClassName="defaultDialog"
            className="dialogComponent"
            onRequestClose={props.onRequestClose}
        >
            <>
                <CloseIcon
                    className="closeIcon"
                    style={{ width: 32, height: 32 }}
                    onClick={props.onRequestClose}
                />
                {props.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
                <div className="dialogContainerIntern">
                    <h3>{props.subtitle}</h3>
                    <div className="buttonsContainer">
                        <Button
                            label="Cancel"
                            onClick={() => props.onCancel()}
                            className="btnMedium btnCancel"
                            borderedButton
                        />
                        <Button
                            label="Confirm"
                            onClick={() => props.onConfirm()}
                            className="btnMedium btnConfirm"
                        />
                    </div>
                </div>
            </>
        </Dialog>
    );
};

export default DialogMessage;
