import React from 'react';
import IconButton from 'material-ui/IconButton';
import Add from 'material-ui/svg-icons/content/add';
import Remove from 'material-ui/svg-icons/content/remove';
// Styles
import './styles/numberInputStyles.css';

const NumberInput = (props) => {
    const { min = -999 } = props;
    return (
        <div
            className="numberInputContainer"
        >
            <IconButton
                className="reduce"
                onClick={() => {
                    if (min < props.value) props.onChange(props.value - 1);
                }}
            >
                <Remove />
            </IconButton>
            <div className="result">
                <span>{(props.value !== 0) ? props.value : 'Unlimited'}</span>
            </div>
            <IconButton
                className="increment"
                onClick={() => props.onChange(props.value + 1)}
            >
                <Add />
            </IconButton>
        </div>
    );
};

export default NumberInput;
