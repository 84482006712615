import React from 'react';
import _ from 'lodash';
import CalendarIcon from 'assets/svg/calendar.svg';
import LocationIcon from 'assets/svg/location.svg';
import TasksEmptyIcon from 'components/charity/assets/tasks-empty.svg';
import { FlatButton } from 'components/common/FlatButton/view';
import './styles.scss';
import { charityUtils } from 'lib/charity';
import { SkillsList } from 'components/common/SkillsList/view';
import { TASK_FREQUENCY, TASK_STATUS, TASK_TYPE } from 'lib/task';
import { withRouter } from 'react-router';

export const CharityDetailsTasks = withRouter(({ charity, history }) => {
  const isOwner = charityUtils.isCharityAdmin(charity);
  const getTaskFrequency = task => {
    if (task.frequency === TASK_FREQUENCY.ONE_OFF) {
      return `${_.get(task, 'oneoff_frequency_config.hours_per_volunteer') || 0}hrs per volunteer`;
    }
    return `${_.get(task, 'ongoing_frequency_config.hours_per_week') || 0}hrs per week`;
  };

  const getTaskStatus = ({ status }) => {
    if (status === TASK_STATUS.COMPLETED) {
      return 'Closed';
    }
    if (isOwner) {
      return 'Open for applications';
    }
    return 'Apply Now';
  };

  const started = charity.Tasks.filter(({ status }) => [TASK_STATUS.ACTIVE, TASK_STATUS.COMPLETED].includes(status))
    .sort((a, b) => {
      return a.created - b.created;
    })
    .sort(({ status }) => {
      return status === TASK_STATUS.ACTIVE ? -1 : 1;
    });

  return (
    <div className="charityDetailsTasks">
      <h2 className="bold mtop0 mbottom24">Tasks ({started.length})</h2>
      {started.map(task => (
        <div key={task._id} className={`charityTask mbottom16 ${task.status === TASK_STATUS.COMPLETED ? 'taskClosed' : ''}`} onClick={() => history.push(`/task/${task._id}`)}>
          <div className="textPrimary fontPoppins mbottom16">{getTaskStatus(task)}</div>
          <h2 className={`bold mtop0 mbottom16 ${task.status === TASK_STATUS.COMPLETED && 'textlightGrey'}`}>{task.name}</h2>
          <div className="flexContainer">
            <div className="flexItem">
              <SkillsList skills={task.TaskSkills} />
            </div>
            <div className="flexItem">
              <div className="flexContainer">
                <div className="textGrey flexItem">
                  <img src={CalendarIcon} alt="calendar" className="mright8" />
                  {task.frequency === TASK_FREQUENCY.ONE_OFF ? 'One Off' : 'Ongoing'}
                  &nbsp;·&nbsp;
                  {getTaskFrequency(task)}
                </div>
                <div className="textGrey flexItem">
                  <img src={LocationIcon} alt="location" className="mright8" />
                  {task.type === TASK_TYPE.REMOTE ? 'Remote' : 'Face to Face'}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {started.length === 0 && (
        charity.user_id === _.get(localStorage, 'userID') ?
        <div className="flexCenter">
          <img src={TasksEmptyIcon} alt="Tasks Empty Icon" />
          <div className="subTitle mtop16 mbottom16">Create your first task</div>
          <div className="content mbottom16">Creating the right opportunity will attract the right volunteers.</div>
          <FlatButton label="Create task" onClick={() => history.push({ pathname: '/task/create/overview', state: { charity_id: charity._id } })} />
        </div> :
        <div className="flexCenter">
          <img src={TasksEmptyIcon} alt="Tasks Empty Icon" />
          <div className="subTitle mtop16 mbottom16">There are no tasks at this time</div>
          <div className="content mbottom16">Please check in later.</div>
        </div>
      )}
    </div>
  );
});
