import gql from 'graphql-tag';

export default gql`
  query FetchCharitiesByUserId($userId: ID!) {
    charityByUserId(user_id: $userId) {
      _id
      Tasks {
        _id
        status
        name
        frequency
        oneoff_frequency_config {
          hours_per_volunteer
          start
          end
        }
      }
      name
      registration
      location {
        location
        street
        city
        state
        country
        postcode
        latitude
        longitude
      }
      email
      phone
      charity_users {
        user_id
        role
        user {
          picture
          firstname
          lastname
          email
        }
      }
      website
    }
  }
`;
