import gql from 'graphql-tag';

export default gql`
  query FetchCharityByID($_id: ID) {
    charityById(_id: $_id) {
      _id
      user_id
      status
      name
      registration
      picture
      description
      causes
      location {
        location
        street
        city
        postcode
        state
        country
        latitude
        longitude
      }
      phone
      email
      website
      settings {
        accepted_terms
      }
      Causes {
        _id
        name
      }
      charity_users {
        user_id
        role
        user {
          _id
          picture
          firstname
          lastname
          email
          about
        }
      }
      charity_followers {
        user_id
        user {
          _id
          picture
          firstname
          lastname
          UserMacros {
            _id
            name
            color
          }
        }
      }
      charity_ambassadors
      Events {
        _id
        name
        picture
        charity {
          _id
          charity_users {
            user_id
          }
        }
        event_causes {
          Cause {
            _id
            name
          }
        }
        config {
          end
          volunteers_needed
        }
        EventReviews {
          _id
          hours
          user_id
        }
        confirmed_users {
          user_id
        }
      }
      EventsToReview {
        _id
        picture
        name
        event_causes {
          Cause {
            _id
            name
          }
        }
      }

      Tasks {
        _id
        name
        type
        frequency
        status
        oneoff_frequency_config {
          hours_per_volunteer
        }
        ongoing_frequency_config {
          hours_per_week
        }
        TaskSkills {
          name
          skill_color
          skill_label_color
        }
        created
      }
    }
  }
`;
