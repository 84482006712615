import React from 'react';
import Popover from 'material-ui/Popover';

export default function (props) {
    const defaultAnchorOrigin = { horizontal: 'left', vertical: 'bottom' };
    const defaultTargetOrigin = { horizontal: 'left', vertical: 'top' };
    const anchorOrigin = {
        ...defaultAnchorOrigin,
        ...props.anchorOrigin
    };
    const targetOrigin = {
        ...defaultTargetOrigin,
        ...props.targetOrigin
    };
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            anchorOrigin={anchorOrigin}
            targetOrigin={targetOrigin}
            onRequestClose={props.handleRequestClose}
            className={`popoverComponent ${props.className ? props.className : ''}`}
            useLayerForClickAway={props.useLayerForClickAway}
        >
            {props.children}
        </Popover>
    );
}
