import React, { useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Input, Button, Causes } from '../common';
import { localStorage } from '../../lib/storage';
import { CharityNetworkButton } from './networkButton/view';

// Assets
import Ella from './assets/ella.png';
import RightArrow from './assets/right-arrow.svg';
import PlaceholderImage from './assets/placeholder.png';

// Queries
import searchCharities from '../../queries/charity/searchCharities';
import fetchUserById from '../../queries/user/fetchUserById';

// Styles
import './styles/charitiesStyles.css';
import { CharityList } from './charityList/view';

function Charities({ history }) {
  const userID = localStorage.get('userID');
  const [showCauses, setShowCauses] = useState(false);
  const [search, setSearch] = useState('');
  const [charitiesObj, setCharitiesObject] = useState({ text: '', causes: [] });
  const [selectedCharityID, setSelectedCharityID] = useState('');

  // Mutations

  const handleSearch = () => {
    setCharitiesObject({ ...charitiesObj, text: search });
  };

  const searchCharitiesQuery = useQuery(searchCharities, {
    variables: {
      searchText: _.get(charitiesObj, 'text') || '',
      searchCauses: _.get(charitiesObj, 'causes') || [],
      status: 1,
    },
  });

  const searchCharitiesData = _.get(searchCharitiesQuery, 'data.searchCharities');

  const renderVisitorCharities = charitiesRaw => {
    const charities = _.size(charitiesRaw) > 0 ? charitiesRaw : [];
    return <CharityList charities={charities} />;
  };

  const renderLoggedInCharities = charitiesRaw => {
    const charities = _.size(charitiesRaw) > 0 ? charitiesRaw : [];
    const myNetwork = charities.filter(charity => charity.charity_followers.some(item => item.user_id === userID)).sort((a, b) => (a.user_id === userID ? -1 : 1));
    const browseCharities = charities.filter(charity => !charity.charity_followers.some(item => item.user_id === userID));
    return (
      <>
        {myNetwork.length > 0 ? (
          <div className="myNetwork">
            <p className="sectionTitle">My Network</p>
            <CharityList charities={myNetwork} />
          </div>
        ) : null}
        {browseCharities.length > 0 ? <p className="sectionTitle">Browse</p> : null}
        <CharityList charities={browseCharities} />
      </>
    );
  };

  return (
    <div className="charitiesContainerNew">
      <div className="searchContainer">
        <div className="search">
          <h1>Search charities on Worthy</h1>
          <div className="inputContainer">
            <Input placeholder="Search by charity name" value={search} onChange={val => setSearch(val)} />
            <Button label="Search" onClick={handleSearch} />
          </div>
          <div className="btnContainer">
            <img src={RightArrow} className={`${showCauses ? 'rotate' : ''}`} alt="Right Arrow" />
            <button type="button" onClick={() => setShowCauses(val => !val)}>
              Select causes
            </button>
          </div>
          {showCauses ? (
            <div className="causesContainer">
              <Causes
                onGetNewCausesArray={value => setCharitiesObject({
                  ...charitiesObj,
                  causes: value
                })}
                selectedValues={charitiesObj.causes}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="charityListContainer">
        <div className="charityLists">
          {!userID ? renderVisitorCharities(searchCharitiesData) : null}
          {userID ? renderLoggedInCharities(searchCharitiesData) : null}
        </div>
      </div>
      <div className="joinNetworkSection">
        <div className="background" />
        <div className="content">
          <div>
            <p className="small">A network for charities and volunteers</p>
            <div className="line" />
            <h1 className="mbottom30">“The volunteers in our network are skilled, supportive, and passionate.”</h1>
            <div className="profile">
              <img src={Ella} alt="Ella" />
              <div>
                <p>Ella</p>
                <p>Charity Manager, Operation Smile</p>
              </div>
            </div>
            <Button className="btn_transparent" label="Join Network" onClick={() => history.push({ pathname: '/sign-up' })} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Charities);
