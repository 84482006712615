import React, { useEffect, useState } from 'react';
import CloseIcon from './assets/close.svg';

// Styles
import './styles/notificationStyles.css';

function Notification(props) {
  const [showNotification, setShowNotification] = useState(false);
  const [localContent, setLocalContent] = useState('');

  let timeout = null;

  useEffect(() => {
    if (props.content !== localContent) {
      setShowNotification(true);
      timeout = setTimeout(() => {
        setShowNotification(false);
      }, 11000);
      setLocalContent(props.content);
    }
  }, [props.content]);

  return (
    <div className={`notification  ${props.className ? props.className : ''} ${!showNotification ? 'close' : ''}`}>
      <div className="content">
        <p>{props.content}</p>
        <a
          className="closeAction"
          onClick={() => {
            setShowNotification(false);
            clearTimeout(timeout);
          }}
        >
          <img src={CloseIcon} alt="Close" />
        </a>
      </div>
    </div>
  );
}

export default Notification;
