import React, { useState } from 'react';
import { ModalBase } from 'components/common/ModalBase/view';
import { FlatButton } from 'components/common/FlatButton/view';
import { MessageButton } from 'components/common';
import _ from 'lodash';
import './styles.scss';
import { ApolloProvider, useApolloClient, useMutation, useQuery } from '@apollo/client';
import fetchTaskById from 'queries/task/fetchTaskById';
import moment from 'moment';
import CheckCircleIcon from 'assets/svg/check-circle.svg';
import assignVolunteerToTask from 'mutations/task/assignVolunteerToTask';

export const ModalAssignVolunteer = ({ onClose, taskId, history }) => {
  const apolloClient = useApolloClient();
  const [open, setOpen] = useState(true);
  const [assignVolunteerMutation] = useMutation(assignVolunteerToTask);
  const { data: taskData } = useQuery(fetchTaskById, {
    variables: {
      _id: taskId,
    },
  });

  const assignVolunteer = async userId => {
    assignVolunteerMutation({
      variables: {
        _task_id: taskId,
        _user_id: userId,
      },
      refetchQueries: [
        {
          query: fetchTaskById,
          variables: {
            _id: taskId,
          },
        },
      ],
    });
  };

  if (!taskData) return null;

  const {
    taskByID: { TaskSubmissions },
  } = taskData;

  if (TaskSubmissions.length < 1) {
    return (
      <ModalBase open={open} onClose={onClose} className="assignVolunteerModal">
        <h2 className="fontPoppins textBlue bold textCenter mt0">Event Management</h2>
        <h3 className="fontPoppins textBlack normal textCenter mbottom40">No applications yet!</h3>
        <h3 className="fontPoppins textBlack normal textCenter">
          You can Invite a volunteer to this task by searching for
          <br />
          their name or skillset and inviting them via their profile.
        </h3>
      </ModalBase>
    );
  }

  return (
    <ModalBase open={open} onClose={onClose} className="assignVolunteerModal">
      <h2 className="fontPoppins textBlue bold textCenter mt0">{_.get(taskData, 'taskByID.name')}</h2>
      <h3 className="fontPoppins textBlack normal textCenter mbottom40">You can assign or remove volunteers until they submit their first timesheet.</h3>
      <div className="row">
        <div className="col-xs-4 textBlack bold">Volunteer Name</div>
        <div className="col-xs-3 textBlack bold">Application date</div>
        <div className="col-xs-3 textBlack bold">Status</div>
        <div className="col-xs-2 textBlack bold">Message</div>
      </div>
      {TaskSubmissions.map(({ _id, User, created, status }) => (
        <div className="row" key={_id}>
          <div className="col-xs-4 textBlack flexRow flexStart">
            <img className="volunteerProfile" alt="volunteer profile" src={User.picture} />
            <a href={`/profile/${User._id}`}>
              {User.firstname} {User.lastname}
            </a>
          </div>
          <div className="col-xs-3 textBlack">{moment(created).format('DD/MM/YYYY')}</div>
          <div className="col-xs-3">
            {status === 1 ? (
              <FlatButton label="Assign to task" filled onClick={() => assignVolunteer(User._id)} />
            ) : (
              status > 2 && (
                <div className="bold textGreen flexRow alignCenter">
                  <img src={CheckCircleIcon} alt="Assigned Icon" />
                  &nbsp; Assigned
                </div>
              )
            )}
          </div>
          <div className="col-xs-2">
            <ApolloProvider client={apolloClient}>
              <MessageButton users={[User]} isDark history={history} />
            </ApolloProvider>
          </div>
        </div>
      ))}
    </ModalBase>
  );
};
