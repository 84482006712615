import { http } from './http';

const location = {
    async getLocation() {
        if (navigator.geolocation) {
            const position = await navigator.geolocation.getCurrentPosition();
            if (!position) {
                throw Error('User didnt allow');
            }
            return {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            };
        }
        throw Error('Geolocation is not supported by this browser.');
    },
    getAddressFromCoords(coords) {
        return http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=AIzaSyAnbCMH9Im11bw45_Nk3wCVyJk-IONW2JA`, {});
    },
    parseAddressComponent(address_components, field, key, secondKey) {
        let value = address_components.filter((component) => {
            return component.types.includes(key);
        });
        // Searches by the second key if nothing was found with the main key and a second key is passed
        if (value.length === 0 && secondKey) {
            value = address_components.filter((component) => {
                return component.types.includes(secondKey);
            });
        }
        if (value.length > 0) return value[0][field];

        return null;
    },
    getRoughLocationByIP() {
        return http.get('https://api.ipdata.co/?api-key=98687b7e0c35b8749c094e6fda582ec4fd769f4c27044b21a851b257');
    },
    getTimezoneFromCoordinates(latitude, longitude) {
        return http.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Math.round(Date.now() / 1000)}&key=AIzaSyAnbCMH9Im11bw45_Nk3wCVyJk-IONW2JA`);
    },
};

export { location };
