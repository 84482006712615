import React from 'react';
import Snackbar from 'material-ui/Snackbar';

const Snack = (props) => {
    return (
        <Snackbar
            open={props.open}
            message={props.message ? props.message : ''}
            autoHideDuration={3000}
            onRequestClose={() => props.snackbarHandleRequestClose()}
        />
    );
};

export default Snack;
