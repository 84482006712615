import moment from 'moment';

export const TASK_TIMESHEETS_STATUS = {
  DRAFT: 0,
  APPROVED: 1,
};

export const getTimeEntry = (from, to) => {
  const fromEntry = `${moment(from).format('DD/MM/YYYY')} | ${moment(from).format('hh:mm a')}`;
  const toEntry = `${moment(to).format('DD/MM/YYYY')} | ${moment(to).format('hh:mm a')}`;
  return `${fromEntry} - ${toEntry}`;
};

export const getTotalTime = (from, to) => {
  const diff = moment(to).diff(moment(from), 'millesecond');
  const duration = moment.duration(diff, 'milliseconds');
  const hours = Math.floor(duration.asHours());
  const mins = duration.asMinutes() - hours * 60 < 1 ? Math.floor(duration.asMinutes() - hours * 60) : Math.ceil(duration.asMinutes() - hours * 60);
  const formated = `${getZeroFormat(hours)} ${':'} ${getZeroFormat(mins)} ${': 00'}`;
  return formated;
};

export const getZeroFormat = val => {
  if (val < 10) {
    return `${0}${val}`;
  }
  return val;
};
