import gql from 'graphql-tag';

export default gql`
    query fetchCauses {
        causes {
            _id
            name
            qtdEvents
        }
    }
`;
