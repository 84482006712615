import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import AutoComplete from '../../AutoComplete/view';
import { location } from '../../../../lib/location';

// Queries
import fetchLocationsBySuburbOrPostcode from '../../../../queries/location/fetchLocationBySuburbOrPostCode';

class LocationAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationDataSource: [],
      location: '',
      loadingLocation: false,
    };

    this.debouncedLocationBySuburbOrPostcode = this.debouncedLocationBySuburbOrPostcode.bind(this);
    this.debounce = _.debounce(this.debouncedLocationBySuburbOrPostcode, 250);
  }

  componentWillMount() {
    if (this.props.value) {
      this.setState({
        location: this.props.value,
      });
    } else {
      this.setState({ loadingLocation: true });
      location.getRoughLocationByIP().then(({ data }) => {
        this.setState({
          location: data.city,
          loadingLocation: false,
        });
      });
    }
  }

  debouncedLocationBySuburbOrPostcode() {
    this.props.locationBySuburbOrPostcode
      .refetch({ location: this.state.location.toLowerCase(), state: '' })
      .then(({ data }) => {
        // It should check the results and build the proper dataSource array to be supplied to the AutoComplete component
        const newArray = data.getLocationBySuburbOrPostcode.map(result => {
          return { ...result, text: `${result.location}, ${result.state}`, value: `${result.location}, ${result.state}` };
        });
        this.setState({
          locationDataSource: newArray,
        });
      })
      .catch(() => {
        this.setState({
          locationDataSource: [],
        });
      });
  }

  render() {
    return (
      <AutoComplete
        name="location"
        placeholder="Start typing your location"
        dataSource={this.state.locationDataSource}
        value={this.state.location}
        loading={this.state.loadingLocation}
        fullWidth
        onClick={() => {
          this.setState({ location: '' });
          if (this.props.onClick) this.props.onClick();
        }}
        dataSourceConfig={{
          text: 'text',
          value: 'value',
          state: 'value',
          longitude: 'longitude',
          latitude: 'latitude',
        }}
        className="locationFilter"
        onUpdateInput={value => {
          this.setState({ location: value });
          this.debounce();
        }}
        onItemSelected={value => {
          this.props.onItemSelected(value);
        }}
      />
    );
  }
}

const LocationsBySuburbOrPostcodeContainer = graphql(fetchLocationsBySuburbOrPostcode, {
  name: 'locationBySuburbOrPostcode',
  options: () => ({
    variables: { location: '' },
  }),
});

export default LocationsBySuburbOrPostcodeContainer(LocationAutocomplete);
