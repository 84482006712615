import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { ModalBase } from '../ModalBase/view';

import './styles.scss';
import { MembersList } from '../MembersList/view';

function MembersModal({ onClose, users, title, history }) {
  return (
    <ModalBase className="membersModal" onClose={onClose}>
      <h3 className="mtop0 mbottom16 fontPoppins textBlack">{title}</h3>
      <div className="mbottom16">{users.length} Members</div>
      <div className="divider mbottom16" />
      <div className="membersContainer">
        <MembersList users={users} onClick={user => history.push(`/profile/${user._id}`)} />
      </div>
    </ModalBase>
  );
}

export default withRouter(MembersModal);
