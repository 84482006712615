import gql from 'graphql-tag';

export default gql`
  query FetchGroupMessagesByUser($_user_id: ID!) {
    groupMessagesByUser(_user_id: $_user_id) {
      _id
      name
      Users {
        _id
        firstname
        lastname
        picture
        UserMacros {
          _id
          name
          color
        }
      }
      Messages {
        _id
        seen
      }
    }
  }
`;
