import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { localStorage } from '../../lib/storage';
import FinishOnboarding from './assets/finish-onboarding.svg';
import Location from './assets/location.svg';
import { Button, GoogleInput, LocationAutocomplete } from '../common';

// Mutations
import updateUserMutation from '../../mutations/user/updateUser';
import followCharityMutation from '../../mutations/charity/followCharity';

function OnboardingFinish(props) {
  const [city, setCity] = useState(localStorage.get('city'));
  const [state, setState] = useState(localStorage.get('state'));
  const { firstname, lastname, causes, skills, promotion, charities } = props.location.state.data;
  const [locationData, setLocationData] = useState(null);
  const [locationText, setLocationText] = useState('');

  // Mutations
  const [updateUser] = useMutation(updateUserMutation);
  const [followCharity] = useMutation(followCharityMutation);

  const onLocationSelected = value => {
    const locationInfo = {
      street: value.street,
      city: value.city,
      postcode: Number(value.postcode),
      state: value.state,
      country: value.country,
      latitude: value.latitude,
      longitude: value.longitude,
      location: value.location,
    };
    onSaveLocation(locationInfo);
    setLocationData(locationInfo);
  };

  const doUpdateUser = () => {
    updateUser({
      variables: {
        id: localStorage.get('userID'),
        user: {
          firstname,
          lastname,
          interests: causes,
          skills,
          settings: {
            notifications: {
              promotional: promotion,
            },
          },
          type: 1, // Volunteer
          location: {
            country: 'US',
            state: localStorage.get('state'),
            city: localStorage.get('city'),
          },
        },
      },
    });

    for (let i = 0; i < charities.length; i += 1) {
      followCharity({
        variables: {
          id: charities[i],
          user_id: localStorage.get('userID'),
        },
      });
    }
  };

  useEffect(() => {
    setCity(_.get(locationData, 'city', ''));
    setState(_.get(locationData, 'state', ''));
  }, [locationData]);

  useEffect(() => {
    doUpdateUser();
  }, []);

  const onSaveLocation = location => {
    // Save city
    localStorage.save('country', 'US');
    // Save city
    localStorage.save('city', _.get(location, 'location'));
    setCity(_.get(location, 'location'));
    // Save state
    localStorage.save('state', _.get(location, 'state'));
    setState(_.get(location, 'state'));

    updateUser({
      variables: {
        id: localStorage.get('userID'),
        user: {
          location,
        },
      },
    });
  };

  return (
    <div className="content">
      <img src={FinishOnboarding} alt="Icon of a person" className="onboardingFinishIcon" />
      <h1 style={{ maxWidth: 600 }}>
        Thank you <span>{_.get(props, 'location.state.data.firstName')}</span> for creating your profile!
      </h1>
      <p>Let’s help charities find you more easily! Confirm or enter your location below.</p>
      <div className="location mbottom16">
        <img src={Location} alt="Location" />
        <p>
          {city}, {state}
        </p>
      </div>
      <div className="newLocationContainer">
        {/* <LocationAutocomplete
          onItemSelected={value => {
            onSaveLocation({
              state: _.get(value, 'state'),
              latitude: _.get(value, 'latitude'),
              longitude: _.get(value, 'longitude'),
              postcode: _.get(value, 'postcode'),
              location: _.get(value, 'location'),
            });
          }}
        /> */}
        <GoogleInput onNewPlace={onLocationSelected} value={locationText} onValueChange={value => setLocationText(value)} />
      </div>
      <div className="hLine" />
      <p style={{ fontWeight: 'bold' }} className="mbottom24">
        Looking good! Take a look at your profile below
      </p>
      <Button
        label="View profile"
        type="button"
        labelStyle={{
          paddingLeft: 30,
          paddingRight: 30,
        }}
        href={`/profile/${localStorage.get('userID')}`}
        className="mbottom24"
      />
      <Button label="Browse tasks" borderedButton href="/task/search" />
    </div>
  );
}

export default withRouter(OnboardingFinish);
