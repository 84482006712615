import gql from 'graphql-tag';

export default gql`
  query SearchTasks(
    $keyword: String!,
    $causes: [ID], 
  )  {
    searchTasks(keyword:$keyword, causes: $causes) {
      _id
      _user_id
      name
      created
      description
      frequency
      type
      oneoff_frequency_config {
        hours_per_volunteer
      }
      ongoing_frequency_config {
        hours_per_week
      }
      location {
        state
      }
      TaskSkills {
        name
        _id
        skill_color
        skill_label_color
        Macro {
          _id
          name
          color
        }
      }
      Charity { 
        _id
        name
        picture
        charity_followers {
          user_id
        }
        Causes {
          _id
          name
        }
        charity_users {
          role
          user {
            _id
            firstname
            lastname
            picture
          }
        }
      }
    }
  }
`;
