import React, { useState } from 'react';
import Select from 'react-select';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { Button, Chips } from '../../../common';
import { SectionHeading, ButtonContainer, CustomSelectStyles } from '../../common/Elements';

// Queries
import fetchSkills from '../../../../queries/skills/fetchSkills';

const SkillsStep = ({ history, setFormData }) => {
  const [primarySkill, setPrimarySkill] = useState('');
  const [macroID, setMacroID] = useState('');
  const [subSkills, setSubSkills] = useState([]);

  const { loading, error, data } = useQuery(fetchSkills);

  if (loading) {
    return null;
  } if (error) {
    console.error(error);
  }

  const normaliseSkills = skills => {
    return skills.map(skill => ({
      _id: skill._id,
      label: skill.name,
      selected: subSkills.find(item => item === skill._id),
      bgColor: _.get(skill, 'skill_color'),
      labelColor: _.get(skill, 'skill_label_color'),
    }));
  };

  const skillsData = _.get(data, 'skills');

  const getRootSkills = () => {
    const parentSkills = _.uniqBy(skillsData, 'Macro.name');
    return parentSkills.map(skill => ({
      label: skill.Macro.name,
      value: _.kebabCase(skill.Macro.name),
      id: skill._macro_id,
      bgColor: skill.skill_color,
    }));
  };

  const handleRootSkillSelection = selected => {
    setPrimarySkill(selected.label);
    setMacroID(selected.id);
    setSubSkills([]);
  };

  const getChipsValues = () => {
    const groupedSkills = _.groupBy(skillsData, 'Macro.name');
    const relevantSkills = groupedSkills[primarySkill];
    return normaliseSkills(relevantSkills);
  };

  const handleClickSkill = skill => {
    const newSubSkills = [...subSkills];
    if (newSubSkills.includes(skill._id)) {
      _.pull(newSubSkills, skill._id);
    } else {
      newSubSkills.push(skill._id);
    }

    setSubSkills(newSubSkills);
  };

  // Make the subskills easier to parse
  const retransformSubSkills = () => {
    return subSkills.map(skill => {
      const subskillObject = getChipsValues().find(chip => chip._id === skill);

      return {
        id: skill,
        name: subskillObject.label,
        bgColor: subskillObject.bgColor,
      };
    });
  };

  return (
    <>
      <SectionHeading>Please choose your skill category</SectionHeading>
      <Select
        name="requiredHours"
        styles={CustomSelectStyles}
        placeholder="Please select a category"
        options={getRootSkills()}
        onChange={handleRootSkillSelection}
      />

      {primarySkill &&
        <div className="mtop40">
          <SectionHeading>Select any specific skills you might need</SectionHeading>
          <Chips values={getChipsValues()} onClick={handleClickSkill} />
        </div>
      }

      <ButtonContainer largeMargin>
        <Button
          label="Back"
          borderedButton
          onClick={() => {
            history.push({ pathname: '/task/create/details' });
          }}
        />
        <Button
          disabled={primarySkill === '' || (subSkills.length === 0)}
          label="Continue"
          onClick={() => {
            setFormData(prevState => ({
              ...prevState,
              primarySkill: getRootSkills().find(skill => skill.id === macroID),
              subSkills: retransformSubSkills(),
            }));
            history.push({ pathname: '/task/create/volunteers' });
          }}
        />
      </ButtonContainer>
    </>
  );
  };

export default SkillsStep;
