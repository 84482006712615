import React from 'react';
import { withRouter } from 'react-router';
import Button from '../../Button/view';

// Assets
import signUpImage from './assets/signUpBanner.svg';

// Styles
import './styles/bannersStyles.css';

const SignUpBanner = (props) => {
    return (
        <div className={`banner banner-signUp ${props.className ? props.className : ''}`}>
            <div className="internalContainerStyle">
                <div className="imgContainer">
                    <img src={signUpImage} alt="Illustration" />
                </div>
                <div className="content">
                    <h1>Sign up to Worthy to get started</h1>
                    <p>Worthy helps you find the charities and volunteering opportunities that suit your preferences</p>
                    <div className="buttonsContainer">
                        <Button
                            label="Sign Up Now"
                            bgColor="#fff"
                            labelColor="#2723EB"
                            href="/sign-up"
                            className="btnSignUp"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(SignUpBanner);
