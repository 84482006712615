import React, { Component } from 'react';
import _ from 'lodash';
import { Query } from '@apollo/client/react/components';
import Chips from '../../Chips/view';
import LoadingOverlay from '../../LoadingOverlay/view';
import { update } from '../../../../lib/utils';

// Queries
import fetchCauses from '../../../../queries/causes/fetchCauses';

/*
    Example of params
    <Causes
        onGetNewCausesArray
    />
*/
class Causes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCauses: [],
    };

    this.allCauses = [];

    this.normaliseCausesArray = this.normaliseCausesArray.bind(this);
    this.onClickCause = this.onClickCause.bind(this);
    this.finishSettingState = this.finishSettingState.bind(this);
  }

  componentWillMount() {
    if (_.size(this.props.selectedValues) > 0) {
      this.setState({
        selectedCauses: this.props.selectedValues,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (_.size(newProps.selectedValues) > 0 && this.state.selectedCauses.length === 0) {
      this.setState({
        selectedCauses: update(this.state.selectedCauses, {
          $push: newProps.selectedValues.map(item => item.cause_id),
        }),
      });
    }
    if (newProps.selectedValues.length === 0 && this.state.selectedCauses.length > 0) {
      this.setState({
        selectedCauses: []
      });
    }
  }

  onClickCause(chip) {
    // If cause already exists in the array it should delete it
    // Otherwise it should add it
    if (this.state.selectedCauses.filter(item => item === chip._id).length > 0) {
      if (chip._id === '9999') {
        this.setState(
          {
            selectedCauses: update(this.state.selectedCauses, {
              $set: [],
            }),
          },
          this.finishSettingState,
        );
      } else {
        this.setState(
          {
            selectedCauses: update(this.state.selectedCauses, {
              $splice: [[this.state.selectedCauses.findIndex(item => item === chip._id), 1]],
            }),
          },
          this.finishSettingState,
        );
      }
    } else if (this.state.selectedCauses.length < (this.props.max || 999)) {
      if (chip._id === '9999') {
        this.setState(
          {
            selectedCauses: update(this.state.selectedCauses, {
              $push: this.allCauses.concat([chip._id]),
            }),
          },
          this.finishSettingState,
        );
      } else {
        this.setState(
          {
            selectedCauses: update(this.state.selectedCauses, {
              $push: [chip._id],
            }),
          },
          this.finishSettingState,
        );
      }
    }
  }

  finishSettingState() {
    if (this.props.onGetNewCausesArray) {
      this.props.onGetNewCausesArray(this.state.selectedCauses);
    }
  }

  normaliseCausesArray(causes) {
    let causesArray = [];
    if (_.size(causes) > 0) {
      causesArray = causes.map(cause => {
        return {
          _id: cause._id,
          label: cause.name,
          selected: this.state.selectedCauses.filter(item => item === cause._id).length > 0,
        };
      });
    }
    return causesArray;
  }

  render() {
    return (
      <Query query={fetchCauses}>
        {result => {
          if (result.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
          let causesResult = [];
          if (this.props.showAll) {
            causesResult.push({
              _id: '9999',
              name: 'All',
            });
            result.data.causes.forEach(cause => causesResult.push(cause));
          } else {
            causesResult = result.data.causes;
          }
          this.allCauses = result.data.causes.map(cause => cause._id);
          return (
            <Chips
              onClick={this.onClickCause}
              containerStyle={this.props.containerClass ? this.props.containerClass : ''}
              values={this.normaliseCausesArray(causesResult)}
              isRounded
            />
          );
        }}
      </Query>
    );
  }
}

export default Causes;
