import React from 'react';
import styled from 'styled-components';
import HintIcon from './assets/information.svg';

const StepCardContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;

  @media (min-width: 1169px) {
    width: 1030px;
    border: 1px solid #e7e7e7;
    border-radius: 16px;
  }
`;

const StepCardSidebar = styled.div`
  display: none;

  @media (min-width: 1169px) {
    display: flex;
    flex-direction: column;
    width: 350px;
    background-color: #3042d4;
    color: white;
    border-radius: 16px;
    padding: 40px 30px 30px;
  }
`;

const StepCardSidebarHeading = styled.h3`
  font-size: 24px;
  margin: 0;
`;

const StepCardHint = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: auto;

  img {
    margin-right: 10px;
  }

  span {
    font-size: 13px;
  }
`;

const StepCardContent = styled.div`
  flex: 1 0 100%;
  padding: 0;

  @media (min-width: 1169px) {
    flex: 1 0 680px;
    padding: 70px 50px;
  }
`;

const StepCard = ({ sidebarHeading, sidebarContent, sidebarHint, mainContent }) => (
  <StepCardContainer>
    <StepCardSidebar>
      <StepCardSidebarHeading>{sidebarHeading}</StepCardSidebarHeading>
      {typeof sidebarContent === 'string' ? <p>{sidebarContent}</p> : sidebarContent}

      <StepCardHint>
        {!!sidebarHint && <img src={HintIcon} alt="Information" />}
        <span>{sidebarHint}</span>
      </StepCardHint>
    </StepCardSidebar>
    <StepCardContent>{mainContent}</StepCardContent>
  </StepCardContainer>
);

export default StepCard;
