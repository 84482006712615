import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import IconButton from 'material-ui/IconButton';
import AddCircleOutline from 'material-ui/svg-icons/content/add-circle-outline';
import { withRouter } from 'react-router';
import _ from 'lodash';
import {
    Button,
    LoadingOverlay,
    NumberInput,
    Toggle,
    Input,
    Chips
} from '../../common';
import { update } from '../../../lib/utils';

// Queries
import fetchEventById from '../../../queries/event/fetchEventById';

// Mutations
import updateEventMutation from '../../../mutations/event/updateEvent';

class CreateEventVolunteers extends Component {
    constructor() {
        super();

        this.state = {
            event: {
                type: '1',
                config: {
                    skills_needed: [],
                    volunteers_needed: 0
                }
            },
            loading: false,
            specificSkills: false,
            skillToAdd: '',
            error: ''
        };

        this.onNextClicked = this.onNextClicked.bind(this);
    }

    componentWillReceiveProps(newProps) {
        const { eventByID } = newProps.eventData;
        if (_.get(eventByID, 'type') > 0) {
            this.setState({
                event: {
                    ...this.state.event,
                    type: eventByID.type.toString(),
                    config: {
                        ...this.state.event.config,
                        skills_needed: eventByID.config.skills_needed,
                        volunteers_needed: eventByID.config.volunteers_needed
                    }
                },
                specificSkills: eventByID.config.skills_needed.length > 0
            });
        }
    }

    onUpdateEventInput(value, fieldName) {
        this.setState({
            event: {
                ...this.state.event,
                [fieldName]: value
            }
        });
    }

    onUpdateEventConfigInput(value, fieldName) {
        this.setState({
            event: {
                ...this.state.event,
                config: {
                    ...this.state.event.config,
                    [fieldName]: value
                }
            }
        });
    }

    onNextClicked() {
        this.setState({
            loading: true
        });

        // Update event
        this.props.updateEvent(this.props.location.state.eventId, this.state.event)
            .then(() => {
                this.setState({
                    loading: false
                });
                this.props.history.push({ pathname: '/event/register/description', state: { ...this.props.location.state } });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error
                });
            });
    }

    normaliseSkillsArray(skills) {
        return skills.map((skill) => {
            return {
                _id: skill,
                label: skill
            };
        });
    }

    renderRadioButtonOptionTitle(title, subtitle) {
        return (
            <div className="radioTitle">
                <p className="bold title">{title}</p>
                <p>{subtitle}</p>
            </div>
        );
    }

    render() {
        if (!_.get(this.props.location, 'state.eventId')) {
            // If there's not Charity id to assign to it should redirect to the homepage
            this.props.history.replace({ pathname: '/' });
        }
        return (
            <div className="childContainer volunteersContainer">
                {this.state.loading &&
                    <LoadingOverlay
                        style={{ opacity: 0.8 }}
                    />
                }
                {this.state.error &&
                    <p className="error">{this.state.error}</p>
                }
                <div className="contentContainer">
                    {/* <div className="typeOfVolunteersContainer">
                        <h3>Type of volunteers</h3>
                        <RadioButton
                            name="typeOfVolunteers"
                            className="mtop20"
                            onChange={value => this.onUpdateEventInput(value, 'type')}
                            value={this.state.event.type}
                            values={[
                                {
                                    label: this.renderRadioButtonOptionTitle('Anyone is free to come', 'Your event will be open for any volunteer to attend'),
                                    value: '1'
                                },
                                {
                                    label: this.renderRadioButtonOptionTitle('I want to review applicants', 'Review volunteer applicants before accepting them'),
                                    value: '2'
                                },
                            ]}
                        />
                    </div> */}
                    <div className="numberOfVolunteersContainer">
                        <h3>Number of volunteers needed</h3>
                        <NumberInput
                            value={this.state.event.config.volunteers_needed}
                            onChange={value => this.onUpdateEventConfigInput(value, 'volunteers_needed')}
                        />
                    </div>
                    <div className="specificSkillsContainer">
                        <Toggle
                            label={(<h3>Specific skills required</h3>)}
                            value={this.state.specificSkills}
                            onChange={value => this.setState({ specificSkills: value })}
                        />
                        {this.state.specificSkills &&
                            <div>
                                <div className="specificSkills">
                                    <Input
                                        name="addSpecificSkills"
                                        placeholder="E.g Accounting..."
                                        type="text"
                                        containerStyle="skillsInput"
                                        value={this.state.skillToAdd}
                                        fullWidth={false}
                                        onChange={value => this.setState({ skillToAdd: value })}
                                    />
                                    <IconButton
                                        className="addSkill"
                                        onClick={() => {
                                            this.setState({
                                                event: {
                                                    ...this.state.event,
                                                    config: {
                                                        ...this.state.event.config,
                                                        skills_needed: update(this.state.event.config.skills_needed, {
                                                            $push: [this.state.skillToAdd]
                                                        })
                                                    }
                                                },
                                                skillToAdd: ''
                                            });
                                        }}
                                    >
                                        <AddCircleOutline
                                            color="#20C1C9"
                                        />
                                    </IconButton>
                                </div>
                                <div className="skillsAddedContainer">
                                    <Chips
                                        containerStyle="mtop20"
                                        values={this.normaliseSkillsArray(this.state.event.config.skills_needed)}
                                        onDelete={(value) => {
                                            this.setState({
                                                event: {
                                                    ...this.state.event,
                                                    config: {
                                                        ...this.state.event.config,
                                                        skills_needed: update(this.state.event.config.skills_needed, {
                                                            $splice: [[this.state.event.config.skills_needed.findIndex(item => item === value._id), 1]]
                                                        })
                                                    }
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="buttonsContainer col-xs-12 p0 mtop60 textCenter">
                    <Button
                        label="Back"
                        labelColor="#212B35"
                        bgColor="#fff"
                        onClick={() => this.props.history.goBack()}
                        className="btnDefault btnWhite"
                    />
                    <Button
                        label="Next"
                        onClick={this.onNextClicked}
                        className="btnDefault btnBlueSolid mleft15"
                        disabled={!(this.state.event.config.volunteers_needed >= 0)}
                    />
                </div>
            </div>
        );
    }
}

const eventByIDContainer = graphql(fetchEventById, {
    name: 'eventData',
    options: ({ location }) => {
        return {
            variables: { id: _.get(location, 'state.eventId') },
        };
    },
});

const updateEventMutationContainer = graphql(updateEventMutation, {
    props: ({ mutate }) => ({
        updateEvent: (id, event) => mutate({
            variables: { id, event }
        }),
    }),
});

export default withRouter(_.flowRight(
    updateEventMutationContainer,
    eventByIDContainer
)(CreateEventVolunteers));
