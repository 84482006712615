import React from 'react';
import Checkbox from 'material-ui/Checkbox';

import './styles/checkboxStyles.css';

const CheckBox = (props) => {
    return (
        <Checkbox
            label={props.label}
            checked={props.checked}
            onCheck={() => props.onCheck(props.id, props.checked, props.label)}
            style={props.style && props.style}
            className={props.className}
            labelStyle={props.labelStyle && props.labelStyle}
            labelPosition={props.labelPosition && props.labelPosition}
            iconStyle={props.iconStyle && props.iconStyle}
        />
    );
};

export default CheckBox;
