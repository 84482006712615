import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import Modal from '../../Modal/view';
import Input from '../../Input';

// Assets
import FacebookIcon from './assets/facebook.png';
import TwitterIcon from './assets/twitter.png';
import LinkedInIcon from './assets/linkedIn.png';

// Styles
import './styles/shareContainerStyles.css';

const shareContainer = props => {
  return (
    <Modal className="shareContainer" open={props.open} onClose={props.onClose}>
      <a className="closeButton" onClick={props.onClose}>
        Close
      </a>
      <div className="content">
        <h2>Share this event with friends</h2>
        <div className="socialMediaContainer">
          <FacebookShareButton url={props.url}>
            <img src={FacebookIcon} alt="Facebook share" />
          </FacebookShareButton>
          <TwitterShareButton url={props.url}>
            <img src={TwitterIcon} alt="Twitter share" />
          </TwitterShareButton>
          <LinkedinShareButton url={props.url}>
            <img src={LinkedInIcon} alt="LinkedIn share" />
          </LinkedinShareButton>
        </div>
        <Input value={props.url} />
      </div>
    </Modal>
  );
};

export default shareContainer;
