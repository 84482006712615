import _ from 'lodash';
import { localStorage } from './storage';

const messageUtils = {
  hasSeenMessage(seen, _user_id) {
    // Function to test whether the user has seen the message already
    return _.includes(seen, _user_id);
  },
};

export { messageUtils };
