import React from 'react';
import Switch from 'react-switch';

import './styles/component.scss';

const SwtichSettingForm = props => {
  return (
    <div className="switchSettingContainer">
      <div className="switchSettingForm">
        <div className="descriptionContainer">
          <p className="title">{props.title}</p>
          <p className="detail">{props.detail}</p>
        </div>
        <div className="switchComponent">
          <span className="label">{props.switchType}</span>
          <Switch
            checked={props.checked ?? false}
            onChange={props.onChange}
            onColor={props.switchStyle.onColor ?? '#3042D4'}
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={props.switchStyle.handleDiameter ?? 30}
            height={props.switchStyle.height ?? 20}
            width={props.switchStyle.width ?? 48}
            className="react-switch"
            id={props.id}
          />
        </div>
      </div>
      {props.underlined && <hr />}
    </div>
  );
};

export default SwtichSettingForm;
