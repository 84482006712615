import React, { Component } from 'react';
import { Stepper } from '../../common';
import { utils } from '../../../lib/utils';

// External Containers
import DetailsContainer from './viewDetails';
import VolunteersContainer from './viewVolunteers';
import DescriptionContainer from './viewDescription';
import FAQsContainer from './viewFaqs';

// Styles
import './styles/createEventStyles.css';

class CreateEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: ''
        };

        this.renderPageDescription = this.renderPageDescription.bind(this);
    }

    renderPageDescription() {
        const { step } = this.props.match.params;
        switch (step) {
            case 'details':
                return 'When and where will your event be taking place?';
            case 'volunteers':
                return 'Are you looking for a specific number or type of volunteering help? Or is your event open to anyone?';
            case 'description':
                return "Now that you've got the important details sorted, give your event a description and some imagery to attract volunteers";
            case 'faqs':
                return 'Here are some standard event FAQs, read through, edit, delete, or add your own. Event FAQs are optional';
            default:
                return 'When and where will your event be taking place?';
        }
    }

    render() {
        const { step } = this.props.match.params;
        const { width } = utils.getScreenSize();

        return (
            <div className="createEventContainer col-xs-12">
                <div className="defaultPageContainer">
                    {this.state.error && (
                        <div className="errorMessage">
                            <p className="error">{this.state.error}</p>
                        </div>
                    )}
                    <h1 className="xlarge">Create Event</h1>
                    <p>{this.renderPageDescription()}</p>
                    <Stepper
                        className="eventStepper"
                        orientation="horizontal"
                        values={[
                            {
                                index: 0,
                                label: (width > 425) ? 'Event Details' : '',
                                link: '/event/register/details'
                            },
                            {
                                index: 1,
                                label: (width > 425) ? 'Volunteers' : '',
                                link: '/event/register/volunteers'
                            },
                            {
                                index: 2,
                                label: (width > 425) ? 'Event Description' : '',
                                link: '/event/register/description'
                            },
                            {
                                index: 3,
                                label: (width > 425) ? 'FAQs' : '',
                                link: '/event/register/faqs'
                            }
                        ]}
                    />
                    {step === 'details' &&
                        <DetailsContainer />
                    }
                    {step === 'volunteers' &&
                        <VolunteersContainer />
                    }
                    {step === 'description' &&
                        <DescriptionContainer />
                    }
                    {step === 'faqs' &&
                        <FAQsContainer />
                    }
                </div>
            </div>
        );
    }
}

export default CreateEvent;
