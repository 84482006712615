import React from 'react';
import ReactDOM from 'react-dom';
import createHistory from 'history/createBrowserHistory';
import { InMemoryCache, ApolloClient, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { localStorage } from './lib/storage';
import { globalVariables } from './lib/globalVariables';
import Routes from './routes';

// Styles
import './styles/bootstrap.min.css';
import './styles/styles.css';

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();

const httpLink = createHttpLink({
  uri: globalVariables.getEnvironmentVariables().graphqlURI,
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: localStorage.get('access_token') || null,
      Accept: 'application/json'
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: 'same-origin',
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Routes history={history} />
    </ApolloProvider>,
    document.getElementById('root')
);
