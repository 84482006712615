import gql from 'graphql-tag';

export default gql`
  mutation inviteToJoinCharity($joinCharity: JoinCharityInput!) {
    inviteToJoinCharity(joinCharity: $joinCharity) {
      _id
      request_id
      email
      role
    }
  }
`;
