import React from 'react';

const InPerson = () => (
  <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.312 0C9.04696 0 5.91573 1.29676 3.6052 3.6052C1.29672 5.91546 0 9.04705 0 12.312C0 20.7247 11.1131 27.7693 11.5869 28.0616L12.3118 28.5176L13.0367 28.0616C13.5106 27.7695 24.6236 20.7247 24.6236 12.312C24.6236 9.04696 23.3268 5.91573 21.0184 3.6052C18.7082 1.29672 15.5766 0 12.3116 0H12.312ZM12.312 25.2581C9.96434 23.6247 2.73607 18.1172 2.73607 12.3119C2.73607 8.89015 4.56183 5.73005 7.52403 4.01824C10.4862 2.30825 14.1378 2.30825 17.0999 4.01824C20.0621 5.73 21.8879 8.8901 21.8879 12.3119C21.8879 18.1167 14.6595 23.6247 12.312 25.2581Z" fill="currentColor" />
    <path d="M12.3117 7.29501C10.2829 7.29501 8.45358 8.51695 7.67698 10.3908C6.90037 12.2665 7.32964 14.4236 8.7653 15.8573C10.1992 17.293 12.3563 17.7222 14.2318 16.9456C16.1057 16.169 17.3276 14.3397 17.3276 12.3109C17.3276 9.54107 15.0814 7.29492 12.3116 7.29492L12.3117 7.29501ZM12.3117 14.591C11.3891 14.591 10.559 14.0352 10.2045 13.1838C9.85183 12.3324 10.0478 11.3509 10.6997 10.699C11.3516 10.047 12.3331 9.8511 13.1845 10.2038C14.0359 10.5583 14.5917 11.3884 14.5917 12.311C14.5917 12.9148 14.3512 13.4956 13.9237 13.9231C13.4962 14.3505 12.9156 14.591 12.3117 14.591L12.3117 14.591Z" fill="currentColor" />
  </svg>
);

export default InPerson;
