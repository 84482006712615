import { Dialog } from 'material-ui';
import React, { useEffect, useState } from 'react';
import CloseIcon from 'assets/svg/close.svg';
import { IconButton } from '../IconButton/view';
import './styles.scss';

export const ModalBase = ({ onClose, className, children, open = true }) => {
  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(onClose, 1000);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} className={className} paperClassName="modalPaper" bodyClassName="modalBody" onRequestClose={() => setOpen(false)} autoScrollBodyContent>
      {children}
      <IconButton
        icon={CloseIcon}
        className="modalClose"
        onClick={() => setOpen(false)}
      />
    </Dialog>
  );
};
