/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import { ModalBase } from 'components/common/ModalBase/view';
import _ from 'lodash';
import './styles.scss';
import { LoadingOverlay } from 'components/common';
import { FlatButton } from 'components/common/FlatButton/view';
import createTaskReview from 'mutations/task/createTaskReview';
import { useMutation, useQuery } from '@apollo/client';
import fetchTasksByCharityId from 'queries/task/fetchTasksByCharityId';
import Tooltip from 'components/common/Tooltip/view';
import { Feedback } from './feedback';

export const ModalLeaveFeedback = ({ taskId, taskName, taskSubmissions, charityId, onClose }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [createTaskReviewMutation] = useMutation(createTaskReview);
  const createReview = useCallback(async () => {
    setLoading(true);
    await createTaskReviewMutation({
      variables: {
        taskReview: reviewData,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: fetchTasksByCharityId,
          variables: {
            _charity_id: charityId,
          },
          skip: false,
        },
      ],
    });
    setLoading(false);
    onClose();
  }, [reviewData, charityId, onClose]);

  useEffect(() => {
    const data = [];
    taskSubmissions.map(taskSubmission => {
      data.push({
        _task_id: taskId,
        _user_id: _.get(taskSubmission, 'User._id'),
        rating: _.get(taskSubmission, 'TaskReview.rating') ?? 0,
        message: _.get(taskSubmission, 'TaskReview.message') ?? '',
      });
      return data;
    });
    setReviewData(data);
  }, [taskSubmissions]);

  return (
    <ModalBase open={open} onClose={onClose} className="leaveFeedbackContainer">
      <div className="headerBackground">
        <h2 className="textWhite fontPetrona bold m0">Leave feedback</h2>
      </div>
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      {taskSubmissions
        .filter(submission => submission.status > 2)
        .map((taskSubmission, index) => (
          <>
            <Feedback taskName={taskName} taskSubmission={taskSubmission} index={index} reviewData={reviewData} setReviewData={setReviewData} />
          </>
        ))}

      <div className="flexRow flexCenter">
        <FlatButton bordered label="Cancel" className="mright16" onClick={onClose} />
        <FlatButton filled label="Publish" onClick={createReview} />
        <Tooltip text="You must leave a star rating for each volunteer before being able to Publish." />
      </div>
    </ModalBase>
  );
};
