import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

// Styles
import './styles/buttonStyles.css';

export default function (props) {
  const defaultButtonProps = {
    borderRadius: 25,
    height: 40,
  };
  const defaultLabelProps = {
    borderRadius: 25,
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    paddingLeft: 24,
    paddingRight: 24,
  };
  const defaultButtonStyle = {
    ...defaultButtonProps,
    ...props.buttonStyle,
  };
  const defaultLabelStyle = {
    ...defaultLabelProps,
    ...props.labelStyle,
  };

  if (props.borderedButton) {
    return (
      <a className={`btn_primary borderedButton ${props.className ? props.className : ''}`} style={defaultButtonStyle} onClick={props.onClick}>
        <div className="borderedButtonContent">
          {props.icon && props.icon}
          <p className="smallBody" style={defaultLabelStyle}>
            {props.label}
          </p>
        </div>
      </a>
    );
  }

  return (
    <RaisedButton
      label={props.label}
      labelPosition={props.labelPosition ? props.labelPosition : 'after'}
      labelColor={props.labelColor ? props.labelColor : '#fff'}
      className={`btn_primary ${props.className ? props.className : ''}`}
      icon={props.icon}
      onClick={props.onClick}
      href={props.href ? props.href : ''}
      backgroundColor={props.bgColor ? props.bgColor : '#3042D4'}
      disabled={props.disabled || false}
      disabledBackgroundColor="#B5BDC4"
      disabledLabelColor="#FFFFFF"
      buttonStyle={defaultButtonStyle}
      overlayStyle={defaultButtonProps}
      style={defaultButtonProps}
      labelStyle={defaultLabelStyle}
      onMouseEnter={props.onMouseEnter && props.onMouseEnter}
      onMouseLeave={props.onMouseLeave && props.onMouseLeave}
      rippleStyle={defaultButtonProps}
      type={props.type ? props.type : null}
    />
  );
}
