import gql from 'graphql-tag';
import { taskFieldsFragment } from '../../fragments/task/taskFields';

export default gql`
  query TaskSubmissionsByTask($taskId: ID!) {
    taskSubmissionsByTask(_task_id: $taskId) {
      _id
      _task_id
      status
      cancel
      User {
        _id
        firstname
        lastname
      }
      Task {
        ...TaskFields
      }
      TaskTimesheet {
        _id
        _task_submission_id
        status
        name
        start
        end
      }
      TaskReview {
        _id
        _user_id
        rating
        message
      }
    }
  }
  ${taskFieldsFragment}
`;
