import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import _ from 'lodash';
import FullStarIcon from 'material-ui/svg-icons/toggle/star';
import EmptyStarIcon from 'material-ui/svg-icons/toggle/star-border';
import ChatIcon from 'material-ui/svg-icons/communication/chat';
import {
    Button,
    NumberInput,
    LoadingOverlay,
} from '../../common';
import { update, utils } from '../../../lib/utils';

// Mutations
import createEventReviewMutation from '../../../mutations/event_reviews/createEventReview';
import updateEventReviewMutation from '../../../mutations/event_reviews/updateEventReview';

// External Components
import ModalVolunteerFeedback from './modalVolunteerFeedback';

// Styles
import './styles/reviewStyles.css';

class RateVolunteers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            loading: false,
            rating: 5,
            message: '',
            userReviews: [],
            openReviewMessageModal: false,
            feedbackSaved: '',
            globalFeedback: '',
            feedbackHelpText: '',
            feedbackTitle: ''
        };

        this.renderFullStar = this.renderFullStar.bind(this);
        this.renderEmptyStar = this.renderEmptyStar.bind(this);
        this.renderConfirmedUsers = this.renderConfirmedUsers.bind(this);
        this.onRateVolunteersClicked = this.onRateVolunteersClicked.bind(this);
        this.onClickRating = this.onClickRating.bind(this);
        this.resetFeedbackVariables = this.resetFeedbackVariables.bind(this);
    }

    componentWillMount() {
        // Check if it should render the full version
        //   Yes -> It should set the userReviews state with all participants
        //   No  -> It should set the state with what has been passed by the router
        if (this.props.fullVersion) {
            let globalRating = 5;
            let globalMessage = '';
            const newObject = this.props.event.confirmed_users.map((item) => {
                // Check whether the person has already been reviewed. If they had, it should show their rating
                const userReviewed = this.props.event.EventReviews.find(review => review.user_id === item.user_id);
                if (userReviewed) {
                    globalRating = userReviewed.rating;
                    globalMessage = userReviewed.message;
                    return {
                        _id: userReviewed._id,
                        event_id: this.props.event._id,
                        user_id: item.User._id,
                        hours: userReviewed.hours,
                        rating: userReviewed.rating,
                        message: userReviewed.message,
                        User: item.User
                    };
                }
                return {
                    event_id: this.props.event._id,
                    user_id: item.User._id,
                    hours: 6,
                    rating: 5,
                    message: '',
                    User: item.User
                };
            });

            this.setState({
                userReviews: newObject,
                rating: globalRating,
                feedbackSaved: globalMessage,
                globalFeedback: globalMessage
            });
        } else if (_.get(this.props.location, 'state.userReviews')) {
            const newObject = this.props.location.state.userReviews.map((eventReview) => {
                return {
                    event_id: eventReview.event_id,
                    user_id: eventReview.user_id,
                    hours: eventReview.hours,
                    rating: _.get(this.props.location, 'state.rating') || 5,
                    message: _.get(this.props.location, 'state.message') || '',
                    User: eventReview.User
                };
            });
            this.setState({
                userReviews: newObject,
                rating: _.get(this.props.location, 'state.rating') || 5,
                message: _.get(this.props.location, 'state.message') || ''
            });
        }
    }

    async onRateVolunteersClicked() {
        this.setState({
            error: '',
            loading: true
        });
        if (_.has(this.state.userReviews[0], '_id')) {
            await Promise.all(this.state.userReviews.map((userReview) => {
                return this.props.updateEventReview(userReview._id, this.normaliseUserReviewObject(userReview));
            }));
        } else {
            // Insert userReview one-by-one
            await Promise.all(this.state.userReviews.map((userReview) => {
                return this.props.createEventReview(this.normaliseUserReviewObject(userReview));
            }));
        }
        this.setState({ loading: false });

        // Redirects to the homepage
        this.props.history.replace({ pathname: '/' });
        window.location.reload();
    }

    onClickRating(rating, isGlobalStar, user_id) {
        if (isGlobalStar) {
            // It should update everyones review
            //  When the global review is activated, it means that the single reviews aren't
            this.setState({ rating }, () => {
                this.state.userReviews.forEach((review) => {
                    this.setState(prevState => ({
                        userReviews: update(prevState.userReviews, {
                            $auto: {
                                [prevState.userReviews.findIndex(item => item.user_id === review.user_id)]: {
                                    $auto: {
                                        rating: {
                                            $set: rating
                                        }
                                    }
                                },
                            }
                        })
                    }));
                });
            });
        } else {
            this.setState({
                userReviews: update(this.state.userReviews, {
                    $auto: {
                        [this.state.userReviews.findIndex(item => item.user_id === user_id)]: {
                            $auto: {
                                rating: {
                                    $set: rating
                                }
                            }
                        },
                    }
                })
            });
        }
    }

    resetFeedbackVariables() {
        this.setState({
            feedbackSaved: '',
            globalFeedback: '',
            feedbackHelpText: '',
            feedbackTitle: ''
        });
    }

    normaliseUserReviewObject(userReview) {
        return {
            event_id: userReview.event_id,
            user_id: userReview.user_id,
            hours: userReview.hours,
            rating: userReview.rating,
            message: userReview.message,
            hidden: false
        };
    }

    renderConfirmedUsers() {
        return this.state.userReviews.map((userReview) => {
            const { User } = userReview;
            return (
                <div
                    className="user"
                    key={User._id}
                >
                    <div
                        className="picture"
                        style={{ backgroundImage: `url(${User.picture})` }}
                    />
                    <h3>{User.firstname} {utils.formatLastName(User.lastname)}</h3>
                    {_.size(this.state.userReviews) <= 10 ? (
                        <>
                            <div className="ratingUser">
                                {_.times(userReview.rating, index => this.renderFullStar(index, false, User._id))}
                                {_.times(5 - userReview.rating, index => this.renderEmptyStar(index, false, User._id, userReview.rating))}
                            </div>
                            <div className="userReview">
                                <a
                                    onClick={() => {
                                        this.setState({
                                            openReviewMessageModal: true,
                                            feedbackSaved: userReview.message,
                                            feedbackTitle: `Feedback for ${User.firstname} ${User.lastname}`,
                                            feedbackHelpText: `'Write a short review for ${User.firstname}`,
                                            onSaveFeedbackCallback: (message) => {
                                                this.setState({
                                                    userReviews: update(this.state.userReviews, {
                                                        $auto: {
                                                            [this.state.userReviews.findIndex(item => item.user_id === userReview.user_id)]: {
                                                                $auto: {
                                                                    message: {
                                                                        $set: message
                                                                    }
                                                                }
                                                            },
                                                        }
                                                    }),
                                                });
                                            },
                                        });
                                    }}
                                >
                                        Review {User.firstname}
                                </a>
                                {userReview.message &&
                                    <ChatIcon
                                        color="#20C1C9"
                                        className="reviewIcon"
                                        style={{ width: 16, height: 16 }}
                                    />
                                }
                            </div>
                            <NumberInput
                                value={userReview.hours}
                                onChange={(value) => {
                                    this.setState({
                                        userReviews: update(this.state.userReviews, {
                                            $auto: {
                                                [this.state.userReviews.findIndex(item => item.user_id === userReview.user_id)]: {
                                                    $auto: {
                                                        hours: {
                                                            $set: value
                                                        }
                                                    }
                                                },
                                            }
                                        })
                                    });
                                }}
                            />
                        </>
                    ) : (
                        <span>{userReview.hours} hours</span>
                    )}
                </div>
            );
        });
    }

    renderFullStar(index, globalStar, user_id) {
        const newRating = index + 1;
        return (
            <FullStarIcon
                key={`star_${index}`}
                color="#F53920"
                onClick={() => this.onClickRating(newRating, globalStar, user_id)}
                className="forceCursor star"
            />
        );
    }

    renderEmptyStar(index, globalStar, user_id, userRating = 0) {
        let emptyIndex = userRating + index + 1;
        if (globalStar) emptyIndex = this.state.rating + index + 1;
        return (
            <EmptyStarIcon
                key={`emptyStar_${index}`}
                color="#F53920"
                onClick={() => this.onClickRating(emptyIndex, globalStar, user_id)}
                className="forceCursor star"
            />
        );
    }

    render() {
        return (
            <div className="reviewEventContainer col-xs-12">
                {this.state.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
                {this.state.error && <p className="error">{this.state.error}</p>}
                <div className="internalContainerStyle">
                    <div className="confirmedUsersContainer col-xs-12">
                        {this.renderConfirmedUsers()}
                    </div>
                    {_.size(this.state.userReviews) > 10 &&
                        <>
                            <p>How would you <strong>rate</strong> your volunteers?</p>
                            <div className="rating">
                                {_.times(this.state.rating, index => this.renderFullStar(index, true))}
                                {_.times(5 - this.state.rating, index => this.renderEmptyStar(index, true))}
                            </div>
                        </>
                    }
                    <p className="font--blue_solid">
                        Send your volunteers a global feedback.&nbsp;
                        <a
                            onClick={() => {
                                this.setState({
                                    openReviewMessageModal: true,
                                    onSaveFeedbackCallback: (message) => {
                                        const globalFeedbackSaved = this.state.globalFeedback;
                                        this.setState({
                                            openReviewMessageModal: false,
                                            userReviews: this.state.userReviews.map((review) => {
                                                let savedMessage = review.message;
                                                // Test if it is updating a global feedback
                                                if (review.message === globalFeedbackSaved) {
                                                    savedMessage = message;
                                                }
                                                return {
                                                    ...review,
                                                    message: savedMessage || message
                                                };
                                            }),
                                            globalFeedback: message,
                                        });
                                    }
                                });
                            }}
                        >
                            Write Review
                        </a>
                    </p>
                    <div className="buttonsContainer">
                        {!this.props.fullVersion &&
                            <Button
                                label="Back"
                                labelColor="#212B35"
                                bgColor="#fff"
                                onClick={() => this.props.history.replace({ pathname: `/review-event/${this.props.event._id}/select`, state: { userReviews: this.state.userReviews, rating: this.state.rating, message: this.state.message } })}
                                className="btnDefault btnWhite mright20"
                            />
                        }
                        <Button
                            label="Rate Volunteers"
                            className="btnDefault"
                            onClick={this.onRateVolunteersClicked}
                        />
                    </div>
                </div>
                <ModalVolunteerFeedback
                    open={this.state.openReviewMessageModal}
                    onClose={() => {
                        this.setState({ openReviewMessageModal: false });
                        this.resetFeedbackVariables();
                    }}
                    title={this.state.feedbackTitle}
                    helpText={this.state.feedbackHelpText}
                    feedback={this.state.feedbackSaved}
                    onSaveFeedback={(message) => {
                        this.state.onSaveFeedbackCallback(message);
                        this.resetFeedbackVariables();
                    }}
                />
            </div>
        );
    }
}

const createEventReviewContainer = graphql(createEventReviewMutation, {
    props: ({ mutate }) => ({
        createEventReview: event_review => mutate({
            variables: { event_review }
        }),
    }),
});

const updateEventReviewContainer = graphql(updateEventReviewMutation, {
    props: ({ mutate }) => ({
        updateEventReview: (_id, event_review) => mutate({
            variables: { _id, event_review }
        })
    }),
});

export default withRouter(_.flowRight(
    createEventReviewContainer,
    updateEventReviewContainer
)(RateVolunteers));
