import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';

const getBorderColor = (hasError, hasContent, hasNoBorder) => {
  if (hasNoBorder) return 'transparent';

  if (hasError) {
    return '#EA3119';
  }

  if (hasContent) {
    return '#3042D4';
  }

  return '#E3E5E5';
};

const StyledLabel = styled.label`
  display: block;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  margin-bottom: 12px;
`;

const StyledInput = styled.input`
  padding: 15px;
  border: 1px solid;
  border-color: ${({ hasError, hasContent, hasNoBorder }) => getBorderColor(hasError, hasContent, hasNoBorder)};
  border-radius: 8px;
  width: 100%;
  color: #3042d4;
  margin-bottom: ${({ hasError, maxLength }) => {
    if (maxLength && maxLength > 0) {
      return '10px';
    }
    if (hasError) {
      return '8px';
    }

    return '24px';
  }};
  height: ${({ multiLine }) => (multiLine ? '180px' : 'auto')};

  &::-webkit-input-placeholder,
  ::placeholder {
    color: #b5bdc4;
  }

  &:active,
  &:focus {
    outline-color: ${({ hasError, hasNoBorder }) => {
      if (hasNoBorder) return 'transparent';
      if (hasError) {
        return '#EA3119';
      }
      return '#3042D4';
    }};
    outline-width: 1px;
    outline-style: solid;
  }
`;

const ErrorMessage = styled.span`
  color: #ea3119;
  font-size: 12px;
  line-height: 1.5;
  display: block;
  margin-bottom: 24px;
  text-align: left;
`;

const NotificationMessage = styled.span`
  color: #b5bdc4;
  font-size: 12px;
  line-height: 1.5;
  display: block;
  margin-bottom: 24px;
`;

const CharacterCountText = styled.span`
  color: #b5bdc4;
  font-size: 12px;
  text-align: right;
  display: block;
  margin-bottom: ${({ hasError }) => (hasError ? '8px' : '24px')};
`;

const Input = forwardRef(({ errorText, notificationText, maxLength, value, onChange, label, name, multiLine, hasNoBorder, ...props }, ref) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <StyledInput
        as={multiLine ? 'textarea' : 'input'}
        name={name}
        value={value != null ? value : inputValue}
        onChange={event => {
          !value && setInputValue(event.target.value);
          onChange && onChange(event.target.value);
        }}
        multiLine={multiLine}
        maxLength={maxLength}
        hasContent={inputValue !== '' || (value != null && value !== '')}
        hasError={errorText && errorText !== ''}
        hasNoBorder={hasNoBorder}
        ref={ref}
        {...props}
      />
      {maxLength && maxLength > 0 && (
        <CharacterCountText>
          {value ? value.length : inputValue.length}/{maxLength}
        </CharacterCountText>
      )}
      {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
      {notificationText && <NotificationMessage>{notificationText}</NotificationMessage>}
    </>
  );
});

export default Input;
