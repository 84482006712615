import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Media from 'react-media';
import _ from 'lodash';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import ArrowDownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import { useQuery } from '@apollo/client';
import { userUtils } from 'lib/user';
import { NotificationsPopover } from 'components/common/NotificationsPopover/view';
import { IconButton } from 'components/common/IconButton/view';
import Popover from '../../Popover/view';
import Menu from '../../Menu/view';
import Button from '../../Button/view';
import { localStorage } from '../../../../lib/storage';
import { CharityManagerSearch } from './CharityManagerSearch/view';

// Queries
import fetchUserById from '../../../../queries/user/fetchUserById';

// Styles
import './styles/headerStyles.scss';

const Header = props => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openCreateEventForMultipleCharitiesMenu, setOpenCreateEventForMultipleCharitiesMenu] = useState(false);
  const [openAnchorEl, setOpenAnchorEl] = useState();
  const notificationsAnchor = useRef(null);
  const [openNotifications, setOpenNotifications] = useState();

  const menuArray = [];

  const userByIdData = useQuery(fetchUserById, {
    variables: {
      id: localStorage.get('userID'),
    },
  });
  const userDetails = _.get(userByIdData, 'data.userByID');
  const charitiesAdmin = _.get(userDetails, 'CharitiesAdmin');
  const isCharityManager = userUtils.isCharityManager(userDetails);

  if (userDetails) {
    if (_.size(charitiesAdmin) > 0) {
      _.each(charitiesAdmin, charity => {
        menuArray.push({
          label: (
            <div className="menuCharityItem">
              {_.get(charity, 'picture') && <div style={{ backgroundImage: `url(${charity.picture})` }} className="imgCharity" />}
              <span>{_.get(charity, 'name')}</span>
            </div>
          ),
          action: () => onRedirect(`/charity/${_.get(charity, '_id')}`),
        });
      });
    }
    menuArray.push({
      label: 'Browse tasks',
      action: () => onRedirect('/task/search'),
      className: 'hideOnDesktop',
    });
    menuArray.push({
      label: 'Search charities',
      action: () => onRedirect('/charities'),
      className: 'hideOnDesktop',
    });
    menuArray.push({
      label: 'Dashboard',
      action: () => onRedirect('/dashboard/index'),
    });
    menuArray.push({
      label: 'My Profile',
      action: () => onRedirect(`/profile/${_.get(userDetails, '_id')}`),
    });
    menuArray.push({
      label: 'Settings',
      action: () => onRedirect('/dashboard/settings'),
    });
    menuArray.push({
      label: 'Help & Support',
      action: () => onRedirect('/contact'),
    });
    menuArray.push({
      label: 'Logout',
      action: () => {
        localStorage.remove('userID');
        setOpenMenu(false);
        onRedirect('/');
        window.location.reload();
      },
    });
  } else {
    menuArray.push({
      label: 'Login',
      action: () => onRedirect('/login'),
    });
    menuArray.push({
      label: 'For volunteers',
      action: () => onRedirect('/for-volunteers'),
    });
    menuArray.push({
      label: 'For charities',
      action: () => onRedirect('/for-charities'),
    });
  }

  const onRedirect = (url, state = {}) => {
    props.history.push({ pathname: url, state });
    setOpenMenu(false);
  };

  const handleClick = event => {
    event.preventDefault();

    setOpenMenu(true);
    setOpenAnchorEl(event.currentTarget);
  };

  const renderNotLoggedUserContainer = () => {
    return (
      <div className="notLoggedUser">
        <Link to="/login" className="smallBody loginButton">
          Login
        </Link>
        <Button href="/sign-up" label="Sign up" />
      </div>
    );
  };

  const renderLoggedUser = () => {
    if (!localStorage.get('userID')) return renderNotLoggedUserContainer();

    if (!_.get(userByIdData, 'loading') && _.get(userDetails, '_id')) {
      return (
        <div className={`loggedUserMenu ${openMenu ? 'openedMenu' : ''}`} onClick={handleClick}>
          <div className="contentUserContainer">
            <div style={{ backgroundImage: `url(${userDetails.picture})` }} className="imgUser" />
            <p>Hi {_.get(userDetails, 'firstname')}</p>
            <ArrowDownIcon color="#000" className="arrowIconDown" />
          </div>
          <Popover open={openMenu} anchorEl={openAnchorEl} handleRequestClose={() => setOpenMenu(false)} className="userPopover">
            <div className="userPopoverContent">
              <div className="mainMenuUser">
                <Menu values={menuArray} className="mainMenuUserContent" />
              </div>
            </div>
          </Popover>
        </div>
      );
    }
  };

  const renderCreateTaskButton = () => {
    if (_.size(charitiesAdmin) === 0) return null;
    const qtdCharitiesAdmin = _.size(charitiesAdmin);
    let charitiesArray = [];
    if (qtdCharitiesAdmin > 1) {
      charitiesArray = _.map(charitiesAdmin, charity => {
        return {
          label: `for ${charity.name}`,
          action: () => {
            props.history.push({ pathname: '/task/create/overview', state: { charity_id: charity._id } });
            setOpenCreateEventForMultipleCharitiesMenu(false);
          },
        };
      });
    }
    return (
      <div className="createTaskContainer">
        <Button
          onClick={event => {
            if (qtdCharitiesAdmin === 1) {
              props.history.push({ pathname: '/task/create/overview', state: { charity_id: charitiesAdmin[0]._id } });
            } else {
              setOpenCreateEventForMultipleCharitiesMenu(true);
              setOpenAnchorEl(event.currentTarget);
            }
          }}
          className="smallBody createEventButton"
          label="Create Task"
        />
        <Popover open={openCreateEventForMultipleCharitiesMenu} anchorEl={openAnchorEl} handleRequestClose={() => setOpenCreateEventForMultipleCharitiesMenu(false)}>
          <Menu values={charitiesArray} className="mainMenuUserContent" />
        </Popover>
      </div>
    );
  };

  const renderLinksContainer = () => {
    // TODO - Fix the links once we have the pages built
    if (userDetails) {
      return (
        <>
          <Link to="/task/search" className={`smallBody headerLink ${_.get(props.location, 'pathname') === '/task/search' ? 'selected' : ''}`}>
            Browse Tasks
          </Link>
          <Link to="/charities" className={`smallBody headerLink ${_.get(props.location, 'pathname') === '/charities' ? 'selected' : ''}`}>
            Search Charities
          </Link>
        </>
      );
    }
    return (
      <>
        <Link to="/for-volunteers" className={`smallBody headerLink ${_.get(props.location, 'pathname') === '/task/search' ? 'selected' : ''}`}>
          For volunteers
        </Link>
        <Link to="/for-charities" className={`smallBody headerLink ${_.get(props.location, 'pathname') === '/charities' ? 'selected' : ''}`}>
          For charities
        </Link>
      </>
    );
  };

  const renderActionsContainer = () => {
    if (!localStorage.get('userID')) return null;
    return (
      <div className="actionsContainer">
        <a href="/dashboard/inbox">
          <img src="/icons/messages.svg" alt="Messages" className="messagesIcon" />
        </a>
        <IconButton
          icon="/icons/notifications.svg"
          ref={notificationsAnchor}
          onClick={event => {
            setOpenNotifications(true);
          }}
          className="notificationsIcon"
        />
        {renderCreateTaskButton()}
      </div>
    );
  };

  const renderMobileTopRightContainer = () => {
    return (
      <div className="mobileTopRightContainer">
        {!_.get(userDetails, '_id') && (
          <div className="contentHeader">
            <div className="userContainer">{renderNotLoggedUserContainer()}</div>
          </div>
        )}
        {renderCreateTaskButton()}
        {!openMenu ? <MenuIcon onClick={handleClick} className="mobileIcon" /> : <CloseIcon onClick={handleClick} className="mobileIcon" />}
        <Popover open={openMenu} anchorEl={openAnchorEl} handleRequestClose={() => setOpenMenu(false)} className="userPopover">
          <Menu values={menuArray} className="mainMenuUserContent" innerDivStyle={{ padding: 0 }} />
        </Popover>
      </div>
    );
  };

  return (
    <div className="headerContainer">
      <div className="topLeftContainer">
        <a href="/">
          <img src="/logo.svg" alt="Worthy logo" className="logo" />
        </a>
        <div className="linksContainer">
          {isCharityManager ? <CharityManagerSearch onUserClicked={user => props.history.push(`/profile/${user._id}`)} /> : renderLinksContainer()}
        </div>
      </div>
      <Media query="(min-width: 426px)">
        {matches =>
          matches ? (
            <div className="contentHeader">
              {renderActionsContainer()}
              <div className="userContainer">{renderLoggedUser()}</div>
            </div>
          ) : (
            renderMobileTopRightContainer()
          )
        }
      </Media>
      {openNotifications && <NotificationsPopover open={openNotifications} anchorEl={notificationsAnchor.current} requestClose={() => setOpenNotifications(false)} />}
    </div>
  );
};

export default withRouter(Header);
