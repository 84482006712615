import gql from 'graphql-tag';

export default gql`
  mutation createMessage($message: MessagesInput!) {
    createMessage(message: $message) {
      _id
      type
      body
      created
      _to_user_id
      _from_user_id
      direction
    }
  }
`;
