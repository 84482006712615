import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, RadioButtonCards, GoogleInput } from '../../../../common';
import { ButtonContainer } from '../../../common/Elements';
import InPersonIcon from '../assets/inPerson';
import RemoteIcon from '../assets/remote';

const cards = [{
  heading: 'In-Person',
  icon: <InPersonIcon />,
  id: 'inPersonRadio',
  value: 'inPerson',
}, {
  heading: 'Remote',
  icon: <RemoteIcon />,
  id: 'remoteRadio',
  value: 'remote',
}];

const InputContainer = styled.div`
  margin: 20px 0;
`;

const TaskLocation = ({ setFormData, setStep }) => {
  const [inPersonLocation, setInPersonLocation] = useState({});
  const [inPersonLocationText, setInPersonLocationText] = useState('');
  const [locationType, setLocationType] = useState('inPerson');

  return (
    <>
      <RadioButtonCards
        name="taskLocationType"
        cards={cards}
        active="inPerson"
        onChange={setLocationType}
      />

      {locationType === 'inPerson' && (
        <InputContainer>
          <GoogleInput
            onNewPlace={setInPersonLocation}
            onValueChange={setInPersonLocationText}
            value={inPersonLocationText}
          />
        </InputContainer>
      )}

      <ButtonContainer largeMargin>
        <Button
          label="Back"
          borderedButton
          onClick={() => setStep('taskName')}
        />
        <Button
          label="Continue"
          disabled={locationType === 'inPerson' && inPersonLocation === ''}
          onClick={() => {
            setFormData(prevState => ({
              ...prevState,
              locationType,
              ...(locationType === 'inPerson' && {
                location: inPersonLocation,
                locationText: inPersonLocationText,
              })
            }));
            setStep('taskDescription');
          }}
        />
      </ButtonContainer>
    </>
  );
};

export default TaskLocation;
