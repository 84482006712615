import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { ModalBase } from 'components/common/ModalBase/view';
import { Button, LoadingOverlay } from 'components/common';
import markAsCompleteMutation from 'mutations/task/markAsComplete';
import markAllAsCompleteMutation from 'mutations/task/markAllAsComplete';
import fetchTasksByUserId from 'queries/task/fetchTasksByUserId';
import fetchTasksByCharityId from 'queries/task/fetchTasksByCharityId';
import './styles.scss';

export const ModalMarkAsComplete = ({ isCharityManager, taskSubmissionId, taskId, taskName, userId, onClose, charityId, onOpenReviewTimesheet, onSetCharityId }) => {
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [markAsComplete] = useMutation(markAsCompleteMutation);
  const [markAllAsComplete] = useMutation(markAllAsCompleteMutation);
  const handleMarkAsComplete = useCallback(async () => {
    setIsLoading(true);
    isCharityManager
      ? await markAllAsComplete({
          variables: {
            taskId,
            submissionId: taskSubmissionId,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: fetchTasksByCharityId,
              variables: {
                _charity_id: charityId,
              },
              skip: false,
            },
          ],
        })
      : await markAsComplete({
          variables: {
            taskId,
            submissionId: taskSubmissionId,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: fetchTasksByUserId,
              variables: {
                _user_id: userId,
              },
            },
          ],
        });
    setIsLoading(false);
    onClose();
  }, [taskSubmissionId, taskId]);

  return (
    <ModalBase open={open} onClose={onClose} className="confirmCompleteTaskContainer">
      {isLoading ? (
        <div className="flexCenter relative">
          <LoadingOverlay style={{ opacity: 0.8 }} />
        </div>
      ) : (
        <>
          {!isCharityManager ? (
            <>
              <div className="subject">Are you sure you want to mark this task as complete?</div>
              <div className="btn_complete_task_cover">
                <Button label="Complete task" className="btn_complete_task_submission" onClick={() => handleMarkAsComplete()} />
              </div>
            </>
          ) : (
            <>
              <div className="task_name">{taskName}</div>
              <div className="subject">Some timesheets are not approved, do you want to approve all and complete this task or review timesheets?</div>
              <div className="btn_complete_task_cover">
                <Button label="Complete task" className="btn_complete_task" onClick={() => handleMarkAsComplete()} />
                <Button
                  label="Review timesheets"
                  className="btn_review_timesheet"
                  onClick={() => {
                    onOpenReviewTimesheet(taskId);
                    onSetCharityId(charityId);
                    onClose();
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </ModalBase>
  );
};
