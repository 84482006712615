import gql from 'graphql-tag';

export default gql`
  mutation updateTaskTimeSheet($id:ID!, $taskTimeSheet: TaskTimesheetInput!) {
    updateTaskTimesheet(_id:$id, task_timesheet: $taskTimeSheet) {
      _id
      _task_submission_id
      name
      start
      end
      status
    }
  }
`;
