import gql from 'graphql-tag';

export default gql`
  query FetchUserByKeyword($keyword: String!) {
    usersByKeyword(keyword: $keyword) {
      _id
      firstname
      lastname
      email
      picture
      UserMacros {
        _id
        name
        color
      }
      location {
        street
        city
        state
        postcode
        country
        latitude
        longitude
        location
      }
      CharitiesFollowing {
        _id
        name
      }
    }
  }
`;
