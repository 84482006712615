import React, { useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';

import { OnboardingContainer } from '../common';
import StepIndicator from '../common/StepIndicator';
import Welcome from './welcome';
import AboutYou from './aboutYou';
import Skills from './skills';
import Networks from './networks';
import Finish from './finish';
import fetchUserById from '../../queries/user/fetchUserById';
import { localStorage } from '../../lib/storage';

import './styles/onboardingStyles.css';

function Onboarding(props) {
  const { step } = props.match.params;

  const userByIdData = useQuery(fetchUserById, {
    variables: {
      id: localStorage.get('userID'),
    },
  });

  useEffect(() => {
    const isOnboarded = _.get(userByIdData, 'data.userByID.IsOnboarded');
    if (isOnboarded) {
      props.history.push({
        pathname: '/',
      });
    }
  }, [_.get(userByIdData, 'data.userByID._id')]);

  const steps = [
    {
      name: 'about-you',
      label: '1. About You',
    },
    {
      name: 'skills',
      label: '2. Your Skills',
    },
    {
      name: 'networks',
      label: '3. Networks',
    },
    {
      name: 'review',
      label: '4. Review',
    },
  ];

  return (
    <div className="onboardingContainer">
      <div className="contentContainer">
        {step === 'welcome' ? <Welcome /> : null}
        {['about-you', 'skills', 'networks'].includes(step) ? (
          <OnboardingContainer>
            <StepIndicator steps={steps} currentActive={step} />
            <h1 className="headTitle">
              <span>{props.location.state.data.firstname}</span>, let&apos;s build your profile
            </h1>
            {step === 'about-you' ? <AboutYou /> : null}
            {step === 'skills' ? <Skills /> : null}
            {step === 'networks' ? <Networks /> : null}
          </OnboardingContainer>
        ) : null}
        {step === 'finish' ? <Finish /> : null}
      </div>
    </div>
  );
}

export default Onboarding;
