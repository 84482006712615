import React from 'react';
import '../styles/legalStyles.css';

function TermsAndConditions() {
  return (
    <div className="legalContainerNew">
      <div className="header">
        <h1>Beta Tester Agreement</h1>
      </div>
      <div className="mainContainer">
        <div className="content">
          <p>
            Worthy Network Inc. (“Company”) grants to you, the party signing below (“you”) a temporary, limited right and license to use the Service(s) (as defined below), subject to the terms of this Beta Test Agreement (this “Agreement”). The term (length) of this Agreement shall be for 90 days, but may be extended at Company’s option in writing, up to a maximum of 6 months. Company has the right to terminate this Agreement for convenience at any time without penalty.
          </p>
          <p>
            Company grants you a non-exclusive, non-transferable license to use certain Company provided software, web-based platforms and/or services (collectively, the “Service(s)”) solely for non-public test use, to beta test and give feedback to Company, as set forth herein. You agree to test the Services and features therein, and to provide feedback, ideas, suggestions or suggested improvements (collectively, “Feedback”) to Company.
          </p>
          <p>
            Any Feedback shall be owned exclusively by Company, and Company has the right to use and exploit the same without compensation to you. Company retains all right, title, and interest in the Service (and Feedback) and in all copies thereof, and all related documentation and materials, and no title to the Service or any intellectual property or other rights therein, are transferred to you by virtue of this Agreement other than as specified herein. You agree not to cause or permit the reverse engineering, reverse assembly, or reverse compilation of the Service or otherwise attempt to derive source code from the Service (if applicable). You may not transfer, lend, lease, assign, sublicense, and/or make available through timesharing or through managed services Service, in whole or in part.
          </p>
          <p>
            The Services, Feedback, and other information you may learn about Company and/or its products, plans, etc., are all “Confidential Information” of Company. You will not use the Confidential Information for any purpose other than the evaluation contemplated herein nor disclose any of the Confidential Information to any third party. Company retains ownership of its Confidential Information.
          </p>
          <p className="bold">THE SERVICES ARE PROVIDED AS IS. COMPANY HAS NO LIABILTY TO YOU FOR YOUR USE OF THE SERVICES. COMPANY’S AGGREGATE LIABILITY FOR ANY AND ALL CLAIMS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, SHALL NOT EXCEED $100; AND IN NO EVENT SHALL COMPANY BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUEN¬TIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, DAM¬AGES RESULTING FROM LOSS OF PROFITS, DATA, OR BUSINESS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
          <p>This Agreement is made under the laws of the State of New York, USA, excluding the choice of law and conflict of law provisions, and the parties expressly consent to sole jurisdiction and venue for any litigation arising from or relating to this Agreement shall be an appropriate federal or state court located in New York, New York. This Agreement is the entire agreement between You and Company relating to Service and supersedes all prior, contemporaneous and future or other oral or written communications, proposals, and representations with respect to its subject matter. No modification to this Agreement is binding unless in writing and signed by a duly authorized representative of each party. The waiver or failure of either party to exercise any right provided for herein shall not be deemed a waiver of any further right hereunder. If any provision of this Agreement is held invalid, all other provisions shall remain valid unless such validity would frustrate the purpose of this Agreement, and this Agreement shall be enforced to the full extent allowable under applicable law. All licenses granted hereunder shall terminate upon expiration of the term. You may not assign any rights, duties or obligations under this Agreement without the prior written consent of Company and any attempt to do so shall be void and of no effect. The confidentiality and ownership provisions herein survive termination or expiration of this Agreement.</p>
          <p>You acknowledge and agree that by clicking on any button labelled “Submit”, “Order”, “I Accept”, “I Agree” or such similar links as may be used by us on the Service(s) (or otherwise) to accept these Terms, you are agreeing to and intend to be legally bound by that electronic signature and are entering into a legally binding agreement.</p>
          <p>For any questions and notices, please contact:<br />Email: testmyproduct@fastmail.com</p>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
