import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import Button from '../../Button/view';
import LoadingOverlay from '../../LoadingOverlay/view';
import Snackbar from '../../Snackbar/view';
import Modal from '../../Modal/view';
import { localStorage } from '../../../../lib/storage';

// Mutations
import resendUserConfirmationEmailMutation from '../../../../mutations/user/resendUserConfirmationEmail';

// Styles
import './styles/verifyEmailStyles.css';

function VerifyEmail(props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Mutations
  const [resendUserConfirmationEmail] = useMutation(resendUserConfirmationEmailMutation);

  const resendLink = async () => {
    const userId = localStorage.get('userID');
    if (userId) {
      setLoading(true);
      setError('');

      try {
        await resendUserConfirmationEmail({
          variables: {
            id: userId,
          },
        });
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage('Email successfully resent');
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    }
  };

  return (
    <Modal open={props.open} onClose={props.onClose} className="verifyEmailContainer flexCenter textCenter">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      {error && (
        <div className="errorMessage">
          <p className="error">{error}</p>
        </div>
      )}
      <div className="content">
        <h1 className="xlarge mbottom40">To do that we need you to confirm your email</h1>
        <h2>
          We&apos;ve sent a verification email to <span className="email">{_.get(props.user, 'email')}</span>. Simply click on the link in the email, and you&apos;ll be all set.
        </h2>
        <p>
          Didn&apos;t get the confirmation email? <a onClick={resendLink}>Resend</a>
        </p>
        <div className="buttonsContainer">
          <Button label="Skip for now" labelColor="#fff" bgColor="#637381" onClick={props.onClose} className="btnDefault btnBlueSolid" />
        </div>
        <Snackbar open={snackbarOpen} message={snackbarMessage} snackbarHandleRequestClose={() => setSnackbarOpen(false)} />
      </div>
    </Modal>
  );
}

export default VerifyEmail;
