import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import _ from 'lodash';
import EventCreatedIcon from 'material-ui/svg-icons/notification/event-available';
import { Button, LoadingOverlay } from '../../common';

// Queries
import fetchEventById from '../../../queries/event/fetchEventById';

// Styles
import './styles/finishEventStyles.css';

class CreateEventFinish extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        if (this.props.eventData.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;

        const { eventByID } = this.props.eventData;
        return (
            <div className="finishContainer flexCenter">
                <div className="finishContentContainer">
                    <div
                        style={{ backgroundImage: `url(${eventByID.picture})` }}
                        className="eventPic"
                    />
                    <div className="eventIconContainer">
                        <EventCreatedIcon
                            className="eventIcon"
                            color="#20C1C9"
                        />
                    </div>
                    <div className="eventContent">
                        <h1 className="xlarge mbottom40">Event Created!</h1>
                        <div className="buttonsContainer">
                            <Button
                                label="Go to the event"
                                onClick={() => this.props.history.replace(`/event/${this.props.location.state.eventId}`)}
                                className="btnDefault btnBlueSolid"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const eventByIDContainer = graphql(fetchEventById, {
    name: 'eventData',
    options: ({ location }) => {
        return {
            variables: { id: _.get(location, 'state.eventId') },
        };
    },
});

export default withRouter(eventByIDContainer(CreateEventFinish));
