import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import {
    LoadingOverlay,
    Button,
    EventThumbnail,
    TopBar
} from '../../common';
import { localStorage } from '../../../lib/storage';

// Styles
import './styles/volunteerConfirmation.css';

class VolunteerConfirmation extends Component {
    constructor() {
        super();

        this.state = {
            error: '',
            loading: false
        };

        this.renderEventsContainer = this.renderEventsContainer.bind(this);
    }

    renderEventsContainer(eventsById) {
        if (_.size(eventsById.charity.Events) > 1) {
            return (
                <div className="eventsForCharity col-xs-12 p0">
                    <div className="defaultMainRootContentContainer mtop40">
                        <h2 className="bold mbottom40">More events like this</h2>
                        {eventsById.charity.Events.map((event) => {
                            if (event._id === this.props.match.params.event_id) return null;
                            return (
                                <EventThumbnail
                                    containerClassName="col-xs-12 col-sm-6 col-md-4"
                                    event={event}
                                    key={event._id}
                                />
                            );
                        })}
                    </div>
                </div>
            );
        }
    }

    render() {
        // if (this.props.eventData.loading || this.props.userData.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
        if (!_.get(this.props.location, 'state.eventId')) {
            // If there's not Charity id to assign to it should redirect to the homepage
            this.props.history.replace({ pathname: '/' });
        }
        const { eventById } = this.props.location.state;
        return (
            <div className="volunteerConfirmationContainer col-xs-12 p0">
                {this.state.error && (
                    <div className="errorMessage">
                        <p className="error">{this.state.error}</p>
                    </div>
                )}
                {this.state.loading && (
                    <LoadingOverlay style={{ opacity: 0.8 }} />
                )}
                <div className="volunteerConfirmationHeader col-xs-12 p0">
                    <TopBar>
                        <p>
                            {`${eventById.name} was added to your profile.`}
                            <a
                                onClick={() => {
                                    this.props.history.push({ pathname: `/profile/${localStorage.get('userID')}` });
                                }}
                                className="mleft15"
                            >
                                View Profile
                            </a>
                        </p>
                    </TopBar>
                </div>
                <div className="contentVolunteers col-xs-12">
                    <div className="defaultMainRootContentContainer contentInternal">
                        <h1 className="xlarge">You&apos;re all set!</h1>
                        <p>Good on you, you&apos;re helping <strong>{eventById.charity.name}</strong></p>
                        <h2>You&apos;re now marked as attending for <strong>{eventById.name}</strong> by <strong>{eventById.charity.name}</strong></h2>
                        <Button
                            label="View event details"
                            className="btnDefault mtop30 btnEventDetails"
                            onClick={() => {
                                this.props.history.replace({ pathname: `/event/${this.props.location.state.eventId}` });
                            }}
                        />
                    </div>
                    {this.renderEventsContainer(eventById)}
                </div>
            </div>
        );
    }
}

export default withRouter(VolunteerConfirmation);
