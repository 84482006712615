import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import FullStarIcon from 'material-ui/svg-icons/toggle/star';
import EmptyStarIcon from 'material-ui/svg-icons/toggle/star-border';
import { LoadingOverlay, Chips, Button, VerifyEmail } from '../../common';
import { localStorage } from '../../../lib/storage';

// Queries
import fetchUserById from '../../../queries/user/fetchUserById';

// Mutations
import followCharityMutation from '../../../mutations/charity/followCharity';
import unfollowCharityMutation from '../../../mutations/charity/unfollowCharity';

// Assets
import reviewsIcon from './assets/reviewsIcon.svg';
import eventsIcon from './assets/eventsIcon.svg';
import charitiesHomeIcon from './assets/charitiesIcon.svg';

// Styles
import './styles/charityListView.css';

class ProfileCharities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      loading: false,
      openVerifyEmail: false,
    };

    this.renderCharitiesList = this.renderCharitiesList.bind(this);
    this.onFollowCharity = this.onFollowCharity.bind(this);
    this.onUnfollowCharity = this.onUnfollowCharity.bind(this);
  }

  onFollowCharity(charity_id, user) {
    if (!localStorage.get('userID')) {
      this.props.history.push({ pathname: '/sign-up', state: { action: 'follow' } });
    } else if (_.get(user, 'status') === 0) {
      this.setState({ openVerifyEmail: true });
    } else {
      this.props.followCharity(charity_id, user._id).catch(error => {
        this.setState({ error });
      });
    }
  }

  onUnfollowCharity(charity_id, user_id) {
    this.props.unfollowCharity(charity_id, user_id).catch(error => {
      this.setState({ error });
    });
  }

  normaliseUpcomingEvents(events) {
    // Function to filter upcoming events to only future ones
    return events.filter(item => {
      return moment(item.config.end).isAfter();
    });
  }

  normaliseCausesArray(causes) {
    return causes.map(cause => {
      return {
        _id: cause._id,
        label: cause.name,
      };
    });
  }

  renderCharitiesList(charities) {
    return charities.map(charity => {
      return (
        <div key={charity._id} className="charity col-xs-12">
          <div className="col-xs-4 col-sm-2 col-md-1">
            <div style={{ backgroundImage: `url(${charity.picture})` }} className="pic" />
          </div>
          <div className="col-xs-8 col-sm-6 nameContainer">
            <a href={`charity/${charity._id}`}>
              <h2 className="name">{charity.name}</h2>
            </a>
            <Chips containerStyle="mtop20" values={this.normaliseCausesArray(charity.Causes)} />
          </div>
          <div className="col-xs-12 col-md-5 buttonsContainer">
            {this.renderFollowCharityButton(charity._id)}
            <Button label="Browse tasks" onClick={() => this.props.history.push({ pathname: '/task/search' })} className="btnDefault viewEventsButton" />
          </div>
        </div>
      );
    });
  }

  renderFollowCharityButton(charity_id) {
    // Check if user has followed the charity already.
    //  Yes -> Show the UNFOLLOW button
    //   No -> Show the FOLLOW button
    const { userByID } = this.props.mineDetails;
    if (userByID && userByID.CharitiesFollowing.find(item => item._id === charity_id)) {
      return <Button label="Unfollow charity" onClick={() => this.onUnfollowCharity(charity_id, userByID._id)} borderedButton icon={<FullStarIcon />} className="btnDefault" />;
    }
    return <Button label="Follow charity" onClick={() => this.onFollowCharity(charity_id, userByID)} borderedButton icon={<EmptyStarIcon />} className="btnDefault" />;
  }

  render() {
    if (this.props.userDetails.loading || this.props.mineDetails.loading) return <LoadingOverlay />;
    if (this.props.userDetails.error) this.props.history.replace({ pathname: '/' });
    const { userByID } = this.props.userDetails;
    const futureEvents = this.normaliseUpcomingEvents(userByID.UpcomingEvents);

    return (
      <div className="profileCharitiesContainer col-xs-12 p0">
        {this.state.error && (
          <div className="errorMessage">
            <p className="error">{this.state.error}</p>
          </div>
        )}
        {this.state.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
        <div className="profileHeader col-xs-12">
          <div className="defaultMainRootContentContainer">
            <div className="profileImage col-xs-6 col-sm-2 p0">
              <div style={{ backgroundImage: `url(${userByID.picture})` }} className="imgUser" />
            </div>
            <div className="headerInformationContainer col-xs-8 col-sm-6">
              <h1>{`${userByID.firstname} ${userByID.lastname}`}</h1>
              <p className="locationContainer">Location ∙ Member since {moment(userByID.created).format('MMMM YYYY')}</p>
            </div>
            <div className="statsContainer col-xs-12 col-sm-4">
              <div className="stat">
                <img src={reviewsIcon} className="icon" alt="Reviews Icon" />
                <h3>{_.size(userByID.EventReviews)}</h3>
                <h5>{_.size(userByID.EventReviews) === 1 ? 'Review' : 'Reviews'}</h5>
              </div>
              <div className="stat">
                <img src={eventsIcon} className="icon" alt="Events Icon" />
                <h3>{_.size(futureEvents) + _.size(userByID.saved_events)}</h3>
                <h5>Events</h5>
              </div>
              <div className="stat">
                <img src={charitiesHomeIcon} className="icon" alt="Charities Icon" />
                <h3>{_.size(userByID.CharitiesFollowing)}</h3>
                <h5>Charities</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="profileCharitiesContent col-xs-12 p0">
          <div className="defaultMainRootContentContainer">
            <div className="content col-xs-12">
              <h2>Saved Charities</h2>
              {this.renderCharitiesList(userByID.CharitiesFollowing)}
            </div>
          </div>
        </div>
        <VerifyEmail open={this.state.openVerifyEmail} onClose={() => this.setState({ openVerifyEmail: false })} user={userByID} />
      </div>
    );
  }
}

const UserDetailsByIdContainer = graphql(fetchUserById, {
  name: 'userDetails',
  options: ({ match }) => ({
    variables: { id: match.params.user_id },
  }),
});

const MyUserDetailsByIdContainer = graphql(fetchUserById, {
  name: 'mineDetails',
  options: () => ({
    variables: { id: localStorage.get('userID') },
  }),
});

const followCharityContainer = graphql(followCharityMutation, {
  props: ({ mutate }) => ({
    followCharity: (id, user_id) =>
      mutate({
        variables: { id, user_id },
        refetchQueries: [
          {
            query: fetchUserById,
            variables: { id: localStorage.get('userID') },
          },
        ],
      }),
  }),
});

const unfollowCharityContainer = graphql(unfollowCharityMutation, {
  props: ({ mutate }) => ({
    unfollowCharity: (id, user_id) =>
      mutate({
        variables: { id, user_id },
        refetchQueries: [
          {
            query: fetchUserById,
            variables: { id: localStorage.get('userID') },
          },
        ],
      }),
  }),
});

export default withRouter(_.flowRight(UserDetailsByIdContainer, MyUserDetailsByIdContainer, followCharityContainer, unfollowCharityContainer)(ProfileCharities));
