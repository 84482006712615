import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import IconSchedule from 'material-ui/svg-icons/action/schedule';
import LocationIcon from 'material-ui/svg-icons/communication/location-on';
import Done from 'material-ui/svg-icons/action/done';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import FavouriteBorder from 'material-ui/svg-icons/action/favorite-border';
import Favourite from 'material-ui/svg-icons/action/favorite';
import ChatIcon from 'material-ui/svg-icons/communication/chat';
import {
    LoadingOverlay,
    Button,
    Chips,
    Modal,
    PersonThumbnail,
    EventThumbnail,
    List,
    Map,
    Input,
    ShareContainer,
    VerifyEmail,
    Notification,
    Dialog
} from '../../common';
import { eventUtils } from '../../../lib/event';
import { localStorage } from '../../../lib/storage';
import { utils } from '../../../lib/utils';
import { userUtils } from '../../../lib/user';

// Queries
import fetchEventById from '../../../queries/event/fetchEventById';
import fetchUserById from '../../../queries/user/fetchUserById';
import fetchEventComments from '../../../queries/event/fetchEventComments';

// Mutations
import saveEvent from '../../../mutations/user/saveEvent';
import unSaveEvent from '../../../mutations/user/unSaveEvent';
import applyForEvent from '../../../mutations/event/applyForEvent';
import cancelApplicationForEvent from '../../../mutations/event/cancelApplicationForEvent';
import commentEvent from '../../../mutations/event/commentEvent';
import deleteEvent from '../../../mutations/event/deleteEvent';
import deleteCommentEvent from '../../../mutations/event/deleteCommentEvent';

// Styles
import './styles/eventDetailsStyles.css';

// External Components
import ModalCancelAttendance from './modalCancelAttendance';
import ModalSendEmailAttendees from './modalSendEmailAttendees';

class EventDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            loading: false,
            openMap: false,
            comment: '',
            fixedHeader: false,
            openShareContainer: false,
            openVerifyEmail: false,
            notificationContent: '',
            showCancel: false,
            showModalCancelAttendance: false,
            openDeleteEventDialog: false,
            deleteEventLoading: false,
            openDeleteCommentDialog: false,
            commentToBeDeleted: '',
            showModalSendEmailAttendees: false
        };

        this.renderEventsContainer = this.renderEventsContainer.bind(this);
        this.renderSaveButton = this.renderSaveButton.bind(this);
        this.onApplyForEvent = this.onApplyForEvent.bind(this);
        this.onCancelApplicationForEvent = this.onCancelApplicationForEvent.bind(this);
        this.onCommentEvent = this.onCommentEvent.bind(this);
        this.renderCommentsOnEvent = this.renderCommentsOnEvent.bind(this);
        this.renderDetailsEvent = this.renderDetailsEvent.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.onDeleteEvent = this.onDeleteEvent.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
    }

    componentWillMount() {
        if (_.get(this.props.location, 'state.applicationSuccessfull')) {
            setTimeout(() => {
                this.setState({
                    notificationContent: <span><strong>Congratulations.</strong> You&apos;re all set!</span>
                });
            }, 500);
        }
    }

    onApplyForEvent() {
        if (_.get(this.props.userData, 'userByID.status') === 0) {
            this.setState({ openVerifyEmail: true });
        } else {
            const { eventByID } = this.props.eventData;
            // If it's an open Event it should apply for the event and then redirect to the volunteer confirmation page
            if (eventUtils.isOpenEvent(eventByID)) {
                this.setState({
                    loading: true,
                    error: ''
                });
                this.props.applyForEvent(this.props.match.params.event_id, localStorage.get('userID'))
                    .then(() => {
                        this.setState({ loading: false });
                        this.props.history.push({ pathname: `/event/${this.props.match.params.event_id}/volunteer_confirmation`, state: { eventId: this.props.match.params.event_id, eventById: this.props.eventData.eventByID } });
                    })
                    .catch((error) => {
                        this.setState({
                            error,
                            loading: false
                        });
                    });
            } else {
                // If its not an opened event it should go to the apply view so the user can confirm
                this.props.history.push({ pathname: `/event/${this.props.match.params.event_id}/apply`, state: { eventId: this.props.match.params.event_id, eventById: this.props.eventData.eventByID } });
            }
        }
    }

    onCancelApplicationForEvent() {
        this.setState({
            loading: true,
            error: ''
        });
        this.props.cancelApplicationForEvent(this.props.match.params.event_id, localStorage.get('userID'))
            .then(() => {
                this.setState({
                    loading: false,
                    showModalCancelAttendance: true,
                    notificationContent: <span>We&apos;ve removed your attendance from this event. <strong>If you change your mind, apply again below</strong></span>
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error
                });
            });
    }

    onCommentEvent() {
        if (_.get(this.props.userData, 'userByID.status') === 0) {
            this.setState({ openVerifyEmail: true });
        } else {
            this.setState({ loading: true });
            const comment = {
                user_id: localStorage.get('userID'),
                content: this.state.comment
            };
            this.props.commentEvent(_.get(this.props.match, 'params.event_id'), comment)
                .then(() => {
                    this.setState({
                        loading: false,
                        comment: ''
                    });
                })
                .catch(() => this.setState({ loading: false }));
        }
    }

    onDeleteEvent() {
        this.setState({ deleteEventLoading: true });

        this.props.deleteEvent(_.get(this.props.match, 'params.event_id'))
            .then(() => {
                this.setState({
                    openDeleteEventDialog: false,
                    deleteEventLoading: false
                });

                // Redirects the user to the homepage
                this.props.history.replace({ pathname: '/' });
            });
    }

    onDeleteComment() {
        this.props.deleteCommentEvent(_.get(this.props.match, 'params.event_id'), this.state.commentToBeDeleted)
            .then(() => {
                this.setState({
                    openDeleteCommentDialog: false,
                    commentToBeDeleted: ''
                });
            })
            .catch(() => {
                this.setState({
                    openDeleteCommentDialog: false,
                    commentToBeDeleted: ''
                });
            });
    }

    handleScroll(event) {
        if (event) event.preventDefault();
        if (window.screen.width < 768) return;
        if (this.state.fixedHeader && ((window.scrollY <= 20 && window.screen.width <= 1170) || (window.scrollY <= 80 && window.screen.width > 1170))) {
            this.setState({ fixedHeader: false });
        } else if (!this.state.fixedHeader && ((window.scrollY > 20 && window.screen.width <= 1170) || (window.scrollY > 80 && window.screen.width > 1170))) {
            this.setState({ fixedHeader: true });
        }
    }

    normaliseSkillsArray(skills_needed) {
        let skillsArray = [];
        if (_.size(skills_needed) > 0) {
            skillsArray = skills_needed.map((skill, index) => {
                return {
                    _id: index,
                    label: skill
                };
            });
        }
        return skillsArray;
    }

    renderApplyNowButton(eventById) {
        //  It should first check if the event is still valid to apply (2 hours before the event starts)
        //  After it should check if user is logged in
        //     No -> It should show the Apply now button, but the action on click should be different
        if (moment(eventById.config.start).diff(moment().valueOf(), 'm') < 120) return null;
        const applyBeforeDate = moment(eventById.config.start).format('MMMM DD, YYYY');
        if (!localStorage.get('userID')) {
            return (
                <div>
                    <Button
                        label="Apply now"
                        className="btnDefault btnApply"
                        icon={<Done />}
                        onClick={() => this.props.history.push({ pathname: '/sign-up', state: { action: 'apply', eventId: this.props.match.params.event_id, eventById: this.props.eventData.eventByID } })}
                    />
                    <h4 className="applyBeforeDate">Apply by {applyBeforeDate}</h4>
                </div>
            );
        }
        // Check if user has applied for event already
        if (eventById.confirmed_users && eventById.confirmed_users.find(item => item.user_id === localStorage.get('userID'))) {
            return (
                <Button
                    label={this.state.showCancel ? 'Cancel Attendance' : 'Attending'}
                    labelColor={this.state.showCancel ? '#fff' : '#2723EB'}
                    bgColor={this.state.showCancel ? '#D02035' : '#E9E8FE'}
                    icon={this.state.showCancel ? <CloseIcon /> : <Done />}
                    className="btnDefault btnAttending"
                    onClick={this.onCancelApplicationForEvent}
                    onMouseEnter={() => this.setState({ showCancel: true })}
                    onMouseLeave={() => this.setState({ showCancel: false })}
                />
            );
        }

        // Check if the event isn't full
        if (eventUtils.isFull(eventById)) return null;

        return (
            <div>
                <Button
                    label="Apply now"
                    className="btnDefault btnApply"
                    icon={<Done />}
                    onClick={this.onApplyForEvent}
                />
                <h4 className="applyBeforeDate">Apply by {applyBeforeDate}</h4>
            </div>
        );
    }

    renderEventsContainer(eventsById) {
        if (_.size(eventsById.charity.Events) > 1) {
            return (
                <div className="eventsForCharity col-xs-12">
                    <div className="defaultMainRootContentContainer mtop40">
                        <h2 className="bold mleft15">More events like this</h2>
                        {eventsById.charity.Events.map((event) => {
                            if (event._id === this.props.match.params.event_id) return null;
                            return (
                                <EventThumbnail
                                    containerClassName="col-xs-12 col-sm-6 col-md-4"
                                    event={event}
                                    key={event._id}
                                />
                            );
                        })}
                    </div>
                </div>
            );
        }
    }

    renderSaveButton(userByID) {
        // It should check if user has saved the event already
        if (userByID && userByID.saved_events.filter(item => item.event_id === this.props.match.params.event_id).length > 0) {
            return (
                <Button
                    label="Saved"
                    labelColor="#E9396E"
                    bgColor="#FDEBF0"
                    className="btnDefault btnSave"
                    icon={<Favourite />}
                    onClick={() => {
                        this.props.unSaveEvent(userByID._id, this.props.match.params.event_id)
                            .then(() => {
                                this.setState({ notificationContent: `${_.get(this.props.eventData, 'eventByID.name')} was removed from you Saved Events` });
                            });
                    }}
                />
            );
        }
        return (
            <Button
                label="Save"
                labelColor="#E9396E"
                bgColor="#F6F7F8"
                className="btnDefault btnSave"
                icon={<FavouriteBorder />}
                onClick={() => {
                    if (!userByID) {
                        this.props.history.push({ pathname: '/sign-up', state: { action: 'save', eventId: this.props.match.params.event_id, eventById: this.props.eventData.eventByID } });
                    } else if (_.get(this.props.userData, 'userByID.status') === 0) {
                        this.setState({ openVerifyEmail: true });
                    } else {
                        this.props.saveEvent(userByID._id, this.props.match.params.event_id)
                            .then(() => {
                                this.setState({
                                    notificationContent: (
                                        <div>
                                            <span>{_.get(this.props.eventData, 'eventByID.name')} was added to your Saved Events</span>
                                            <a className="mleft15" href={`/profile/${_.get(this.props.userData, 'userByID._id')}`}>View on Profile</a>
                                        </div>
                                    )
                                });
                            });
                    }
                }}
            />
        );
    }

    renderCommentsOnEvent(eventComments) {
        return eventComments.map((comment, index) => {
            return (
                <div
                    className="comment"
                    key={`comment_${index}`}
                >
                    <div className="userImageContainer">
                        <div
                            style={{ backgroundImage: `url(${comment.User.picture})` }}
                            className="userPic"
                        />
                    </div>
                    <div className="dataContainer">
                        <div className="firstRowContainer">
                            <h3 className="fullname">{`${comment.User.firstname} ${utils.formatLastName(comment.User.lastname)}`}</h3>
                            {userUtils.isAdmin(comment.user_id) &&
                                <div className="buttonsContainer">
                                    <a
                                        onClick={() => {
                                            this.setState({
                                                openDeleteCommentDialog: true,
                                                commentToBeDeleted: comment._id
                                            });
                                        }}
                                    >
                                        Delete
                                    </a>
                                </div>
                            }
                        </div>
                        <p className="message">{comment.content}</p>
                        <p className="date">{moment(comment.created).fromNow()}</p>
                    </div>
                </div>
            );
        });
    }

    renderDetailsEvent(eventByID) {
        return (
            <div>
                <div className="charityContainer col-xs-12 p0">
                    <div className="pictureContainer col-xs-2 p0">
                        <div
                            style={{ backgroundImage: `url(${eventByID.charity.picture})` }}
                            className="defaultImageStyle imgCharity"
                        />
                    </div>
                    <div className="charityContentContainer col-xs-9">
                        <h2><Link to={`/charity/${eventByID.charity._id}`}>{eventByID.charity.name}</Link></h2>
                        <p className="smallBody">{eventUtils.isPast(eventByID) ? 'was' : 'is'} looking for <strong>{eventUtils.getNumberOfVolunteersLabel(eventByID.config.volunteers_needed)} volunteers</strong></p>
                    </div>
                </div>
                {_.size(eventByID.config.skills_needed) > 0 &&
                    <div className="skillsNeededContainer col-xs-12 p0">
                        <Chips
                            containerStyle="mtop30 mbottom30"
                            values={this.normaliseSkillsArray(eventByID.config.skills_needed)}
                        />
                    </div>
                }
                <div className="eventTimeContainer col-xs-12 p0">
                    <div className="col-xs-2">
                        <IconSchedule color="#B5BDC4" style={{ width: 28, height: 28 }} className="iconSchedule" />
                    </div>
                    <div className="col-xs-10">
                        <p>{moment(eventByID.config.start).format('dddd, MMMM DD, YYYY')}</p>
                        <p>{moment(eventByID.config.start).format('hh:mm A')} - {moment(eventByID.config.end).format('hh:mm A')}</p>
                    </div>
                </div>
                <div className="locationContainer col-xs-12 p0">
                    <div className="col-xs-2">
                        <LocationIcon style={{ width: 28, height: 28 }} color="#B5BDC4" />
                    </div>
                    <div className="col-xs-10 mbottom40">
                        <p>{eventByID.location.location}</p>
                        <p>{eventByID.location.street}</p>
                        <p>{`${eventByID.location.city}, ${eventByID.location.state} ${eventByID.location.postcode}`}</p>
                        <a
                            onClick={() => this.setState({ openMap: true })}
                            className="bold"
                        >
                            View Map
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    renderEventStatusMessage(event) {
        // Method to check whether the user has joined an event
        //   Yes -> It should check if he has received his feedback already or not
        //    No -> It should render a message to alert he's missed the event
        const hasAttendedEvent = eventUtils.hasUserAttendedEvent(event, localStorage.get('userID'));
        const hasBeenReviewed = eventUtils.hasUserBeenReviewed(event, localStorage.get('userID'));

        return (
            <div className="missedEventsContainer">
                {hasAttendedEvent && !hasBeenReviewed &&
                    <>
                        <p>Feedback in Review</p>
                        <h4 className="qtdAttended">{_.size(event.confirmed_users)} volunteers attended</h4>
                    </>
                }
                {!hasAttendedEvent && !hasBeenReviewed &&
                    <>
                        <p>Sadly, you missed it, this event is over.</p>
                        <h4 className="qtdAttended">{_.size(event.confirmed_users)} volunteers attended</h4>
                    </>
                }
            </div>
        );
    }

    render() {
        if (this.props.eventData.loading || this.props.userData.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
        const { eventByID } = this.props.eventData;
        const { userByID } = this.props.userData;
        const { eventComments } = this.props.commentsData;
        const qtdVolunteersNeeded = eventByID.config.volunteers_needed - _.size(eventByID.confirmed_users);

        return (
            <div className="eventDetailsContainer col-xs-12 p0">
                {this.state.error && (
                    <div className="errorMessage">
                        <p className="error">{this.state.error}</p>
                    </div>
                )}
                {this.state.loading && (
                    <LoadingOverlay style={{ opacity: 0.8 }} />
                )}
                <div className={`eventHeader col-xs-12 ${this.state.fixedHeader ? 'fixedHeader' : ''}`}>
                    <div className="defaultMainRootContentContainer">
                        <div className="titleContainer col-xs-12 col-sm-6">
                            {eventUtils.isPast(eventByID) && <span className="pastEvent">Past Event</span>}
                            <h1>{eventByID.name}</h1>
                            <p className="smallBody causeName">{eventByID.event_causes[0].Cause.name}</p>
                        </div>
                        <div className="headerButtonsContainer col-xs-12 col-sm-6">
                            {eventUtils.isEventAdmin(eventByID) ? (
                                <div>
                                    {eventUtils.shouldReviewEvent(eventByID) ? (
                                        <Button
                                            label="Give Feedback"
                                            labelColor="#2723EB"
                                            bgColor="#fff"
                                            icon={<ChatIcon />}
                                            onClick={() => this.props.history.push({ pathname: `/review-event/${eventByID._id}/${(_.size(_.get(eventByID, 'confirmed_users')) <= 10) ? 'rate-final' : 'select'}` })}
                                            className="btnDefault"
                                        />
                                    ) : (
                                        <>
                                            <Button
                                                label="Edit Event"
                                                onClick={() => this.props.history.push({ pathname: '/event/register/details', state: { eventId: this.props.match.params.event_id, updateMode: true } })}
                                                disabled={eventUtils.isPast(eventByID)}
                                            />
                                            <Button
                                                label="Delete Event"
                                                labelColor="#2723EB"
                                                bgColor="#fff"
                                                onClick={() => this.setState({ openDeleteEventDialog: true })}
                                                className="mleft15"
                                                disabled={eventUtils.isPast(eventByID)}
                                            />
                                            {_.size(eventByID.confirmed_users) > 0 &&
                                                <Button
                                                    label="Message Attendees"
                                                    onClick={() => this.setState({ showModalSendEmailAttendees: true })}
                                                    disabled={eventUtils.isPast(eventByID)}
                                                    className="mleft15"
                                                />
                                            }
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className="contentButtons">
                                    <div className="shareContainer" onClick={() => this.setState({ openShareContainer: true })}>
                                        <img src="/icons/exitToAppIcon.svg" alt="Share icon" />
                                        <p>Share</p>
                                    </div>
                                    {eventUtils.isPast(eventByID) ? (
                                        this.renderEventStatusMessage(eventByID)
                                    ) : (
                                        <div className="flex">
                                            {this.renderSaveButton(userByID)}
                                            {this.renderApplyNowButton(eventByID)}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`content col-xs-12 p0 ${this.state.fixedHeader ? 'fixedHeader' : ''}`}>
                    <div className="defaultMainRootContentContainer mtop40">
                        <div className="leftContainer col-xs-12 col-md-7">
                            {eventByID.picture &&
                                <div
                                    className="photosContainer col-xs-12 p0"
                                    style={{ backgroundImage: `url(${eventByID.picture})` }}
                                />
                            }
                            <div className="rightContainer col-xs-12 hidden-md hidden-lg">
                                <div className="mtop30">
                                    {this.renderDetailsEvent(eventByID)}
                                </div>
                            </div>
                            <div className="aboutContainer col-xs-12 p0">
                                <h3>About this opportunity</h3>
                                <p>{eventByID.description}</p>
                            </div>
                            {_.get(eventByID, 'testimonials[0].description') !== '' &&
                                <div className="reviewsContainer col-xs-12 p0">
                                    <h3>Reviews</h3>
                                    {eventByID.testimonials.map((item, index) => {
                                        return (
                                            <div key={`testimonial-${index}`}>
                                                <div className="col-xs-1">
                                                    <i className="material-icons quotes">&#xE244;</i>
                                                </div>
                                                <div className="col-xs-10 col-sm-9 col-sm-offset-1">
                                                    <h1 className="quotesContent">{item.description}</h1>
                                                    <p className="smallBody quotesAuthor">{`— ${item.author}`}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                            {localStorage.get('userID') &&
                                <div className="attendeesContainer col-xs-12 p0">
                                    <div className="attendeesHeader">
                                        <h3>Attendees ∙ {_.size(eventByID.confirmed_users)}</h3>
                                        {qtdVolunteersNeeded > 0 &&
                                            <p>{`${qtdVolunteersNeeded} more needed`}</p>
                                        }
                                    </div>
                                    <div className="membersContentContainer">
                                        {_.map(eventByID.confirmed_users, (item) => {
                                            return (
                                                <PersonThumbnail
                                                    user={item.User}
                                                    key={item.User._id}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                            {_.get(eventByID.faqs[0], 'question') !== '' &&
                                <div className="faqsContentContainer col-xs-12 p0">
                                    <h3>FAQ</h3>
                                    <List
                                        className="defaultListStyles"
                                        values={eventByID.faqs.map((item) => {
                                            return {
                                                name: <p className="m0">{item.question}</p>,
                                                component: (
                                                    <p
                                                        className="m0 p15 answer"
                                                        key={item.question}
                                                    >
                                                        {item.answer}
                                                    </p>
                                                ),
                                            };
                                        })}
                                    />
                                </div>
                            }
                            {localStorage.get('userID') &&
                                <div className="commentsContainer col-xs-12 p0">
                                    <h3>Comments</h3>
                                    <div className="commentsBox">
                                        <Input
                                            name="comment"
                                            placeholder="Leave a comment"
                                            helpText="Comment or ask a question about the event"
                                            type="text"
                                            value={this.state.comment}
                                            onChange={value => this.setState({ comment: value })}
                                            rowsMax={2}
                                            multiLine
                                        />
                                        <Button
                                            label="Comment"
                                            className="btnDefault btnComment"
                                            onClick={this.onCommentEvent}
                                            disabled={!this.state.comment}
                                        />
                                    </div>
                                    <div className="commentContent">
                                        {eventComments && this.renderCommentsOnEvent(eventComments)}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="rightContainer hidden-xs hidden-sm col-md-offset-1 col-md-4">
                            {this.renderDetailsEvent(eventByID)}
                        </div>
                    </div>
                    {this.renderEventsContainer(eventByID)}
                </div>
                <Modal
                    open={this.state.openMap}
                    onClose={() => this.setState({ openMap: false })}
                    className="modalMap"
                    bodyClassName="modalMapContent"
                >
                    <Map
                        zoom={15}
                        lat={eventByID.location.latitude}
                        lng={eventByID.location.longitude}
                    />
                </Modal>
                <ShareContainer
                    open={this.state.openShareContainer}
                    onClose={() => this.setState({ openShareContainer: false })}
                    url={window.location.href}
                />
                <VerifyEmail
                    open={this.state.openVerifyEmail}
                    onClose={() => this.setState({ openVerifyEmail: false })}
                    user={_.get(this.props.userData, 'userByID')}
                />
                <ModalCancelAttendance
                    open={this.state.showModalCancelAttendance}
                    onClose={() => this.setState({ showModalCancelAttendance: false })}
                    event={eventByID}
                />
                <ModalSendEmailAttendees
                    open={this.state.showModalSendEmailAttendees}
                    onClose={(messageSent) => {
                        this.setState({ showModalSendEmailAttendees: false });
                        if (messageSent) {
                            this.setState({
                                notificationContent: <span>Your message has been sent!</span>
                            });
                        }
                    }}
                    event_id={eventByID._id}
                />
                <Notification content={this.state.notificationContent} />
                <Dialog
                    title="Are you sure you want to delete this event?"
                    subtitle="The event won't be accessible by anyone anymore"
                    onConfirm={this.onDeleteEvent}
                    onCancel={() => this.setState({ openDeleteEventDialog: false })}
                    loading={this.state.deleteEventLoading}
                    open={this.state.openDeleteEventDialog}
                    onRequestClose={() => this.setState({ openDeleteEventDialog: false })}
                />
                <Dialog
                    title="Are you sure you want to delete your comment?"
                    onConfirm={this.onDeleteComment}
                    onCancel={() => this.setState({ openDeleteCommentDialog: false })}
                    open={this.state.openDeleteCommentDialog}
                    onRequestClose={() => this.setState({ openDeleteCommentDialog: false })}
                />
            </div>
        );
    }
}

const eventByIDContainer = graphql(fetchEventById, {
    name: 'eventData',
    options: ({ match }) => ({
        variables: { id: match.params.event_id },
    }),
});

const UserDetailsByIdContainer = graphql(fetchUserById, {
    name: 'userData',
    options: () => ({
        variables: { id: localStorage.get('userID') },
    }),
});

const fetchEventCommentsContainer = graphql(fetchEventComments, {
    name: 'commentsData',
    options: ({ match }) => ({
        variables: { id: match.params.event_id },
    }),
});

const saveEventContainer = graphql(saveEvent, {
    props: ({ mutate }) => ({
        saveEvent: (id, event_id) => mutate({
            variables: { id, event_id },
            refetchQueries: [{
                query: fetchUserById,
                variables: { id: localStorage.get('userID') },
            }]
        }),
    }),
});

const unSaveEventContainer = graphql(unSaveEvent, {
    props: ({ mutate }) => ({
        unSaveEvent: (id, event_id) => mutate({
            variables: { id, event_id },
            refetchQueries: [{
                query: fetchUserById,
                variables: { id: localStorage.get('userID') },
            }]
        }),
    }),
});

const applyForEventContainer = graphql(applyForEvent, {
    props: ({ mutate }) => ({
        applyForEvent: (id, user_id) => mutate({
            variables: { id, user_id },
            refetchQueries: [{
                query: fetchEventById,
                variables: { id },
            }]
        }),
    }),
});

const cancelApplicationForEventContainer = graphql(cancelApplicationForEvent, {
    props: ({ mutate }) => ({
        cancelApplicationForEvent: (id, user_id) => mutate({
            variables: { id, user_id },
            refetchQueries: [{
                query: fetchEventById,
                variables: { id },
            }]
        }),
    }),
});

const commentEventContainer = graphql(commentEvent, {
    props: ({ mutate }) => ({
        commentEvent: (id, comment) => mutate({
            variables: { id, comment },
            refetchQueries: [{
                query: fetchEventComments,
                variables: { id },
            }]
        }),
    }),
});

const deleteEventContainer = graphql(deleteEvent, {
    props: ({ mutate }) => ({
        deleteEvent: id => mutate({
            variables: { id }
        }),
    }),
});

const deleteCommentEventContainer = graphql(deleteCommentEvent, {
    props: ({ mutate }) => ({
        deleteCommentEvent: (event_id, comment_id) => mutate({
            variables: { event_id, comment_id },
            refetchQueries: [{
                query: fetchEventComments,
                variables: { id: event_id },
            }]
        }),
    }),
});

export default withRouter(_.flowRight(
    eventByIDContainer,
    saveEventContainer,
    fetchEventCommentsContainer,
    unSaveEventContainer,
    UserDetailsByIdContainer,
    applyForEventContainer,
    cancelApplicationForEventContainer,
    commentEventContainer,
    deleteEventContainer,
    deleteCommentEventContainer
)(EventDetails));
