import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SingleRadioButtonCard from './card';

const RadioCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RadioButtonCards = ({ active, name, cards, onChange }) => {
  const [activeRadio, setActiveRadio] = useState(active);

  useEffect(() => {
    onChange(activeRadio);
  }, [activeRadio]);

  return (
    <RadioCardsContainer>
      {cards.map(card => (
        <SingleRadioButtonCard
          key={card.id}
          name={name}
          {...card}
          active={activeRadio}
          onClick={setActiveRadio}
        />
      ))}
    </RadioCardsContainer>
  );
};

export default RadioButtonCards;
