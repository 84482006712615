import gql from 'graphql-tag';

export default gql`
  mutation updateMultiTaskTimeSheet($taskSubmissionId: ID!) {
    updateMultiTaskTimesheet(_task_submission_id: $taskSubmissionId) {
      _id
      _task_submission_id
      name
      start
      end
      status
    }
  }
`;
