import React from 'react';
import { IconButton as MaterialIconButton } from 'material-ui';
import './styles.scss';

export const IconButton = React.forwardRef(({ icon, onClick, style, className, filled = false }, ref) => (
  <div className={`${filled ? 'iconFilled' : ''} ${className}`} ref={ref}>
    <MaterialIconButton onClick={onClick} className="iconButton">
      <img src={icon} alt="button icon" />
    </MaterialIconButton>
  </div>
));
