import React, { useEffect, useState } from 'react';
import NotificationIcon from 'assets/svg/notifications.svg';
import Popover from 'material-ui/Popover';
import { ModalBase } from '../ModalBase/view';
import './styles.scss';

export const NotificationsPopover = ({ open, anchorEl, requestClose }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onRequestClose={requestClose}
      className="notificationsPopover"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      targetOrigin={{ horizontal: 'right', vertical: 'top' }}
      useLayerForClickAway
    >
      <h3 className="textBlack fontPoppins mtop0 mbottom24">Notifications</h3>
      <div className="divider" />
      <div className="notificationsEmpty">
        <img src={NotificationIcon} alt="Notification Icon" />
        <h3 className="textBlack fontPoppins mtop12 mbottom12">No Notifications yet</h3>
        <div>
          It&#39;s only just the beginning!
        </div>
      </div>
    </Popover>
  );
};
