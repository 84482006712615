import React from 'react';
import '../styles/legalStyles.css';

function PrivacyPolicy() {
  return (
    <div className="legalContainerNew">
      <div className="header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="mainContainer">
        <div className="content">
          <h1 id="privacy">Privacy Policy</h1>
          <p>
            This Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us, or otherwise collected by us, offline or online, including
            through this website (Site). In this Privacy Policy we, us or our means Voluntech Pty Ltd ACN 623 358 663 trading as Worthy®.
          </p>
          <p>By accessing or using our Site, you agree to be found by our Terms of Service, Terms of Use and this Privacy Policy.</p>
          <p>
            We are committed to protecting your privacy through compliance with the Privacy Act 1988 (Cth) (“Privacy Act”), the Australian Privacy Principles (“APP”), the General
            Data Protection Regulation (“GDPR”) and any other privacy laws applicable to our platform.
          </p>
          <h2>Personal information</h2>
          <p>
            <span className="bold">Personal information:</span> The types of personal information we may collect about you include:
          </p>
          <ul>
            <li>your name;</li>
            <li>images of you;</li>
            <li>details regarding your volunteer hours;</li>
            <li>your contact details, including email address, mailing address, street address and/or telephone number;</li>
            <li>your employer and profession;</li>
            <li>your age and/or date of birth;</li>
            <li>your demographic information, such as postcode;</li>
            <li>your preferences and/or opinions, including your preferences regarding charitable causes and opinions for review purposes;</li>
            <li>information you provide to us through customer surveys;</li>
            <li>details of products and services we have provided to you and/or that you have enquired about, and our response to you;</li>
            <li>
              your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition sources, search queries and/or browsing
              behaviour;
            </li>
            <li>
              information about your access and use of our Site, including through the use of Internet cookies, your communications with our Site, the type of browser you are
              using, the type of operating system you are using and the domain name of your Internet service provider;
            </li>
            <li>
              additional personal information that you provide to us, directly or indirectly, through your use of our Site, associated applications, associated social media
              platforms and/or accounts from which you permit us to collect information; and
            </li>
            <li>any other personal information requested by us and/or provided by you or a third party.</li>
          </ul>
          <p>We may collect these types of personal information directly from you or from third parties.</p>
          <h2>Collection and use of personal information</h2>
          <p>
            <span className="bold">Legal basis of our Data Processing</span>: We collect, store, use and disclose your personal information through lawful and fair means, so we can
            perform our business activities. The legal basis for our processing of your personal information and other data is based on:
          </p>
          <ul>
            <li>your consent through your voluntary submission of the information and agreeing to these terms or ticking “I agree” button on our platform;</li>
            <li>the information being necessary for the performance of a contract to which you are a party (primary purpose);</li>
            <li>for carrying out pre-contractual measures; and/or</li>
            <li>any other legitimate interests as detailed in this Privacy Policy.</li>
          </ul>
          <p>We may collect, hold, use and disclose personal information for the following purposes :</p>
          <ul>
            <li>to provide your contact details to charities that you follow, using our platform, to allow that charity to send you information or newsletter updates;</li>
            <li>to enable you to access and use our Site, associated applications and associated social media platforms;</li>
            <li>to contact and communicate with you;</li>
            <li>for internal record keeping and administrative purposes;</li>
            <li>
              for analytics, market research and business development, including to operate and improve our Site, associated applications and associated social media platforms;
            </li>
            <li>to run competitions and/or offer additional benefits to you;</li>
            <li>
              for advertising and marketing, including to send you notifications regarding charities you are interested in or follow, volunteering opportunities relevant to your
              skill set and experience or to send you other information about third parties that we consider may be of interest to you;
            </li>
            <li>to verify your identity, investigate any complaints related to you, or if we have reason to suspect that you are in breach of any of our Terms and Conditions;</li>
            <li>managing, researching and developing our products and services;</li>
            <li>to comply with our legal obligations and resolve any disputes that we may have; and</li>
            <li>if you apply for employment with us, to consider your employment application.</li>
          </ul>
          <h2>Disclosure of personal information to third parties</h2>
          <p>We may disclose personal information to:</p>
          <ul>
            <li>charities listed on our platform that you follow for the purpose of enabling them to provide you with updates and notifications;</li>
            <li>
              third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage,
              web-hosting and server providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment
              systems operators;
            </li>
            <li>our employees, contractors and/or related entities;</li>
            <li>our existing or potential agents or business partners;</li>
            <li>sponsors or promoters of any competition we run;</li>
            <li>anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;</li>
            <li>credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to you;</li>
            <li>
              courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in
              order to establish, exercise or defend our legal rights; and
            </li>
            <li>
              third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to you. This may include parties
              located, or that store data, outside of Australia including in the United States of America;
            </li>
            <li>
              third parties to collect and process data, such as Google Analytics, Facebook or other relevant businesses. This may include parties that store data outside of
              Australia including in the United States of America.
            </li>
          </ul>
          <p>
            Your personal information may be transferred overseas or stored overseas for a variety of reasons (such as to our data hosting and IT service providers based overseas).
            If your personal information is sent to a recipient in a country with data protection laws which are at least substantially similar to the APP, and where there are
            mechanisms available to you to enforce protection of your personal information under that overseas law, we will not be liable for a breach of the APP if your personal
            information is mishandled in that jurisdiction. If your personal information is transferred to a jurisdiction which does not have data protection laws as comprehensive
            as Australia&apos;s, we will take reasonable steps to secure a contractual commitment from the recipient to handle your information in accordance with the APP.
          </p>
          <p>
            <span className="bold">Automated Individual Decision-Making</span>: If you reside in the European Union or European Free Trade Association (EFTA) States, you shall have
            the right not to be subject to a decision based solely on automated processing, including profiling, as long as the decision is not necessary for entering into, or the
            performance of, a contract between us, or is not authorised by European Union or EFTA Member State law to which we are subject, or is not based on your explicit
            consent. If you wish to exercise your rights please contact us.
          </p>
          <h2>How we treat personal information that is also sensitive information</h2>
          <p>
            Sensitive information is a sub-set of personal information that is given a higher level of protection under the Australian Privacy Principles. Sensitive information
            means information relating to your racial or ethnic origin, political opinions, religion, trade union or other professional associations or memberships, philosophical
            beliefs, sexual orientation or practices, criminal records, health information or biometric information.
          </p>
          <p>We will not collect sensitive information about you without first obtaining your consent.</p>
          <p>Sensitive information may also be used or disclosed if required or authorised by law.</p>
          <h2>Your rights and controlling your personal information</h2>
          <p>
            <span className="bold">Choice and consent:</span> Please read this Privacy Policy carefully. By providing personal information to us, you consent to us collecting,
            holding, using and disclosing your personal information in accordance with this Privacy Policy.
          </p>
          <p>
            <span className="bold">Information from third parties:</span> If we receive personal information about you from a third party, we will protect it as set out in this
            Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person&apos;s consent to
            provide the personal information to us.
          </p>
          <p>
            <span className="bold">Restrict:</span> You may contact us or update your settings to restrict the collection or use of your personal information or delete your
            personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by
            contacting us using the details below.
          </p>
          <p>
            <span className="bold">Access:</span> You may request details of the personal information that we hold about you. An administrative fee may be payable for the provision
            of such information. In certain circumstances, as set out in the Privacy Act 1988 (Cth), we may refuse to provide you with personal information that we hold about you.
          </p>
          <p>
            <span className="bold">Correction:</span> If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please
            contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.
          </p>
          <p>
            <span className="bold">Complaints:</span> If you believe that we have breached the Australian Privacy Principles and wish to make a complaint, please contact us using
            the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the
            outcome of our investigation and the steps we will take to deal with your complaint.
          </p>
          <p>
            <span className="bold">Unsubscribe:</span> To unsubscribe from our e-mail database or opt-out of communications (including marketing communications), please contact us
            using the details below or opt-out using the opt-out facilities provided in the communication.
          </p>
          <h2>Storage and security</h2>
          <p>
            <span className="bold">Anonymity, Destruction and De-identification</span>: We will allow our users to transact with us anonymously or by using a pseudonym wherever
            that is lawful and practicable.
          </p>
          <p>
            We destroy or permanently de-identify personal information which is no longer needed in accordance with the APP 11, unless we are otherwise required or authorised by
            law to retain the information for a period of time.
          </p>
          <p>
            In some circumstances, we will not attempt to match de-identified or anonymous data collected through surveys or such online devices as &quot;cookies&quot;, without
            your consent. By providing us your personal information, you consent and agree to our cookies-related activities as set out under this Policy. below.
          </p>
          <p>
            <span className="bold">Data Quality and Security</span>: We take reasonable precautions to ensure that the personal information we collect, use, store and disclose is
            accurate, complete, relevant and up-to-date. However, the accuracy of that information depends to a large extent on the information users provide. That&apos;s why we
            recommend that you:
          </p>
          <ul>
            <li>let us know if there are any errors in your personal information</li>
            <li>keep us up-to-date with changes to your personal information such as your name or address.</li>
          </ul>
          <p>
            We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable
            physical, electronic and managerial procedures to safeguard and secure the personal information and protect it from misuse, interference, loss and unauthorised access,
            modification and disclosure.
          </p>
          <p>
            We cannot guarantee the security of any information that is transmitted to or by us over the Internet. The transmission and exchange of information is carried out at
            your own risk. Although we take measures to safeguard against unauthorised disclosures of information, we cannot assure you that the personal information we collect
            will not be disclosed in a manner that is inconsistent with this Privacy Policy. In the event of a data breach, we will attend to the reporting requirements that apply
            to us. We cannot accept responsibility for the misuse, loss or unauthorised access to, your personal information where the security of information is not within our
            control.
          </p>
          <p>
            If you reside in Australia, in the event that there is a data breach and we are required to comply with NDBS of the Privacy Act, we will take all reasonable steps to
            contain the suspected or known breach where possible and follow the process set out in this clause.
          </p>
          <p>
            If we have reasonable grounds to suspect that the data breach is likely to result in serious harm to any individuals involved, then we will take all reasonable steps to
            ensure an assessment is completed within 30 days of the breach or sooner if possible. We will follow the guide published by the Office of the Australian Information
            Commissioner (if any) in making this assessment. If we reasonably determine that the data breach is not likely to result in serious harm to any individuals involved or
            any remedial action we take is successful in making serious harm no longer likely, then no notification or statement will be made.
          </p>
          <p>
            In the case of a personal information breach that affects an EU citizen, we shall without undue delay and where feasible, not later than 72 hours after having become
            aware of the breach, notify you and the Information Commissioner’s Office, unless the breach is unlikely to result in a risk to your right and privacy.
          </p>
          <h2>Cookies and web beacons</h2>
          <p>
            We may use cookies on our Site from time to time. Cookies are text files placed in your computer&apos;s browser to store your preferences. Cookies, by themselves, do
            not tell us your email address or other personally identifiable information. However, they do allow third parties, such as Google and Facebook, to cause our
            advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our Site with personal
            information, this information may be linked to the data stored in the cookie.
          </p>
          <p>
            If you do not want to activate cookies, you can opt-out of receiving them by amending the settings of your internet browser, although you may find that some parts of
            our platform will consequently have limited functionality and personalisation if you do so.
          </p>
          <p>
            We may use web beacons on our Site from time to time. Web beacons (also known as Clear GIFs) are small pieces of code placed on a web page to monitor the visitor’s
            behaviour and collect data about the visitor’s viewing of a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a
            cookie to the browser of a visitor viewing that page.
          </p>
          <h2>Third Party Analytics Tools</h2>
          <p>
            Third-party analytics tools collect non-personal information such as how often you visit our platform, the web pages you visit, add-ons, and other analytics data that
            assists us in improving our services. These tools might include Google Analytics, Google AdWords conversion tracking, Google Tag Manager, or Facebook Ads conversion
            tracking. Third-party cookies may be placed on your computer by a service provider to us, for example, to help us understand how our platform is being used. Third-party
            cookies may also be placed on your device by our business partners to advertise the service to you elsewhere on the Internet. We reserve our rights to modify, add or
            remove any third-party analytics tools. By using our Site, you consent to the processing of any non-personal data these tools will collect in the way and for the
            purposes described above.
          </p>
          <p className="m0">Links to other websites</p>
          <p>
            Our Site may contain links to other websites. We do not have any control over those websites and we are not responsible for the protection and privacy of any personal
            information which you provide whilst visiting those websites. Those websites are not governed by this Privacy Policy.
          </p>
          <h2>Amendments</h2>
          <p>
            We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy on our Site. We recommend you check our Site regularly to
            ensure you are aware of our current Privacy Policy.
          </p>
          <h1 id="contact">Contact</h1>
          <p className="bold">For any questions or notices about your rights, personal information or account settings, please contact our Privacy Officer at :</p>
          <p>Voluntech Pty Ltd ACN 623 358 663 trading as Worthy®</p>
          <p>
            <span className="bold">Email:</span> mail@testenvironment1.com
          </p>
          <p className="bold">For any questions about privacy issues and protecting your privacy, please visit your local privacy officer:</p>
          <p>
            <span className="bold">Australia:</span> Office of the Australian Information Commissioner (OAIC) at{' '}
            <a href="www.oaic.gov.au/privacy/privacy-complaints">www.oaic.gov.au/privacy/privacy-complaints</a>
          </p>
          <p>
            <span className="bold">Europe</span>: European Data Protection Supervisor (EDPS) at https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en
          </p>
          <p>
            <span className="bold">Last update:</span> 23 October 2022
          </p>
        </div>
        <div className="sidebar">
          <a href="#privacy">Privacy Policy</a>
          <a href="#contact">Contact</a>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
